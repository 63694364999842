import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { CommingSoonModule } from 'src/app/comming-soon/comming-soon.module';
import { GeneralService } from 'src/app/services/general.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-echeck-applications',
  templateUrl: './add-echeck-applications.component.html',
  styleUrls: ['./add-echeck-applications.component.scss']
})
export class AddEcheckApplicationsComponent implements OnInit {

  form: FormGroup;
  @Output() fileSelected = new EventEmitter<File>();
  @Output() fileError = new EventEmitter<string>();
  fileExtension: string;
  showOwnerForm: boolean = false;
  breadCrumbItems: Array<{}>;
  stateID: any;
  StateArray: any[] = []
  currentUser: any;
  submitted: boolean;
  file: Array<any>;
  isLoading: boolean;
  constructor(private Fb: FormBuilder, private gs: GeneralService, private router: Router) {
    this.currentUser = this.gs.getcurrentUser();
  }

  ngOnInit() {
    this.fetchstate()
    this.submitted = false
    this.breadCrumbItems = [{ label: 'E-Check Dashboard', path: '/e-check/echeck-dashboard' },
    { label: 'Add Application', path: '/', active: true }];
    this.form = this.Fb.group({
      PropertyOwners: this.Fb.array([
        this.initOwner()
      ]),
      AppNo: new FormControl('', Validators.required),
      IsOwnerSame: new FormControl('False', Validators.required),
      File: new FormControl(null),
      StateID: new FormControl(null, Validators.required),
      // owner: new FormControl('',[Validators.required,Validators.pattern(/^\S.*$/)])
      // owners: this.Fb.array([
      //   this.Fb.control('',[Validators.pattern(/^\S.*$/),Validators.required])
      // ])
    });
  }

  isOwnerNameEmpty(index: number): boolean {
    const ownerNameControl = this.form.controls.PropertyOwners['controls'][index].get('OwnerName');
    return !ownerNameControl.value || ownerNameControl.value.trim() === '';
  }

  isOwnerNameTouched(index: number): boolean {
    const ownerNameControl = this.form.controls.PropertyOwners['controls'][index].get('OwnerName');
    return ownerNameControl.touched;
  }

  get owners() {
    return this.form.get('owners') as FormArray;
  }
  onFileChange(event: any) {
    const files: FileList = event.target.files;
    if (files.length === 0) {
      this.fileError.emit('Please add a file.');
      return;
    }
    const file: File = files[0];
    const allowedExtensions = ['jpg', 'jpeg', 'png', 'pdf'];
    const fileExtension = file.name.split('.').pop().toLowerCase();
    if (!allowedExtensions.includes(fileExtension)) {
      this.fileError.emit('Invalid file format. Allowed formats: JPG, JPEG, PNG, PDF');
      return;
    }
    this.fileSelected.emit(file);
  }
  initOwner(i?) {
    if (i && i !== undefined) {
      return this.Fb.group({
        OwnerName: new FormControl(i.OwnerName, Validators.required),
      });
    } else {
      return this.Fb.group({
        OwnerName: new FormControl('', Validators.required)
      });
    }
  }
  removeOwner(i: number) {
    const control = this.f.PropertyOwners as FormArray;
    control.removeAt(i);
  }
  addOwner() {
    // Need to check before adding owner same ownene name not allowed
    const control = this.f.PropertyOwners as FormArray;
    control.push(this.initOwner());

  }

  onchange(event: any) {

    if (this.file.length) {
      const unsupportedFiles = [];
      const supportedFiles = [];
      for (let i = 0; i < this.file.length; i++) {
        const filetype = this.file[i].type;
        if (
          filetype.toLowerCase() !== 'application/pdf' &&
          filetype.toLowerCase() !== 'image/png' &&
          filetype.toLowerCase() !== 'image/jpeg' &&
          filetype.toLowerCase() !== 'image/jpg'
        ) {
          unsupportedFiles.push(this.file[i].name);
        }
        else {
          supportedFiles.push(this.file[i])
        }
      }
      if (unsupportedFiles.length > 0) {
        Swal.fire({
          title: 'Error',
          text: `${unsupportedFiles.join(', ')} File(s) Are Not Supported`,
          type: 'error',
          showConfirmButton: false,
          timer: 3000
        }).then(() => {
          this.file = supportedFiles;
        });
      } else {
        // Code to execute when all file types are supported
        // ...
      }
    }
  }

  Owners() {
    this.showOwnerForm = true
  }

  setform(fileName, filetype, extension) {
    if (
      (filetype.toLowerCase() === "image/jpeg" && (extension.toLowerCase() === "jpg" || extension.toLowerCase() === "jpeg")) ||
      (filetype.toLowerCase() === "image/gif" &&
        extension.toLowerCase() === "gif") ||
      (filetype.toLowerCase() === "image/png" &&
        extension.toLowerCase() === "png")
    ) {

      this.form.controls.FileType.setValue("Photo");
      this.form.controls.FileName.setValue(fileName);
      this.fileExtension = extension.toLowerCase();

    } else if (
      filetype.toLowerCase() === "application/pdf" &&
      extension.toLowerCase() === "pdf"
    ) {

      console.log("File type", filetype)
      this.form.controls.FileType.setValue("pdf");
      this.form.controls.FileName.setValue(fileName);
      this.fileExtension = extension.toLowerCase();
    } else {
      this.form.controls.File.setValue([]);
      Swal.fire({
        title: `Error`,
        text: `${extension} File Are Not Supported`,
        type: "error",
        showConfirmButton: false,
        timer: 3000,
      });
    }

  }

  fetchstate() {
    this.isLoading = true
    this.gs
      .statesList(this.currentUser.UserID)
      .pipe(first())
      .subscribe((data: any) => {
        this.isLoading = false;
        if (data.error) {
          return;
        } else {
          this.StateArray = data.data;
        }
      });
  }
  get f() { return this.form.controls; }

  isOwnerNameDuplicate(index: number, owners: FormArray, ownerName: string): boolean {
    const lowerCaseOwnerName = ownerName.toLowerCase();
    
    for (let i = 0; i < index; i++) {
      const lowerCaseName = owners.at(i).value.OwnerName.toLowerCase();
      
      if (lowerCaseName === lowerCaseOwnerName) {
        return true;
      }
    }
    
    return false;
  }

  
  

  save() {
    this.submitted = true;
    const input = new FormData();
   
    // this.isLoading = true;
    for (var i = 0; i < this.file.length; i++) {
      input.append("files", this.file[i], this.file[i].name);
    }
    if (this.form.controls.PropertyOwners['controls'].length) {
      let ownerNames = [];

      this.form.controls.PropertyOwners['controls'].forEach((control) => {
        let ownerName = control.get('OwnerName').value;
        ownerNames.push(ownerName);
        input.append('Owners', ownerName);
      });
      input.append('ApplicationID', this.form.controls.AppNo.value);
      input.append('StateID',this.form.controls.StateID.value)
    }

    this.gs.addEcheckApplication(input).subscribe((res) => {
      this.isLoading = false;
      Swal.fire({
        title: `Application Added`,
        text: `Successfuly added E-check Application`,
        type: 'success',
        showConfirmButton: false,
        timer: 1500
      });
      this.router.navigate(['/e-check/echeck-dashboard']);
    },
      ((error) => {
        this.isLoading = false;
        Swal.fire({
          title: 'Failed ',
          text: 'Faild to Add Application',
          type: 'error',
          showConfirmButton: false,
          timer: 1500
        })
      }))
      if(this.form.valid && this.file.length > 1){
        this.submitted = false;
      }
  }

}
