import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutUsComponent } from '../about-us/about-us.component';
import { ContactUsComponent } from '../contact-us/contact-us.component';
import { HelpComponent } from '../help/help.component';

const routes: Routes = [

  {
    path: 'aboutUs',
    component: AboutUsComponent
  },

  {
    path: 'contactUs',
    component: ContactUsComponent
  },
  {
    path: 'help',
    component: HelpComponent
  },
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LayoutsRoutingModule { }
