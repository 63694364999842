import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-confim-dialog',
  templateUrl: './confim-dialog.component.html',
  styleUrls: ['./confim-dialog.component.scss']
})
export class ConfimDialogComponent implements OnInit {
  title:string;
  acceptBtn:string;
  cancelBtn:string;
  data:any
  constructor() { }

  ngOnInit() {
    console.log("data",this.data);
    if(this.data){
      this.title = this.data.title;
      this.acceptBtn  = this.data.acceptBtn
      this.cancelBtn = this.data.cancelBtn;
    }
  }

}
