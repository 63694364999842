import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { GeneralService } from 'src/app/services/general.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-property-report-form',
  templateUrl: './property-report-form.component.html',
  styleUrls: ['./property-report-form.component.scss']
})
export class PropertyReportFormComponent implements OnInit {

  breadCrumbItems:any
  isLoading:boolean
  public form: FormGroup;
  applicationID:string;
  PropertyID:string;
  constructor(private Fb:FormBuilder, private service:GeneralService, private router:Router) { 
    let a = router.getCurrentNavigation().extras;
    if(a){
      if(a.state){
        console.log("s. at5ate",a.state);
        this.PropertyID = a.state.PropertyID;
        this.applicationID = a.state.AppID
      }
    }
    if(localStorage.getItem('AppID')){
      this.applicationID = localStorage.getItem('AppID');
    }
    if(localStorage.getItem('PropertyID')){
      this.PropertyID = localStorage.getItem('PropertyID');
    }
    this.breadCrumbItems = [{ label: 'Dashboard', path: 'loan' }, { label: 'PropertyForm', path: '/loan/property' }]
  }


  ngOnInit() {
    this.form = this.Fb.group({
      // applicationID: new FormControl(''),
      // PropertyID: new FormControl(''),
      Cultivator_Name:false,
      Owner_Name:false,
      Khewat_No:false,
      Irrigation_Source:false,
      remarks: (''),
      Khasra_No:false,
      Other_Remarks:false,
      result:false,
      Khatoni_No:false,
      Type_of_Land:false
    })
  }
  save(){
    this.isLoading = true 
    let obj = { AppID:this.applicationID, PropertyID : this.PropertyID};
    console.log("AppID",this.applicationID)
    let data = Object.assign(obj, this.form.value);
    data['Cultivator_Name'] =  this.form.controls['Cultivator_Name'].value ? 1:0;
    data['Owner_Name'] =  this.form.controls['Owner_Name'].value ? 1:0;
    data['Khewat_No'] =  this.form.controls['Cultivator_Name'].value ? 1:0;
    data['Other_Remarks'] =  this.form.controls['Other_Remarks'].value ? 1:0;
    data['Khatoni_No'] =  this.form.controls['Khatoni_No'].value ? 1:0;
    data['Type_of_Land'] =  this.form.controls['Type_of_Land'].value ? 1:0;
    data['Irrigation_Source'] =  this.form.controls['Irrigation_Source'].value ? 1:0;
    data['Khasra_No'] =  this.form.controls['Khasra_No'].value ? 1:0;
    data['result'] = this.form.controls['result'].value ? 'Change' : 'No Change';
   
    this.service.addEcheckPropertyReport(data).subscribe((data)=>{
      this.isLoading = false; 
      Swal.fire({
        title: `Property Added`,
        text: `Successfuly added Property Report`,
        type: 'success',
        showConfirmButton: false,
        timer:1500
      })
      this.router.navigate(['/e-check/view-echeck-application'])
    },(error)=>{
      this.isLoading = false;
      Swal.fire({
        title: error.error_code,
        text: error.error,
        type: 'error',
        showConfirmButton: false,
        timer:1500
      })
    })

    const formdData = new FormData();
    formdData.append('AppID', this.applicationID);
    formdData.append('PropertyID', this.applicationID);
    console.log("data",data);
    console.log("from ",this.form.value);
    this.form.reset();
  }

}
