import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { PosRoutingModule } from './pos-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { NgbProgressbarModule, NgbAccordionModule, NgbDatepickerModule, NgbModalModule, NgbAlertModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { DataTablesModule } from 'angular-datatables';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { NgApexchartsModule } from 'ng-apexcharts';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxEditorModule } from 'ngx-editor';
import { AngularMaterialModule } from '../angular-material/angular-material.module';
import { PinchZoomModule } from '../bank-module/pinch-zoom/pinch-zoom.module';
import { LayoutsModule } from '../layouts/layouts.module';
import { SharedModule } from '../shared/shared.module';
import { UIModule } from '../shared/ui/ui.module';
import { AddSharesPerOWnerFormComponent } from './add-shares-per-owner-form/add-shares-per-owner-form.component';
import { ViewPOSApplicationComponent } from './view-posapplication/view-posapplication.component';
import { AddPosApplicationComponent } from './add-pos-application/add-pos-application.component';

@NgModule({
  declarations: [DashboardComponent, AddSharesPerOWnerFormComponent, ViewPOSApplicationComponent, AddPosApplicationComponent],
  imports: [
    CommonModule,
    SharedModule,
    CommonModule,
    LayoutsModule,
    UIModule,
    FormsModule,
    NgSelectModule,
    NgbProgressbarModule,
    NgApexchartsModule,
    NgbAccordionModule, 
    NgbDatepickerModule,
    DataTablesModule,
    NgbModalModule,
    NgbAlertModule,
    NgbDropdownModule,
    ReactiveFormsModule,
    FormsModule,
    AutocompleteLibModule,
    FileUploadModule,
    PDFExportModule,
    PdfViewerModule,
    NgxEditorModule,
    AngularMaterialModule,
    PinchZoomModule,
    PdfViewerModule,
    SharedModule,
    CommonModule,
    PosRoutingModule
  ],
  providers : [DatePipe]
})
export class PosModule { }
