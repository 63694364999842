import { ActivatedRoute, Router } from '@angular/router';
import { GeneralService } from 'src/app/services/general.service';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material';
import Swal from 'sweetalert2';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { WindowRef } from '@agm/core/utils/browser-globals';
import { AssignLawyerComponent } from '../assign-lawyer/assign-lawyer.component';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';


class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Component({
  selector: 'app-applicant-dashboard',
  templateUrl: './applicant-dashboard.component.html',
  styleUrls: ['./applicant-dashboard.component.scss']
})
export class ApplicantDashboardComponent implements OnInit, OnDestroy {
  breadCrumbItems: Array<{}>;
  interval;
  reviewd = 0;
  lawyerInfo: any;
  IsLawyerLoaded: boolean;
  index = 0;
  PVRData: any;
  array = ['2425',];
  PVRDetailsLoaded: boolean;
  statusList = ['Village Level Officer has received the request',
    'Village Level Officer has fetched the latest Govt. Land Records', 'Latest Land Record Document being uploaded',
    'Documents are under review by PropLegit', 'iPVR getting finalized'];
  meth: any;
  totaldocument = 0;
  pending = 0;
  received = 0;
  AppID: any;
  dtOptions: DataTables.Settings = {};
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtTrigger = new Subject<any>();
  propertyDocumentData: any;
  loaded: boolean;
  applicationData: any;
  revenueRadialChart: any;
  LawyerDetails: any;
  currentUser: any;
  ApplicationStatus:string;
  constructor(
    private service: GeneralService, private Route: ActivatedRoute, private router: Router, public dialog: MatDialog,
    private formBuilder: FormBuilder, private http: HttpClient) {
    this.AppID = this.Route.snapshot.params.id;
    let a = this.router.getCurrentNavigation().extras;
    if(a){
      if(a.state){
        this.ApplicationStatus = a.state.ApplicationStatus;
        localStorage.setItem('ApplicationStatus',this.ApplicationStatus);
      }
    }
    if(localStorage.getItem('ApplicationStatus')){
      this.ApplicationStatus = localStorage.getItem('ApplicationStatus');
    }
  }
  ngOnDestroy() {
    clearInterval(this.interval);
  }
  ngOnInit() {
    this.loaded = false;
    this.currentUser = this.service.getcurrentUser();
    console.log(typeof(this.array));
    this.IsLawyerLoaded = false;
    this.breadCrumbItems = [{ label: 'Dashboard', path: '/' },
    { label: 'Applications', path: '/loan/applications' }, { label: 'Title Search', path: '/', active: true }];
    this.service.GetApplicationInformation(this.Route.snapshot.params.id).subscribe((res) => {
      this.applicationData = res.data[0];
        if (this.applicationData.PVRDocumentID === null) {
          this.PVRDetailsLoaded = false;
          this.statusChange();
        } else {
          this.service.GetPVRData(this.Route.snapshot.params.id).subscribe((PVRData) => {
            this.PVRDetailsLoaded = true;
            this.PVRData = PVRData.data;
          });
        }
        if (this.applicationData.LawyerID) {
          this.service.viewLawyer(this.applicationData.LawyerID).subscribe((lawyerInfo) => {
            this.lawyerInfo = lawyerInfo.data[0];
            this.IsLawyerLoaded = true;
          });
        }
        if(this.applicationData.ApplicationStatus !== 'iPVR Sent' && this.applicationData.ApplicationStatus !== 'iPVR In Progress') {
          this.getLawyerDetails(this.applicationData.LawyerID);
          this.service.GetTitleClearDocumentList(this.Route.snapshot.params.id).subscribe((Response) => {
            this.propertyDocumentData = Response.data;
            if (this.propertyDocumentData.length > 0) {
              this.propertyDocumentData.filter((data) => {
                if(data.isMandatory == true) {
                  this.totaldocument++;
                if (data.Status === 'Pending') {
                  this.pending++;
                } else {
                  this.received++;
                }
                }
              });
            }
            this.LoadChart();
            this.loaded = true;
          });
        } else {
          this.service.GetDocumentList(this.Route.snapshot.params.id).subscribe((Response) => {
            this.propertyDocumentData = Response.data;
            if (this.propertyDocumentData.length > 0) {
              this.propertyDocumentData.filter((data) => {
                this.totaldocument++;
                if (data.Status === 'Pending') {
                  this.pending++;
                } else {
                  this.received++;
                }
              });
            }
            this.LoadChart();
            this.loaded = true;
          });
        }
    });
    this.reloadDataTable(this.ApplicationStatus);
    
  }

  reloadDataTable(ApplicationStatus){
    if(ApplicationStatus !== 'iPVR Sent' && ApplicationStatus !== 'iPVR In Progress'){
        this.dtOptions = {
          pagingType: "simple_numbers",
          pageLength: 10,
          // processing: true,
          serverSide:false,
          ordering: false,
          searching: true,
          ajax: (dataTablesParameters: any, callback) => {
            this.http
              .get<DataTablesResponse>(
                this.service.GetBaseUrl() + `tcc/loan/application/Documents/AppID/${this.Route.snapshot.params.id}`,
                {}
              ).subscribe((resp: any) => {
                this.loaded = true;
                callback({
                  recordsTotal: resp.data.length,
                  recordsFiltered: resp.data.length,
                  data: resp.data
                });
              });
          },
          responsive: true,
          order: [[0, "desc"]],
          columns: [{
            title: 'Sr No.', data: 'row', render: (data, type, row, meta) => {
              return meta.row + 1;
            }
          }, {
            title: 'Document',
            data: 'DocumentName',
          }, {
            title: 'Status',
            render: (data, type, row) => {
              // if (row.Status === 'Pending') {
              //   return `<span class="badge badge-danger p-1">${row.Status}</span>`;
              // } else if (row.Status === 'Under Review') {
              //   return `<span class="badge badge-primary p-1">${row.Status}</span>`;
              // } else {
              //   return `<span class="badge badge-success p-1">${row.Status}</span>`;
              // }
              if (row.Status === 'Pending') {
                return `<span class="badge badge-danger p-1">${row.Status}</span>`;
              }
              else if(row.Status === 'Reviewed'){
                return `<span class="badge badge-primary p-1">${row.Status}</span>`;
              } else {
                return `<span class="badge badge-success p-1">${row.Status}</span>`;
              }
            }
          }, {
            title: 'Action',
            data: null
          }, {
            title: 'Comment',
            render: (data, type, row) => {
              let comment = '';
              if(row.DocumentComment != undefined ) {
                row.DocumentComment.forEach(element => {
                  comment += element.Comment + '<br>';
                });
                return comment;
              }
              else {
                return '';
              }
            },
          }
          ],
          columnDefs: [{targets:3,orderable:false}],
          rowCallback(row, data: any) {
            let btn = '';
            if (data.FileURL === null) {
              btn += '<a href="javascript:void(0)" class="uploadDocument m-1" title="Upload Document" DocumentSubType="' + data.DocumentSubType + '" DocumentType="' + data.DocumentType + '" DocumentName="' + data.DocumentName + '">';
              btn += '<i class="mdi mdi-file-upload-outline font-18 text-secondary" aria-hidden="false" DocumentSubType="' + data.DocumentSubType + '" DocumentType="' + data.DocumentType + '" DocumentName="' + data.DocumentName + '"></i>';
              btn += '</a>';
            } else if (data.FileURL !== null && typeof(data.DocumentID) == 'object') {
              btn += '<a href="javascript:void(0)" class="viewDocument m-1" title="View Document" DocumentID="' + data.DocumentID[0] + '">';
              btn += '<i class="mdi mdi-eye font-18 text-secondary" aria-hidden="false" DocumentID="' + data.DocumentID[0] + '"></i>';
              btn += '</a>';
              if (data.Status !== 'Reviewed') {
                btn += '<a href="' + data.FileURL + '" class="m-1" title="Download This Document">';
                btn += '<i class="mdi mdi-file-download font-18 text-secondary" aria-hidden="false"></i>';
                btn += '</a>';
              }
            } else if (data.FileURL !== null && typeof(data.DocumentID) != 'object') {
              btn += '<a href="javascript:void(0)" class="viewDocument m-1" title="View Document" DocumentID="' + data.DocumentID + '">';
              btn += '<i class="mdi mdi-eye font-18 text-secondary" aria-hidden="false" DocumentID="' + data.DocumentID + '"></i>';
              btn += '</a>';
              if (data.Status !== 'Reviewed') {
                btn += '<a href="' + data.FileURL + '" class="m-1" title="Download This Document">';
                btn += '<i class="mdi mdi-file-download font-18 text-secondary" aria-hidden="false"></i>';
                btn += '</a>';
              }
            }
            $('td:eq(3)', row).html(btn);
          }, drawCallback: () => {
            $('.uploadDocument').on('click', (e) => {
              this.onUploadDocument($(e.target).attr('DocumentType'), $(e.target).attr('DocumentSubType'), $(e.target).attr('DocumentName'));
            });
            $('.viewDocument').on('click', (e) => {
              console.log($(e.target).attr('DocumentID'))
              this.onViewDocument($(e.target).attr('DocumentID'));
            });
            $('.requestDocument').on('click', (e) => {
              this.onRequestingDocument($(e.target).attr('receipt-id'));
            });
          }
        };
      }
      else{
        this.dtOptions = {
          pagingType: "simple_numbers",
          pageLength: 10,
          // processing: true,
          serverSide:false,
          ordering: false,
          searching: true,
          ajax: (dataTablesParameters: any, callback) => {
            this.http
              .get<DataTablesResponse>(
                this.service.GetBaseUrl() + `loan/application/Documents/AppID/${this.Route.snapshot.params.id}`,
                {}
              ).subscribe((resp: any) => {
                this.loaded = true;
                callback({
                  recordsTotal: resp.data.length,
                  recordsFiltered: resp.data.length,
                  data: resp.data
                });
              });
          },
          responsive: true,
          order: [[0, "desc"]],
          columns: [{
            title: 'Sr No.', data: 'row', render: (data, type, row, meta) => {
              return meta.row + 1;
            }
          }, {
            title: 'Document',
            data: 'DocumentName',
          }, {
            title: 'Status',
            render: (data, type, row) => {
              // if (row.Status === 'Pending') {
              //   return `<span class="badge badge-danger p-1">${row.Status}</span>`;
              // } else if (row.Status === 'Under Review') {
              //   return `<span class="badge badge-primary p-1">${row.Status}</span>`;
              // } else {
              //   return `<span class="badge badge-success p-1">${row.Status}</span>`;
              // }
              if (row.Status === 'Pending') {
                return `<span class="badge badge-danger p-1">${row.Status}</span>`;
              }
              else if(row.Status === 'Reviewed'){
                return `<span class="badge badge-primary p-1">${row.Status}</span>`;
              } else {
                return `<span class="badge badge-success p-1">${row.Status}</span>`;
              }
            }
          }, {
            title: 'Action',
            data: null
          }, {
            title: 'Comment',
            render: (data, type, row) => {
              let comment = '';
              if(row.DocumentComment != undefined ) {
                row.DocumentComment.forEach(element => {
                  comment += element.Comment + '<br>';
                });
                return comment;
              }
              else {
                return '';
              }
            },
          }
          ],
          columnDefs: [{targets:3,orderable:false}],
          rowCallback(row, data: any) {
            let btn = '';
            if (data.FileURL === null) {
              btn += '<a href="javascript:void(0)" class="uploadDocument m-1" title="Upload Document" DocumentSubType="' + data.DocumentSubType + '" DocumentType="' + data.DocumentType + '" DocumentName="' + data.DocumentName + '">';
              btn += '<i class="mdi mdi-file-upload-outline font-18 text-secondary" aria-hidden="false" DocumentSubType="' + data.DocumentSubType + '" DocumentType="' + data.DocumentType + '" DocumentName="' + data.DocumentName + '"></i>';
              btn += '</a>';
            } else if (data.FileURL !== null && typeof(data.DocumentID) == 'object') {
              btn += '<a href="javascript:void(0)" class="viewDocument m-1" title="View Document" DocumentID="' + data.DocumentID[0] + '">';
              btn += '<i class="mdi mdi-eye font-18 text-secondary" aria-hidden="false" DocumentID="' + data.DocumentID[0] + '"></i>';
              btn += '</a>';
              if (data.Status !== 'Reviewed') {
                btn += '<a href="' + data.FileURL + '" class="m-1" title="Download This Document">';
                btn += '<i class="mdi mdi-file-download font-18 text-secondary" aria-hidden="false"></i>';
                btn += '</a>';
              }
            } else if (data.FileURL !== null && typeof(data.DocumentID) != 'object') {
              btn += '<a href="javascript:void(0)" class="viewDocument m-1" title="View Document" DocumentID="' + data.DocumentID + '">';
              btn += '<i class="mdi mdi-eye font-18 text-secondary" aria-hidden="false" DocumentID="' + data.DocumentID + '"></i>';
              btn += '</a>';
              if (data.Status !== 'Reviewed') {
                btn += '<a href="' + data.FileURL + '" class="m-1" title="Download This Document">';
                btn += '<i class="mdi mdi-file-download font-18 text-secondary" aria-hidden="false"></i>';
                btn += '</a>';
              }
            }
            $('td:eq(3)', row).html(btn);
          }, drawCallback: () => {
            $('.uploadDocument').on('click', (e) => {
              this.onUploadDocument($(e.target).attr('DocumentType'), $(e.target).attr('DocumentSubType'), $(e.target).attr('DocumentName'));
            });
            $('.viewDocument').on('click', (e) => {
              console.log($(e.target).attr('DocumentID'))
              this.onViewDocument($(e.target).attr('DocumentID'));
            });
            $('.requestDocument').on('click', (e) => {
              this.onRequestingDocument($(e.target).attr('receipt-id'));
            });
          }
        };
      }
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });  }
  statusChange() {
    this.interval = setInterval(() => {
      this.index++;
      document.getElementById('status').innerHTML = this.statusList[this.index];
      if ((this.index + 1) >= this.statusList.length) {
        clearInterval(this.interval);
      }
    }, 30000);
  }
  onUploadDocument(doctype, docsubtype, docname) {
    this.router.navigate(['loan/uploadlawyerdocument/' + this.applicationData.AppID], { queryParams: { DocumentType: doctype ? doctype : '', DocumentSubType: docsubtype ? docsubtype: '', DocumentName: docname} });
  }
  onViewDocument(id) {
    this.router.navigate(['/loan/viewdocument/' + this.applicationData.PropertyID + '/' + id + '/' + this.applicationData.AppID]);
  }
  onRequestingDocument(id) {
    console.log('Requesting ' + id);

  }
  LoadChart() {
    this.revenueRadialChart = {
      chart: {
        height: 200,
        type: 'radialBar',
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: '65%',
          },
          dataLabels: {
            name: {
              show: false
            },
            value: {
              fontSize: '24px',
              color: 'rgb(29, 173, 56)',
              offsetY: 10,
              formatter: (val) => {
                return val + '';
              }
            }
          }
        }
      },
      colors: ['rgb(29, 173, 56)'],
      series: [this.totaldocument ? (((this.received >= this.totaldocument ? this.totaldocument : this.received) / this.totaldocument) * 100).toFixed() : 0],
      stroke: {
        lineCap: 'round',
      },
    };
  }
  EcChange() {
    this.loaded = false;
    this.service.ChangeECResponce(this.AppID).subscribe(() => {
      this.loaded = true;
      Swal.fire({
        title: 'Request Received',
        text: 'EC Request will be delivered shortly',
        type: 'success',
        showConfirmButton: false,
        timer:3000
      }).then(() => { location.reload() })

    })
  }
  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }
  TcChange() {
    // this.loaded = false;
    // this.service.ChangeECResponce(this.AppID).subscribe(() => {
    //   this.loaded = true;
    //   Swal.fire({
    //     title: 'Request Received',
    //     text: 'EC Request will be delivered shortly',
    //     type: 'success',
    //   }).then(() => { location.reload() })

    // })
  }
  openAssignLawyerDialog(): void {
    this.router.navigate(['loan/documents/' + this.AppID]);
  }
  openAssignValuerDialog(): void {
    this.router.navigate(['loan/valuationdocuments/' + this.AppID]);
  }
  getLawyerDetails(LawyerID) {
    this.loaded = false;
    this.service.GetUser(LawyerID).subscribe((res) => {
      this.loaded = true;
      this.LawyerDetails = res.data[0];
      console.log(this.LawyerDetails);
    },(error)=>{
      this.loaded = true;
    });
  }
  updateAppStatus(status) {
    let data = {
      applicationStatus: status
    }
    this.loaded = true;
    console.log(data);
    this.service.updateAppStatus(this.AppID, data).subscribe((res) => {
      this.loaded = false;
      Swal.fire({
        title: 'Success',
        text: 'Application has been Sent For a Post Mortagage Pratection',
        type: 'success',
        showConfirmButton: false,
        timer:3000
      }).then(() => { location.reload() })
    }, (error) => {
      this.loaded = false;
    });
  }
}
