import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { GeneralService } from 'src/app/services/general.service';

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}


@Component({
  selector: 'app-gpp-dashboard',
  templateUrl: './gpp-dashboard.component.html',
  styleUrls: ['./gpp-dashboard.component.scss']
})
export class GppDashboardComponent implements OnInit {

  breadCrumbItems:any;
  isLoadingTable:boolean = false;
  dtOptions: DataTables.Settings = {};
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective | undefined;
  dtTrigger = new Subject<any>();
  inputValue:string ='';
  applications:any;
  AppID:string='';
  constructor(private http:HttpClient, private service: GeneralService, private datepipe: DatePipe,private router:Router,private renderer: Renderer2,) {
    let a = this.router.getCurrentNavigation().extras;
    if(a){
      if(a.state){
        this.AppID = a.state.AppID;
        console.log("appid",this.AppID)
        localStorage.setItem('AppID',this.AppID);
      }
    }
    if(localStorage.getItem('AppID')){
      this.AppID = localStorage.getItem('AppID');
    }

   }

  ngOnInit() {
    this.breadCrumbItems = [{ label: 'View Application', path: '/loan/view-pvr-list' },
    { label: 'GPP List', path: '/', active: true }];
    this.dtOptions = {
      pagingType: "simple_numbers",
      pageLength: 10,
      serverSide: true,
      searching: false,
      ordering: false,
      // processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        this.isLoadingTable = true;
        this.http
          .get<DataTablesResponse>(
            this.service.GetBaseUrl() +
            `get/gpp/propertyList/${this.AppID}`+ 
            `?pageNo=${(dataTablesParameters.start + dataTablesParameters.length) /
            dataTablesParameters.length
            }` +
            `${dataTablesParameters.length.value != ""
              ? "&limit=" + dataTablesParameters.length
              : ""
            }`
            //  +
            // `${this.inputValue != "" ? "&search=" + this.inputValue : ""
            // }`
            ,

            {}
          )
          .subscribe((resp: any) => {
            console.log("line==199", resp)
            this.isLoadingTable = false;
            this.applications = resp.data;
            callback({
              recordsTotal: resp.data.length,
              recordsFiltered: resp.data.length,
              data: this.applications,
            });
          });
      },
      columns: [
        {
        title: 'Sr No.', data: 'row', render: (data, type, row, meta) => {
          return meta.row + 1;
        }
      },
        {
          title: "District",
          name: "district",
          data: "district",
          render: (data) => this.renderData(data),
        },
        {
          title: "Taluka",
          name: "taluka",
          data: "taluka",
          render: (data) => this.renderData(data),
        },
        {
          title: "Village",
          name: "village",
          data: "village",
          render: (data) => this.renderData(data),
        },
        {
          title: "Survey Number",
          name: "survey_number",
          data: "survey_number",
          render: (data) => this.renderData(data),
        },
        {
          title: "EPP In Days",
          name: "EPP_In_Days",
          data: "EPP_In_Days",
          render: (data) => this.renderData(data),
        },
        {
          title: "Start Date",
          name: "start_date",
          data: "start_date",
          render: (data, type, row) => {
            return this.datepipe.transform(data, 'MMM dd,yyyy');
          }
        },
        {
          title: "End Date",
          name: "end_date",
          data: "end_date",
          render: (data, type, row) => {
            return this.datepipe.transform(data, 'MMM dd,yyyy');
          }
        },
      ],
      order: [[0, "desc"]],
      columnDefs: [
        { targets: 0, visible: true },
        { targets: 2, orderable: false },
      ],
    };
  }
  onSearch() {
    this.rerender();
  }
  onchangeSearch() {
    console.log(this.inputValue);
    if (this.inputValue != "") {
      return;
    }
    this.rerender();
  }
  ngAfterViewInit(): void {
    this.dtTrigger.next();
    this.renderer.listen('document', 'click', (event) => {
      let AppID = event.target.getAttribute('viewID');
      console.log('appid',AppID)
      if (event.target.hasAttribute('viewID')) {
        this.router.navigate(['/GPP/Add-Gpp-Alert'], {state:{AppID: AppID}});
      }
      
    })
  
  }
  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  renderData(data) {
    if (data === '' || data === null || data === 'null') {
      return '--';
    } else {
      return data;
    }
  }


}
