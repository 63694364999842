import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { CookieService } from 'src/app/core/services/cookie.service';
import { GeneralService } from 'src/app/services/general.service';
import Swal from 'sweetalert2';
class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}
@Component({
  selector: 'app-assign-valuers',
  templateUrl: './assign-valuers.component.html',
  styleUrls: ['./assign-valuers.component.scss']
})
export class AssignValuersComponent implements OnInit {
  ValuerForm: FormGroup;
  valuerType: any;
  dtOptions: DataTables.Settings = {};
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtTrigger = new Subject<any>();
  user: any;
  breadCrumbItems: ({ label: string; path: string; active?: undefined; } | { label: string; path: string; active: boolean; })[];
  AppID: any;
  isLoading: boolean;
  ValuerID: any;
  isAssignValuer: boolean = false;
  constructor(
    // public dialogRef: MatDialogRef<AssignValuerComponent>,
    // @Inject(MAT_DIALOG_DATA) public data: any,
    private service: GeneralService,
    private route: ActivatedRoute,
    private Fb: FormBuilder,
    private router: Router,
    private http: HttpClient,
    private cookie: CookieService
  ) {
    this.AppID = this.route.snapshot.queryParamMap.get('AppID');
  }

  ngOnInit() {
    if(this.AppID != undefined) {
      this.isAssignValuer = true;
    }
    if(this.isAssignValuer == true) {
      this.breadCrumbItems = [
        { label: 'Dashboard', path: '/' },
        { label: 'Applications', path: '/loan/applications' }, 
        { label: 'Property Valuation', path: '/loan/title-search/' + this.AppID},
        { label: 'Select Documents', path: '/loan/documents/' + this.AppID},
        { label: 'Assign Valuer', path: '/loan/valuer', active: true }];
    } else {
      this.breadCrumbItems = [
        { label: 'Dashboard', path: '/' },
        { label: 'Valuers', path: '/loan/valuer', active: true }];
    }
    
    this.user = JSON.parse(this.cookie.getCookie('currentUser'));
    this.ValuerForm = this.Fb.group({
      type: new FormControl(null, Validators.required),

    });
    if(this.isAssignValuer == true) {
      this.dtOptions = {
        pagingType: "simple_numbers",
        pageLength: 10,
        processing: true,
        serverSide: true,
        ordering: false,
        ajax: (dataTablesParameters: any, callback) => {
          this.http
            .get<DataTablesResponse>(
              `${this.service.getLawyers()}${this.user.CompanyID}`+
              `?pageno=${((dataTablesParameters.start+dataTablesParameters.length)/ dataTablesParameters.length)}`+
              `${dataTablesParameters.length.value!=''?'&recordsno='+dataTablesParameters.length:''}`+
              `${dataTablesParameters.search.value!=''?'&search='+dataTablesParameters.search.value:''}`,
               {}
            ).subscribe((resp: any) => {
              callback({
                recordsTotal: resp.LawyerCount,
                recordsFiltered: `${resp.filtercount ? resp.filtercount : resp.LawyerCount}`,
                data: resp.data
              });
            });
        },
        responsive: true,
        columns: [
          {
            title: 'Sr.No.',
            data: null,
            render: (data, type, row, meta) => {
              return meta.row + 1;
            },
          },
          {
            title: 'User ID',
            data: null,
            render: (data) => {
              return data.UserID;
            },
          },
          {
            title: 'Name',
            data: null,
            render: (data) => {
              return data.FirstName + ' ' + data.LastName;
            },
          },
          {
            title: 'Mobile Number',
            // data: 'MobileNo',
            data: null,
            render: (data) => {
              return data.MobileNumber;
            },
          },
          {
            title: 'Email',
            // data: 'EmailID',
            data: null,
            render: (data) => {
              return data.EmailAddress;
            }
          },
          {
            title: 'Status',
            data: 'IsActive', render: (data) => {
              // if (data === 'Pending') {
              //   return `<span class="badge badge-danger p-1">${data}</span>`;
              // } else if (data === 'iPVR Sent') {
              //   return `<span class="badge badge-success p-1">${data}</span>`;
              // } else if (data) {
              //   return `<span class="badge badge-secondary p-1">${data}</span>`;
              // } else {
              //   return data;
              // }
              if (data === true) {
                return `<span class="badge badge-success p-1">Active</span>`;
              } else {
                return `<span class="badge badge-secondary p-1">In Active</span>`;
              }
            }
          },
          {
            title: 'Action',
            data: null,
            render(data) {
              if (data.IsActive === true) {
                return `<span class="badge badge-primary p-1 assign" UserID="${data.UserID}"><button type="button" class="btn btn-primary" UserID="${data.UserID}" style="padding: 0px !important; margin: 0px !important">Assign Valuer</button></span>`;
              } else {
                return ``;
              }
              
            },
          },
        ],
        drawCallback: () => {
          $('.assign').on('click', (e) => {
            console.log(e.target.getAttribute('UserID'));
            this.save(e.target.getAttribute('UserID'));
          });
        },
      };
    } else {
      this.dtOptions = {
        pagingType: "simple_numbers",
        pageLength: 10,
        processing: true,
        serverSide: true,
        ordering: false,
        ajax: (dataTablesParameters: any, callback) => {
          this.http
            .get<DataTablesResponse>(
              `${this.service.getLawyers()}${this.user.CompanyID}`+
              `?pageno=${((dataTablesParameters.start+dataTablesParameters.length)/ dataTablesParameters.length)}`+
              `${dataTablesParameters.length.value!=''?'&recordsno='+dataTablesParameters.length:''}`+
              `${dataTablesParameters.search.value!=''?'&search='+dataTablesParameters.search.value:''}`,
               {}
            ).subscribe((resp: any) => {
              callback({
                recordsTotal: resp.LawyerCount,
                recordsFiltered: `${resp.filtercount ? resp.filtercount : resp.LawyerCount}`,
                data: resp.data
              });
            });
        },
        responsive: true,
        columns: [
          {
            title: 'Sr.No.',
            data: null,
            render: (data, type, row, meta) => {
              return meta.row + 1;
            },
          },
          {
            title: 'User ID',
            data: null,
            render: (data) => {
              return data.UserID;
            },
          },
          {
            title: 'Name',
            data: null,
            render: (data) => {
              return data.FirstName + ' ' + data.LastName;
            },
          },
          {
            title: 'Mobile Number',
            // data: 'MobileNo',
            data: null,
            render: (data) => {
              return data.MobileNumber;
            },
          },
          {
            title: 'Email',
            // data: 'EmailID',
            data: null,
            render: (data) => {
              return data.EmailAddress;
            }
          },
          {
            title: 'Status',
            data: 'IsActive', render: (data) => {
              // if (data === 'Pending') {
              //   return `<span class="badge badge-danger p-1">${data}</span>`;
              // } else if (data === 'iPVR Sent') {
              //   return `<span class="badge badge-success p-1">${data}</span>`;
              // } else if (data) {
              //   return `<span class="badge badge-secondary p-1">${data}</span>`;
              // } else {
              //   return data;
              // }
              if (data === true) {
                return `<span class="badge badge-success p-1">Active</span>`;
              } else {
                return `<span class="badge badge-secondary p-1">In Active</span>`;
              }
            }
          }
        ],
        drawCallback: () => {
        },
      };
    }
    
  }
  get f() {
    return this.ValuerForm.controls;
  }
  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }
  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  save(ValuerID?) {
    let data;
    if(!ValuerID) {
      data = {
        "AppID":Number(this.AppID),
        "ValuerID": null,
        "DocumentList":null,
        "ToProplegit":true,
        "FromProlegit":false
      }
    } else {
      data = {
        "AppID":Number(this.AppID),
        "ValuerID":Number(ValuerID),
        "DocumentList":null,
        "ToProplegit":false,
        "FromProlegit":false
      }
    }
    this.router.navigate(['/loan/title-search/' + this.AppID]);
    this.isLoading = true;
    // this.service.startTitleClear(data)
    //     .subscribe((res: any) => {
    //       this.isLoading = false;
    //       if (res.status === 200) {
    //         Swal.fire({
    //           title: 'Added to Title Clear',
    //           text: res.message,
    //           type: 'success',
    //           timer: 2000
    //         }).then(() => {
    //           this.router.navigate(['/loan/title-search/' + this.AppID]);
    //         });
    //       } else {
    //         Swal.fire({
    //           title: res.error_code,
    //           text: res.error,
    //           type: 'error'
    //         });
    //       }
    //     },(error: any) => {   
    //       console.log(error.error_code); 
    //       this.isLoading = false;
    //       Swal.fire({
    //         title: error.error_code,
    //         text: error.error,
    //         type: "error",
    //       });
          
    //     });
  }

  addValuer() {
      this.router.navigate(['/loan/add-valuer']);
  }
}
