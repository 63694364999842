import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GeneralService } from 'src/app/services/general.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-past-public-notice',
  templateUrl: './add-past-public-notice.component.html',
  styleUrls: ['./add-past-public-notice.component.scss']
})
export class AddPastPublicNoticeComponent implements OnInit {
  pastPublicNoticeForm: FormGroup;
  breadCrumbItems: any;
  currentUser: any;
  isLoading: boolean;
  submited: boolean;
  file: any;
  propertyID:string;
  constructor(
    private generalService: GeneralService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.currentUser = this.generalService.getcurrentUser();
    this.pastPublicNoticeForm = new FormGroup({
      FileName: new FormControl(null, Validators.required),
      AppID: new FormControl(this.route.snapshot.params.AppID, Validators.required),
      Description: new FormControl(null, Validators.required),
      File: new FormControl(null),
      PublishDate: new FormControl(null, Validators.required),
      NoticeDays: new FormControl(null, Validators.required),
      NewsPaper: new FormControl(null, Validators.required),
      PublishedBy: new FormControl(null, Validators.required),
      
    });
    this.pastPublicNoticeForm.controls.FileName.disable();
    if(this.route.snapshot.queryParamMap.get('PropertyID')){
      this.propertyID = this.route.snapshot.queryParamMap.get('PropertyID')
    }
  }
  ngOnInit() {
    this.breadCrumbItems = [
      { label: "Dashboard", path: "/loan" },
      { label: "Assignment", path: "/loan/assignment/" +  this.route.snapshot.params.AppID},
      { label: "Public Notice", path: "/loan/addPublicNotice/" +  this.route.snapshot.params.AppID, active: true },
    ];
  }
  
  get f() {
    return this.pastPublicNoticeForm.controls;
  }
  onchange(e) {
    if (e && e.length > 0) {
      this.file = e[0];
      let fileName = this.file.name;
      this.pastPublicNoticeForm.controls.FileName.setValue(fileName);
      
    } else {
      this.pastPublicNoticeForm.controls.FileName.setValue("");
    }   
  }
  onSubmit() {
    this.pastPublicNoticeForm.controls.FileName.enable();
    this.submited = true;
    if (this.pastPublicNoticeForm.valid) {
      this.isLoading = true;      
      let formData: FormData = new FormData();
      formData.append('AppID', this.pastPublicNoticeForm.controls.AppID.value);
      formData.append('Description', this.pastPublicNoticeForm.controls.Description.value);
      formData.append('FileName', this.pastPublicNoticeForm.controls.FileName.value);
      formData.append('File', this.file, this.pastPublicNoticeForm.controls.FileName.value);
      formData.append('PublishDate', this.pastPublicNoticeForm.controls.PublishDate.value);
      formData.append('NoticeDays', this.pastPublicNoticeForm.controls.NoticeDays.value);
      formData.append('NewsPaper', this.pastPublicNoticeForm.controls.NewsPaper.value);
      formData.append('PublishedBy', this.pastPublicNoticeForm.controls.PublishedBy.value);
      if(this.propertyID){
        formData.append('PropertyID',this.propertyID);
      }
      this.generalService.addPublicNotice(formData).subscribe((res) => {
        this.isLoading = false;
        console.log(res);
        if(res.status == 200) {
          Swal.fire({
            title: `Success`,
            text: res.message,
            type: 'success',
            showConfirmButton: false,
            timer:3000
          }).then(() => { 
            this.updateAppStatus('TC Notice In Progress');
            this.router.navigate(['/loan/tcc-dashboard-lawyer/' + this.route.snapshot.params.AppID]);  })
        } else {
          Swal.fire({
            title: `Error`,
            text: res.message,
            type: 'error',
            showConfirmButton: false,
            timer:3000
          }).then(() => {});
        }
      }, (error) => {
        console.log(error);
        this.isLoading = false;
        Swal.fire({
          title: `Error`,
          text: 'Internal Server Error',
          type: 'error',
          showConfirmButton: false,
          timer:3000
        }).then(() => {});
      });
    } else{
      console.log(this.pastPublicNoticeForm);
    }
  }
  updateAppStatus(status) {
    let data = {
      applicationStatus: status
    }
    this.isLoading = true;
    console.log(data);
    this.generalService.updateAppStatus(this.route.snapshot.params.AppID, data).subscribe((res) => {
      this.isLoading = false;
    }, (error) => {
      this.isLoading = false;
    });
  }
}
