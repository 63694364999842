import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { CookieService } from 'src/app/core/services/cookie.service';
import { GeneralService } from 'src/app/services/general.service';
import { UploadDocumentDialogComponent } from '../upload-document-dialog/upload-document-dialog.component';
class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}
@Component({
  selector: 'app-view-requested-documents',
  templateUrl: './view-requested-documents.component.html',
  styleUrls: ['./view-requested-documents.component.scss']
})
export class ViewRequestedDocumentsComponent implements OnInit {
  [x: string]: any;
  dtOptions: DataTables.Settings = {};
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtTrigger = new Subject<any>();
  user: any;
  breadCrumbItems: ({ label: string; path: string; active?: undefined; } | { label: string; path: string; active: boolean; })[];
  AppID: any;
  LawyerID: any;
  DocumentList: any;
  myGroup: FormGroup;
  documentID: any = [];
  isLoading: boolean;
  StateList: any=[];
  isShow: boolean;
  constructor(
    private service: GeneralService,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private cookie: CookieService,
    public dialog: MatDialog
  ) {
    this.appID = this.route.snapshot.params.AppID;
  }
  ngOnInit() {
    this.user = this.service.getcurrentUser();
      this.isLoading = true;
      this.service.getApplicationInformationdisplay(this.appID).subscribe((res) => { 
        this.applicationDatadisplay = res.data;
      });
      this.service.GetApplicationInformation(this.appID).subscribe((res) => {
        this.applicationData = res.data[0];
        this.isLoading = false;
      });
      this.breadCrumbItems = [
        { label: 'Dashboard', path: '/' },
        { label: 'All Requested Documents', path: '/loan/requested-documents'},
        { label: `${this.applicationDatadisplay ? this.applicationDatadisplay.FirstName + ' ' + this.applicationDatadisplay.LastName + ' Requested Documents' : 'Requested Documents - ' + this.appID}`, path: '/loan/view-requested-documents/'+ this.appID, active: true }]; 
        let id;
        if(this.user.UserType == 'Proplegit') {
          id = 0;
        } else {
          id = this.user.UserID;
        }
        if(this.user.UserType == 'Lawyer') {
          this.dtOptions = {
            pagingType: "simple_numbers",
            pageLength: 10,
            ordering: true,
            serverSide: false,
            ajax: (dataTablesParameters: any, callback) => {
              this.http
                .get<DataTablesResponse>(
                  `${this.service.GetBaseUrl()}requested/document/list/lawyer/${this.appID}/${id}`,{}
                ).subscribe((resp: any) => {
                  callback({
                    recordsTotal: resp.count,
                    recordsFiltered: resp.count,
                    data: resp.data,
                  });
                });
            },
            responsive: true,
            columns: [
              {
                title: 'Sr.No.',
                data: null,
                render: (data, type, row, meta) => {
                  return meta.row + 1;
                },
              },
              {
                title: 'Document Name',
                data: null,
                render: (data) => {
                  return `${ data.DocumentName != null && data.DocumentName != 'null' ? data.DocumentName : data.FileName }`;
                },
              },
              {
                title: 'Property ID',
                data: null,
                render: (data) => {
                  return `${ data.PropertyID != null && data.PropertyID != 'null' ? data.PropertyID : '--' }`;
                },
              },
              {
                title: 'Status',
                render: (data, type, row) => {
                  if (row.Status === 'Requested') {
                    return `<span class="badge badge-danger p-1">${row.Status}</span>`;
                  } else {
                    return `<span class="badge badge-success p-1">${row.Status}</span>`;
                  }
                }
              },
              {
                title: 'Action',
                data: null,
                render:(data) => {
                  if (data.Status === 'Requested') {
                    return `<a href="javascript:void(0)" class="view m-1" title="Upload Requested Documents" DocumentSubType="${data.DocumentSubType}" DocumentType="${data.DocumentType}" DocumentName="${data.DocumentName}" FileName="${data.FileName}" DocumentID="${data.DocumentID}">
                      <i class="mdi mdi-file-upload-outline font-18 text-secondary" aria-hidden="false" DocumentSubType="${data.DocumentSubType}" DocumentType="${data.DocumentType}" DocumentName="${data.DocumentName}" FileName="${data.FileName}" DocumentID="${data.DocumentID}"
                      PropertyID="${data.PropertyID}"></i>
                      </a>`;
                  } else {
                    return ``;
                  }
                           
                },
              },
            ],
            drawCallback: () => {
              $('.view').on('click', (e) => {
                this.openUploadDocumentDialog($(e.target).attr('DocumentType'), $(e.target).attr('DocumentSubType'), $(e.target).attr('DocumentName'), $(e.target).attr('FileName'), $(e.target).attr('DocumentID'),$(e.target).attr('PropertyID'));
              });
            },
          };
        } else {
          this.dtOptions = {
            pagingType: "simple_numbers",
            pageLength: 10,
            ordering: true,
            serverSide: false,
            ajax: (dataTablesParameters: any, callback) => {
              this.http
                .get<DataTablesResponse>(
                  `${this.service.GetBaseUrl()}requested/document/list/${this.appID}/${id}`,{}
                ).subscribe((resp: any) => {
                  callback({
                    recordsTotal: resp.count,
                    recordsFiltered: resp.count,
                    data: resp.data,
                  });
                });
            },
            responsive: true,
            columns: [
              {
                title: 'Sr.No.',
                data: null,
                render: (data, type, row, meta) => {
                  return meta.row + 1;
                },
              },
              {
                title: 'Property ID',
                data: null,
                render: (data) => {
                  return `${ data.PropertyID != null && data.PropertyID != 'null' ? data.PropertyID : '--' }`;
                },
              },
              {
                title: 'Document Name',
                data: null,
                render: (data) => {
                  return `${ data.DocumentName != null && data.DocumentName != 'null' ? data.DocumentName : data.FileName }`;
                },
              },
              {
                title: 'Status',
                render: (data, type, row) => {
                  if (row.Status === 'Requested') {
                    return `<span class="badge badge-danger p-1">${row.Status}</span>`;
                  } else {
                    return `<span class="badge badge-success p-1">Uploaded</span>`;
                  }
                }
              },
              {
                title: 'Action',
                data: null,
                render:(data) => {
                  if (data.Status === 'Requested') {
                    return `<a href="javascript:void(0)" class="view m-1" title="Upload Requested Documents" DocumentSubType="${data.DocumentSubType}" DocumentType="${data.DocumentType}" DocumentName="${data.DocumentName}" FileName="${data.FileName}" DocumentID="${data.DocumentID}">
                      <i class="mdi mdi-file-upload-outline font-18 text-secondary" aria-hidden="false" DocumentSubType="${data.DocumentSubType}" DocumentType="${data.DocumentType}" DocumentName="${data.DocumentName}" FileName="${data.FileName}" DocumentID="${data.DocumentID}" PropertyID="${data.PropertyID} "></i>
                      </a>`;
                  } else {
                    return ``;
                  }
                           
                },
              },
            ],
            drawCallback: () => {
              $('.view').on('click', (e) => {
                this.openUploadDocumentDialog($(e.target).attr('DocumentType'), $(e.target).attr('DocumentSubType'), $(e.target).attr('DocumentName'), $(e.target).attr('FileName'), $(e.target).attr('DocumentID'),$(e.target).attr('PropertyID'));
              });
            },
          };
        }
      
  }
  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }
  ngAfterViewInit() {
    this.dtTrigger.next();
  }
  openUploadDocumentDialog(doctype, docsubtype, docname, filename, docid,propertyID): void {
    const dialogRef = this.dialog.open(UploadDocumentDialogComponent, {
      disableClose: false,
      width:'480px',
      data: {
        DocumentSubType: docsubtype,
        DocumentType: doctype,
        DocumentName: `${ docname != null && docname != 'null' ? docname : filename }`,
        DocumentId: docid,
        Appid: this.route.snapshot.params.AppID,
        PropertyID:propertyID
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.rerender();
    });
  }
}
