import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GeneralService } from 'src/app/services/general.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-property',
  templateUrl: './add-property.component.html',
  styleUrls: ['./add-property.component.scss']
})
export class AddPropertyComponent implements OnInit {

  breadCrumbItems: Array<{}>;
  form: FormGroup;
  talukaList:any[] = [];
  districtList:any[] = [];
  villageList:any[] = [];
  isLoading: boolean;
  URL: string;
  file: Array<any>;
  AppID:any;
  submitted: boolean = false;
  constructor(private Fb: FormBuilder, private service :GeneralService, private router:Router) { 

    let a = router.getCurrentNavigation().extras;
    if(a){
      if(a.state){
        this.AppID = a.state.AppID;
      }
    }
    if(localStorage.getItem('AppID')){
      this.AppID = localStorage.getItem('AppID');
    }
  }

  ngOnInit() {
    this.getDistrictList('7');
    this.breadCrumbItems = [{ label: 'Dashboard', path: '/e-check/echeck-dashboard' },
    { label: 'Add Property', path: '/', active: true }];
    this.form = this.Fb.group({
        AppID: new FormControl(''),
        File: new FormControl(null),
        talukaID: new FormControl(null, Validators.required),
        districtID: new FormControl(null, Validators.required),
        villageID: new FormControl(null,Validators.required),
        khewatNo:new FormControl(null, [Validators.required])
      })
  }

  fetchData(url: string, dataProperty: string) {
    if (url !== '') {
      this.isLoading = true;
      this.service.dynamicData(url).subscribe((data) => {
        this.isLoading = false;
        if (data.error) {
          Swal.fire({
            title: data.error_code,
            text: data.message,
            type: 'error',
            showConfirmButton: false,
            timer: 3000
          });
          return;
        } else {
          this[dataProperty] = data.data;
        }
      });
    }
  }

  getDistrictList(stateID: string) {
    if (stateID !== undefined) {
      const url = 'https://ds.proplegit.com/api/district/list/' + stateID;
      this.fetchData(url, 'districtList');
    }
  }

  ChangeDistrict(id: any) {
    if (id !== undefined) {
      const url = 'https://ds.proplegit.com/api/taluka/list/' + id;
      this.fetchData(url, 'talukaList');
    }
  }

  changeTaluka(id: any) {
    if (id !== undefined) {
      const url = 'https://ds.proplegit.com/api/village/list/' + id;
      this.fetchData(url, 'villageList');
    }
  }
  onchange(event:any){
    console.log("event",event);
    console.log("this.file",this.file);
    if (this.file && this.file.length === 1) {
      const filetype = this.file[0].type;
      if (filetype.toLowerCase() !== 'application/pdf') {
        Swal.fire({
          title: `Error`,
          text: `${this.file[0].name} File Are Not Supported`,
          type: 'error',
          showConfirmButton: false,
          timer:3000
        }).then(() => { this.file = [] })
      } else {
      }
    } 
    else if(this.file && this.file.length > 1) {
      // let a = this.file[0];
      // this.file = a;
      Swal.fire({
        title: `Select only one file`,
        text: `Only one file can be upload`,
        type: 'error',
        showConfirmButton: false,
        timer:3000
      }).then(() => { 
        this.file = [this.file[0]];

        // for(let i = 0 ; i < this.file.length;i++){
        //   if(i != 0){
        //     this.file.pop();
        //   }
           
        // }
       })
    }
    else{
      // Swal.fire({
      //   title: `Please Select File`,
      //   text: `No file Selected`,
      //   type: 'error',
      //   showConfirmButton: false,
      //   timer:3000
      // })
    }
  }

  save(){
    this.submitted = true;
    if(this.form.status === "VALID"){
      if (this.file && this.file.length < 1){
        Swal.fire({
          title: `Please Select File`,
          text: `No file Selected`,
          type: 'error',
          showConfirmButton: false,
          timer:1500
        })
      }
      else{
        this.isLoading = true;
        const formdData = new FormData();
        formdData.append('AppID', this.AppID);
        formdData.append('talukaID',this.form.controls.talukaID.value)
        formdData.append('districtID',this.form.controls.districtID.value);
        formdData.append('villageID',this.form.controls.villageID.value);
        formdData.append('khewatNo',this.form.controls.khewatNo.value);
        formdData.append('files',this.file[0],this.file[0].name)
        this.submitted = false;
        this.service.addEcheckProperty(formdData).subscribe((data)=>{
          this.isLoading = false; 
          Swal.fire({
            title: `Property Added`,
            text: `Successfuly added Property Document`,
            type: 'success',
            showConfirmButton: false,
            timer:1500
          },)
          this.router.navigate(['/e-check/echeck-dashboard'])
        },(error)=>{
          this.isLoading = false;
          Swal.fire({
            title: error.error_code,
            text: error.error,
            type: 'error',
            showConfirmButton: false,
            timer:1500
          })
        })
      }   
    }
  }
}


