import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { CookieService } from 'src/app/core/services/cookie.service';
import { GeneralService } from 'src/app/services/general.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-lawyer',
  templateUrl: './add-lawyer.component.html',
  styleUrls: ['./add-lawyer.component.scss']
})

export class AddLawyerComponent implements OnInit {
  lawyerForm: FormGroup;
  submitted = false;
  error = '';
  keyword = 'Area';
  areas: string[];

  isEdit = false;
  isAreaLoaded = false;
  initialValue = '';

  StateArray: any;
  Array2: any;
  Array3: any;
  Array4: any;
  Array5: string[];
  breadCrumbItems: any;
  urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
  AppID: any;
  user: any;
  documents: string;
  isLoading: boolean;
  isAssignLawyer: boolean = false;
  constructor(private formBuilder: FormBuilder, private router: Router, private route: ActivatedRoute, private service: GeneralService, private cookie: CookieService) {
  }
  ngOnInit() {
    this.user = JSON.parse(this.cookie.getCookie('currentUser'));
    console.log(this.user);
    this.breadCrumbItems = [
      { label: 'Dashboard', path: '/' },
      { label: 'Lawyers', path: '/loan/lawyer'},
      { label: 'Add Lawyer', path: '/loan/add-lawyer' , active: true }];    
    this.lawyerForm = this.formBuilder.group({
      FirstName: new FormControl('', [Validators.required, Validators.maxLength(25)]),
      LastName: new FormControl('', [Validators.required, Validators.maxLength(25)]),
      MobileNumber: new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]),
      EmailAddress: new FormControl('', [Validators.required, Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/)]),
      CompanyID: new FormControl(this.user.CompanyID, Validators.required),
      CreatedBy: new FormControl(this.user.UserID, Validators.required),
      StateID: new FormControl(null, Validators.required),
    });
    this.fetchstate();
  }
  fetchstate() {
    this.service.states()
      .pipe(first())
      .subscribe(
        data => {
          if (data.error) {
            console.log(data.error);
            return;
          } else {
            this.StateArray = data.data;
          }
        });
  }
  isValid(event) {
    if ((event.keyCode >= 48 && event.keyCode <= 57) && event.target.value.length < 10) {
    } else {
      return false;
    }
  }
  onSubmit() {
    this.submitted = true;
    console.log(this.lawyerForm.value);
    console.log(this.lawyerForm);
    if (this.lawyerForm.valid) {
      this.isLoading = true;
      this.service.addnewLawyer(this.lawyerForm.value)
        .subscribe(res => {
          this.submitted = false;
          this.isLoading = false;
          this.lawyerForm.reset();
          if (res.status === 200) {
            Swal.fire({
              title: 'Added',
              text: res.message,
              type: 'success',
              showConfirmButton: false,
              timer:3000
            }).then(() => {
              this.router.navigate(['/loan/lawyer']);    
          });
        } else {
          this.isLoading = false;
          Swal.fire({
            title: res.error_code,
            text: res.message,
            type: 'error',
            showConfirmButton: false,
            timer:3000
          });
        }
      }, (error) => {
        this.isLoading = false;
        Swal.fire({
          title: error.error_code,
          text: error.message,
          type: 'error',
          showConfirmButton: false,
          timer:3000
        });
      });
    }
  }

  get f() { return this.lawyerForm.controls; }
}

