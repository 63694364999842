import { NgxEditorModule } from 'ngx-editor';
import { LawyersComponent } from './lawyers/lawyers.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { DataTablesModule } from 'angular-datatables';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UIModule } from './../shared/ui/ui.module';
import { NgbAccordionModule, NgbDatepickerModule, NgbProgressbarModule, NgbModalModule, NgbAlertModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { NumbersOnlyDirective } from '../directive/numbers-only.directive';
import { BankModuleRoutingModule } from './bank-module-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ApplicationComponent } from './application/application.component';
import { LayoutsModule } from '../layouts/layouts.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgApexchartsModule } from 'ng-apexcharts';
import { ApplicantDashboardComponent } from './applicant-dashboard/applicant-dashboard.component';
import { AddApplicationComponent } from './add-application/add-application.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { ViewDocumentComponent } from './view-document/view-document.component';
import { UploadDocumentComponent } from './upload-document/upload-document.component';
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { PvrReportComponent } from './pvr-report/pvr-report.component';
import { GeneratePVRReportComponent } from './generate-pvr-report/generate-pvr-report.component';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { AssignmentsComponent } from './assignments/assignments.component';
import { AssignmentComponent } from './assignment/assignment.component';
import { PublicNoticesComponent } from './public-notices/public-notices.component';
import { PublicNoticeComponent } from './public-notice/public-notice.component';
import { TitleClearListComponent } from './title-clear-list/title-clear-list.component';
import { TitleClearComponent } from './title-clear/title-clear.component';
import { PublicNoticeViewComponent } from './public-notice-view/public-notice-view.component';
import { TitleClearCompleteComponent } from './title-clear-complete/title-clear-complete.component';
import { ViewFilterDocumentsComponent } from './view-filter-documents/view-filter-documents.component';
import { BulkUploadComponent } from './bulk-upload/bulk-upload.component';
import { AngularMaterialModule } from '../angular-material/angular-material.module';
import { AssignLawyerComponent } from './assign-lawyer/assign-lawyer.component';
import { AddLawyerComponent } from './add-lawyer/add-lawyer.component';
import { SelectDocumentsComponent } from './select-documents/select-documents.component';
import { UploadLawyerDocumentsComponent } from './upload-lawyer-documents/upload-lawyer-documents.component';
import { ViewIpvrDocumentsComponent } from './view-ipvr-documents/view-ipvr-documents.component';
import { ViewTitleclearDocumentsComponent } from './view-titleclear-documents/view-titleclear-documents.component';
import { AddTitleclearDocumentsComponent } from './add-titleclear-documents/add-titleclear-documents.component';
import { AddIpvrDocumentsComponent } from './add-ipvr-documents/add-ipvr-documents.component';
import { SearchInProgressComponent } from './search-in-progress/search-in-progress.component';
import { AssignValuersComponent } from './assign-valuers/assign-valuers.component';
import { AddValuerComponent } from './add-valuer/add-valuer.component';
import { ValuationDocumentsComponent } from './valuation-documents/valuation-documents.component';
import { ValuersAssignmentsComponent } from './valuers-assignments/valuers-assignments.component';
import { MortgageProtectionComponent } from './mortgage-protection/mortgage-protection.component';
import { PastPublicNoticesMainComponent } from './past-public-notices-main/past-public-notices-main.component';
import { ViewPastPublicNoticeComponent } from './view-past-public-notice/view-past-public-notice.component';
import { AddPastPublicNoticeComponent } from './add-past-public-notice/add-past-public-notice.component';
import { AddResponseComponent } from './add-response/add-response.component';
import { ViewNoticeComponent } from './view-notice/view-notice.component';
import { PinchZoomModule } from './pinch-zoom/pinch-zoom.module';
import { RequestDocumentDialogComponent } from './request-document-dialog/request-document-dialog.component';
import { RequestedDocumentListComponent } from './requested-document-list/requested-document-list.component';
import { ViewRequestedDocumentsComponent } from './view-requested-documents/view-requested-documents.component';
import { UploadDocumentDialogComponent } from './upload-document-dialog/upload-document-dialog.component';
import { ViewResponseDialogComponent } from './view-response-dialog/view-response-dialog.component';
import { AddPublicNoticeRulesComponent } from './add-public-notice-rules/add-public-notice-rules.component';
import { ViewTitleClearReportComponent } from './view-title-clear-report/view-title-clear-report.component';
import { DocumentListComponent } from './document-list/document-list.component';
import { IpvrSentListComponent } from './ipvr-sent-list/ipvr-sent-list.component';
import { TitleClearApplicationsComponent } from './title-clear-applications/title-clear-applications.component';
import { SharedModule } from '../shared/shared.module';
import { SafePipe } from '../shared/safe-pipe';
import { MortgageProtectedListComponent } from './mortgage-protected-list/mortgage-protected-list.component';
import { PostMortgageDashboardComponent } from './post-mortgage-dashboard/post-mortgage-dashboard.component';
import { ImageModalComponent } from './image-modal/image-modal.component';
import { ViewGppComponent } from './view-gpp/view-gpp.component';
import { ViewGppDocComponent } from './view-gpp-doc/view-gpp-doc.component';
import { ConfimDialogComponent } from '../shared/components/confim-dialog/confim-dialog.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ViewDocumentDialogComponent } from './view-document-dialog/view-document-dialog.component';
import { ViewLastPPPAlertDocumentComponent } from '../view-last-pppalert-document/view-last-pppalert-document.component';
import { ViewPvrComponent } from './view-pvr/view-pvr.component';
import { AddApplicationByImageComponent } from './add-application-by-image/add-application-by-image.component';
import { AddPropertiesByImageComponent } from './add-properties-by-image/add-properties-by-image.component';
import { ViewPvrListComponent } from './view-pvr-list/view-pvr-list.component';
import { AddPosApplicationComponent } from './add-pos-application/add-pos-application.component';
import { DialogboxComponent } from './dialogbox/dialogbox.component';
import { AdminUploadLawyerComponent } from './admin-upload-lawyer/admin-upload-lawyer.component';
import { TitleClearApplicantDashboardComponent } from './title-clear-applicant-dashboard/title-clear-applicant-dashboard.component';
import { TccDashboardLawyerComponent } from './tcc-dashboard-lawyer/tcc-dashboard-lawyer.component';
import { AddModApplicationComponent } from './add-mod-application/add-mod-application.component';
import { AddPropertiesForModComponent } from './add-properties-for-mod/add-properties-for-mod.component';
import { EllipsisPipe } from '../ellipsis.pipe';
import { GppDashboardComponent } from '../gpp/gpp-dashboard/gpp-dashboard.component';
import { AddQuestionsComponent } from './add-questions/add-questions.component';
import { QuestionMasterComponent } from './question-master/question-master.component';


@NgModule({
  declarations: [ApplicantDashboardComponent, ApplicationComponent,GppDashboardComponent,
    DashboardComponent, AddApplicationComponent, ViewDocumentComponent, LawyersComponent,
    UploadDocumentComponent, PvrReportComponent, GeneratePVRReportComponent, NumbersOnlyDirective,ViewLastPPPAlertDocumentComponent,
    AssignmentsComponent, AssignmentComponent, PublicNoticesComponent, PublicNoticeComponent,ViewDocumentDialogComponent,ViewPvrListComponent,
    TitleClearListComponent, TitleClearComponent, AssignLawyerComponent, PublicNoticeViewComponent, TitleClearCompleteComponent, ViewFilterDocumentsComponent, BulkUploadComponent, AddLawyerComponent, SelectDocumentsComponent, UploadLawyerDocumentsComponent, ViewIpvrDocumentsComponent,ViewPvrComponent, ViewTitleclearDocumentsComponent, AddTitleclearDocumentsComponent, AddIpvrDocumentsComponent, SearchInProgressComponent, AssignValuersComponent, AddValuerComponent, ValuationDocumentsComponent, ValuersAssignmentsComponent, MortgageProtectionComponent, PastPublicNoticesMainComponent, ViewPastPublicNoticeComponent, AddPastPublicNoticeComponent, AddResponseComponent, ViewNoticeComponent, RequestDocumentDialogComponent, RequestedDocumentListComponent, ViewRequestedDocumentsComponent, UploadDocumentDialogComponent, ViewResponseDialogComponent, AddPublicNoticeRulesComponent, ViewTitleClearReportComponent, DocumentListComponent, IpvrSentListComponent, TitleClearApplicationsComponent, MortgageProtectedListComponent, PostMortgageDashboardComponent, ImageModalComponent, ViewGppComponent, ViewGppDocComponent, ViewPvrComponent, AddApplicationByImageComponent, AddPropertiesByImageComponent, AddPosApplicationComponent, DialogboxComponent, AdminUploadLawyerComponent, TitleClearApplicantDashboardComponent, TccDashboardLawyerComponent, AddModApplicationComponent, AddPropertiesForModComponent, AddQuestionsComponent, QuestionMasterComponent],
  imports: [
    CommonModule, LayoutsModule, UIModule, FormsModule, NgSelectModule, NgbProgressbarModule, NgApexchartsModule, NgbAccordionModule,
    BankModuleRoutingModule, NgbDatepickerModule, DataTablesModule, NgbModalModule, NgbAlertModule, NgbDropdownModule,
    ReactiveFormsModule, FormsModule, AutocompleteLibModule, FileUploadModule, PDFExportModule,MatTooltipModule,
    PdfViewerModule, NgxEditorModule, AngularMaterialModule, PinchZoomModule, PdfViewerModule, SharedModule
  ],
  exports:[AdminUploadLawyerComponent],
   providers: [DatePipe,EllipsisPipe], entryComponents: [AdminUploadLawyerComponent,AssignLawyerComponent,RequestDocumentDialogComponent,ViewDocumentDialogComponent, UploadDocumentDialogComponent,DialogboxComponent, ViewResponseDialogComponent, TitleClearCompleteComponent, ImageModalComponent,ConfimDialogComponent]
})
export class BankModuleModule { }
