import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { CookieService } from 'src/app/core/services/cookie.service';
import { GeneralService } from 'src/app/services/general.service';
import Swal from 'sweetalert2';
class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}
@Component({
  selector: 'app-valuation-documents',
  templateUrl: './valuation-documents.component.html',
  styleUrls: ['./valuation-documents.component.scss']
})
export class ValuationDocumentsComponent implements OnInit {
  lawyerType: any;
  dtOptions: DataTables.Settings = {};
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtTrigger = new Subject<any>();
  user: any;
  breadCrumbItems: ({ label: string; path: string; active?: undefined; } | { label: string; path: string; active: boolean; })[];
  AppID: any;
  LawyerID: any;
  DocumentList: any;
  documentID: any = [];
  isLoading: boolean;
  constructor(
    // public dialogRef: MatDialogRef<AssignLawyerComponent>,
    // @Inject(MAT_DIALOG_DATA) public data: any,
    private service: GeneralService,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private cookie: CookieService
  ) {
    this.AppID = this.route.snapshot.params.AppID;
    this.LawyerID = this.route.snapshot.params.LawyerID;
  }

  ngOnInit() {
    this.breadCrumbItems = [
      { label: 'Dashboard', path: '/' },
      { label: 'Applications', path: '/loan/applications' }, 
      { label: 'Property Valuation', path: '/loan/title-search/' + this.AppID},
      { label: 'Select Documents', path: '/loan/documents/' + this.AppID + '/' + this.LawyerID, active: true }];   
    this.user = JSON.parse(this.cookie.getCookie('currentUser'));
    this.dtOptions = {
      pagingType: "simple_numbers",
      pageLength: 10,
      ordering: true,
      ajax: (dataTablesParameters: any, callback) => {
        this.http
          .get<DataTablesResponse>(
            `${this.service.getDocuments()}?AppID=${this.AppID}`
            // + `?pageno=${((dataTablesParameters.start+dataTablesParameters.length)/ dataTablesParameters.length)}`+
            // `${dataTablesParameters.length.value!=''?'&recordsno='+dataTablesParameters.length:''}`+
            // `${dataTablesParameters.search.value!=''?'&search='+dataTablesParameters.search.value:''}`,
             ,{}
          ).subscribe((resp: any) => {
            if(resp.dataList) {
              this.DocumentList = resp.dataList;
              this.DocumentList.forEach(element => {
                element.isSelected = 1;
              });
            } else {
              this.DocumentList = [];
            }
            
            this.SelectDocumentID();
            callback({
              recordsTotal: this.DocumentList.length,
              recordsFiltered: this.DocumentList.length,
              data: this.DocumentList,
            });
          });
      },
      responsive: true,
      columns: [
        {
          title: 'Sr.No.',
          data: null,
          render: (data, type, row, meta) => {
            return meta.row + 1;
          },
        },
        {
          title: 'Document',
          data: null,
          render: (data) => {
            return data.DocumentName;
          },
        },
        // {
        //   title: 'Status',
        //   data: 'IsActive', render: (data) => {
        //     if (data === true) {
        //       return `<span class="badge badge-success p-1">Received</span>`;
        //     } else {
        //       return `<span class="badge badge-secondary p-1">Pending</span>`;
        //     }
        //   }
        // },
        {
          title: 'Is Document Required',
          data: null,
          render(data) {
            return `
            <input
              class="assign"
              type="radio"
              checked="checked"
              DocID="${data.DocumentType}"
              id="${data.DocumentName}"
              name="${data.DocumentName}"
              value=1
              tabindex="0"
            />
            Yes
            <t>
            <input
              class="assign"
              type="radio"
              DocID="${data.DocumentType}"
              id="${data.DocumentName}"
              name="${data.DocumentName}"
              value=0
              tabindex="0"
            />
            No
            `;            
          },
        },
      ],
      drawCallback: () => {
        $('.assign').on('click', (e) => {
          this.DocumentList.forEach(element => {
            if(element.DocumentType == e.target.getAttribute('DocID')) {
              element.isSelected = Number(e.target.getAttribute('value'));
            }
          });
          this.SelectDocumentID();
        });
      },
    };
  }
  SelectDocumentID() {
    this.documentID = [];
    this.DocumentList.forEach(element => {
      if(element.isSelected == 1) {
        this.documentID.push(element);
      }
    });
  }
  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }
  ngAfterViewInit() {
    this.dtTrigger.next();
  }
  save() {
    console.log();
    this.isLoading = true;
    const data = {
      "dataList" : this.documentID
    }
    this.router.navigate(['/loan/valuer'], { queryParams: { AppID: this.AppID } });
    // this.service.updateRequiredDocuments(data)
    //     .subscribe((res: any) => {
    //       this.isLoading = false;
    //       if (res.status === 200) {
    //         Swal.fire({
    //           title: 'Document Selected',
    //           text: res.message,
    //           type: 'success',
    //           timer: 2000
    //         }).then(() => {
    //           this.router.navigate(['/loan/valuer'], { queryParams: { AppID: this.AppID } });
    //         });
    //       } else {
    //         Swal.fire({
    //           title: res.error_code,
    //           text: res.error,
    //           type: 'error'
    //         });
    //       }
    //     },(error: any) => {   
    //       console.log(error.error_code); 
    //       this.isLoading = false;
    //       Swal.fire({
    //         title: error.error_code,
    //         text: error.error,
    //         type: "error",
    //       });
          
    //     });
  }
}
