import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { ErrorInterceptor } from './core/helpers/error.interceptor';
import { JwtInterceptor } from './core/helpers/jwt.interceptor';
import { FakeBackendProvider } from './core/helpers/fake-backend';
import { DataTablesModule } from 'angular-datatables';
import { LayoutsModule } from './layouts/layouts.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ReactiveFormsModule } from '@angular/forms';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { CommonModule, DatePipe } from '@angular/common';
import { AngularMaterialModule } from './angular-material/angular-material.module';
import { ECheckModule } from './e-check/e-check.module';
import { PosModule } from './POS/pos.module';
import { CGApplicationsModule } from './cg-applications/cg-applications.module';
// import { EllipsisPipe } from './ellipsis.pipe';


@NgModule({
  declarations: [
    AppComponent,
    // EllipsisPipe,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LayoutsModule,
    AppRoutingModule,
    ECheckModule,
    PosModule,
    CGApplicationsModule,
    ReactiveFormsModule, DataTablesModule, PDFExportModule, AngularMaterialModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

    // provider used to create fake backend
    FakeBackendProvider
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
