import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { GeneralService } from 'src/app/services/general.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-public-notice-rules',
  templateUrl: './add-public-notice-rules.component.html',
  styleUrls: ['./add-public-notice-rules.component.scss']
})
export class AddPublicNoticeRulesComponent implements OnInit {
  breadCrumbItems: any;
  isLoading: boolean;
  
  public document: FormGroup;
  submitted: boolean;
  Amount: any;
  currentUser: any;
  ID: any;
  constructor(private service: GeneralService, private route: ActivatedRoute, private Fb: FormBuilder, private router: Router) {
    this.breadCrumbItems = [
    { label: 'Dashboard', path: 'loan' }, 
    { label: 'Set Public Notice Rule', path: '/loan/app-public-notice-rules', active: true },];
    this.isLoading = false;
  }

  ngOnInit() {
    this.getPublishNoticeAmountData();
    this.submitted = false;
    this.currentUser = this.service.getcurrentUser();
    this.document = this.Fb.group({
      Amount: new FormControl(this.Amount, Validators.required),
      checked: new FormControl(0, Validators.required),
    });
  }
  save() {
    this.submitted = true;
    if (this.document.valid) {
      this.isLoading = true;
      if(this.ID != undefined && this.ID != null) {
        const data = {
          Amount: this.document.value.Amount,
          BankID: 51,   
          "CreatedBy":this.currentUser.UserID
        }
        this.service.updatePublishNoticeAmount(data, this.ID).subscribe((res: any ) => {
          this.isLoading = false;
          if (res.error) {
            Swal.fire({
              title: res.error_code,
              text: res.message,
              type: 'error',
              showConfirmButton: false,
              timer:3000
            });
            return;
          } else {
            Swal.fire({
              title: 'Success',
              text: 'Document Added Successfully',
              type: 'success',
              showConfirmButton: false,
              timer:3000
            });
          }
          this.isLoading = false;
        });
      } else {
        const data = {
          Amount: this.document.value.Amount,
          BankID: 51,   
          "CreatedBy":this.currentUser.UserID
        }
        this.service.addDataToPublishNoticeAmount(data).subscribe((res: any) => {
          this.isLoading = false;
          if (res.error) {
            Swal.fire({
              title: res.error_code,
              text: res.message,
              type: 'error',
              showConfirmButton: false,
              timer:3000
            });
            return;
          } else {
            Swal.fire({
              title: 'Success',
              text: 'Document Added Successfully',
              type: 'success',
              showConfirmButton: false,
              timer:3000
            });
          }
          this.isLoading = false;
        });
      }
      
    } else {
      console.log(this.document.value);
      Swal.fire({
        title: 'Invalid',
        text: 'Invalid Form Data',
        type: 'error',
        showConfirmButton: false,
        timer:3000
      });
    }
  }
  get f() { return this.document.controls; }
  getPublishNoticeAmountData() {
    this.service.getPublishNoticeAmountData('51')
      .pipe(first())
      .subscribe(
        data => {
          if (data.error) {
            Swal.fire({
              title: data.error_code,
              text: data.message,
              type: 'error',
              showConfirmButton: false,
              timer:3000
            });
            return;
          } else {
            this.Amount = data.data[0].Amount;
            this.ID = data.data[0].ID;
            this.document.controls.Amount.setValue(this.Amount);
            console.log(this.Amount);
          }
        });
  }
  
}
