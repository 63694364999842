import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-image-modal',
  templateUrl: './image-modal.component.html',
  styleUrls: ['./image-modal.component.scss']
})
export class ImageModalComponent implements OnInit {
  breadCrumbItems: Array<{}>;
  currentUser: any;
  ID: any;
  AppID: any;
  alertData: any;
  isLoading: boolean;
  constructor(
    private service: GeneralService, private Route: ActivatedRoute, private sanitizer: DomSanitizer, private router: Router) {
      this.currentUser = this.service.getcurrentUser();
      this.ID = this.Route.snapshot.params.alertid;    
  }

  ngOnInit() {
    this.isLoading = true;
    this.service.GetAlert(this.ID).subscribe((Response) => {
      this.alertData = Response.data[0];
      this.isLoading = false;
    });
    this.AppID = this.Route.snapshot.params.AppID;
    this.service.GetApplicationInformation(this.Route.snapshot.params.AppID).subscribe((Response) => {
     
        this.breadCrumbItems = [{ label: 'Dashboard', path: 'loan' }, { label: 'Applications', path: 'loan/applications' },
        {label: `${Response.data[0].FirstName} ${Response.data[0].LastName}` ,
         path: `/loan/post-mortgage-dashboard/${this.Route.snapshot.params.AppID}`}
        , { label: 'View GPP', path: '/', active: true }];
      
      });
  }

}
