import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {FormBuilder,FormGroup,FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  public LoginForm!:FormGroup;
  submitted:boolean=false;

  constructor(private router : Router,private formBuilder : FormBuilder) { }

  ngOnInit() {
    this.LoginForm = this.formBuilder.group({

      email : [null,Validators.required],
      password:[null,Validators.required]

    });
  }

}
