import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { GeneralService } from 'src/app/services/general.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-shares-per-owner-form',
  templateUrl: './add-shares-per-owner-form.component.html',
  styleUrls: ['./add-shares-per-owner-form.component.scss']
})
export class AddSharesPerOWnerFormComponent implements OnInit {

  breadCrumbItems: Array<{}>
  form: FormGroup;
  isLoading: boolean;
  ownerList : any[] = [];
  file: Array<any>;
  data:any;
  submitted: boolean;
  dtOptions: DataTables.Settings = {};
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective | undefined;
  dtTrigger = new Subject<any>();
  ApplicationID:string;
  constructor(private Fb:FormBuilder, private Service: GeneralService, private router: Router) {
    this.dtOptions = {
      paging: false,
      searching: false,
      ordering: false,
      info: false,
    }
    let a = router.getCurrentNavigation().extras;

    if(a){
      if(a.state){
        this.ownerList = a.state.appData.owners;
        this.data = a.state.appData;
        this.ApplicationID = a.state.appData.POS_AppID;
        localStorage.setItem('POSAppID',this.ApplicationID);
        localStorage.setItem('AppData',JSON.stringify(this.data));
      }
    }
    if(localStorage.getItem('POSAppID')){
      this.ApplicationID = localStorage.getItem('POSAppID');
    }
    if(localStorage.getItem('AppData'))
    {
      this.data =  JSON.parse(localStorage.getItem('AppData'));
      this.ownerList = this.data.owners
    }
   }

  ngOnInit() {
    this.breadCrumbItems = [{ label: 'POS Dashboard', path: '/POS/POS-dashboard' },
    { label: 'Add Application', path: '/', active: true }];
    this.form = this.Fb.group({
      File: new FormControl(null,Validators.required),
      comment: new FormControl(''),
      PropertyOwners: this.Fb.array([
      ]),
      
    })
    if(this.ownerList.length){
      this.ownerList.forEach((i)=>{
        this.addOwner(i);
       })
    }
    
  }
  initOwner(i?) {
    if (i && i !== undefined) {
      return this.Fb.group({
        owner_name: new FormControl(i.owner_name, Validators.required),
        Share: new FormControl(i.Share,[Validators.required,Validators.pattern("^[0-9]\.*$")]),
        owner_id: new FormControl(i.owner_id, Validators.required),
        comment: new FormControl('')
      });
    } else {
      return this.Fb.group({
        owner_name: new FormControl(null, Validators.required),
        Share: new FormControl('', [Validators.required,Validators.pattern("^[0-9]\.*$")]),
        owner_id: new FormControl(i.owner_id, Validators.required),
        comment:new FormControl(''),
      });
    }

  }

  onchange(event: any) {

    if (this.file.length) {
      const unsupportedFiles = [];
      const supportedFiles = [];
      for (let i = 0; i < this.file.length; i++) {
        const filetype = this.file[i].type;
        if (
          filetype.toLowerCase() !== 'application/pdf' &&
          filetype.toLowerCase() !== 'image/png' &&
          filetype.toLowerCase() !== 'image/jpeg' &&
          filetype.toLowerCase() !== 'image/jpg'
        ) {
          unsupportedFiles.push(this.file[i].name);
        }
        else {
          supportedFiles.push(this.file[i])
        }
      }
      if (unsupportedFiles.length > 0) {
        Swal.fire({
          title: 'Error',
          text: `${unsupportedFiles.join(', ')} File(s) Are Not Supported`,
          type: 'error',
          showConfirmButton: false,
          timer: 3000
        }).then(() => {
          this.file = supportedFiles;
        });
      } else {
        // Code to execute when all file types are supported
        // ...
      }
    }
  }


  getPOSData(){
    this.isLoading = true;
    this.Service.getPOSData().subscribe((res: any) => {
      if (res) {
        this.ownerList = res.data[0].owners;
        this.data = res.data[3]
        if(this.ownerList.length){
          this.ownerList.forEach((i)=>{
           this.addOwner(i);
          })
        }
        this.isLoading = false;
      }
    },
      (error) => {
        this.isLoading = false;
        Swal.fire({
          title: error.status,
          text: "Internal Server Error",
          type: "error",
          timer: 1500,
          showConfirmButton: false
        });
      })
  }

  addOwner(i){
    const control = this.f.PropertyOwners as FormArray;
    control.push(this.initOwner(i));
  }

  get f(){
    return this.form.controls
  }
  
  get PropertyOwners(){
    return this.form.get('PropertyOwners') as FormArray;
  }
  
  
  save() {
    this.checkOwnersValidation();
    console.log('this.form',this.form)
    this.submitted = true;
      if(this.form.valid && this.file.length >= 1){
        const input = new FormData();
        input.append('POS_AppID',this.ApplicationID)
        input.append('comment',this.form.controls.comment.value ? this.form.controls.comment.value : '')
        for (var i = 0; i < this.file.length; i++) {
          input.append("uploadfile", this.file[i], this.file[i].name);
        }
        if (this.form.controls.PropertyOwners['controls'].length) {
          let ownerNames = [];
          input.append("owners", JSON.stringify(this.form.controls.PropertyOwners.value))
        }
        this.isLoading = true;
        this.Service
        .updatePOSApplication(input).subscribe((res:any) => {
          this.isLoading = false;
          this.submitted = false;
          Swal.fire({
            title: `POS Application Updated`,
            text: `Successfuly Updated POS Application`,
            type: 'success',
            showConfirmButton: false,
            timer: 1500
          });
          this.router.navigate(['/POS/POS-dashboard']);
        },
          ((error) => {
            this.isLoading = false;
            Swal.fire({
              title: 'Failed ',
              text: 'Faild to Add Application',
              type: 'error',
              showConfirmButton: false,
              timer: 1500
            })
          }))
      }
      else{
        Swal.fire({
          title: 'Invalid',
          text: 'Invalid Form Data',
          type: 'error',
          showConfirmButton: false,
          timer: 3000
        });
      }
  }

  
  checkOwnersValidation(){
    console.log('owners length',this.form.controls['PropertyOwners'])
    if(this.form.controls['PropertyOwners'].value.length){
      for(let i=0;i<this.form.controls['PropertyOwners'].value.length;i++){
        for(var control in this.form.controls['PropertyOwners']['controls'][i].value){
          if(this.form.controls['PropertyOwners']['controls'][i].value[control]){
            console.log(this.form.controls['PropertyOwners']['controls'][i].value[control]);
            // console.log(this.form.controls['PropertyOwners']['controls'][i].value[control].trim())
            let a = (typeof (this.form.controls['PropertyOwners']['controls'][i].value[control]) === 'string') ? this.form.controls['PropertyOwners']['controls'][i].value[control].trim() : this.form.controls['PropertyOwners']['controls'][i].value[control];
            this.form.controls['PropertyOwners']['controls'][i].controls[control].setValue(a);
          }
        }
      }
    }
    if(this.form.controls['comment'].value.length){
      this.form.controls.comment.setValue(this.form.controls['comment'].value.trim());
    }
  }

}
