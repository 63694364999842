
import { Component, ComponentFactoryResolver, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { GeneralService } from 'src/app/services/general.service';
import Swal from 'sweetalert2';
import { FN_SubInput } from './fn_subInput.validator';
import { event } from 'jquery';

@Component({
  selector: 'app-add-application',
  templateUrl: './add-application.component.html',
  styleUrls: ['./add-application.component.scss']
})
export class AddApplicationComponent implements OnInit {
  breadCrumbItems: any;
  notfoundshow: boolean;
  isLoading: boolean;
  keyword = 'Area';
  areas: string[];
  propertyType: any;
  bankList: any;
  initialValue = '';
  loanTypes: any;
  currentUser: any;
  DistrictList: any;
  StateList: any[];
  baseDocType: any;
  fieldsData: any[];
  DataList: any = {};
  URL: string;
  dynamicFields = [];
  stateID: any;
  StateBaseDocType: any;
  propertyDetails: any;

  public loan: FormGroup;
  submitted: boolean;
  myValue: any;
  myValue_1: any;
  myValue_3: any;
  myValue_4: any;
  myValue_5: any;
  data: any = { fieldsData: [], DataLists: [] };
  constructor(private service: GeneralService, private Fb: FormBuilder, private router: Router) {
    this.breadCrumbItems = [{ label: 'Dashboard', path: 'loan' }, { label: 'Applications', path: '/loan/applications' },
    { label: 'Add New Application', path: '/loan/addapplication', active: true }];
    this.isLoading = false;
  }

  ngOnInit() {
    this.notfoundshow = false;
    this.submitted = false;
    this.currentUser = this.service.getcurrentUser();
    this.service.GetLoanTypes().subscribe((res) => {
      this.loanTypes = res.data;
    });
    this.service.GetPVRBankList().subscribe((res) => {
      this.bankList = res.data;
    });
    this.service.GetLoanPropertyTypes().subscribe((res) => {
      this.propertyType = res.data;

    });
    this.loan = this.Fb.group({
      PropertyOwners: this.Fb.array([
        this.initOwner()
      ]),
      Properties: this.Fb.array([
        // this.initproperty()
      ]),
      BaseDocumentType: new FormControl(null),
      PropertyType: new FormControl(null, Validators.required),
      ApplicationNo: new FormControl('', [Validators.required, Validators.pattern('^[0-9a-zA-Z-]+$')]),
      // taluka: new FormControl('', Validators.required),
      // BankID: new FormControl(null, Validators.required),
      // BranchCode: new FormControl('', Validators.required),
      ApplicantFirstName: new FormControl('', [Validators.required,Validators.pattern('^[a-zA-Z,/-/ ]*$')]),
      // Khata_No: new FormControl(''),
      ApplicantLastName: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z,/-/ ]*$')]),
      MobileNo: new FormControl('', [Validators.pattern("^[2-9][0-9]{9}$")]),
      Email: new FormControl('', [Validators.email]),
      LoanPropertyTypeID: new FormControl(null, Validators.required),
      IsOwnerSame: new FormControl('False', Validators.required),
      PropertyAddress: new FormControl('', Validators.required),
      TypeOfLoan: new FormControl(null, Validators.required),
      IsLien: new FormControl('False'),
      IsForTC:new FormControl('False'),
      // TalukaID: new FormControl('', Validators.required),
      // VillageID: new FormControl('', Validators.required),
      // DistrictID: new FormControl(null, Validators.required),
      // SurveyNo: new FormControl('', [Validators.required, Validators.pattern(/^\S*$/)]),
      // CitySurveyNumber: new FormControl(''),
      // TpNo: new FormControl(''),
      // FpNo: new FormControl(''),
      LienAmount: new FormControl('', Validators.required),
      LienDate: new FormControl('', Validators.required),
      LienPersonName: new FormControl('', [Validators.required, Validators.pattern(/^\S*$/)]),
      LienFrom: new FormControl('', [Validators.required, Validators.pattern(/^\S*$/)]),
      LoanAmount: new FormControl('', [Validators.required]),
      CreatedBy: new FormControl(this.currentUser.UserID),
      StateID: new FormControl(null, Validators.required),
    });

    this.fetchstatelist();
    this.onChangeLien('False')
  }


  addAnotherProperty(i) {
    if (this.data['DataLists'][0]['DistrictID']) {
      this.data['DataLists'].push({ 'DistrictID': this.data['DataLists'][0]['DistrictID'] });
    }
    if(this.data['DataLists'][0]['CityID']){
      this.data['DataLists'].push({ 'CityID': this.data['DataLists'][0]['CityID'] });
    }
    let ele = this.Fb.group({});
    // this.data['DataLists'][i][element.DBColumnName]
    this.fieldsData.forEach((element, index) => {
      this.dynamicFields.push(element.DBColumnName);
      if (element.FieldType === 'Text' && (element.ValidationFunctionName != '' && element.ValidationFunctionName != null )) {
        ele.addControl(`${element.DBColumnName}`, this.Fb.control(null, this[element.ValidationFunctionName]()));
      } else if ((element.FieldType === 'Dropdown' && element.APIName != '' && element.IsDependantField != true)) {
        ele.addControl(`${element.DBColumnName}`, this.Fb.control(null));
        this.URL = element.APIName + '/' + this.stateID;
        this.isLoading = true;

        this.service.dynamicData(this.URL).pipe(first()).subscribe((data) => {
          this.URL = '';
          this.isLoading = false;
          if (data.error) {
            Swal.fire({
              title: data.error_code,
              text: data.message,
              type: 'error',
              showConfirmButton: false,
              timer: 3000
            });
            return;
          } else {
            if (element.DBColumnName !== 'DistrictID') {
              this.data['DataLists'][i][element.DBColumnName] = data.data;
            }
          }
        });
        // } else if(element.FieldType === 'Dropdown' && element.APIName != '' && element.IsDependantField == true){
        //   ele.addControl(`${element.DBColumnName}`, this.Fb.control(null));
      }
      else {
        ele.addControl(`${element.DBColumnName}`, this.Fb.control(null));
      }
    });
    if (this.data['fieldsData'].length) {
      this.users.push(ele);
      // console.log("loan form", this.loan);
    }

  }
  removeProperty(index) {
    const control = this.f.Properties as FormArray;
    control.removeAt(index);
    this.data.DataLists.splice(index, 1);
  }
  initEncumbranceDetails(i?) {
    if (i && i !== undefined) {
      return this.Fb.group({
        LoanTakenBy: new FormControl(i.LoanTakenBy),
        LoanAmount: new FormControl(i.LoanAmount),
        LoanTakenOn: new FormControl(i.LoanTakenOn),
        LoanGivenBy: new FormControl(i.LoanGivenBy),
      });
    } else {
      return this.Fb.group({
        LoanTakenBy: new FormControl(""),
        LoanAmount: new FormControl(""),
        LoanTakenOn: new FormControl(""),
        LoanGivenBy: new FormControl(""),
      });
    }
  }


  removeWhitespace(value: any) {
    // this.myValue = this.myValue.replace(/\s/g, '');
    // console.log('ApplicantFirstName;',this.loan.get('ApplicantFirstName').invalid)
  }

  removeWhitespace_1(value: any) {
    this.myValue_1 = this.myValue_1.replace(/\s/g, '');
  }

  removeWhitespace_3(value: any) {
    this.myValue_3 = this.myValue_3.replace(/\s/g, '');
  }
  removeWhitespace_4(value: any) {
    this.myValue_4 = this.myValue_4.replace(/\s/g, '');
  }

  onChangeLien(e) {
    if (e === 'False') {
      this.f.LienAmount.disable();
      this.f.LienDate.disable();
      this.f.LienPersonName.disable();
      this.f.LienFrom.disable();
    } else {
      this.f.LienAmount.enable();
      this.f.LienDate.enable();
      this.f.LienPersonName.enable();
      this.f.LienFrom.enable();
    }
  }

  onChangeIsForTC(e){
    // console.log('e',e);
  }
  valid(e) {
    if (!((e.keyCode > 95 && e.keyCode < 106)
      || (e.keyCode > 47 && e.keyCode < 58)
      // tslint:disable-next-line: triple-equals
      || e.keyCode == 8)) {
      return false;
    }
    if (e.target.value.length > 10) {
      // tslint:disable-next-line: triple-equals
      if (e.keyCode != 8) {
        return false;
      }
    }
  }
  // searchcountry(term: string, item: any){
  //   term = term.toLocaleLowerCase();
  //   console.log(term,item);
  // //  return item.countrycode.toLocaleLowerCase().indexOf(term) > -1 || item.countryname.toLocaleLowerCase().includes(term);
  // }
  save() {
    this.checkValidationForProperties();
    this.checkForValidation();
    // console.log('loan form',this.loan.controls);
    this.onChangeLien(this.f.IsLien.value);
    this.submitted = true;
    let data = this.loan.value;
    if(data.IsOwnerSame === 'False'){
      this.initOwner()
      let owners = []
      this.loan.controls.PropertyOwners['controls'].forEach((i, index) => {
        owners.push(this.loan.controls.PropertyOwners['controls'][index].getRawValue().OwnerName);
      })
      data['PropertyOwners'] = owners;
      if (this.loan.valid) {
          // this.f.control.value.trim().length === 0;
          this.isLoading = true;
          if(this.loan.controls.StateID.value === 15 && this.loan.controls.BaseDocumentType.value ===4){
            data['Properties'].forEach((item:any,index)=>{
              data['Properties'][index]['Region'] = data['Properties'][index]['RegionID'];

            })
            delete data['Properties']['RegionID'];
            // console.log('data',data);
            this.addApplication(data);
          }
          else{
           this.addApplication(data);
          }
        } else {
          Swal.fire({
            title: 'Invalid',
            text: 'Invalid Form Data',
            type: 'error',
            showConfirmButton: false,
            timer: 3000
          });
        }
    }
    else{
        this.loan.controls.PropertyOwners.clearValidators();
        this.loan.controls.PropertyOwners.updateValueAndValidity();
        this.loan.controls.PropertyOwners['controls'][0]['controls']['OwnerName'].clearValidators()
        this.loan.controls.PropertyOwners['controls'][0]['controls']['OwnerName'].updateValueAndValidity()
        data['PropertyOwners'] = [];
        if (this.loan.valid) {
          this.submitted = false;
            // this.f.control.value.trim().length === 0;
            this.isLoading = true;
            if(this.loan.controls.StateID.value === 15 && this.loan.controls.BaseDocumentType.value ===4){
              data['Properties'].forEach((item:any,index)=>{
                data['Properties'][index]['Region'] = data['Properties'][index]['RegionID'];
                delete data['Properties'][index]['RegionID'];
              })
              // console.log('data',data);
              this.addApplication(data);
            }
            else{
             this.addApplication(data);
            }
            // this.addApplication(data);
          } else {
            
            Swal.fire({
              title: 'Invalid',
              text: 'Invalid Form Data',
              type: 'error',
              showConfirmButton: false,
              timer: 3000
            });
          }
    }
  }
  checkValidationForProperties(){
    if(this.loan.controls['Properties'].value.length){
      for(let i=0;i<this.loan.controls['Properties'].value.length;i++){
        for(var control in this.loan.controls['Properties']['controls'][i].value){
          if(this.loan.controls['Properties']['controls'][i].value[control]){
            let a = (typeof (this.loan.controls['Properties']['controls'][i].value[control]) === 'string') ? this.loan.controls['Properties']['controls'][i].value[control].trim() : this.loan.controls['Properties']['controls'][i][control].value;
            this.loan.controls['Properties']['controls'][i].controls[control].setValue(a);
          }
        }
      }
    }
  }
  checkForValidation() {
    for (var control in this.loan.value) {
      if (this.loan.value[control]) {
        let a = (typeof (this.loan.value[control]) === 'string') ? this.loan.controls[control].value.trim() : this.loan.controls[control].value;
        this.loan.controls[control].setValue(a);
      }
    }
  }

  addApplication(data:any){
    this.service.AddLoanApplication(data).subscribe((res:any) => {
      this.isLoading = false;
      if (res.error) {
        Swal.fire({
          title: res.error_code,
          text: res.message,
          type: 'error',
          showConfirmButton: false,
          timer: 3000
        });
        return;
      }
      else {
        Swal.fire({
          title: 'Success',
          text: 'Application Added Successfully',
          type: 'success',
          showConfirmButton: false,
          timer: 3000
        }).then(() => {
          // console.log('this.loan.controls.IsForTC.value',this.loan.controls.IsForTC.value);
          if(this.loan.controls.IsForTC.value === 'True'){
            // console.log('appid',res.data[0].AppID)
            //  this.service.changeStatus(res.data[0].AppID, "TitleClear In Progress");
             this.service
             .changeStatus(res.data[0].AppID, "TitleClear In Progress")
             .subscribe(() => {
                 this.router.navigate(['loan/title-clear-applications']);
             });
          }
         else{
          this.router.navigate(['/loan/applications']);
         }
        });
      }
      this.isLoading = false;
    });
  }
  getStateBaseDocTypeByStateID(id) {
  
    if(id != undefined){
      this.isLoading = true;
      this.service.getStateBaseDocTypeByStateID(id).subscribe((res: any) => {
        if (this.propertyDetails != undefined) {
          this.loan.controls.BaseDocumentType.setValue(Number(this.propertyDetails.BaseDocumentType));
          this.ChangeBaseDocType(this.propertyDetails.BaseDocumentType);
        }
        this.isLoading = false;
        this.StateBaseDocType = res.data;
      });
    }
  }
  // ChangeBaseDocType(data) {
  //   this.baseDocType = data;
  //   this.areas = [];
  //   if(this.StateBaseDocType != undefined) {
  //     this.loan.controls.PropertyType.setValue(this.StateBaseDocType.find(x => x.ID === data).Name)
  //   }
  //   this.dynamicFields.forEach(element => {
  //     this.loan.removeControl(element);
  //   });
  //   if (data !== undefined) {
  //     let param = new URLSearchParams();
  //     param.set('StateID', this.stateID);
  //     param.set('Service_subtype_id', '1');
  //     param.set('StateBaseDocTypeID', this.baseDocType);
  //     param.set('DisplayLevel', '1');
  //     this.isLoading = true;
  //     this.fieldsData.forEach(element => {
  //       this.loan.removeControl(`${element.DBColumnName}`); 
  //     });
  //     this.service.getDynamicFields(param.toString()).subscribe((res: any) => {
  //       this.isLoading = false;
  //       this.fieldsData = res.data;

  //       this.fieldsData.forEach(element => {
  //         this.dynamicFields.push(element.DBColumnName);

  //         if (element.FieldType === 'Text' && element.ValidationFunctionName != '') {
  //       //     const ele =  this.Fb.control(null, this[element.ValidationFunctionName]())
  //       // let name = element.DBColumnName;
  //       // let  validation = this[element.ValidationFunctionName]();
  //       // this.users.push(this.Fb.group({
  //       //   [name]: [null, validation]
  //       // }));
  //         this.returnFormgroup(element);
  //           // this.loan.addControl(`${element.DBColumnName}`, this.Fb.control(null, this[element.ValidationFunctionName]()));

  //         } else if ((element.FieldType === 'Dropdown' && element.APIName != '' && element.IsDependantField != true)) {
  //           // this.loan.addControl(`${element.DBColumnName}`, this.Fb.control(null));
  //       //     const ele =  this.Fb.control(null, this[element.ValidationFunctionName]())
  //       // let name = element.DBColumnName;
  //       // let  validation = this[element.ValidationFunctionName]();
  //       // this.users.push(this.Fb.group({
  //       //   [name]: [null, validation]
  //       // }));
  //       this.returnFormgroup(element)

  //           this.URL = element.APIName + '/' + this.stateID;
  //           this.isLoading = true;
  //           this.service.dynamicData(this.URL).pipe(first()).subscribe((data) => {
  //             this.URL = '';
  //             this.isLoading = false;
  //             if (data.error) {
  //               Swal.fire({
  //                 title: data.error_code,
  //                 text: data.message,
  //                 type: 'error',
  //                 showConfirmButton: false,
  //                 timer: 3000
  //               });
  //               return;
  //             } else {
  //               this.DataList[element.DBColumnName] = data.data;
  //             }
  //           });
  //         } else {
  //           // this.loan.addControl(`${element.DBColumnName}`, this.Fb.control(null));
  //       //     const ele =  this.Fb.control(null, this[element.ValidationFunctionName]())
  //       // let name = element.DBColumnName;
  //       // let  validation = this[element.ValidationFunctionName]();
  //       // this.users.push(this.Fb.group({
  //       //   [name]: [null, validation]
  //       // }));
  //       this.returnFormgroup(element)
  //         }
  //         console.log(this.loan);
  //       });
  //       this.fieldsData.forEach(element => {
  //         if (this.propertyDetails != undefined && element.FieldType === 'Dropdown' && element.IsDependantField == true) {
  //           let index = this.fieldsData.findIndex(x => x.DependentFieldName === element.FieldName);
  //           if(index != -1 && index != null) {
  //             this.onChangesDynamicField(index, this.propertyDetails[this.fieldsData[index].DBColumnName]);
  //           }
  //         }
  //         // this.loan.controls[element.DBColumnName].setValue(this.propertyDetails[element.DBColumnName]);
  //       //   const ele =  this.Fb.control(null, this[element.ValidationFunctionName]())
  //       // let name = element.DBColumnName;
  //       // let  validation = this[element.ValidationFunctionName]();
  //       // this.users.push(this.Fb.group({
  //       //   [name]: [null, validation]
  //       // }));
  //       this.returnFormgroup(element)
  //       });
  //     });
  //   } else {
  //     this.DistrictList = [];
  //     this.fieldsData = [];
  //   }
  //   // this.loan.controls.VillageID.setValue(null);
  //   // this.loan.controls.DistrictID.setValue(null);
  //   // this.loan.controls.TalukaID.setValue(null);
  //   // this.loan.controls.taluka.setValue('');
  // }
  ChangeBaseDocType(data) {
   
    this.baseDocType = data;
    this.areas = [];
    let ele = this.Fb.group({});
    if (this.StateBaseDocType != undefined && data) {
      this.loan.controls.PropertyType.setValue(this.StateBaseDocType.find(x => x.ID === data).Name);
    }
    if (data !== undefined) {
      let param = new URLSearchParams();
      param.set('StateID', this.stateID);
      param.set('Service_subtype_id', '1');
      param.set('StateBaseDocTypeID', this.baseDocType);
      param.set('DisplayLevel', '1');
      this.isLoading = true;
      // this.fieldsData.forEach(element => {
      //   this.loan.removeControl(`${element.DBColumnName}`); 
      // });
      for (let i = 0; i < this.f.Properties['controls'].length; i++) {
        const control = this.f.Properties as FormArray;
        control.removeAt(i);
        this.data = { fieldsData: [], DataLists: [] }
      }
      this.service.getDynamicFields(param.toString()).subscribe((res: any) => {
        this.isLoading = false;
        this.data['fieldsData'].push(res.data);
        this.fieldsData = res.data;
        // console.log("this.filedsdata", this.fieldsData);
        this.fieldsData.forEach((element, index) => {
          this.dynamicFields.push(element.DBColumnName);
          if (element.FieldType === 'Text' && (element.ValidationFunctionName != '' && element.ValidationFunctionName != null)) {
            ele.addControl(`${element.DBColumnName}`, this.Fb.control(null, this[element.ValidationFunctionName]()));
          } else if ((element.FieldType === 'Dropdown' && element.APIName != '' && element.IsDependantField != true)) {
            ele.addControl(`${element.DBColumnName}`, this.Fb.control(null));
            this.URL = element.APIName + '/' + this.stateID;
            console.log('url',this.URL);
            this.isLoading = true;
            this.service.dynamicData(this.URL).pipe(first()).subscribe((data) => {
              this.URL = '';
             
              if (data.error) {
                this.isLoading = false;
                Swal.fire({
                  title: data.error_code,
                  text: data.message,
                  type: 'error',
                  showConfirmButton: false,
                  timer: 3000
                });
                return;
              } else {
                this.isLoading = false;
                if (data.data) {
                  if(element.DBColumnName === 'CityID' && this.loan.controls.BaseDocumentType.value === 37 &&this.stateID == 14 ){
                    this.data['DataLists'][0]['CityID'] = data.data;
                  }
                  else{
                    this.data['DataLists'].push({ [element.DBColumnName]: data.data });
               }
              }
              }
              console.log('datlists',this.data);
            });
            // }else if((element.FieldType === 'Dropdown' && element.APIName != '' && element.IsDependantField != true)){
            //   ele.addControl(`${element.DBColumnName}`, this.Fb.control(null));
          }
          else {
            ele.addControl(`${element.DBColumnName}`, this.Fb.control(null));
          }
          // console.log('this.data', this.data);
          // console.log("this.fieldts data", this.fieldsData);

        });
        // })

        // this.fieldsData.forEach(element => {
        //   if (this.propertyDetails != undefined && element.FieldType === 'Dropdown' && element.IsDependantField == true) {
        //     let index = this.fieldsData.findIndex(x => x.DependentFieldName === element.FieldName);
        //     if(index != -1 && index != null) {
        //       this.onChangesDynamicField(index, this.propertyDetails[this.fieldsData[index].DBColumnName]);
        //     }
        //   }
        //   this.loan.controls[element.DBColumnName].setValue(this.propertyDetails[element.DBColumnName]);
        // });
        if (this.data['fieldsData']) {
          this.users.push(ele);
        }
      });

    } else {
      this.DistrictList = [];
      for(let i =0;i<this.loan.controls['Properties']['controls'].length;i++){
        this.removeProperty(i);
      }
    }



  }
  returnFormgroup(element) {
    let name = element.DBColumnName;
    let validation = this[element.ValidationFunctionName]();
    // this.users.push(this.Fb.group({
    //   [name]: [null, validation]
    // }));
    return this.Fb.group({
      [name]: [null, validation]
    })
  }
  
  ChangeState(e) {
    this.submitted = false;
    if(e != undefined){
      this.getStateBaseDocTypeByStateID(e);
    }
    this.stateID = e;
    this.StateBaseDocType  =[];
    this.loan.controls.BaseDocumentType.setValue(null);
    
    for (let i = 0; i < this.f.Properties['controls'].length; i++) {
      const control = this.f.Properties as FormArray;
      control.removeAt(i);
      this.data = { fieldsData: [], DataLists: [] }
    }

    this.DistrictList = [];
    this.fieldsData = [];
    // this.loan.controls.VillageID.setValue(null);
    // this.loan.controls.DistrictID.setValue(null);
    // this.loan.controls.TalukaID.setValue(null);
    // this.loan.controls.taluka.setValue('');
  }

  onChangesDynamicField(fieldIndex, i, id,DBColumnName) {
    if(DBColumnName === 'RegionID'){
      this.data['DataLists'][i]['DistrictID'] = [];
      this.data['DataLists'][i]['CitySurveyOffice'] = [];
      this.data['DataLists'][i]['VillageID'] = [];
      this.resetControlValue(this.loan, i, 'DistrictID');
      this.resetControlValue(this.loan, i, 'CitySurveyOffice');
      this.resetControlValue(this.loan, i, 'VillageID');
    }
    if (DBColumnName === 'DistrictID') {
      this.data['DataLists'][i]['TalukaID'] = [];
      this.data['DataLists'][i]['VillageID'] = [];
      this.data['DataLists'][i]['CitySurveyOffice'] = [];
      this.data['DataLists'][i]['CityWardNo'] = [];
      this.data['DataLists'][i]['HobliID'] = [];
      this.data['DataLists'][i]['HalkaID'] = [];
      this.data['DataLists'][i]['TNTownID'] = [];
      this.data['DataLists'][i]['TNWardID'] = [];
      this.data['DataLists'][i]['APCorporationID'] = [];
      this.data['DataLists'][i]['CircleID'] = [];
      this.data['DataLists'][i]['TNBlockID'] = [];
      this.resetControlValue(this.loan, i, 'TNBlockID');
      this.resetControlValue(this.loan, i, 'CircleID');
      this.resetControlValue(this.loan, i, 'APCorporationID');
      this.resetControlValue(this.loan, i, 'TNWardID');
      this.resetControlValue(this.loan,i,'TNTownID');
      this.resetControlValue(this.loan,i,'HalkaID');
      this.resetControlValue(this.loan, i, 'TalukaID');
      this.resetControlValue(this.loan, i, 'VillageID');
      this.resetControlValue(this.loan, i, 'CitySurveyOffice');
      this.resetControlValue(this.loan, i, 'CityWardNo');
      this.resetControlValue(this.loan, i, 'HobliID');
    }
    if(DBColumnName === 'CitySurveyOffice'){
      this.data['DataLists'][i]['CityWardNo'] = [];
      this.resetControlValue(this.loan, i, 'CityWardNo');
      this.data['DataLists'][i]['VillageID'] = [];
      this.resetControlValue(this.loan, i, 'VillageID');
      this.data['DataLists'][i]['TNBlockID'] = [];
      this.resetControlValue(this.loan, i, 'TNBlockID');

    }
    if (DBColumnName === 'TalukaID') {
      this.data['DataLists'][i]['VillageID'] = [];
      this.data['DataLists'][i]['HobliID'] = [];
      this.data['DataLists'][i]['HalkaID'] = [];
      this.data['DataLists'][i]['TNTownID'] = [];
      this.data['DataLists'][i]['TNWardID'] = [];
      this.data['DataLists'][i]['TNBlockID'] = [];
      this.resetControlValue(this.loan, i, 'TNBlockID');
      this.resetControlValue(this.loan, i, 'TNWardID');
      this.resetControlValue(this.loan,i,'TNTownID');
      this.resetControlValue(this.loan,i,'HalkaID');
      this.resetControlValue(this.loan, i, 'VillageID');
      this.resetControlValue(this.loan, i, 'HobliID');
    }
    if(DBColumnName === 'TNTownID'){
      this.data['DataLists'][i]['TNWardID'] = [];
      this.resetControlValue(this.loan, i, 'TNWardID');
      this.data['DataLists'][i]['TNBlockID'] = [];
      this.resetControlValue(this.loan, i, 'TNBlockID');
    }
    if(DBColumnName === 'TNWardID'){
      this.data['DataLists'][i]['TNBlockID'] = [];
      this.resetControlValue(this.loan, i, 'TNBlockID');
    }
    if(DBColumnName === 'HobliID' || DBColumnName === 'HalkaID'){
      this.data['DataLists'][i]['VillageID'] = [];
      this.resetControlValue(this.loan, i, 'VillageID');
    }

    // this.clearFields(index);
    if (id !== undefined) {
      this.fieldsData.forEach(element => {
        if (this.fieldsData[fieldIndex].DependentFieldName === element.FieldName) {
          if (element.FieldType === 'Dropdown' && element.APIName !== '') {
            this.URL = element.APIName + '/' + id;
            if (this.URL != '') {
              this.isLoading = true;
              this.service.dynamicData(this.URL).pipe(first()).subscribe((data) => {
                this.URL = '';
                this.isLoading = false;
                if (data.error) {
                  Swal.fire({
                    title: data.error_code,
                    text: data.message,
                    type: 'error',
                    showConfirmButton: false,
                    timer: 3000
                  });
                  return;
                } else {
                  // if (element.DBColumnName !== 'DistrictID') {
                    // console.log('reso',data.data);
                  this.data['DataLists'][i][element.DBColumnName] = data.data;
                  // }
                  // else if(element.DBColumnName )
                  ;
                }
              });
            }
          }

        }
      });
      // console.log('this.datalist', this.data['DataLists'])
    }

  }

  resetControlValue(formGroup: FormGroup, i: number, controlName: string) {
    if (formGroup instanceof FormGroup && formGroup.controls.Properties['controls'][i]['controls'].hasOwnProperty(controlName)) {
      formGroup.controls.Properties['controls'][i]['controls'][controlName].setValue(null);
    }
  }

  clearFields(i) {
    for (let index = 0; index < this.fieldsData.length; index++) {
      if (this.fieldsData[i].DependentFieldName != '' && this.fieldsData[i].DependentFieldName === this.fieldsData[index].FieldName) {
        this.loan.controls[`${this.fieldsData[index].DBColumnName}`].setValue(null);
        this.clearFields(index);
      }
    }
  }

  FN_SubInput() {
    return FN_SubInput();
  }
  get f() { return this.loan.controls; }

  // get form() {return this.form.controls;}

  fetchstatelist() {
    this.isLoading = true;
    this.service.GetStatePermissionWise(this.currentUser.UserID)
      .pipe(first())
      .subscribe(
        data => {
          if (data.error) {
            this.isLoading = false;
            Swal.fire({
              title: data.error_code,
              text: data.message,
              type: 'error',
              showConfirmButton: false,
              timer: 3000
            });
            return;
          } else {
            this.isLoading = false;
            this.StateList = data.data;
            // console.log("statelist", this.StateList);
          }
        });
    // this.f.DistrictID.disable();
  }
  // ChangeDistrict() {
  //   this.f.VillageID.setValue(null);
  //   this.f.TalukaID.setValue(null);
  //   this.f.taluka.setValue('');
  // }
  // selectEvent(item) {
  //   // do something with selected item
  //   this.loan.controls.VillageID.setValue(item.VillageId);
  //   this.loan.controls.DistrictID.setValue(item.DistrictId);
  //   this.loan.controls.TalukaID.setValue(item.TalukaId);
  //   this.loan.controls.StateID.setValue(item.stateID);
  // }
  // onChangeSearch(search: string) {
  //   this.loan.controls.VillageID.setValue(null);
  //   this.loan.controls.TalukaID.setValue(null);
  //   // this.loan.controls.taluka.setValue('');
  //   //  fetch remote data from here
  //   //  And reassign the 'data' which is binded to 'data' property.

  //   if (!this.loan.controls.StateID.value) {
  //     Swal.fire({
  //       title: 'Error',
  //       text: 'Please Select State',
  //       type: 'error'
  //     });
  //     return;
  //   }
  //   if (search !== undefined && search.length >= 3) {
  //     this.service.areabystateid(this.loan.controls.StateID.value, search)
  //       .pipe(first())
  //       .subscribe(
  //         data => {
  //           if (data.error) {
  //             return;
  //           } else {
  //             this.areas = data.data;
  //             this.notfoundshow = true;
  //           }
  //         });
  //   } else if (search !== undefined && search.length === 0) {
  //     this.areas = null;
  //     this.notfoundshow = false;
  //   }
  // }
  // onclear() {
  //   this.loan.controls.VillageID.setValue(null);
  //   this.loan.controls.TalukaID.setValue(null);
  // }
  isOwnerNameDuplicate(index: number, owners: FormArray, ownerName: string): boolean {
    for (let i = 0; i < index; i++) {
      if (owners.at(i).value.OwnerName === ownerName) {
        return true;
      }
    }
    return false;
  }
  removeOwner(i: number) {
    const control = this.f.PropertyOwners as FormArray;
    control.removeAt(i);
  }
  addOwner() {
    const control = this.f.PropertyOwners as FormArray;
    control.push(this.initOwner());
  }
  isOwnerNameEmpty(index: number): boolean {
    const ownerNameControl = this.loan.controls.PropertyOwners['controls'][index].get('OwnerName');
    return !ownerNameControl.value || ownerNameControl.value.trim() === '';
  }

  isOwnerNameTouched(index: number): boolean {
    const ownerNameControl = this.loan.controls.PropertyOwners['controls'][index].get('OwnerName');
    return ownerNameControl.touched;
  }
  initOwner(i?) {
    if (i && i !== undefined) {
      return this.Fb.group({
        OwnerName: new FormControl(i.OwnerName, Validators.required),
      });
    } else {
      return this.Fb.group({
        OwnerName: new FormControl('', Validators.required)
      });
    }
  }

  get users() {
    return this.loan.get('Properties') as FormArray;
  }


}
