import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ApplicationsListComponent } from "./applications-list/applications-list.component";
import { ViewApplicationComponent } from "./view-application/view-application.component";
import { ViewDocumentsComponent } from "./view-documents/view-documents.component";

const routes: Routes = [
  {
    path: "",
    redirectTo: "Applicatons",
    pathMatch: "full",
  },
  {
    path: "Applications",
    component: ApplicationsListComponent,
  },
  {
    path:'View-Application',
    component:ViewApplicationComponent
  },
  {
    path:'View-documents/:AppID',
    component: ViewDocumentsComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CGApplicationsRoutingModule {}
