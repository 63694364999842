import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { GeneralService } from 'src/app/services/general.service';


class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}


@Component({
  selector: 'app-view-echeck-application',
  templateUrl: './view-echeck-application.component.html',
  styleUrls: ['./view-echeck-application.component.scss']
})
export class ViewEcheckApplicationComponent implements OnInit {
  ownerName: string = "John Doe";
  shareCount: string = '3.5 Acr';
  shareValue: number = 10.5;
  ownersList:any[] = [];
  breadCrumbItems: Array<{}>;
  applicationID : string;
  dtOptions: DataTables.Settings = {};
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective | undefined;
  dtTrigger = new Subject<any>();
  isLoadingTable: boolean;
  inputValue: string = '';
  applications: any;
  constructor(private router: Router, private http: HttpClient, private service: GeneralService,private renderer: Renderer2) { 
    let a = router.getCurrentNavigation().extras;
    if(a){
      if(a.state){
        this.ownersList = a.state.appData;
        this.applicationID = a.state.AppID;
      }
    }
    if(localStorage.getItem('AppID')){
      this.applicationID = localStorage.getItem('AppID');
    }
    if(localStorage.getItem('owners'))
    {
      this.ownersList =  JSON.parse(localStorage.getItem('owners'));
    }
  }

  ngOnInit() {
    this.breadCrumbItems = [{ label: 'E-Check Dashboard', path: 'e-check/echeck-dashboard' },
    { label: 'E-Check Applications', path: '/', active: true }];
    this.dtOptions = {
      pagingType: "simple_numbers",
      pageLength: 10,
      serverSide: true,
      searching: false,
      ordering: false,
      // processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        this.isLoadingTable = true;
        this.http
          .get<DataTablesResponse>(
            this.service.GetBaseUrl() +
              `getlist/e_check/property/${this.applicationID}` +
              //  
              `${
                this.inputValue != "" ? "&search=" + this.inputValue : ""
              }` ,
             
            {}
          )
          .subscribe((resp: any) => {
            console.log("line==199",resp)
            this.isLoadingTable = false;
            this.applications = resp.data;
            callback({
              recordsTotal: resp.data.length,
              recordsFiltered: resp.data.length,
              data: this.applications,
            });
          });
      },
      columns: [
        {
          title: 'Sr.No.',
          data: null,
          render: (data, type, row, meta) => {
            return meta.row + 1;
          },
        },
        {
          title: "District",
          name: "DistrictName",
          data: "DistrictName",
          render:(data, type, row)=>{
            if(data === null || data === undefined || data === 'null'){
              return '--'
            }
            else{
              return data ? data : '--'
            }}
        },
        {
          title: "Taluka",
          name: "TalukaName",
          data: "TalukaName",
          render:(data, type, row)=>{
            if(data === null || data === undefined || data === 'null'){
              return '--'
            }
            else{
              return data ? data : '--'
            }}
        },
        {
          title: "village",
          name: "villageName",
          data: "villageName",
          render:(data, type, row)=>{
            if(data === null || data === undefined || data === 'null'){
              return '--'
            }
            else{
              return data ? data : '--'
            }
        }
        },
        {
          title: "khewat No.",
          name: "khewatNo",
          data: "khewatNo",
          render:(data, type, row)=>{
              if(data === null || data === undefined || data === 'null'){
                return '--'
              }
              else{
                return data ? data : '--'
              }
          }
        },
        {
          title: "Status",
          name: "ApplicationStatus",
          data: "",
          render:(data, type, row)=>{
            if(data === null || data === undefined || data === 'null'){
              return '--'
            }
            else{
              return data ? data : '--'
            }
        }
        },
        {
          title: "Action",
          data: null,
          render: function (data, type, row) {
            
            return `<a class="text-primary" title="View Document"
            AppID = "${row.AppID}"><i class=" m-1 ml-3 mdi mdi-eye font-18 text-secondary" AppID = "${row.AppID}"  
            PropertyID="${row.PropertyID}"   PropertyData='${JSON.stringify(row)}' aria-hidden="false"></i></a>
            <a title='Property report'>  <i class="mdi mdi-plus-box" ViewID = "${row.AppID}" ropertyID="${row.PropertyID}"></i></a>`;
          }
        },
      ],
      order: [[0, "desc"]],
      columnDefs: [
        { targets: 0, visible: true },
        { targets: 6, orderable: false },
      ],
    };
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  } 
  ngAfterViewInit(): void {
    this.dtTrigger.next();
    this.renderer.listen('document', 'click', (event) => {
      if (event.target.hasAttribute('AppID')) {
        let PropertyData = JSON.parse(event.target.getAttribute('PropertyData'));
        let appID = event.target.getAttribute('AppID');
        let propertyID = event.target.getAttribute('PropertyID');
        localStorage.setItem('AppID',appID);
        localStorage.setItem('PropertyID',propertyID );
        localStorage.setItem('PropertyData',event.target.getAttribute('PropertyData'));
        this.router.navigate(['/e-check/view-echeck-document'], {state:{PropertyID: propertyID, AppID:appID , PropertyData: PropertyData}, });
      }
      else if(event.target.hasAttribute('ViewID')){
        let appID = event.target.getAttribute('ViewID');
        let propertyID = event.target.getAttribute('PropertyID');
        localStorage.setItem('AppID',appID);
        localStorage.setItem('PropertyID',propertyID );
        this.router.navigate(['/e-check/property'], {state:{PropertyID: propertyID, AppID:appID },})
        console.log('Property Id',propertyID)
      }
  })
 
 }
}
