import { ActivatedRoute, Router } from "@angular/router";
import { GeneralService } from "./../../services/general.service";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
  FormArray,
} from "@angular/forms";
import Swal from "sweetalert2";

@Component({
  selector: "app-pvr-report",
  templateUrl: "./pvr-report.component.html",
  styleUrls: ["./pvr-report.component.scss"],
})
export class PvrReportComponent implements OnInit {
  today = new Date();
  breadCrumbItems: any;
  appID: any;
  applicationData: any;
  applicationDatadisplay: any;
  submitted: boolean;
  isLoading: boolean;
  PVRStatusList = ["Verified", "Provisionally Verified", "Not Verified"];
  PVRRiskMeterList = ["Excellent", "Good", "Average", "Poor"];
  PVRForm: FormGroup;
  currentUser: any;
  ownership: any;
  property: any;
  encumbrance: any;
  encumbranceFound: any;
  government: any;
  governmentFound: any;
  regexStr = "^[0-9]*$";
  NumberOfOwners: any
  isDocUploaded: boolean = false;
  encumbranceRemarks: any;
  propertyText1: any;
  propertyText2: any;
  interval: any;
  datalength: any;
  isDataAdded: any = false;
  converted: any = false;
  isNan: any = false;
  ownerName: any;
  pertaining: any;
  defaultUnit: any = '';
  selectedUnit: any = null;
  aipvr_id: any;
  is_litigation: string;
  is_cersai: string;
  propertyText3: string;
  constructor(
    private service: GeneralService,
    private route: ActivatedRoute,
    private Fb: FormBuilder,
    private router: Router
  ) {
    this.breadCrumbItems = [
      { label: "Dashboard", path: "loan" },
      { label: "Applications", path: "/loan/applications" },
      { label: "IPVR Report", path: "/", active: true },
    ];
    this.appID = this.route.snapshot.params.Appid;
  }
  asIsOrder(a, b) {
    return 1;
  }
  ngOnInit() {

    this.isLoading = true;
    this.submitted = false;
    this.currentUser = this.service.getcurrentUser();
    this.service.getApplicationInformationdisplay(this.appID).subscribe((res) => { 
      this.applicationDatadisplay = res.data;
      this.defaultUnit = this.getDefaultUnit(this.applicationDatadisplay.State);
    });
    this.service.GetApplicationInformation(this.appID).subscribe((res) => {
      this.applicationData = res.data[0];
      this.isLoading = false;
      this.PVRForm = this.Fb.group({
        OwnerCoOwnersName: this.Fb.array([this.initOwner()]),
        EncumbranceDetails: this.Fb.array([
          this.initEncumbranceDetails()
        ]),
        // CropDetails: this.Fb.array([
        //   this.initCropDetails()
        // ]),
        TotalLandSize: new FormControl("", Validators.required),
        NumberOfOwners: new FormControl("1", Validators.required),
        PVRPropertyStatus: new FormControl(null, Validators.required),
        PVRRiskMeterStatus: new FormControl(null, Validators.required),
        ownership: new FormControl("", Validators.required),
        property: new FormControl("", Validators.required),
        encumbrance: new FormControl("", Validators.required),
        unit: new FormControl("", Validators.required),
        encum_applicant: new FormControl("", Validators.required),
        is_litigation: new FormControl(""),
        is_cersai: new FormControl(""),
        no_encum_applicant: new FormControl("", Validators.pattern("^[0-9]*$")),
        government: new FormControl("", Validators.required),
        OwnerRemarks: new FormControl("", Validators.required),
        PVRStatusLine1: new FormControl("", Validators.required),
        PVRStatusLine2: new FormControl("", Validators.required),
        PropertyRemarks: new FormControl("", Validators.required),
        EncumbranceRemarks: new FormControl("", Validators.required),
        CreatedBy: new FormControl(
          this.currentUser.UserID,
          Validators.required
        ),

        encumbranceFound: new FormControl("", Validators.pattern("^[0-9]*$")),
        encumbranceText: new FormControl(""),
        governmentFound: new FormControl(""),
        governmentText: new FormControl(""),
      });
    });
  }
  onKeyPress(e) {
    if (
      [46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (e.keyCode == 65 && e.ctrlKey === true) ||
      // Allow: Ctrl+C
      (e.keyCode == 67 && e.ctrlKey === true) ||
      // Allow: Ctrl+V
      (e.keyCode == 86 && e.ctrlKey === true) ||
      // Allow: Ctrl+X
      (e.keyCode == 88 && e.ctrlKey === true) ||
      // Allow: home, end, left, right
      (e.keyCode >= 35 && e.keyCode < 40)
    ) {
      // let it happen, don't do anything
      return;
    }
    let ch = String.fromCharCode(e.keyCode);
    let regEx = new RegExp(this.regexStr);
    if (regEx.test(ch)) {
      return;
    } else {
      e.preventDefault();
    }
  }
  enableButton() {
    this.isDocUploaded = true;
    this.isLoading = true;
    this.interval = setInterval(() => {
      this.isLoading = false;
      this.pauseTimer();
    },1);
  }
  pauseTimer() {
    clearInterval(this.interval);
    
  }
  removeOwner() {
    const control = this.PVRForm.controls.OwnerCoOwnersName as FormArray;
    let length = control.length
    for(let i=length-1;i>=0;i--){
      if( control.value[i].PVRID === this.aipvr_id) {
        control.removeAt(i);
      }
    }
    if(control.length == 0) {
      control.push(this.initOwner());
      this.PVRForm.controls.NumberOfOwners.setValue(0);
      this.isDataAdded = false;
    }
    this.PVRForm.reset();  
    this.PVRForm.controls.CreatedBy.setValue(this.currentUser.UserID);
    this.ownership = '';
    this.property = '';
    this.encumbrance = '';
    this.government = '';
    this.pertaining = '';
    this.is_cersai = '';
    this.is_litigation = '';
  }
  initOwner(i?) {
    if (i && i !== undefined) {
      console.log(i);
      return this.Fb.group({
        OwnersName: new FormControl(i.OwnerName, Validators.required),
        LandSize: new FormControl(i.LandSize,),
        PVRID: new FormControl(i.PVRID),
      });
    } else {
      return this.Fb.group({
        OwnersName: new FormControl("", Validators.required),
        LandSize: new FormControl(""),
        PVRID: new FormControl(""),
      });
    }
  }
  initEncumbranceDetails(i?) {
    if (i && i !== undefined) {
      return this.Fb.group({
        LoanTakenBy: new FormControl(i.LoanTakenBy),
        LoanAmount: new FormControl(i.LoanAmount),
        LoanTakenOn: new FormControl(i.LoanTakenOn),
        LoanGivenBy: new FormControl(i.LoanGivenBy),
      });
    } else {
      return this.Fb.group({
        LoanTakenBy: new FormControl(""),
        LoanAmount: new FormControl(""),
        LoanTakenOn: new FormControl(""),
        LoanGivenBy: new FormControl(""),
      });
    }
  }
  initCropDetails(i?) {
    if (i && i !== undefined) {
      return this.Fb.group({
        Year: new FormControl(i.Year),
        Season: new FormControl(i.Season),
        Crop: new FormControl(i.Crop),
        Area: new FormControl(i.Area),
        MSP: new FormControl(i.MSP),
      });
    } else {
      return this.Fb.group({
        Year: new FormControl(""),
        Season: new FormControl(""),
        Crop: new FormControl(""),
        Area: new FormControl(""),
        MSP: new FormControl(""),
      });
    }
  }
  get f() {
    return this.PVRForm.controls;
  }
  save() {
    const data = this.PVRForm.value
    data.TotalLandSize = this.PVRForm.controls.TotalLandSize.value + ' ' + this.PVRForm.controls.unit.value
    this.submitted = true;
    if (this.PVRForm.valid) {
      
      this.isLoading = true;
      this.service.GetDocumentList(this.appID).subscribe((list) => {
        if (
          list.data.every((x) => x.DocumentID == null && x.Status == "Pending")
        ) {
          this.isLoading = false;
          Swal.fire({
            title: "Are you sure?",
            text: "There Is No Document Attached With this PVR, Are You Sure You Want To Generate PVR Without Document Attached ?",
            type: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            confirmButtonClass: "btn btn-danger mt-2",
            cancelButtonClass: "btn btn-success ml-2 mt-2",
            buttonsStyling: false,
            showConfirmButton: true,
          }).then((result) => {
            if (result.value) {
              this.isLoading = true;
              const data = this.PVRForm.value
              data.TotalLandSize = this.PVRForm.controls.TotalLandSize.value  + this.PVRForm.controls.unit.value
              this.service
                .SavePVR(data, this.appID)
                .subscribe((res) => {
                  this.isLoading = false;
                  if (res.error) {
                    Swal.fire({
                      title: res.error_code,
                      text: res.message,
                      type: "error",
                      showConfirmButton: false,
                      timer:3000
                    });
                    return;
                  } else {
                    this.isLoading = true;
                    this.service.GeneratePVR(this.appID).subscribe((res) => {
                      this.isLoading = false;
                      Swal.fire({
                        title: "Success",
                        text: "PVR generated",
                        type: "success",
                        showConfirmButton: false,
                        timer:3000
                      }).then(() => {
                        this.service
                          .changeStatus(this.appID, "iPVR Sent")
                          .subscribe(() => {
                            this.router.navigate(["/loan/applications"]);
                          });
                      });
                    });
                  }
                });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              return false;
            }
          });
        } else {
          this.service
            .SavePVR(this.PVRForm.value, this.appID)
            .subscribe((res) => {
              if (res.error) {
                Swal.fire({
                  title: res.error_code,
                  text: res.message,
                  type: "error",
                  showConfirmButton: false,
                  timer:3000
                });
                return;
              } else {
                this.service.GeneratePVR(this.appID).subscribe((res) => {
                  Swal.fire({
                    title: "Success",
                    text: "PVR generated",
                    type: "success",
                    showConfirmButton: false,
                    timer:3000
                  }).then(() => {
                    this.service
                      .changeStatus(this.appID, "iPVR Sent")
                      .subscribe(() => {
                        this.router.navigate(["/loan/applications"]);
                      });
                  });
                });
              }
            });
          this.isLoading = true;
        }
      });
    } else {
      Swal.fire({
        title: "Invalid",
        text: "Invalid Form Data",
        type: "error",
        showConfirmButton: false,
        timer:3000
      });
    }
  }
  addEncumbranceDetails() {
    const control = this.PVRForm.controls.EncumbranceDetails as FormArray;
    control.push(this.initEncumbranceDetails());
  }
  removeEncumbranceDetails(i) {
    const control = this.PVRForm.controls.EncumbranceDetails as FormArray;
    control.removeAt(i);
  }
  removeCropDetails(i) {
    const control = this.PVRForm.controls.CropDetails as FormArray;
    control.removeAt(i);
  }
  addCropDetails() {
    const control = this.PVRForm.controls.CropDetails as FormArray;
    control.push(this.initCropDetails());
  }
  removeOwnerDetails(i) {
    const control = this.PVRForm.controls.OwnerCoOwnersName as FormArray;
    control.removeAt(i);
    this.calculateIndividualShare();
    this.PVRForm.controls.NumberOfOwners.setValue(control.length);
  }
  addOwnerDetails() {
    const control = this.PVRForm.controls.OwnerCoOwnersName as FormArray;
    // this.LandSize.push(new FormControl('', Validators.required));

    control.push(this.initOwner());
    this.calculateIndividualShare();
    this.PVRForm.controls.NumberOfOwners.setValue(control.length);
  }
  convertLandSize() {
    this.converted = true;
    this.PVRForm.controls.TotalLandSize.setValue(this.convertintoAcre(Number(this.PVRForm.controls.TotalLandSize.value) , this.defaultUnit, this.selectedUnit).toFixed(4))
    this.calculateIndividualShare();
  }
  calculateIndividualShare() {
    const control = this.PVRForm.controls.OwnerCoOwnersName as FormArray;
    let length = control.length
    for(let i=length-1;i>=0;i--){
      control.at(i).get('LandSize').setValue((Number(this.PVRForm.controls.TotalLandSize.value) / length).toString() + ' ' + (this.PVRForm.controls.unit.value == null || this.PVRForm.controls.unit.value == undefined ? '' : this.PVRForm.controls.unit.value));
    }
  }
  getusers() {
    let names = "";
    this.applicationData.PropertyOwners.forEach((owner) => {
      names += ` ${owner.OwnerName}`;
    });
    return names;
  }
  displayvalue(e) {
    if(e == 'success') {
      this.isDocUploaded = true;
    }
    this.service.GetiPVRDetailsRead(this.appID).subscribe((data) => {
      const myValue = data.data[0];
      this.isLoading = false;
      this.PVRForm.controls["TotalLandSize"].setValue(myValue.Area);
      // this.PVRForm.controls["OwnerRemarks"].setValue(myValue.owner);
      // this.PVRForm.controls["PropertyRemarks"].setValue(myValue.land_use);
      if (myValue.encumbrance) {
        this.PVRForm.controls["encumbranceText"].setValue(myValue.encumbrance);
        this.PVRForm.controls["encumbrance"].setValue("found");
        this.encumbrance = "found";
        // this.PVRForm.controls["EncumbranceRemarks"].setValue(
        //   myValue.encumbrance
        // );
        // this.encumbranceRemarks = myValue.encumbrance;
      } else {
        this.PVRForm.controls["encumbranceText"].setValue("");
        this.encumbrance = "";
      }
      if (myValue.government) {
        this.PVRForm.controls["governmentText"].setValue(myValue.government);
        this.PVRForm.controls["government"].setValue("true");
        this.government = "true";
      } else {
        this.PVRForm.controls["governmentText"].setValue("");
        this.government = "";
      }
      const control = this.PVRForm.controls.OwnerCoOwnersName as FormArray;
      if(!this.isDataAdded) {
        control.removeAt(0);
        this.isDataAdded = true;
       
    
      }
      this.aipvr_id = myValue.aipvr_id
      console.log('1');
      if(this.applicationDatadisplay.State == 'Andhra Pradesh') {
        let i = 0;
        let owners = myValue.owner.split(",");
        console.log('1', owners.length);
        while(i < owners.length) {
          console.log(i, owners[i], owners[i+1]);
          control.push(
            this.initOwner({
            OwnerName: owners[i],
            PVRID: myValue.aipvr_id,
            LandSize: owners[i+1],
          }));
          i= i + 2;
        }
      } else {
        myValue.owner.split(",").forEach((element) => {
          control.push(
          this.initOwner({
            OwnerName: element,
            PVRID: myValue.aipvr_id
            //LandSize: myValue.owner,
        })
        );
        });
      }
      
      this.PVRForm.controls.NumberOfOwners.setValue(control.length);
    });
  }
  onChangeCheckboxes(formControlName, targetType) {

    if (this.PVRForm.value.ownership === targetType && formControlName === "ownership") {
      if (formControlName === "ownership") {
        this.ownership = "";
        this.PVRForm.patchValue({
          ownership: "",
        });
      }
      this.setStatus();
      return;
    }
    if (this.PVRForm.value.property === targetType  && formControlName === "property") {
      if (formControlName === "property") {
        this.property = "";
        this.PVRForm.patchValue({
          property: "",
        });
      }
      this.setStatus();
      return;
    }
    if (this.PVRForm.value.encumbrance === targetType && formControlName === "encumbrance") {
      if (formControlName === "encumbrance") {
        this.encumbrance = "";
        this.PVRForm.patchValue({
          encumbrance: "",
        });
        this.PVRForm.patchValue({
          encumbranceFound: "",
        });
        this.encumbranceRemarks = null;
      }
      this.setStatus();
      return;
    }
    if (this.PVRForm.value.government === targetType && formControlName === "government") {
      if (formControlName === "government") {
        this.government = "";
        this.PVRForm.patchValue({
          government: "",
        });
        this.PVRForm.patchValue({
          governmentFound: "",
        });
      }
      this.setStatus();
      return;
    }
    
    if (this.PVRForm.value.encum_applicant === targetType && formControlName === "Pertaining") {
      if (formControlName === "Pertaining") {
        this.pertaining = "";
        this.PVRForm.patchValue({
          encum_applicant: "",
          no_encum_applicant:""
        });
      }
      this.setStatus();
      return;
    }
    if (this.PVRForm.value.is_litigation === targetType && formControlName === "is_litigation") {
      if (formControlName === "is_litigation") {
        this.is_litigation = "";
        this.PVRForm.patchValue({
          is_litigation: "",
        });
      }
      this.setStatus();
      return;
    }
    if (this.PVRForm.value.is_cersai === targetType && formControlName === "is_cersai") {
      if (formControlName === "is_cersai") {
        this.is_cersai = "";
        this.PVRForm.patchValue({
          is_cersai: "",
        });
      }
      this.setStatus();
      return;
    } 
    // this.setValidator(this.PVRForm.value.ownership);
    if (formControlName === "ownership") {
      this.ownership = targetType;
      this.PVRForm.patchValue({
        ownership: targetType,
      });
    }
    if (formControlName === "property") {
      this.property = targetType;
      this.PVRForm.patchValue({
        property: targetType,
      });
    }
    if (formControlName === "encumbrance") {
      this.encumbrance = targetType;
      this.PVRForm.patchValue({
        encumbrance: targetType,
      });
      // if(targetType === "found") {
      this.PVRForm.patchValue({
        encumbranceFound: "",
      });
      this.encumbranceRemarks = null;
      // }
    }
    if (formControlName === "government") {
      this.government = targetType;
      this.PVRForm.patchValue({
        government: targetType,
      });
      // if(targetType === "found") {
      this.PVRForm.patchValue({
        governmentfound: "",
      });
      // }
    }
    if (formControlName === "Pertaining") {
      this.pertaining = targetType;
      this.PVRForm.patchValue({
        encum_applicant: targetType,
      });
    }
    if (formControlName === "is_litigation") {
      this.is_litigation = targetType;
      this.PVRForm.patchValue({
        is_litigation: targetType,
      });
    }
    if (formControlName === "is_cersai") {
      this.is_cersai = targetType;
      this.PVRForm.patchValue({
        is_cersai: targetType,
      });
    }
    this.setStatus();
  }
  onChangeEncrumbranceFound() {
    if (this.encumbranceRemarks) {
      this.PVRForm.patchValue({
        EncumbranceRemarks:
          this.PVRForm.value.encumbranceFound + this.encumbranceRemarks,
      });
    }
  }
  onChangeLandSize() {
    if (
      this.PVRForm.value.TotalLandSize &&
      this.PVRForm.value.PropertyRemarks &&
      this.PVRForm.value.property === "verified"
    ) {
      this.PVRForm.patchValue({
        PropertyRemarks:
        this.propertyText1 +
        this.PVRForm.value.TotalLandSize + ' ' +
        this.selectedUnit + ' ' +
        this.propertyText3 + ' ' +
        this.PVRForm.value.TotalshareOwned + ' ' +
        this.applicationDatadisplay.FirstName + ' ' +
        this.propertyText2,
      });
    }
    this.calculateIndividualShare();
  }
  setStatusValues(
    riskMeter,
    ownerRemarks,
    propertyRemarks,
    encumbranceRemarks,
    line1,
    line2,
    
  ) {
    if (this.PVRForm.value.encumbranceFound && encumbranceRemarks) {
      this.PVRForm.patchValue({
        PVRRiskMeterStatus: riskMeter,
        OwnerRemarks: ownerRemarks,
        PropertyRemarks: propertyRemarks,
        EncumbranceRemarks:
          this.PVRForm.value.encumbranceFound + encumbranceRemarks,
        PVRStatusLine1: line1,
        PVRStatusLine2: line2,
      });
    } else {
      this.PVRForm.patchValue({
        PVRRiskMeterStatus: riskMeter,
        OwnerRemarks: ownerRemarks,
        PropertyRemarks: propertyRemarks,
        EncumbranceRemarks: encumbranceRemarks,
        PVRStatusLine1: line1,
        PVRStatusLine2: line2,
      });
    }
  }
  setStatus() {
    // Ownership 1  Property  Encumbrance
    // Confirmed    Verified  Not Found
    if (
      this.PVRForm.value.ownership === "confirmed" &&
      this.PVRForm.value.property === "verified" &&
      this.PVRForm.value.encumbrance === "not found"
    ) {
      let propertyText: any;
      this.propertyText1 = "Property verified with total area of ";
      this.propertyText3 = "from which"
      this.propertyText2 = "owned as per the latest government land records.";
      if (this.PVRForm.value.TotalLandSize) {
        propertyText =
          "Property verified with total area of " +
          this.PVRForm.value.TotalLandSize + ' ' + this.selectedUnit +
          " as per the latest government land records.";
      } else {
        propertyText =
          "Property verified with total area of as per the latest government land records.";
      }
      this.setStatusValues(
        "Good",
        "Applicant name verified as per the latest government land records.",
        propertyText,
        "No encumbrance found as per the latest government land records.",
        `Property recommended for ${this.applicationData.Type_of_Loan}`,
        "-"
      );
    }
    // Ownership 2  Property  Encumbrance
    // Confirmed    Verified  Found
    if (
      this.PVRForm.value.ownership === "confirmed" &&
      this.PVRForm.value.property === "verified" &&
      this.PVRForm.value.encumbrance === "found"
    ) {
      let propertyText: any;
      this.propertyText1 = "Property verified with total area of ";
      this.propertyText3 = "from which"
      this.propertyText2 = "owned as per the latest government land records.";
      if (this.PVRForm.value.TotalLandSize) {
        propertyText =
          "Property verified with total area of " +
          this.PVRForm.value.TotalLandSize + ' ' + this.selectedUnit +
          " as per the latest government land records.";
      } else {
        propertyText =
          "Property verified with total area of as per the latest government land records.";
      }
      this.setStatusValues(
        "Average",
        "Applicant name verified as per the latest government land records.",
        propertyText,
        " encumbrance found as per the latest government land records.",
        `Property recommended for ${this.applicationData.Type_of_Loan}`,
        "Provided that No Due certificate is obtained from the applicant."
      );
      this.encumbranceRemarks =
        " encumbrance found as per the latest government land records.";
    }
    // Ownership 3  Property      Encumbrance
    // Confirmed    Not Verified  Not Found
    if (
      this.PVRForm.value.ownership === "confirmed" &&
      this.PVRForm.value.property === "not verified" &&
      this.PVRForm.value.encumbrance === "not found"
    ) {
      this.setStatusValues(
        "Average",
        "Applicant name verified as per the latest government land records.",
        "Property not verified as per the latest government land records.",
        "No encumbrance found as per the latest government land records.",
        "-",
        "-"
      );
    }
    // Ownership 4  Property      Encumbrance
    // Confirmed    Not Verified  Found
    if (
      this.PVRForm.value.ownership === "confirmed" &&
      this.PVRForm.value.property === "not verified" &&
      this.PVRForm.value.encumbrance === "found"
    ) {
      this.setStatusValues(
        "Poor",
        "Applicant name verified as per the latest government land records.",
        "Property not verified as per the latest government land records.",
        " encumbrance found as per the latest government land records.",
        "-",
        "-"
      );
      this.encumbranceRemarks =
        " encumbrance found as per the latest government land records.";
      // this.myInputField.select();
    }
    // Ownership 5      Property      Encumbrance
    // Co Owners found  Verified      Not Found
    if (
      this.PVRForm.value.ownership === "co-owners found" &&
      this.PVRForm.value.property === "verified" &&
      this.PVRForm.value.encumbrance === "not found"
    ) {
      let propertyText: any;
      this.propertyText1 = "Property verified with total area of ";
      this.propertyText3 = "from which"
      this.propertyText2 = "owned as per the latest government land records.";
      if (this.PVRForm.value.TotalLandSize) {
        propertyText =
          "Property verified with total area of " +
          this.PVRForm.value.TotalLandSize + ' ' + this.selectedUnit +
          " as per the latest government land records.";
      } else {
        propertyText =
          "Property verified with total area of as per the latest government land records.";
      }
      this.setStatusValues(
        "Average",
        "Applicant name verified as co-owner as per the latest government land records.",
        propertyText,
        "No encumbrance found as per the latest government land records.",
        `Property recommended for ${this.applicationData.Type_of_Loan}`,
        "provided Co-owner's consent in obtained"
      );
    }
    // Ownership 6      Property  Encumbrance
    // Co Owners found  Verified  Found
    if (
      this.PVRForm.value.ownership === "co-owners found" &&
      this.PVRForm.value.property === "verified" &&
      this.PVRForm.value.encumbrance === "found"
    ) {
      this.propertyText1 = "Property verified with total area of ";
      this.propertyText3 = "from which"
      this.propertyText2 = "owned as per the latest government land records.";
      let propertyText: any;
      if (this.PVRForm.value.TotalLandSize) {
        propertyText =
          "Property verified with total area of " +
          this.PVRForm.value.TotalLandSize + ' ' + this.selectedUnit +
          " as per the latest government land records.";
      } else {
        propertyText =
          "Property verified with total area of as per the latest government land records.";
      }
      this.setStatusValues(
        "Average",
        "Applicant name verified as co-owner as per the latest government land records.",
        propertyText,
        " encumbrance found as per the latest government land records.",
        `Property recommended for ${this.applicationData.Type_of_Loan}`,
        "Provided Co-owner's consent is obtained and No-Due Certificate is obtained from applicant"
      );
      this.encumbranceRemarks =
        " encumbrance found as per the latest government land records.";
    }
    // Ownership 7      Property      Encumbrance
    // Co Owners found  Not Verified  Not Found
    if (
      this.PVRForm.value.ownership === "co-owners found" &&
      this.PVRForm.value.property === "not verified" &&
      this.PVRForm.value.encumbrance === "not found"
    ) {
      this.setStatusValues(
        "Average",
        "Applicant name verified as co-owner as per the latest government land records.",
        "Property not verified as per the latest government land records.",
        "No encumbrance found as per the latest government land records.",
        "-",
        "-"
      );
    }
    // Ownership 8      Property      Encumbrance
    // Co Owners found  Not Verified  Found
    if (
      this.PVRForm.value.ownership === "co-owners found" &&
      this.PVRForm.value.property === "not verified" &&
      this.PVRForm.value.encumbrance === "found"
    ) {
      this.setStatusValues(
        "Poor",
        "Applicant name verified as co-owner as per the latest government land records.",
        "Property not verified as per the latest government land records.",
        " encumbrance found as per the latest government land records.",
        "-",
        "-"
      );
      this.encumbranceRemarks =
        " encumbrance found as per the latest government land records.";
    }
    // Ownership 9     Property   Encumbrance
    // Not Confirmed   Verified   Not Found
    if (
      this.PVRForm.value.ownership === "not confirmed" &&
      this.PVRForm.value.property === "verified" &&
      this.PVRForm.value.encumbrance === "not found"
    ) {
      this.propertyText1 = "Property verified with total area of ";
      this.propertyText3 = "from which"
      this.propertyText2 = "owned as per the latest government land records.";
      let propertyText: any;
      if (this.PVRForm.value.TotalLandSize) {
        propertyText =
          "Property verified with total area of " +
          this.PVRForm.value.TotalLandSize + ' ' + this.selectedUnit +
          " as per the latest government land records.";
      } else {
        propertyText =
          "Property verified with total area of as per the latest government land records.";
      }
      this.setStatusValues(
        "Average",
        "Applicant name not verified as per the latest government land records.",
        propertyText,
        "No encumbrance found as per the latest government land records.",
        `Property recommended for ${this.applicationData.Type_of_Loan}`,
        "Provided Ownership is authenticated by Sales Deed / Index-II copy"
      );
    }
    // Ownership 10    Property   Encumbrance
    // Not Confirmed   Verified   Found
    if (
      this.PVRForm.value.ownership === "not confirmed" &&
      this.PVRForm.value.property === "verified" &&
      this.PVRForm.value.encumbrance === "found"
    ) {
      this.propertyText1 = "Property verified with total area of ";
      this.propertyText3 = "from which"
      this.propertyText2 = "owned as per the latest government land records.";
      let propertyText: any;
      if (this.PVRForm.value.TotalLandSize) {
        propertyText =
          "Property verified with total area of " +
          this.PVRForm.value.TotalLandSize + ' ' + this.selectedUnit +
          " as per the latest government land records.";
      } else {
        propertyText =
          "Property verified with total area of as per the latest government land records.";
      }
      this.setStatusValues(
        "Poor",
        "Applicant name not verified as per the latest government land records.",
        propertyText,
        " encumbrance found as per the latest government land records.",
        `Property recommended for ${this.applicationData.Type_of_Loan}`,
        "Provided Ownership is authenticated by Sales Deed / Index-II copy and No-Due Certificate is obtained from applicant"
      );
      this.encumbranceRemarks =
        " encumbrance found as per the latest government land records.";
    }
    // Ownership 11    Property       Encumbrance
    // Not Confirmed   Not Verified   Not Found
    if (
      this.PVRForm.value.ownership === "not confirmed" &&
      this.PVRForm.value.property === "not verified" &&
      this.PVRForm.value.encumbrance === "not found"
    ) {
      this.setStatusValues(
        "Poor",
        "Applicant name not verified as per the latest government land records.",
        "Property not verified as per the latest government land records.",
        "No encumbrance found as per the latest government land records.",
        `Property not recommended for ${this.applicationData.Type_of_Loan}`,
        "-"
      );
    }
    // Ownership 12   Property       Encumbrance
    // Not Confirmed  Not Verified   Found
    if (
      this.PVRForm.value.ownership === "not confirmed" &&
      this.PVRForm.value.property === "not verified" &&
      this.PVRForm.value.encumbrance === "found"
    ) {
      this.setStatusValues(
        "Poor",
        "Applicant name not verified as per the latest government land records.",
        "Property not verified as per the latest government land records.",
        " encumbrance found as per the latest government land records.",
        "-",
        "-"
      );
      this.encumbranceRemarks =
        " encumbrance found as per the latest government land records.";
    }
    if (
      this.PVRForm.value.ownership === "" ||
      this.PVRForm.value.property === "" ||
      this.PVRForm.value.encumbrance === ""
    ) {
      this.setStatusValues("", "", "", "", "", "");
      // this.PVRForm.patchValue({
      //   encumbranceFound: "",
      // });
      this.encumbranceRemarks = null;
    }
  }
  getDefaultUnit (state) {
    if (state === 'Gujarat' || state === 'Maharashtra' || state === 'Tamil Nadu' || state === 'Uttarakhand' || state === 'Goa' || state === 'Uttar Pradesh' || state === 'Madhya Pradesh' || state === 'Maharashtra')
    {
      return 'sq_Meter';
    } else if (state === 'Andhra Pradesh' || state === 'Bihar' || state === 'Jharkhand' || state === 'West Bengal' || state === 'Tripura')
    {
      return 'Acres';
    } else if (state === 'Karnataka' || state === 'Telangana')
    {
      return 'Acres.Guntha';
    } else if (state === 'Chhatisgarh')
    {
      return 'Hector';
    } else if (state === 'Himanchal Pradesh')
    {
      return 'Hector';
    } else if (state === 'Rajasthan')
    {
      return 'sq_Meter';
    } else if (state === 'Punjab' || state === 'Harayana')
    {
      return 'Kanal';
    }
  }
  convertintoAcre(data , CurrentUnit, ConvertedUnit) {
    let valueinAcre = 0;
    switch (CurrentUnit){
      case 'Acres': {
        valueinAcre =  data * 1;
        break;
      }
      case 'Acres.Guntha': {
        const roundoff = Number(data)%1;
        valueinAcre = Math.floor(Number(data)) + Number(roundoff * 2.499999999);
        break;
      }
      case 'Bigha': {
        valueinAcre =  data * 0.619834;
        break;
      } 
      case 'Biswa': {
        valueinAcre =  data * 0.030992;
        break;
      } 
      case 'Guntha': {
        valueinAcre =  data * 0.025000;
        break;
      } 
      case 'Hector': {
        valueinAcre =  data * 2.471052;
        break;
      } 
      case 'Kanal': {
        valueinAcre =  data * 0.125000;
        break;
      } 
      case 'sq_Foot': {
        valueinAcre =  data * 0.000023;
        break;
      } 
      case 'Marla': {
        valueinAcre =  data * 0.006250;   
        break;
      } 
      case 'sq_Inch': {
        valueinAcre =  data * 1.594224e-7;    
        break;
      } 
      case 'sq_Kilometer': {
        valueinAcre =  data * 247.105163;   
        break;
      } 
      case 'sq_Meter': {
        valueinAcre =  data * 0.000247;   
        break;
      } 
      case 'sq_Mile': {
        valueinAcre =  data * 640.002990;  
        break;
      } 
      case 'sq_Yard': {
        valueinAcre =  data * 0.000207; 
        break;    
      } 
    }
    return this.convertsize(valueinAcre, ConvertedUnit);
  }
  convertsize(valueinAcre, ConvertedUnit) {
    switch (ConvertedUnit){
        case 'Acres': {
          this.defaultUnit = ConvertedUnit;
          return (valueinAcre * 1);
        } 
        case 'Acres.Guntha': {
          const roundoff = Number(valueinAcre)%1;
          this.defaultUnit = ConvertedUnit;
          return (Math.floor(Number(valueinAcre)) + Number(roundoff * 0.4000000001));
        }
        case 'Bigha': {
          this.defaultUnit = ConvertedUnit;
          return (valueinAcre * 1.613335);
        } 
        case 'Biswa': {
          this.defaultUnit = ConvertedUnit;
          return (valueinAcre * 32.266695);
        } 
        case 'Guntha': {
          this.defaultUnit = ConvertedUnit;
          return (1 * 40.000036);
          
        } 
        case 'Hector': {
          this.defaultUnit = ConvertedUnit;
          return (valueinAcre * 0.404686);
        } 
        case 'Kanal': {
          this.defaultUnit = ConvertedUnit;
          return (valueinAcre * 8.000008);
        } 
        case 'sq_Foot': {
          this.defaultUnit = ConvertedUnit;
          return (valueinAcre * 43560.057264);
        } 
        case 'Marla': {
          this.defaultUnit = ConvertedUnit;
          return (valueinAcre * 159.999842);
        } 
        case 'sq_Inch': {
          this.defaultUnit = ConvertedUnit;
          return (valueinAcre * 6272645.545291);
        } 
        case 'sq_Kilometer': {
          this.defaultUnit = ConvertedUnit;
          return (valueinAcre * 0.004047);
        } 
        case 'sq_Meter': {
          this.defaultUnit = ConvertedUnit;
          return (valueinAcre * 4046.860000);
        } 
        case 'sq_Mile': {
          this.defaultUnit = ConvertedUnit;
          return (valueinAcre * 0.001562);
        } 
        case 'sq_Yard': {
          this.defaultUnit = ConvertedUnit;
          return (valueinAcre * 4840.006363);
        } 
      }
  } 
  
  encumbranceCount() {
    const count = Number(this.PVRForm.controls.no_encum_applicant.value);
    const control = this.PVRForm.controls.EncumbranceDetails as FormArray;
    if (!Number.isNaN(count) && count != 0) {
      const length = control.length; 
      for(let i=length-1;i>=0;i--) {
        control.removeAt(i);
      }
      if(control.length == 0) {
        for(let i=0;i<count;i++) {
          control.push(this.initEncumbranceDetails());
        }
      }
    } else {
      const length = control.length; 
      for(let i=length-1;i>=0;i--) {
        control.removeAt(i);
      }
      control.push(this.initEncumbranceDetails());
    }
  }
}
