import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { EcheckDashboardComponent } from './echeck-dashboard/echeck-dashboard.component';
import { SharedModule } from '../shared/shared.module';
import { ECheckModuleRoutingModule } from './echeck-module-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { NgbProgressbarModule, NgbAccordionModule, NgbDatepickerModule, NgbModalModule, NgbAlertModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { DataTablesModule } from 'angular-datatables';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { NgApexchartsModule } from 'ng-apexcharts';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxEditorModule } from 'ngx-editor';
import { AngularMaterialModule } from '../angular-material/angular-material.module';
import { PinchZoomModule } from '../bank-module/pinch-zoom/pinch-zoom.module';
import { LayoutsModule } from '../layouts/layouts.module';
import { UIModule } from '../shared/ui/ui.module';
import { ViewEcheckDocumentComponent } from './view-echeck-document/view-echeck-document.component';
import { ViewEcheckApplicationComponent } from './view-echeck-application/view-echeck-application.component';
import { AddEcheckApplicationsComponent } from './add-echeck-applications/add-echeck-applications.component';
import { AddPropertyComponent } from './add-property/add-property.component';
import { PropertyReportFormComponent } from './property-report-form/property-report-form.component';


@NgModule({
  declarations: [
    EcheckDashboardComponent, 
    ViewEcheckApplicationComponent,
    ViewEcheckDocumentComponent,
    AddEcheckApplicationsComponent,
    AddPropertyComponent,
    PropertyReportFormComponent
],
  imports: [
    CommonModule,
    SharedModule,
    CommonModule,
    LayoutsModule,
    UIModule,
    FormsModule,
    NgSelectModule,
    NgbProgressbarModule,
    NgApexchartsModule,
    NgbAccordionModule, 
    NgbDatepickerModule,
    DataTablesModule,
    NgbModalModule,
    NgbAlertModule,
    NgbDropdownModule,
    ReactiveFormsModule,
    FormsModule,
    AutocompleteLibModule,
    FileUploadModule,
    PDFExportModule,
    PdfViewerModule,
    NgxEditorModule,
    AngularMaterialModule,
    PinchZoomModule,
    PdfViewerModule,
    SharedModule,
    ECheckModuleRoutingModule
  ],
  providers: [DatePipe] ,entryComponents:[AddPropertyComponent],
})
export class ECheckModule { }
