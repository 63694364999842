import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralService } from 'src/app/services/general.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-view-posapplication',
  templateUrl: './view-posapplication.component.html',
  styleUrls: ['./view-posapplication.component.scss']
})
export class ViewPOSApplicationComponent implements OnInit {

  breadCrumbItems: any;
  ownerList: any[] = [];
  isLoading: boolean;
  ApplicationID: any;

  villageName: string;
  talukaName: string;
  districtName: string;
  stateName: string;
  data: any;

  constructor(private Service: GeneralService, private router: Router) {
    this.breadCrumbItems = [{ label: 'POS Dashboard', path: 'POS/POS-dashboard' },
    { label: 'POS Applications', path: '/', active: true }];
    let a = router.getCurrentNavigation().extras;

    if (a) {
      if (a.state) {
        this.ownerList = a.state.appData.owners;
        this.data = a.state.appData;
        this.ApplicationID = a.state.appData.ApplicationID;
        localStorage.setItem('POSAppID', this.ApplicationID);
        localStorage.setItem('AppData', JSON.stringify(this.data));
      }
    }
    if (localStorage.getItem('POSAppID')) {
      this.ApplicationID = localStorage.getItem('POSAppID');
    }
    if (localStorage.getItem('AppData')) {
      this.data = JSON.parse(localStorage.getItem('AppData'));
      this.ownerList = this.data.owners
    }

  }

  ngOnInit() {
    // this.getPOSData();
  }

  ngDestroy() {
    localStorage.removeItem('AppData');
    localStorage.removeItem('POSAppID');
  }

  getPOSData() {
    this.isLoading = true;
    this.Service.getPOSData().subscribe((res: any) => {
      if (res) {
        this.ownerList = res.data[3].owners;
        this.data = res.data[3]
        this.ApplicationID = res.data[3].ApplicationID;
        if (this.ownerList.length) {
          this.ownerList.forEach((i) => {
            //  this.addOwner(i);
          })
        }
        this.isLoading = false;
      }
    },
      (error) => {
        this.isLoading = false;
        Swal.fire({
          title: error.status,
          text: "Internal Server Error",
          type: "error",
          timer: 1500,
          showConfirmButton: false
        });
      })
  }

}
