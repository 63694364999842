import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EcheckDashboardComponent } from './echeck-dashboard/echeck-dashboard.component';
import { ViewEcheckApplicationComponent } from './view-echeck-application/view-echeck-application.component';
import { ViewEcheckDocumentComponent } from './view-echeck-document/view-echeck-document.component';
import { AddEcheckApplicationsComponent } from './add-echeck-applications/add-echeck-applications.component';
import { AddPropertyComponent } from './add-property/add-property.component';
import { PropertyReportFormComponent } from './property-report-form/property-report-form.component';

const routes: Routes = [
  { path: '', redirectTo: 'echeck-dashboard', pathMatch: 'full' },
  {
    path: 'echeck-dashboard',
    component: EcheckDashboardComponent
  },
  {
    path: 'view-echeck-application',
    component: ViewEcheckApplicationComponent
  },
  {
    path: 'view-echeck-document',
    component: ViewEcheckDocumentComponent
  },
  {
    path: 'add-echeck-application',
    component: AddEcheckApplicationsComponent
  },
  {
    path: 'add-property',
    component: AddPropertyComponent
  },
  {
    path:'property',
    component: PropertyReportFormComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ECheckModuleRoutingModule { }
