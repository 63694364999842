import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { GeneralService } from 'src/app/services/general.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-response',
  templateUrl: './add-response.component.html',
  styleUrls: ['./add-response.component.scss']
})
export class AddResponseComponent implements OnInit {
  pastPublicNoticeForm: FormGroup;
  breadCrumbItems: any;
  currentUser: any;
  isLoading: boolean;
  submited: boolean;
  file: any;
  applicationData: any;
  constructor(
    private generalService: GeneralService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.currentUser = this.generalService.getcurrentUser();
    this.pastPublicNoticeForm = new FormGroup({
      FileName: new FormControl(null, Validators.required),
      Description: new FormControl(null, Validators.required),
      File: new FormControl(null),
    });
    this.pastPublicNoticeForm.controls.FileName.disable();
  }
  ngOnInit() {
    this.isLoading = true;
    this.generalService.GetApplicationInformation(this.route.snapshot.params.AppID).subscribe((res) => {
      this.isLoading = false;
      this.applicationData = res.data[0];
      this.breadCrumbItems = [{ label: 'Dashboard', path: '/' }, { label: 'Assignments', path: '/loan/assignment' }, { label: `${this.applicationData.FirstName != null ? this.applicationData.FirstName : ''} ${ this.applicationData.LastName != null ? this.applicationData.LastName : ''}`, path: '/loan/assignment/' + this.route.snapshot.params.AppID }, {label: 'Public Notices', path: '/loan/viewpastpublicnotice/' + this.route.snapshot.params.AppID}, { label: "Add a Response", path: "/loan/addResponse/" +  this.route.snapshot.params.AppID + '/' + this.route.snapshot.params.NoticeID, active: true }];
    });
  }
  
  get f() {
    return this.pastPublicNoticeForm.controls;
  }
  onchange(e) {
    if (e && e.length > 0) {
      this.file = e[0];
      let fileName = this.file.name;
      this.pastPublicNoticeForm.controls.FileName.setValue(fileName);
      
    } else {
      this.pastPublicNoticeForm.controls.FileName.setValue("");
    }   
  }
  onSubmit() {
    this.pastPublicNoticeForm.controls.FileName.enable();
    this.submited = true;
    if (this.pastPublicNoticeForm.valid) {
      this.isLoading = true;      
      let formData: FormData = new FormData();
      formData.append('comment', this.pastPublicNoticeForm.controls.Description.value);
      formData.append('FileName', this.pastPublicNoticeForm.controls.FileName.value);
      formData.append('File', this.file, this.pastPublicNoticeForm.controls.FileName.value);
      this.generalService.addNoticeResponse(formData , this.route.snapshot.params.NoticeID).subscribe((res) => {
        this.isLoading = false;
        console.log(res);
        if(res.status == 200) {
          Swal.fire({
            title: `Success`,
            text: res.message,
            type: 'success',
            showConfirmButton: false,
            timer:3000
          }).then(() => { this.router.navigate(['/loan/viewNotice/' +  this.route.snapshot.params.AppID + '/' + this.route.snapshot.params.NoticeID]);  })
        } else {
          Swal.fire({
            title: `Error`,
            text: res.message,
            type: 'error',
            showConfirmButton: false,
            timer:3000
          }).then(() => {});
        }
      }, (error) => {
        console.log(error);
        this.isLoading = false;
        Swal.fire({
          title: `Error`,
          text: 'Internal Server Error',
          type: 'error',
          showConfirmButton: false,
          timer:3000
        }).then(() => {});
      });
    } else{
      console.log(this.pastPublicNoticeForm);
    }
  }
}
