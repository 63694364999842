import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GeneralService } from 'src/app/services/general.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-questions',
  templateUrl: './add-questions.component.html',
  styleUrls: ['./add-questions.component.scss']
})
export class AddQuestionsComponent implements OnInit {
  breadCrumbItems:any;
  form:FormGroup;
  currentUser:any
  answerTypes = [
    { type: 'Text Box', id: 'Text_Box' },
    { type: 'Radio Button', id: 'Radio_Button' },
    { type: 'Checkbox', id: 'Check_Box' },
    {type: 'Html Editor', id:'Html_Editor'}
  ];
  IsMandatory = [
    { type: 'Yes', id: '1'},
    { type: 'No', id: '0' },
    
  ];
  submitted: boolean;
  isLoading: boolean;
  constructor(private fb: FormBuilder, private service: GeneralService,private router: Router) { 
    this.breadCrumbItems = [
      { label: 'Dashboard', path: '/dashboard' },
      { label: 'Applications', path: '/loan/title-clear-applications ' },
      { label:'Add Questions',path:'',active: true}];
  }

  ngOnInit() {
    this.currentUser = this.service.getcurrentUser();
    this.form = this.fb.group({
      questions: this.fb.array([
        this.createQuestion()
      ]),
      CreatedBy: new FormControl(this.currentUser.UserID),
    });
    console.log('form',this.form)
  }

  createQuestion(): FormGroup {
    return this.fb.group({     
      Question: ['', Validators.required],
      AnswerType: ['text', Validators.required],
      isMandatory: [0,Validators.required],
      options: this.fb.array([])
    });
  }
  addAnotherQuestion(index:number){
    const control = this.form.controls.questions as FormArray;
    // if(control.length == 0) {
      control.push(this.createQuestion());
  }

  removeQuestion(index:number){
    const control = this.form.controls.questions as FormArray;
    control.removeAt(index)
 
  }
  get questions(): FormArray {
    return this.form.get('questions') as FormArray;
  }
  addOption(questionIndex: number) {
    const options = this.questions.at(questionIndex).get('options') as FormArray;
    options.push(this.fb.control('', Validators.required));
  }
  removeOption(questionIndex: number, optionIndex?: number) {
    const options = this.questions.at(questionIndex).get('options') as FormArray;
    options.removeAt(optionIndex);
  }

  onAnswerTypeChange(questionIndex: number) {
    const question = this.questions.at(questionIndex);
    const answerType = question.get('AnswerType').value;
    const options = question.get('options') as FormArray;

    if (answerType === 'radio' || answerType === 'checkbox') {
      if (options.length === 0) {
        // this.addOption(questionIndex);
        this.addOption(questionIndex);
      }
    } else {
      // while (options.length !== 0) {
      //   options.removeAt(0);
      // }
      // console.log('options',options)
      // for(var i =0; i<=options.length;i++){
      //   options.removeAt(i);
      // }
      options.clear();
    }
  }


  save(){
    console.log('form details',this.form)
    this.submitted = true;
    if(this.form.valid){
      this.isLoading = true;
    this.service.AddQuestions(this.form.value).subscribe((res:any)=>{
      if (res.error) {
        this.submitted = false;
        this.isLoading = false;
        Swal.fire({
          title: res.error_code,
          text: res.message,
          type: 'error',
          showConfirmButton: false,
          timer: 3000
        });
        return;
      }
      else {
        this.submitted = false;
        this.isLoading = false;
        Swal.fire({
          title: 'Success',
          text: res.message,
          type: 'success',
          showConfirmButton: false,
          timer: 3000
        }).then(() => {
          this.router.navigate(['/loan/Question-Master']);
        });
      }
    })
    }
    else{
      this.isLoading = false;
      Swal.fire({
        title: 'Invalid',
        text: 'Invalid Form Data',
        type: 'error',
        showConfirmButton: false,
        timer: 3000
      });
    }
    
  }
}
