import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-dialogbox',
  templateUrl: './dialogbox.component.html',
  styleUrls: ['./dialogbox.component.scss']
})
export class DialogboxComponent implements OnInit {
  PostMortageForm: FormGroup;
  AppID:any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private fb:FormBuilder,
  private service: GeneralService,private Route: ActivatedRoute,private router: Router,
  public dialogRef: MatDialogRef<DialogboxComponent>) { }

  ngOnInit() {
    this.AppID = this.data.applicationData.data.ApplicatntDetails[0].AppID;
    this.PostMortageForm = this.fb.group({
      AppID: this.AppID,
      CERSAI: [true],
      GovernmentPortal: [true],
      None: [false],
    });
  }
  closeDialog(){
    this.dialogRef.close();
  }

  submit() {
    console.log('post mortgage form',this.PostMortageForm.value);
    let obj = {
      AppID: Number(this.PostMortageForm.controls.AppID.value),
      CERSAI: this.PostMortageForm.controls.CERSAI.value ? 1 :0,
      GovernmentPortal: this.PostMortageForm.controls.GovernmentPortal.value ? 1 :0,
      None: this.PostMortageForm.controls.None.value ? 1 :0,
    };
    console.log('post mortgage form',this.PostMortageForm.value);
    console.log('post mortgage form',obj);
    this.service.userMortgage(obj).subscribe((res: any) => {
      this.service.changeStatus(this.AppID, 'Post Mortgage').subscribe(() => {
        this.router.navigate(["/loan/post-mortgage-dashboard/"+this.AppID]);
        this.dialogRef.close(true);
        this.PostMortageForm.reset();
      });
    });
  }
 
}
