import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GeneralService } from 'src/app/services/general.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-application-by-image',
  templateUrl: './add-application-by-image.component.html',
  styleUrls: ['./add-application-by-image.component.scss']
})
export class AddApplicationByImageComponent implements OnInit {

  breadCrumbItems: any;
  public loan: FormGroup;
  file: Array<any>;
  submitted: boolean;
  isLoading: boolean;
  myValue: any;
  myValue_1: any;
  myValue_3: any;
  myValue_4: any;
  myValue_5: any;
  constructor(private Fb: FormBuilder,private gs: GeneralService, private router: Router) {
    this.breadCrumbItems = [{ label: 'Dashboard', path: 'loan' }, { label: 'Applications', path: '/loan/applications' },
    { label: 'Add New Application', path: '/loan/addApplicationByImage', active: true }];

   }

  ngOnInit() {
    this.loan = this.Fb.group({
      PropertyOwners: this.Fb.array([
        this.initOwner()
      ]),
      ApplicantFirstName: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z,/-/ ]*$')]),
      ApplicantLastName: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z,/-/ ]*$')]),
      ApplicationID: new FormControl('', [Validators.required, Validators.pattern('^[0-9a-zA-Z-]+$')]),
      File: new FormControl(null, Validators.required),
    })
  }
  removeWhitespace(value: any) {
    this.myValue = this.myValue.replace(/\s/g, '');
  }

  removeWhitespace_1(value: any) {
    this.myValue_1 = this.myValue_1.replace(/\s/g, '');
  }

  removeWhitespace_3(value: any) {
    this.myValue_3 = this.myValue_3.replace(/\s/g, '');
  }
  removeWhitespace_4(value: any) {
    this.myValue_4 = this.myValue_4.replace(/\s/g, '');
  }
  
  initOwner(i?) {
    if (i && i !== undefined) {
      return this.Fb.group({
        OwnerName: new FormControl(i.OwnerName ? i.OwnerName.trim():i.OwnerName, Validators.required),
      });
    } else {
      return this.Fb.group({
        OwnerName: new FormControl('', Validators.required)
      });
    }
  }
  isOwnerNameDuplicate(index: number, owners: FormArray, ownerName: string): boolean {
    for (let i = 0; i < index; i++) {
      if (owners.at(i).value.OwnerName.trim() === ownerName.trim()) {
        return true;
      }
    }
    return false;
  }
  get f() { return this.loan.controls; }
  
  removeOwner(i: number) {
    const control = this.f.PropertyOwners as FormArray;
    control.removeAt(i);
  }
  addOwner() {
    const control = this.f.PropertyOwners as FormArray;
    control.push(this.initOwner());
  }
  isOwnerNameEmpty(index: number): boolean {
    const ownerNameControl = this.loan.controls.PropertyOwners['controls'][index].get('OwnerName');
    return !ownerNameControl.value || ownerNameControl.value.trim() === '';
  }

  isOwnerNameTouched(index: number): boolean {
    const ownerNameControl = this.loan.controls.PropertyOwners['controls'][index].get('OwnerName');
    return ownerNameControl.touched;
  }
  onchange(event: any) {
    if (this.file.length) {
      const unsupportedFiles = [];
      const supportedFiles = [];
      for (let i = 0; i < this.file.length; i++) {
        const filetype = this.file[i].type;
        if (
          filetype.toLowerCase() !== 'application/pdf' && 
          filetype.toLowerCase() !== 'image/png' &&
          filetype.toLowerCase() !== 'image/jpeg' &&
          filetype.toLowerCase() !== 'image/jpg' &&
          filetype.toLowerCase() !== 'image/tiff'
        ) {
          unsupportedFiles.push(this.file[i].name);
        }
        else {
          supportedFiles.push(this.file[i])
        }
      }
      if (unsupportedFiles.length > 0) {
        Swal.fire({
          title: 'Error',
          text: `${unsupportedFiles.join(', ')} File(s) Are Not Supported`,
          type: 'error',
          showConfirmButton: false,
          timer: 3000
        }).then(() => {
          this.file = supportedFiles;
        });
      } else {
        // Code to execute when all file types are supported
        // ...
      }
    }
  }

  // Owners() {
  //   this.showOwnerForm = true
  // }

  save() {
    this.submitted = true;
    this.checkForValidation();
    this.checkValidationForProperties();
    const input = new FormData();
    let userdetails = this.gs.getcurrentUser();
    let Id = userdetails.UserID;
    console.log('loan',this.loan.controls);
    input.append('CreatedBy',Id);
    if (this.loan.controls.PropertyOwners['controls'].length) {
      let ownerNames = [];
      this.loan.controls.PropertyOwners['controls'].forEach((control) => {
        let ownerName = control.get('OwnerName').value;
        ownerNames.push(ownerName);
        input.append('Owners', ownerName);
      });
      input.append('ApplicationID', this.loan.controls.ApplicationID.value);
    }
    input.append('ApplicantFirstName',this.loan.controls.ApplicantFirstName.value);
    input.append('ApplicantLastName',this.loan.controls.ApplicantLastName.value);
      if(this.loan.valid){
        this.isLoading = true;
        this.submitted = false;
        if (this.file.length > 0) {
          const firstFileExtension = this.getFileExtension(this.file[0].name);
          input.append('type',this.file[0].name.split('.').pop().toLowerCase())
          if (this.file.every(file => this.getFileExtension(file.name) === firstFileExtension)) {
            for (let i = 0; i < this.file.length; i++) {
              input.append('images', this.file[i], this.file[i].name);
            }
          } else {
            this.isLoading = false;
            console.error('All files must have the same extension.');
            Swal.fire({
              title: 'Failed ',
              text: 'All files must have the same extension.',
              type: 'error',
              showConfirmButton: false,
              timer: 3000
            });
            return
          }
        } else {
          this.isLoading = false;
          console.error('No files to append.');
        }
        this.isLoading = true;
        this.gs.addApplicationByImage(input).subscribe((res) => {
          this.isLoading = false;
          Swal.fire({
            title: `Application Added`,
            text: `Application Added Successfully`,
            type: 'success',
            showConfirmButton: false,
            timer: 1500
          });
          this.router.navigate(['/loan/applications']);
        },
          ((error) => {
            this.isLoading = false;
            Swal.fire({
              title: 'Failed ',
              text: 'Faild to Add Application',
              type: 'error',
              showConfirmButton: false,
              timer: 1500
            })
          }))
      }
      else{
        this.isLoading = false;
        Swal.fire({
          title: 'Invalid',
          text: 'Invalid Form Data',
          type: 'error',
          showConfirmButton: false,
          timer: 1500
        });
      }
  }

  getFileExtension(fileName: string): string {
    console.log('filename',fileName);
    return fileName.split('.').pop().toLowerCase();
  }

  checkForValidation() {
    for (var control in this.loan.value) {
      if (this.loan.value[control]) {
        let a = (typeof (this.loan.value[control]) === 'string') ? this.loan.controls[control].value.trim() : this.loan.controls[control].value;
        this.loan.controls[control].setValue(a);
      }
    }
  }

  checkValidationForProperties(){
    if(this.loan.controls['PropertyOwners'].value.length){
      for(let i=0;i<this.loan.controls['PropertyOwners'].value.length;i++){
        for(var control in this.loan.controls['PropertyOwners']['controls'][i].value){
          if(this.loan.controls['PropertyOwners']['controls'][i].value[control]){
            let a = (typeof (this.loan.controls['PropertyOwners']['controls'][i].value[control]) === 'string') ? this.loan.controls['PropertyOwners']['controls'][i].value[control].trim() : this.loan.controls['PropertyOwners']['controls'][i].value[control];
            this.loan.controls['PropertyOwners']['controls'][i].controls[control].setValue(a);
          }
        }
      }
    }
  }

  
}
