import { DatePipe } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  Renderer2,
  AfterViewInit,
} from "@angular/core";
import { Router } from "@angular/router";
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { DataTableDirective } from "angular-datatables";
import { param } from "jquery";
import { Subject } from "rxjs";
import { filter, first } from "rxjs/operators";
import { GeneralService } from "src/app/services/general.service";

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit, AfterViewInit {
  breadCrumbItems: Array<{}>;
  countData: any;
  isLoading: boolean;
  isdropdownShow: boolean;
  isFilterLoading: boolean;
  rightsData:any[] = [];
  filterobj: {
    FilterStartDate: string;
    FilterEndDate: string;
    TypeOfLoan: number;
    LoanPropertyTypeID: number;
    ApplicationStatus: string;
    UserID: string;
    CompanyUserMasterID: any;
    stateID: number;
  };
  fromNGDate: NgbDate;
  toNGDate: NgbDate;
  allLoanTypes: any;
  allStatus = ["iPVR sent", "iPVR in Progress"];
  // allStatus = ['iPVR sent', 'iPVR in Progress', 'TitleClear In Progress', 'TC Document In Progress', 'TC Document Completed', 'TC Notice In Progress', 'TC Notice Completed', 'Title Clear Sent','Post Mortgage Protection'];
  allProperty: any;
  allBanks = [
    "State Bank of India",
    "Bank of Baroda",
    "Union Bank of India",
    "Canara Bank",
  ];
  allIndia = ["Maharashtra", "Punjab", "Gujarat"];
  selected: any;
  hidden: boolean;
  ChartType: {
    chart?: any;
    plotOptions?: any;
    colors?: any;
    series?: any;
    stroke?: any;
    fill?: any;
    labels?: any;
    markers?: any;
    legend?: any;
    xaxis?: any;
    yaxis?: any;
    tooltip?: any;
    grid?: any;
    datasets?: any;
    options?: any;
    toolbar?: any;
    type?: any;
    height?: any;
    dataLabels?: any;
    sparkline?: any;
  };
  ChartType2: {
    chart?: any;
    plotOptions?: any;
    colors?: any;
    series?: any;
    stroke?: any;
    fill?: any;
    labels?: any;
    markers?: any;
    legend?: any;
    xaxis?: any;
    yaxis?: any;
    tooltip?: any;
    grid?: any;
    datasets?: any;
    options?: any;
    toolbar?: any;
    type?: any;
    height?: any;
    dataLabels?: any;
    sparkline?: any;
  };
  ChartType3: {
    chart?: any;
    plotOptions?: any;
    colors?: any;
    series?: any;
    stroke?: any;
    fill?: any;
    labels?: any;
    markers?: any;
    legend?: any;
    xaxis?: any;
    yaxis?: any;
    tooltip?: any;
    grid?: any;
    datasets?: any;
    options?: any;
    toolbar?: any;
    type?: any;
    height?: any;
    dataLabels?: any;
    sparkline?: any;
  };
  // tslint:disable-next-line: variable-name
  user_type: number;
  @Input() fromDate: Date;
  @Input() toDate: Date;
  dtOptions: DataTables.Settings = {};
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtTrigger = new Subject<any>();
  currentUser: any;
  loanTypeData: Array<any>;
  loanTypes: Array<string>;
  loanapp: Array<number>;
  @Output() dateRangeSelected: EventEmitter<{}> = new EventEmitter();

  @ViewChild("dp", { static: true }) datePicker: any;
  applications: any;
  LawyercountData: any;
  assignmentCount: any;
  interval: NodeJS.Timer;
  totalAssignmentCount: any;
  inputValue: any = "";
  isLoadingTable: boolean;
  StateArray: any[] = [];
  Statearray: any[] = [];
  constructor(
    private router: Router,
    private service: GeneralService,
    private renderer: Renderer2,
    private datePipe: DatePipe,
    private http: HttpClient
  ) {
    this.currentUser = this.service.getcurrentUser();
    this.isLoading = true;
    this.isFilterLoading = true;
  }

  ngOnInit() {
    this.getRights();
    this.ChartType = {
      type: "donut",
      height: 260,
      series: [42, 30, 28],
      labels: ["5 Lacs to 10 Lacs", "Below 5 Lacs", "Above 10 Lacs"],
      colors: ["#26c6e1", "#6658dd", "#7FFF00"],
      dataLabels: {
        enabled: true,
      },
      legend: {
        show: false,
      },
      options: {
        maintainAspectRatio: false,
        cutoutPercentage: 70,
        legend: {
          display: true,
        },
      },
    };
    this.ChartType2 = {
      type: "donut",
      height: 260,
      series: [42, 30, 28],
      // series: this.loanapp,
      labels: ["5 Lacs to 10 Lacs", "Below 5 Lacs", "Above 10 Lacs"],
      // labels: this.loanTypes,
      colors: [
        "#26c6e1",
        "#6658dd",
        "#d9847e",
        "#ebeff2",
        "#9483de",
        "#7fe393",
      ],
      dataLabels: {
        enabled: true,
      },
      legend: {
        show: false,
      },
      options: {
        maintainAspectRatio: false,
        cutoutPercentage: 70,
        legend: {
          display: true,
        },
      },
    };
    this.ChartType3 = {
      type: "donut",
      height: 260,
      series: [400, 500, 200, 314, 314],
      labels: [
        "Buildings",
        "Open Land",
        "Bungalows",
        "Flats",
        "Factory",
      ],
      colors: ["#26c6e1", "#6658dd", "#7FFF00", "#d9847e", "#9483de"],
      dataLabels: {
        enabled: true,
      },
      legend: {
        show: false,
      },
      options: {
        maintainAspectRatio: false,
        cutoutPercentage: 70,
        legend: {
          display: true,
        },
      },
    };
    this.service.Statelist().subscribe(res => {
      // console.log("line==165",res) ;
     this. Statearray= res.data
      this.isLoading = false;
    })
    this.isdropdownShow = false;
    this.currentUser = this.service.getcurrentUser();
    this.fetchstate();
    this.hidden = true;
    this.breadCrumbItems = [];
    this.loanTypes = [];
    this.loanapp = [];
    this.filterobj = {
      FilterStartDate: "",
      FilterEndDate: "",
      TypeOfLoan: null,
      LoanPropertyTypeID: null,
      ApplicationStatus: "",
      UserID: this.currentUser.UserID,
      CompanyUserMasterID: this.currentUser.CompanyUserMasterID,
      stateID: null,
    };
    this.service.GetLoanPropertyTypes().subscribe((res) => {
      this.allProperty = res.data;
      this.service.GetLoanTypes().subscribe((data) => {
        this.allLoanTypes = data.data;
        this.service
          .GetLoanTypePVRStatus(this.currentUser.UserID)
          .subscribe((Res) => {
            this.loanTypeData = Res.data;
            this.isLoading = false;
            this.loanTypeData.forEach((x) => {
              this.loanTypes.push(x.Type_of_Loan);
              this.loanapp.push(+x.Count_App_By_LoanType);
            });
            this.ChartType = {
              type: "donut",
              height: 260,
              series: [42, 30, 28],
              labels: ["5 Lacs to 10 Lacs", "Below 5 Lacs", "Above 10 Lacs"],
              colors: ["#26c6e1", "#6658dd", "#7FFF00"],
              dataLabels: {
                enabled: true,
              },
              legend: {
                show: false,
              },
              options: {
                maintainAspectRatio: false,
                cutoutPercentage: 70,
                legend: {
                  display: true,
                },
              },
            };
            this.ChartType2 = {
              type: "donut",
              height: 260,
              series: [42, 30, 28],
              // series: this.loanapp,
              labels: ["5 Lacs to 10 Lacs", "Below 5 Lacs", "Above 10 Lacs"],
              // labels: this.loanTypes,
              colors: [
                "#26c6e1",
                "#6658dd",
                "#d9847e",
                "#ebeff2",
                "#9483de",
                "#7fe393",
              ],
              dataLabels: {
                enabled: true,
              },
              legend: {
                show: false,
              },
              options: {
                maintainAspectRatio: false,
                cutoutPercentage: 70,
                legend: {
                  display: true,
                },
              },
            };
            this.ChartType3 = {
              type: "donut",
              height: 260,
              series: [400, 500, 200, 314, 314],
              labels: [
                "Buildings",
                "Open Land",
                "Bungalows",
                "Flats",
                "Factory",
              ],
              colors: ["#26c6e1", "#6658dd", "#7FFF00", "#d9847e", "#9483de"],
              dataLabels: {
                enabled: true,
              },
              legend: {
                show: false,
              },
              options: {
                maintainAspectRatio: false,
                cutoutPercentage: 70,
                legend: {
                  display: true,
                },
              },
            };
          });
      });
    });
    this.filterCount();
    if (this.currentUser.UserType === "Bank Manager") {
      this.dtOptions = {
        pagingType: "simple_numbers",
        pageLength: 10,
        serverSide: true,
        processing: true,
        searching: false,
        ajax: (dataTablesParameters: any, callback) => {
          this.isLoadingTable = true;
          this.http
            .get<DataTablesResponse>(
              this.service.GetBaseUrl() +
                `get/loan/application/bank` +
                `?UserID=${this.currentUser.UserID}` +
                `&pageNo=${
                  (dataTablesParameters.start + dataTablesParameters.length) /
                  dataTablesParameters.length
                }` +
                `${
                  dataTablesParameters.length.value != ""
                    ? "&limit=" + dataTablesParameters.length
                    : ""
                }` +
                `${
                  this.inputValue != "" ? "&searchkey=" + this.inputValue : ""
                }` +
                `${
                  dataTablesParameters.order
                    ? "&orderingkey=" +
                      dataTablesParameters.columns[
                        dataTablesParameters.order[0].column
                      ].name
                    : "&orderingkey=AppID"
                }` +
                `${
                  dataTablesParameters.order
                    ? "&order=" + dataTablesParameters.order[0].dir
                    : "&order=desc"
                }` +
                `${
                  this.filterobj.FilterStartDate != ""
                    ? "&startdate=" + this.filterobj.FilterStartDate
                    : ""
                }` +
                `${
                  this.filterobj.FilterEndDate != ""
                    ? "&enddate=" + this.filterobj.FilterEndDate
                    : ""
                }` +
                `${
                  this.filterobj.TypeOfLoan != null
                    ? "&loanType=" + this.filterobj.TypeOfLoan
                    : ""
                }` +
                `${
                  this.filterobj.ApplicationStatus != "" &&
                  this.filterobj.ApplicationStatus != null
                    ? "&status=" + this.filterobj.ApplicationStatus
                    : ""
                }` +
                `${
                  this.filterobj.LoanPropertyTypeID != null
                    ? "&propertyType=" + this.filterobj.LoanPropertyTypeID
                    : ""
                }` +
                `${
                  this.filterobj.stateID != null
                    ? "&StateID=" + this.filterobj.stateID
                    : ""
                }`,

              {}
            )
            .subscribe((resp: any) => {
              this.isLoadingTable = false;
              this.applications = resp.data;
              callback({
                recordsTotal: resp.allCount,
                recordsFiltered: resp.count,
                data: this.applications,
              });
            });
        },
        columns: [
          {
            title: "AppID",
            name: "AppID",
            data: "AppID",
          },
          {
            title: "Application ID",
            name: "ApplicationID",
            data: "ApplicationID",
          },
          {
            title: 'Name',
            name: 'FirstName',
            data: 'ApplicantName',
            render: (data) => this.renderData(data),
         },
        // {
            // title: "Name",
            // name: "FirstName",
            // data: null,
            // render: (data) => {
            //   const maxLength = 15;
              // if (data.ApplicantName.length > maxLength) {
              //   return `<span title="${
              //     data.ApplicantName
              //   }">${data.ApplicantName.slice(0, maxLength)}...</span>`;
              // } else {
              //   return data.ApplicantName;
              // }
            // },
          // },
          {
            title: "State Name",
            name: "StateName",
            data: "StateName",
          },
          {
            title: "Loan Type",
            name: "Type_of_Loan",
            data: "Type_of_Loan",
          },
          {
            title: "Status",
            name: "ApplicationStatus",
            data: "ApplicationStatus",
            render: (data) => {
              // if (data === 'Pending') {
              //   return `<span class="badge badge-danger p-1">${data}</span>`;
              // } else if (data === 'iPVR Sent') {
              //   return `<span class="badge badge-success p-1">${data}</span>`;
              // } else if (data) {
              //   return `<span class="badge badge-secondary p-1">${data}</span>`;
              // } else {
              //   return data;
              // }
              if (data === "iPVR Sent" || data === "Title Clear Sent") {
                return `<span class="badge badge-success p-1">${data}</span>`;
              } else if (data) {
                return `<span class="badge badge-secondary p-1">${data}</span>`;
              } else {
                return data;
              }
            },
          },
          {
            title: "Amount (₹)",
            name: "LoanAmount",
            data: "LoanAmount",
          },
          {
            title: "Action",
            data: null,
            render: (data, type, row) => {
              if (row.ApplicationStatus === 'iPVR Sent') {
                return `<a class="text-primary" title="View Application"
              viewID = "${row.AppID}"><i class="mdi mdi-eye font-18 text-secondary" viewID = "${row.AppID}"  ApplicationStatus= '${row.ApplicationStatus}' aria-hidden="false"></i></a>
              <a DownloadIPVRAppID = "${row.AppID}" class="m-1" title="Download iPVR">
              <i class="mdi mdi-file-download font-18 text-secondary" aria-hidden="false" DownloadIPVRAppID = "${row.AppID}"></i>
              </a>`;
              } else {
                return `<a class="btn text-primary" title="View Application"
              viewID = "${row.AppID}"><i class="mdi mdi-eye font-18 text-secondary" viewID = "${row.AppID}" aria-hidden="false" ApplicationStatus= '${row.ApplicationStatus}'></i></a>`;
              }
            },
          },
        ],
        order: [[0, "desc"]],
        columnDefs: [
          { targets: 0, visible: false },
          { targets: 7, orderable: false },
        ],
      };
    }
  }

  renderData(data) {
    if (data === '' || data === null || data === 'null') {
      return '--';
    } else {
      return data;
    }
  }

  fetchstate() {
    // this.Loading = true
    this.service
      .statesList(this.currentUser.UserID)
      .pipe(first())
      .subscribe((data: any) => {
        // console.log("line==457",data)
        // this.Loading = false;
        if (data.error) {
          // console.log(data.error);
          return;
        } else {
          this.StateArray = data.data;
        }
      });
  }

  onDateSelection(date: NgbDate) {
    this.filterobj.FilterStartDate = "";
    this.filterobj.FilterEndDate = "";
    if (!this.fromDate && !this.toDate) {
      this.fromNGDate = date;
      this.fromDate = new Date(date.year, date.month - 1, date.day);
      this.selected = "";
    } else if (this.fromDate && !this.toDate && date.after(this.fromNGDate)) {
      this.toNGDate = date;
      this.toDate = new Date(date.year, date.month - 1, date.day);
      this.hidden = true;
      this.selected =
        this.fromDate.toLocaleDateString() +
        "-" +
        this.toDate.toLocaleDateString();
      this.filterobj.FilterStartDate = this.datePipe.transform(
        this.fromDate,
        "yyyy-MM-dd"
      );
      this.filterobj.FilterEndDate = this.datePipe.transform(
        this.toDate,
        "yyyy-MM-dd"
      );
      this.dateRangeSelected.emit({
        fromDate: this.fromDate,
        toDate: this.toDate,
      });
      this.fromDate = null;
      this.toDate = null;
      this.fromNGDate = null;
      this.toNGDate = null;
      this.filterCount();
    } else {
      this.fromNGDate = date;
      this.fromDate = new Date(date.year, date.month - 1, date.day);
      this.selected = "";
    }
    this.rerender();
  }

  filterCount() {
    this.isFilterLoading = false;
     if(this.filterobj.CompanyUserMasterID === 3) {
      this.isFilterLoading = true;
      // console.log(this.filterobj);
      this.service.getLawyerfilterCount(this.filterobj.UserID).subscribe((res) => {
        this.service.getLawyerfilterCount(this.currentUser.UserID).subscribe((res) => {
          this.isFilterLoading = false;
          this.LawyercountData = res.data[0];
        });
      });
    } else if(this.currentUser.UserType === "Proplegit") {
      // this.isFilterLoading = true;
      let param = '?UserID=' + this.filterobj.UserID + `${this.filterobj.FilterStartDate!=''?'&startdate='+this.filterobj.FilterStartDate:''}`+
      `${this.filterobj.FilterEndDate!=''?'&enddate='+this.filterobj.FilterEndDate:''}`+
      `${this.filterobj.TypeOfLoan!=null?'&loanType='+this.filterobj.TypeOfLoan:''}`+ `${this.filterobj.stateID!=null?'&stateID='+this.filterobj.stateID:''}` +
      `${this.filterobj.ApplicationStatus!='' && this.filterobj.ApplicationStatus!=null?'&status='+this.filterobj.ApplicationStatus:''}`+
      `${this.filterobj.LoanPropertyTypeID!=null?'&propertyType='+this.filterobj.LoanPropertyTypeID:''}`;

      this.service.getLoanDashboard_1(param).subscribe((res) => {
        this.countData = res.data[0];
        this.isFilterLoading = false
      })
    }
    else{
      let param = '?UserID=' + this.filterobj.UserID + `${this.filterobj.FilterStartDate!=''?'&startdate='+this.filterobj.FilterStartDate:''}`+
      `${this.filterobj.FilterEndDate!=''?'&enddate='+this.filterobj.FilterEndDate:''}`+
      `${this.filterobj.TypeOfLoan!=null?'&loanType='+this.filterobj.TypeOfLoan:''}`+ `${this.filterobj.stateID!=null?'&stateID='+this.filterobj.stateID:''}` +
      `${this.filterobj.ApplicationStatus!='' && this.filterobj.ApplicationStatus!=null?'&status='+this.filterobj.ApplicationStatus:''}`+
      `${this.filterobj.LoanPropertyTypeID!=null?'&propertyType='+this.filterobj.LoanPropertyTypeID:''}`;
      this.service.getLoanDashboard(param).subscribe((res) => {
        this.countData = res.data[0]
        this.isFilterLoading = false
        // console.log("scklskd", res.data[0])
      })
      
    }
  }
    // columns: [
    //   {
    //     title: "AppID",
    //     name: "AppID",
    //     data: "AppID",
    //   },
    //   {
    //     title: "Application ID",
    //     name: "ApplicationID",
    //     data: "ApplicationID",
    //   },
    //   // {
    //   //   title: 'Name',
    //   //   name: 'FirstName',
    //   //   data: 'ApplicantName'
    //   // },
    //   {
    //     title: "Name",
    //     name: "FirstName",
    //     data: null,
    //     render: (data) => {
    //       const maxLength = 15;
    //       if (data.ApplicantName.length > maxLength) {
    //         return `<span title="${
    //           data.ApplicantName
    //         }">${data.ApplicantName.slice(0, maxLength)}...</span>`;
    //       } else {
    //         return data.ApplicantName;
    //       }
    //     },
    //   },
    //   {
    //     title: "State Name",
    //     name: "StateName",
    //     data: "StateName",
    //   },
    //   {
    //     title: "Loan Type",
    //     name: "Type_of_Loan",
    //     data: "Type_of_Loan",
    //   },
    //   {
    //     title: "Status",
    //     name: "ApplicationStatus",
    //     data: "ApplicationStatus",
    //     render: (data) => {
    //       // if (data === 'Pending') {
    //       //   return `<span class="badge badge-danger p-1">${data}</span>`;
    //       // } else if (data === 'iPVR Sent') {
    //       //   return `<span class="badge badge-success p-1">${data}</span>`;
    //       // } else if (data) {
    //       //   return `<span class="badge badge-secondary p-1">${data}</span>`;
    //       // } else {
    //       //   return data;
    //       // }
    //       if (data === "iPVR Sent" || data === "Title Clear Sent") {
    //         return `<span class="badge badge-success p-1">${data}</span>`;
    //       } else if (data) {
    //         return `<span class="badge badge-secondary p-1">${data}</span>`;
    //       } else {
    //         return data;
    //       }
    //     },
    //   },
    //   {
    //     title: "Amount (₹)",
    //     name: "LoanAmount",
    //     data: "LoanAmount",
    //   },
    //   {
    //     title: "Action",
    //     data: null,
    //     render: (data, type, row) => {
    //       if (row.FileURL) {
    //         return `<a class="btn text-primary" title="View Application"
    //       viewID = "${row.AppID}"><i class="mdi mdi-eye font-18 text-secondary" viewID = "${row.AppID}" aria-hidden="false"></i></a>
    //       <a href="${row.FileURL}" class="m-1" target="_blank" title="Download iPVR">
    //       <i class="mdi mdi-file-download font-18 text-secondary" aria-hidden="false"></i>
    //       </a>`;
    //       } else {
    //         return `<a class="btn text-primary" title="View Application"
    //       viewID = "${row.AppID}"><i class="mdi mdi-eye font-18 text-secondary" viewID = "${row.AppID}" aria-hidden="false"></i></a>`;
    //       }
    //     },
    //   },
    // ]
  
  redirect() {
    // this.router.navigate(['/titlesearch/dashboard']);
  }
  ngAfterViewInit(): void {
    this.dtTrigger.next();
    this.renderer.listen("document", "click", (event) => {
      if (event.target.hasAttribute("viewID")) {
        this.router.navigate([
          "/loan/view-pvr-list",
        ],{state:{ApplicationStatus:event.target.getAttribute('ApplicationStatus'),AppID:event.target.getAttribute("viewID")}});
      }
      if(event.target.hasAttribute('DownloadIPVRAppID')){
        console.log('event.target.getAttribute("viewID")',event.target.getAttribute("DownloadIPVRAppID"))
        this.router.navigate([
          "loan/viewdocument/" + event.target.getAttribute("DownloadIPVRAppID"),
        ]);
    }
    });
  }
  callback() {
    this.selected = "";
    this.filterobj.FilterStartDate = "";
    this.filterobj.FilterEndDate = "";
    // this.filterCount()
    this.hidden = true;
  }
  ChangeLoan(e) {
    if (e === undefined) {
      this.filterobj.TypeOfLoan = null;
    } else {
      this.filterobj.TypeOfLoan = e;
    }
    this.filterCount();
    this.rerender();
  }
  onstatusChange(e) {
    if (e === undefined) {
      this.filterobj.ApplicationStatus = null;
    } else {
      this.filterobj.ApplicationStatus = e;
    }
    this.filterCount();
    this.rerender();
  }

  onstateChange(e) {
    if (e === undefined) {
      this.filterobj.stateID = null;
      // console.log("line==674",e)

    } else {
      this.filterobj.stateID = e;
      // console.log("line==697",e)
    }
    this.filterCount();
    this.rerender();
  }

  ChangePropertyType(e) {
    if (e === undefined) {
      this.filterobj.LoanPropertyTypeID = null;
    } else {
      this.filterobj.LoanPropertyTypeID = e;
    }
    this.filterCount();
    this.rerender();
  }
  onSearch() {
    this.rerender();
  }
  onchangeSearch() {
    // console.log(this.inputValue);
    if (this.inputValue != "") {
      return;
    }
    this.rerender();
  }
  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }
  resetForm(form: any){
    form.reset('');
    if(this.currentUser.UserType === "Proplegit"){
      let param = '?UserID=' + this.filterobj.UserID 
      this.isFilterLoading = true;
      this.service.getLoanDashboard_1(param).subscribe((res) => {
        this.countData = res.data[0];
        this.isFilterLoading = false
      })
    }
    else{
      let param = '?UserID=' + this.filterobj.UserID 
      this.isFilterLoading = true;
      this.service.getLoanDashboard(param).subscribe((res) => {
        this.countData = res.data[0]
        this.isFilterLoading = false
        // console.log("scklskd", res.data[0])
      })
    }
 
  }
  getRights(){
    this.isFilterLoading = true;
    this.service.getRights(this.currentUser.UserID).subscribe((res:any) => {
      this.rightsData = res.data;
      // console.log('rights data',this.rightsData);
      this.isFilterLoading = false
    })

  }
  sendPOS(){
    this.router.navigate(['POS/POS-dashboard'])
  }
}
