import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { NgbProgressbarModule, NgbAccordionModule, NgbDatepickerModule, NgbModalModule, NgbAlertModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { DataTablesModule } from 'angular-datatables';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { NgApexchartsModule } from 'ng-apexcharts';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxEditorModule } from 'ngx-editor';
import { AngularMaterialModule } from '../angular-material/angular-material.module';
import { PinchZoomModule } from '../bank-module/pinch-zoom/pinch-zoom.module';
import { LayoutsModule } from '../layouts/layouts.module';
import { UIModule } from '../shared/ui/ui.module';
import { CGApplicationsRoutingModule } from './cg-applications-routing.module';
import { ApplicationsListComponent } from './applications-list/applications-list.component';
import { ViewApplicationComponent } from './view-application/view-application.component';
import { AdminUploadLawyerComponent } from '../bank-module/admin-upload-lawyer/admin-upload-lawyer.component';
import { BankModuleModule } from '../bank-module/bank-module.module';
import { ViewDocumentsComponent } from './view-documents/view-documents.component';

@NgModule({
  declarations: [ApplicationsListComponent, ViewApplicationComponent, ViewDocumentsComponent],
  imports: [
    SharedModule,
    CommonModule,
    LayoutsModule,
    UIModule,
    FormsModule,
    NgSelectModule,
    NgbProgressbarModule,
    NgApexchartsModule,
    NgbAccordionModule, 
    NgbDatepickerModule,
    DataTablesModule,
    NgbModalModule,
    NgbAlertModule,
    NgbDropdownModule,
    ReactiveFormsModule,
    FormsModule,
    AutocompleteLibModule,
    FileUploadModule,
    PDFExportModule,
    PdfViewerModule,
    NgxEditorModule,
    AngularMaterialModule,
    PinchZoomModule,
    PdfViewerModule,
    BankModuleModule,
    CGApplicationsRoutingModule
  ],
  entryComponents: [AdminUploadLawyerComponent]
})
export class CGApplicationsModule { }
