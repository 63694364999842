import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AddSharesPerOWnerFormComponent } from './add-shares-per-owner-form/add-shares-per-owner-form.component';
import { ViewPOSApplicationComponent } from './view-posapplication/view-posapplication.component';
import { AddPosApplicationComponent } from './add-pos-application/add-pos-application.component';

const routes: Routes = [
  { path: '', redirectTo: 'POS-dashboard', pathMatch: 'full' },
  {
    path: 'POS-dashboard',
    component: DashboardComponent
  },
  {
    path:'Add-Shares-Per-Owner',
    component: AddSharesPerOWnerFormComponent
  },
  {
    path:'view-POS-Application',
    component: ViewPOSApplicationComponent
  },
  {
    path:'add-POS-Application',
    component: AddPosApplicationComponent
  },
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PosRoutingModule { }
