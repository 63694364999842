import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { GeneralService } from 'src/app/services/general.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-view-echeck-document',
  templateUrl: './view-echeck-document.component.html',
  styleUrls: ['./view-echeck-document.component.scss']
})
export class ViewEcheckDocumentComponent implements OnInit {
  breadCrumbItems: Array<{}>;
  PropertyData:any;
  PropertyID:string;
  applicationID:string;
  @ViewChild(DataTableDirective, { static: false })
  dtOptions: DataTables.Settings = {};
  isLoading: boolean;
  documents:any;
  constructor(private router: Router, private generalService:GeneralService) {
    this.dtOptions = {
      paging: false,
      searching: false,
    }

    let a = router.getCurrentNavigation().extras;
    if(a){
      if(a.state){
        console.log("s. at5ate",a.state);
        this.PropertyData = a.state.PropertyData;
        this.PropertyID = a.state.PropertyID;
        this.applicationID = a.state.AppID
      }
    }
    if(localStorage.getItem('AppID')){
      this.applicationID = localStorage.getItem('AppID');
    }
    if(localStorage.getItem('PropertyID')){
      this.PropertyID = localStorage.getItem('PropertyID');
    }
    if(localStorage.getItem('PropertyData'))
    {
      this.PropertyData =  JSON.parse(localStorage.getItem('PropertyData'));
      console.log('tihis.prooerp',this.PropertyData);
    }

   }

  ngOnInit() {

    this.breadCrumbItems = [{ label: 'View E-Check Application', path: 'e-check/view-echeck-application' },
    { label: 'Property Details', path: '/', active: true }];
    this.getPropertyDocumentList();
  }
  getPropertyDocumentList(){
    this.isLoading = true;;
    this.generalService.getPropertyDocumentList(this.applicationID,this.PropertyID).subscribe((res: any) => {
      if (res) {
        this.documents = res.data;
        // this.franchiseIdList = res.data;
        this.isLoading = false;
      }
    },
      (error) => {
        this.isLoading = false;
        Swal.fire({
          title: error.status,
          text: "Internal Server Error",
          type: "error",
          timer: 1500,
          showConfirmButton: false
        });
      })
  }
}
