import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { GeneralService } from 'src/app/services/general.service';
import { DialogboxComponent } from '../dialogbox/dialogbox.component';
import { MatDialog } from '@angular/material';

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Component({
  selector: 'app-title-clear-applicant-dashboard',
  templateUrl: './title-clear-applicant-dashboard.component.html',
  styleUrls: ['./title-clear-applicant-dashboard.component.scss']
})
export class TitleClearApplicantDashboardComponent implements OnInit {

  breadCrumbItems:any;
  AppID:string;
  applicationData: any;
  isLoading: boolean;
  firstName:string;
  lastName:string;
  currentUser:any;
  StateName: any;
  owners: any[] = [];
  ApplicationID: any;
  TotalLandSize: any;
  lawyerInfo: any;
  lawyerId:string;
  lawyerDetails: any;
  PropertyID: any;
  DocumentID: any;
  ApplicationStatus: any;
  Properties: any;
  propertyIds: any;
  doclist: any[] = [];
  dtOptions1: DataTables.Settings = {pageLength: 5};
  dtOptions: DataTables.Settings = {};
  dtOptions2: DataTables.Settings = {};
  DocumentList: any[] = [];
  arrayOfDocumentArrays: any;
  @ViewChild(DataTableDirective, { static: false })
  dtElement1: DataTableDirective;
  dtTrigger1 = new Subject<any>();
  PostMortageForm: FormGroup;
  dataFromDialog:any;
  constructor(private http: HttpClient,
    private dialog: MatDialog,
    private fb:FormBuilder,private router: Router,private service: GeneralService, private Route: ActivatedRoute) { 
    this.AppID = this.Route.snapshot.params.id;
    this.currentUser = this.service.getcurrentUser();
    this.dtOptions= {
      paging: true,
      searching: true,
      ordering: false,
      info: false,
      serverSide: false,
      pageLength: 10,
      pagingType: "simple_numbers"
    }
    this.dtOptions2 = {
      paging: false,
      searching: false,
      ordering: false,
      info: false,
      pageLength: 10
    }
    
  }

  ngOnInit() {
    this.breadCrumbItems = [{ label: 'Dashboard', path: '/' },
    { label: 'Applications', path: '/loan/title-clear-applications' }, { label: 'Title Search', path: '/', active: true }];
    this.isLoading = true;
    this.service.getMultiplePropertyInfo(this.AppID).subscribe((res)=>{
      this.applicationData = res;
      this.isLoading=false;
      this.owners = this.applicationData.data.PropertyOwnerShip;
      this.firstName = this.applicationData.data.ApplicatntDetails[0].FirstName;
      this.lastName =  this.applicationData.data.ApplicatntDetails[0].LastName;
      this.StateName = this.applicationData.data.Properties[0].StateName;
      this.ApplicationID =  this.applicationData.data.ApplicatntDetails[0].ApplicationID;
      this.TotalLandSize =this.applicationData.data.ApplicatntDetails[0].TotalLandSize
      this.PropertyID = this.applicationData.data.Properties[0].PropertyID
      this.DocumentID = this.applicationData.data.Properties[0].Documents[0].DocumentID
      this.AppID = this.applicationData.data.ApplicatntDetails[0].AppID
      // this.applicationData = this.applicationData.data.Properties.Documents[0].DocumentID
      console.log("application data",this.applicationData.data.ApplicatntDetails[0].LawyerID);
      this.lawyerId = this.applicationData.data.ApplicatntDetails[0].LawyerID;
      this.Properties = this.applicationData.data.Properties;
      this.ApplicationStatus = this.applicationData.data.ApplicatntDetails[0].ApplicationStatus
      // this.LawyerID = this.applicationData.data.ApplicatntDetails[0].LawyerID;
      // console.log('lawyerid',this.LawyerID, this.ApplicationStatus)
      // if (this.applicationData.data.ApplicatntDetails[0].LawyerID) {
      //   this.service.viewLawyer(this.applicationData.data.ApplicatntDetails[0].LawyerID).subscribe((lawyerInfo) => {
      //     this.lawyerInfo = lawyerInfo.data[0];
      //     console.log("lawyer Info",this.lawyerInfo);
      //     // this.IsLawyerLoaded = true;
      //   });
      // }

      this.getLawyerDetails(this.lawyerId);
      this.PostMortageForm = this.fb.group({
        AppID: [this.AppID],
        CERSAI: [true],
        GovernmentPortal: [true],
        None: [false],
      });
    })
    
    // this.isLoading=false;
    this.dtOptions1 = {
      pagingType: "simple_numbers",
      pageLength: 10,
      ordering: false,
      ajax: (dataTablesParameters: any, callback) => {
        this.http
          .get<DataTablesResponse>(
            this.service.GetBaseUrl() + `tcc/loan/application/Documents/AppID/${this.Route.snapshot.params.id}`,
            {}
          ).subscribe((resp: any) => {
            const list = resp.data;
            this.DocumentList = [];
            this.arrayOfDocumentArrays = resp.data;
            this.propertyIds = this.Properties.map((d) => { return d.PropertyID });
            this.propertyIds.forEach((id) => {
              list[id].forEach((doc) => {
                this.doclist.push(doc);
              })
            });
            if(this.DocumentList.length > 0){
              this.DocumentList.forEach((ele)=>{
                this.doclist.push(ele);
              })
            }
          
            console.log('document list',this.doclist);
            // console.log('arrayOfEntries',this.arrayOfDocumentArrays["1188140"]);
            // for(let i = 0; i< this.arrayOfDocumentArrays["1188140"].length;i++){
            //   console.log('i',this.arrayOfDocumentArrays["1188140"][i]);
            // }
            // this.isallDocumentsReviewed = this.Compaire();
            // console.log('isallodoucreveir',this.isallDocumentsReviewed);
            // if (this.isallDocumentsReviewed == true && this.applicationData &&this.applicationData.data.ApplicatntDetails[0].ApplicationStatus == 'TC Document In Progress') {
            //   this.updateAppStatus('TC Document Completed');
            // }
            // console.log(this.isallDocumentsReviewed);
            console.log('list',this.DocumentList);
            console.log('array of documentd',this.arrayOfDocumentArrays);
            callback({
              // recordsTotal: this.arrayOfDocumentArrays['null'] ?   this.arrayOfDocumentArrays['null'].length  : 0,
              // recordsFiltered: this.arrayOfDocumentArrays['null'] ? this.arrayOfDocumentArrays['null'].length  : 0 ,
              // data:  this.arrayOfDocumentArrays['null'].length ? this.arrayOfDocumentArrays['null'] : []
              recordsTotal: this.DocumentList.length  ? this.DocumentList.length : 0,
              recordsFiltered:this.DocumentList.length ? this.DocumentList.length  : 0 ,
              data:  this.arrayOfDocumentArrays['null'] ? this.arrayOfDocumentArrays['null'] :[]
            });
          });
      },
      responsive: true,
      columns: [{
        title: 'Sr No.', data: 'row', render: (data, type, row, meta) => {
          return meta.row + 1;
        }
      }, {
        title: 'Document',
        render: (data, type, row) => {
          return row.DocumentName.split(`.PDF`).join("");

        }
      }, {
        title: 'Status',
        render: (data, type, row) => {
          if (row.Status === 'Pending') {
            return `<span class="badge badge-danger p-1">${row.Status}</span>`;
          } else if (row.Status === 'Received') {
            return `<span class="badge badge-primary p-1">${row.Status}</span>`;
          } else if (row.Status === 'requested' || row.Status === 'Requested') {
            return `<span class="badge bg-soft-warning text-warning p-1">${row.Status}</span>`;
          } else {
            return `<span class="badge badge-success p-1">${row.Status}</span>`;
          }
        }
      }, {
        title: 'Action',
        data: null
      },
      {
        title: 'Comment',
        render: (data, type, row) => {
          let comment = '';
          if (row.DocumentComment != undefined) {
            row.DocumentComment.forEach(element => {
              comment += element.Comment + '<br>';
            });
            return comment;
          }
          else {
            return '';
          }
        },
      }
      ],
      columnDefs: [{ targets: 3, orderable: false }],
      rowCallback(row, data: any) {
        let btn = '';
        if (data.FileURL === null && this.applicationData && this.applicationData.data.ApplicatntDetails[0].ApplicationStatus !== 'Title Clear Sent') {
          btn += '<a href="javascript:void(0)" class="uploadDocument m-1" title="Upload Document" DocumentSubType="' + data.DocumentSubType + '" DocumentType="' + data.DocumentType + '" DocumentName="' + data.DocumentName + '">';
          btn += '<i class="mdi mdi-file-upload-outline font-18 text-secondary" aria-hidden="false" DocumentSubType="' + data.DocumentSubType + '" DocumentType="' + data.DocumentType + '" DocumentName="' + data.DocumentName + '"></i>';
          btn += '</a>';
          btn += '<a href="javascript:void(0)" class="requestDocument m-1" title="Request this Document" DocumentSubType="' + data.DocumentSubType + '" DocumentType="' + data.DocumentType + '" DocumentName="' + data.DocumentName + '">';
          btn += '<i class="mdi mdi mdi-file-question font-18 text-secondary" aria-hidden="false" DocumentSubType="' + data.DocumentSubType + '" DocumentType="' + data.DocumentType + '" DocumentName="' + data.DocumentName + '"></i>';
          btn += '</a>';
        } else if (data.FileURL !== null) {
          if (data.Status !== 'Reviewed' && this.applicationData &&  this.applicationData.data.ApplicatntDetails[0].ApplicationStatus !== 'Title Clear Sent') {
            btn += '<a href="javascript:void(0)" class="uploadDocument m-1" title="Upload Document" DocumentSubType="' + data.DocumentSubType + '" DocumentType="' + data.DocumentType + '" DocumentName="' + data.DocumentName + '">';
            btn += '<i class="mdi mdi-file-upload-outline font-18 text-secondary" aria-hidden="false" DocumentSubType="' + data.DocumentSubType + '" DocumentType="' + data.DocumentType + '" DocumentName="' + data.DocumentName + '"></i>';
            btn += '</a>';
            btn += '<a href="javascript:void(0)" class="requestDocument m-1" title="Request this Document" DocumentName="' + data.DocumentName + '">';
            btn += '<i class="mdi mdi mdi-file-question font-18 text-secondary" aria-hidden="false" DocumentName="' + data.DocumentName + '"></i>';
            btn += '</a>';
          }
          btn += '<a href="javascript:void(0)" class="viewDocument m-1" title="View Document" receipt-id="' + data.DocumentID + '">';
          btn += '<i class="mdi mdi-eye font-18 text-secondary" aria-hidden="false" receipt-id="' + data.DocumentID + '"></i>';
          btn += '</a>';
          if (data.dropdownitemtype === 'multiple') {
            btn += '<a href="javascript:void(0)" class="uploadDocument m-1" title="Upload Another Document" receipt-id="' + data.DocumentID + '">';
            btn += '<i class="mdi mdi-plus font-18 text-secondary" aria-hidden="false" receipt-id="' + data.DocumentID + '"></i>';
            btn += '</a>';
          }
        }
        if (data.isMandatory == true) {
          btn += '<a title="Mark as Non-Mandatory" class="update m-1" >'
          btn += '<i class="font-18 mdi mdi-cancel text-secondary"  DocumentID="' + data.DocumentID + '" DocumentSubType="' + data.DocumentSubType + '" DocumentType="' + data.DocumentType + '" isMandatory="0"></i>'
          btn += '</a>';
        } else {
          btn += '<a title="Mark as Mandatory" class="update m-1" >'
          btn += '<i class="font-18 mdi mdi-recycle  text-secondary"  DocumentID="' + data.DocumentID + '"DocumentSubType="' + data.DocumentSubType + '" DocumentType="' + data.DocumentType + '" isMandatory="1"></i>'
          btn += '</a>';
        }
        $('td:eq(3)', row).html(btn);
      }, drawCallback: () => {
        $('.uploadDocument').on('click', (e) => {
          this.onUploadDocument($(e.target).attr('DocumentType'), $(e.target).attr('DocumentSubType'), $(e.target).attr('DocumentName'),'');
        });
        $('.viewDocument').on('click', (e) => {
          this.onViewDocument($(e.target).attr('receipt-id'),'');
        });
        // $('.requestDocument').on('click', (e) => {
        //   this.openRequestDocumentDialog($(e.target).attr('DocumentType'), $(e.target).attr('DocumentSubType',), $(e.target).attr('DocumentName'),'');
        // });
        // $('.update').on('click', (e) => {
        //   this.UpdateDocument($(e.target).attr('DocumentType'), $(e.target).attr('DocumentSubType'), $(e.target).attr('DocumentID'), $(e.target).attr('isMandatory'));
        // });
      }
    };
  }


  onUploadDocument(doctype, docsubtype, docname,propertyIndex) {
    this.router.navigate(['loan/uploadlawyerdocument/' +this.applicationData.data.ApplicatntDetails[0].AppID], { queryParams: { DocumentType: doctype, DocumentSubType: docsubtype, DocumentName: docname, PropertyIndex:propertyIndex } });
  }
  onViewDocument(id,propertyIndex,data?) {
    this.router.navigate(['loan/viewdocument/' + this.applicationData.data.Properties[propertyIndex].PropertyID + '/' + id + '/' + this.applicationData.data.ApplicatntDetails[0].AppID],{queryParams:{isForMulProperty: true}});
  }
  uploadOtherDocument() {
    this.router.navigate(['loan/uploadlawyerdocument/' + this.applicationData.data.ApplicatntDetails[0].AppID], { queryParams: { OtherDocument: true } });
  }

  getLawyerDetails(LawyerID) {
    this.isLoading=true;
    this.service.GetUser(LawyerID).subscribe((res) => {
    this.isLoading=false;
      this.lawyerDetails = res.data[0];
      console.log(this.lawyerDetails);
    },(error)=>{
    this.isLoading=false;
    });
  }

  seeFullReport(){
    console.log('see full report is called')
    this.router.navigate(['/loan/viewdocument/' + this.AppID ]);
  }

  seeTCReport(){
    console.log('see full report is called')
    this.router.navigate(['/loan/viewTitleReport/' + this.AppID ]);
  }
  openAssignLawyerDialog(){
    this.router.navigate(['loan/documents/' + this.AppID],{state:{ApplicationStatus : this.applicationData.data.ApplicatntDetails[0].ApplicationStatus}});
  }
  ngAfterViewInit() {
    this.dtTrigger1.next();
  }

  rerender(): void {
    this.dtElement1.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger1.next();
    });
  }
  generateCommentsHtml(comments: any[]): string {
    // console.log('comments',comments);
    let html = '';
    if (comments) {
      comments.forEach((element, index) => {
        html += element.Comment;
        if (index !== comments.length - 1) {
          html += ', ';
        }
      });
    }
    return html;
  }

  // openRequestDocumentDialog(doctype, docsubtype, docname,PropertyID): void {
  //   console.log('request another document is cllaedd')
  //   const dialogRef = this.dialog.open(RequestDocumentDialogComponent, {
  //     disableClose: true,
  //     width: '480px',
  //     data: {
  //       AppID: this.Route.snapshot.params.AppID,
  //       DocumentSubType: docsubtype,
  //       DocumentType: doctype,
  //       DocumentName: docname,
  //       PropertyID: PropertyID,
  //       DropDownValue:
  //         [
  //           { ID: this.applicationData.data.ApplicatntDetails[0].ApplicantID, Name: 'Applicant' },
  //           { ID: this.applicationData.data.ApplicatntDetails[0].CreatedBy, Name: 'Bank Manager' },
  //           { ID: '0', Name: 'Proplegit Admin' },
  //         ]
  //     }
  //   });
  //   dialogRef.afterClosed().subscribe(result => {
  //     if(result){
  //       this.ngOnInit();
  //       this.rerender();
  //     }
  //   });
  // }

  openViewDocumentDialog(){
    const dialogRef = this.dialog.open(DialogboxComponent, {
    disableClose: true,
    width: '600px',
    height:'-1vh',
    data: {'applicationData':this.applicationData}
  });

  dialogRef.afterClosed().subscribe((data) => {
    this.dataFromDialog = data.PostMortageForm;
    if (data.clicked === 'submit') {
    let obj = {
    AppID: Number(this.PostMortageForm.controls.AppID.value),
    CERSAI: this.PostMortageForm.controls.CERSAI.value ? 1 :0,
    GovernmentPortal: this.PostMortageForm.controls.GovernmentPortal.value ? 1 :0,
    None: this.PostMortageForm.controls.None.value ? 1 :0,
  };
  this.service.userMortgage(obj).subscribe((res: any) => {
    // this.applicationData = res;
    console.log('respomse of user mortgage',res);
    this.service.changeStatus(this.AppID, 'Post Mortgage').subscribe(() => {
      this.router.navigate(["/loan/titleclear-dashbboard/"+this.AppID]);
      this.PostMortageForm.reset();
    });
  });

    }

  });

}

}
