import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { CookieService } from 'src/app/core/services/cookie.service';
import { GeneralService } from 'src/app/services/general.service';
import Swal from 'sweetalert2';
class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}
@Component({
  selector: 'app-assign-lawyer',
  templateUrl: './assign-lawyer.component.html',
  styleUrls: ['./assign-lawyer.component.scss']
})
export class AssignLawyerComponent implements OnInit {
  LawyerForm: FormGroup;
  lawyerType: any;
  dtOptions: DataTables.Settings = {};
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtTrigger = new Subject<any>();
  user: any;
  breadCrumbItems: ({ label: string; path: string; active?: undefined; } | { label: string; path: string; active: boolean; })[];
  AppID: any;
  isLoading: boolean;
  LawyerID: any;
  isAssignLawyer: boolean = false;
  constructor(
    // public dialogRef: MatDialogRef<AssignLawyerComponent>,
    // @Inject(MAT_DIALOG_DATA) public data: any,
    private service: GeneralService,
    private route: ActivatedRoute,
    private Fb: FormBuilder,
    private router: Router,
    private http: HttpClient,
    private cookie: CookieService
  ) {
    this.AppID = this.route.snapshot.queryParamMap.get('AppID');
  }

  ngOnInit() {
    if(this.AppID != undefined) {
      this.isAssignLawyer = true;
    }
    if(this.isAssignLawyer == true) {
      this.breadCrumbItems = [
        { label: 'Dashboard', path: '/' },
        { label: 'Applications', path: '/loan/title-clear-applications' }, 
        // { label: 'Title Search', path: '/loan/title-search/' + this.AppID},
        { label: 'Select Documents', path: '/loan/documents/' + this.AppID},
        { label: 'Assign Lawyer', path: '/loan/lawyer', active: true }];
    } else {
      this.breadCrumbItems = [
        { label: 'Dashboard', path: '/' },
        { label: 'Lawyers', path: '/loan/lawyer', active: true }];
    }
    
    this.user = JSON.parse(this.cookie.getCookie('currentUser'));
    this.LawyerForm = this.Fb.group({
      type: new FormControl(null, Validators.required),

    });
    if(this.isAssignLawyer == true) {
      this.dtOptions = {
        pagingType: "simple_numbers",
        pageLength: 10,
        processing: true,
        serverSide: true,
        ordering: false,
        ajax: (dataTablesParameters: any, callback) => {
          this.http
            .get<DataTablesResponse>(
              `${this.service.getLawyers()}${this.user.CompanyID}`+
              `?pageno=${((dataTablesParameters.start+dataTablesParameters.length)/ dataTablesParameters.length)}`+
              `${dataTablesParameters.length.value!=''?'&recordsno='+dataTablesParameters.length:''}`+
              `${dataTablesParameters.search.value!=''?'&search='+dataTablesParameters.search.value:''}`,
               {}
            ).subscribe((resp: any) => {
              callback({
                recordsTotal: resp.LawyerCount,
                recordsFiltered: `${resp.filtercount ? resp.filtercount : resp.LawyerCount}`,
                data: resp.data
              });
            });
        },
        responsive: true,
        columns: [
          {
            title: 'Sr.No.',
            data: null,
            render: (data, type, row, meta) => {
              return meta.row + 1;
            },
          },
          {
            title: 'Name',
            data: null,
            render: (data) => {
              return data.FirstName + ' ' + data.LastName;
            },
          },
          {
            title: 'Total Assignments',
            // data: 'EmailID',
            data: null,
            render: (data) => {
              return data.TotalCount;
            }
          },
          {
            title: 'Assignments in Progress',
            // data: 'EmailID',
            data: null,
            render: (data) => {
              return Number(Number(data.TotalCount) - Number(data.InProgressCount));
            }
          },
          {
            title: 'Assignments Completed',
            // data: 'EmailID',
            data: null,
            render: (data) => {
              return Number(data.InProgressCount);
            }
          },
          {
            title: 'Mobile Number',
            // data: 'MobileNo',
            data: null,
            render: (data) => {
              return data.MobileNumber;
            },
          },
          {
            title: 'Email',
            // data: 'EmailID',
            data: null,
            render: (data) => {
              return data.EmailAddress;
            }
          },
          {
            title: 'Action',
            data: null,
            render(data) {
              if (data.IsActive === true) {
                return `<span class="badge badge-primary p-1 assign" UserID="${data.UserID}"><button type="button" class="btn btn-primary" UserID="${data.UserID}" style="padding: 0px !important; margin: 0px !important">Assign Lawyer</button></span>`;
              } else {
                return ``;
              }
              
            },
          },
        ],
        drawCallback: () => {
          $('.assign').on('click', (e) => {
            console.log(e.target.getAttribute('UserID'));
            this.save(e.target.getAttribute('UserID'));
          });
        },
      };
    } else {
      this.dtOptions = {
        pagingType: "simple_numbers",
        pageLength: 10,
        // processing: true,
        serverSide: true,
        ordering: false,
        ajax: (dataTablesParameters: any, callback) => {
          this.http
            .get<DataTablesResponse>(
              `${this.service.getLawyers()}${this.user.CompanyID}`+
              `${dataTablesParameters.search.value!=''?'&search='+dataTablesParameters.search.value:''}`,
               {}
            ).subscribe((resp: any) => {
              callback({
                recordsTotal: resp.LawyerCount,
                recordsFiltered: `${resp.filtercount ? resp.filtercount : resp.LawyerCount}`,
                data: resp.data
              });
            });
        },
        responsive: true,
        columns: [
          {
            title: 'Sr.No.',
            data: null,
            render: (data, type, row, meta) => {
              return meta.row + 1;
            },
          },
          {
            title: 'Name',
            data: null,
            render: (data) => {
              return data.FirstName + ' ' + data.LastName;
            },
          },
          {
            title: 'Total Assignments',
            // data: 'EmailID',
            data: null,
            render: (data) => {
              return data.TotalCount;
            }
          },
          {
            title: 'Assignments in Progress',
            // data: 'EmailID',
            data: null,
            render: (data) => {
              return Number(Number(data.TotalCount) - Number(data.InProgressCount));
            }
          },
          {
            title: 'Assignments Completed',
            // data: 'EmailID',
            data: null,
            render: (data) => {
              return Number(data.InProgressCount);
            }
          },
          {
            title: 'Mobile Number',
            // data: 'MobileNo',
            data: null,
            render: (data) => {
              return data.MobileNumber;
            },
          },
          {
            title: 'Email',
            // data: 'EmailID',
            data: null,
            render: (data) => {
              return data.EmailAddress;
            }
          }
        ],
        drawCallback: () => {
        },
      };
    }
    
  }
  get f() {
    return this.LawyerForm.controls;
  }
  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }
  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  save(LawyerID?) {
    let data;
    if(!LawyerID) {
      data = {
        "AppID":Number(this.AppID),
        "LawyerID": null,
        "DocumentList":null,
        "ToProplegit":true,
        "FromProlegit":false
      }
    } else {
      data = {
        "AppID":Number(this.AppID),
        "LawyerID":Number(LawyerID),
        "DocumentList":null,
        "ToProplegit":false,
        "FromProlegit":false
      }
    }
    this.isLoading = true;
    this.service.startTitleClear(data)
        .subscribe((res: any) => {
          this.isLoading = false;
          if (res.status === 200) {
            Swal.fire({
              title: 'Added to Title Clear',
              text: 'Lawyer is Assigned and Application Moved to Title Clear',
              type: 'success',
              showConfirmButton: false,
              timer:3000
            }).then(() => {
              // this.router.navigate(['/loan/title-search/' + this.AppID]);
              this.router.navigate(['/loan/titleclear-dashbboard/' + this.AppID]);
            });
          } else {
            Swal.fire({
              title: res.error_code,
              text: res.error,
              type: 'error',
              showConfirmButton: false,
              timer:3000
            });
          }
        },(error: any) => {   
          console.log(error.error_code); 
          this.isLoading = false;
          Swal.fire({
            title: error.error_code,
            text: error.error,
            type: "error",
            showConfirmButton: false,
            timer:3000
          });
          
        });
  }

  addLawyer() {
      this.router.navigate(['/loan/add-lawyer']);
  }
}
