import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-valuers-assignments',
  templateUrl: './valuers-assignments.component.html',
  styleUrls: ['./valuers-assignments.component.scss']
})
export class ValuersAssignmentsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
