import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { GeneralService } from 'src/app/services/general.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-mod-application',
  templateUrl: './add-mod-application.component.html',
  styleUrls: ['./add-mod-application.component.scss']
})
export class AddModApplicationComponent implements OnInit {

  breadCrumbItems: any;
  isLoading: boolean;
  StateList: any[] = [];
  currentUser: any;
  file: any;
  form: FormGroup;
  submitted: boolean;
  errorArray: any[] = [];
  constructor(private service: GeneralService, private Fb: FormBuilder, private router: Router) { }

  ngOnInit() {
    this.currentUser = this.service.getcurrentUser();
    this.form = this.Fb.group({
      // ApplicantFirstName: new FormControl('', [Validators.required,Validators.pattern('^[a-zA-Z,/-/ ]*$')]),
      // ApplicantLastName: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z,/-/ ]*$')]),
      StateID: new FormControl(null, [Validators.required, Validators.pattern('^[0-9a-zA-Z-]+$')]),
      File: new FormControl(null, Validators.required),
    })

    this.breadCrumbItems = [
      { label: 'Dashboard', path: '/loan/dashboard' },
      { label: 'Applications', path: '/applications ', active: true },];
    this.fetchstatelist();
  }
  fetchstatelist() {
    this.isLoading = true;
    this.service.GetStatePermissionWise(this.currentUser.UserID)
      .pipe(first())
      .subscribe(
        data => {
          if (data.error) {
            this.isLoading = false;
            Swal.fire({
              title: data.error_code,
              text: data.message,
              type: 'error',
              showConfirmButton: false,
              timer: 3000
            });
            return;
          } else {
            this.isLoading = false;
            this.StateList = data.data;
            // console.log("statelist", this.StateList);
          }
        });
    // this.f.DistrictID.disable();
  }
  get f() { return this.form.controls; }

  save() {
    this.submitted = true;
    // if(this.form.controls.ApplicantFirstName.value)
    // {
    //   this.form.controls.ApplicantFirstName.setValue(this.form.controls.ApplicantFirstName.value.trim())
    // }
    // if(this.form.controls.ApplicantLastName.value)
    // {
    //   this.form.controls.ApplicantLastName.setValue(this.form.controls.ApplicantLastName.value.trim())
    // }
    console.log('form value', this.form.controls);
    if (this.form.valid) {
      if (this.file && this.file.length === 1) {
        const filetype = this.file[0].type;
        let formData: FormData = new FormData();
        formData.append('files', this.file[0], this.file[0].name);
        formData.append('CreatedBy', this.currentUser.UserID);
        formData.append('StateID', this.form.controls.StateID.value);
        // formData.append('ApplicantFirstName', this.form.controls.ApplicantFirstName.value);
        // formData.append('ApplicantLastName', this.form.controls.ApplicantLastName.value);
        if (filetype.toLowerCase() !== 'application/vnd.ms-excel' && filetype.toLowerCase() !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
          Swal.fire({
            title: `Error`,
            text: `${this.file[0].name} File Is Not Supported`,
            type: 'error',
            showConfirmButton: false,
            timer: 3000
          }).then(() => { this.file = [] })
        }
        else {
          this.isLoading = true;
          this.service.addModApplication(formData).subscribe((res: any) => {
            if (res.error) {
              this.submitted = false;
              this.isLoading = false;
              Swal.fire({
                title: res.error_code,
                text: res.message,
                type: 'error',
                showConfirmButton: false,
                timer: 3000
              });
              return;
            }
            else if(res.message === 'Excel column header Mismatch ... '){
              this.submitted = false;
              this.isLoading = false;
              Swal.fire({
                title: 'Failed',
                text: res.message,
                type: 'error',
                showConfirmButton: false,
                timer:3000
              });
              return;
            }
            else {
              this.submitted = false;
              this.isLoading = false;
              this.errorArray = res.data;
              Swal.fire({
                title: 'Success',
                text: 'Application Added Successfully',
                type: 'success',
                showConfirmButton: false,
                timer: 3000
              }).then(() => {
                // this.router.navigate(['/loan/applications']);
              });
            }
            // this.isLoading = false;
          });
        }
      }
 
    }
    else {
      Swal.fire({
        title: `Invalid Form`,
        text: `Please Select State and Excel File`,
        type: 'error',
        showConfirmButton: false,
        timer: 3000
      })
      return;
    }

    console.log('form valiue', this.form.controls);
  }

  reset() {
    this.errorArray = [];
    this.file = [];
    // this.StateID = null;
    this.form.reset();
  }

  onchange(event: any) {
    if (this.file.length) {
      const unsupportedFiles = [];
      const supportedFiles = [];
      if (this.file && this.file.length === 1) {
        const filetype = this.file[0].type;
        if (filetype.toLowerCase() !== 'application/vnd.ms-excel' && filetype.toLowerCase() !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
          Swal.fire({
            title: `Error`,
            text: `${this.file[0].name} File Is Not Supported`,
            type: 'error',
            showConfirmButton: false,
            timer: 3000
          }).then(() => { this.file = [] })
        }

      }
      else if (this.file && this.file.length > 1) {
        Swal.fire({
          title: `Invalid Form`,
          text: `Please Select Only One Excel File`,
          type: 'error',
          showConfirmButton: false,
          timer: 3000
        }).then(() => { this.file = [] })

        return;
      }
      else {
        //
      }
    }
  }
}
