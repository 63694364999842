import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { GeneralService } from 'src/app/services/general.service';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FN_SubInput } from './fn_subInput.validator';

@Component({
  selector: 'app-add-pos-application',
  templateUrl: './add-pos-application.component.html',
  styleUrls: ['./add-pos-application.component.scss']
})
export class AddPosApplicationComponent implements OnInit {

  breadCrumbItems:any;
  loan: FormGroup;
  currentUser:any;
  isLoading: boolean;
  StateList:any[]=[];
  baseDocType: any;
  fieldsData: any[];
  DataList: any = {};
  URL: string;
  dynamicFields = [];
  stateID: any;
  StateBaseDocType: any;
  propertyDetails: any;
  submitted:boolean;
  areas: any[];
  DistrictList: any[];
  myValue_3: any;
  constructor(private Fb: FormBuilder, private service: GeneralService, private router: Router) {
    this.breadCrumbItems = [{ label: 'Dashboard', path: 'POS/POS-dashboard' },
    { label: 'Add New POS Application', path: '/POS/addapplication', active: true }];
   }

  ngOnInit() {
    this.submitted = false;
    this.currentUser = this.service.getcurrentUser();
    this.fetchstatelist();
    this.loan = this.Fb.group({
      Owners: this.Fb.array([
        this.initOwner()
      ]),
      BaseDocumentType: new FormControl(null),
      PropertyType: new FormControl(null, Validators.required),
      ApplicationID: new FormControl('', Validators.required),
      // ApplicantFirstName: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z]*$')]),
      // ApplicantLastName: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z]*$')]),
      CreatedBy: new FormControl(this.currentUser.UserID),
      StateID: new FormControl(21, Validators.required),
    })
    this.ChangeState(21);
  }
  ChangeState(e) {

    if (e != undefined) {
      this.getStateBaseDocTypeByStateID(e);
      this.stateID = e;
    }
    this.loan.controls.BaseDocumentType.setValue(null);
    this.dynamicFields.forEach(element => {
      this.loan.removeControl(element);
    });
    this.fieldsData = [];
    this.StateBaseDocType = []
  }


  getStateBaseDocTypeByStateID(id) {
    id=21;
    this.isLoading = true;
    this.service.getStateBaseDocTypeByStateID(id).subscribe((res: any) => {
      this.isLoading = false;
      this.StateBaseDocType = res.data;
    });
  }
  
  ChangeBaseDocType(data) {
    this.baseDocType = data;
    this.areas = [];
    if(this.StateBaseDocType != undefined && data != undefined) {
      this.loan.controls.PropertyType.setValue(this.StateBaseDocType.find(x => x.ID === data).Name)
    }
    else {
    }
    if ( this.stateID !== undefined && this.baseDocType !== undefined) {
      let param = new URLSearchParams();
      param.set('StateID', this.stateID);
      param.set('Service_subtype_id', '1');
      param.set('StateBaseDocTypeID', this.baseDocType);
      param.set('DisplayLevel', '1');
      this.isLoading = true;
      this.fieldsData.forEach(element => {
        this.loan.removeControl(`${element.DBColumnName}`);
      });
      this.service.getDynamicFields(param.toString()).subscribe((res: any) => {
        this.isLoading = false;
        this.fieldsData = res.data;
        this.fieldsData.forEach(element => {
          this.dynamicFields.push(element.DBColumnName);
          if (element.FieldType === 'Text' && element.ValidationFunctionName != '') {
            this.loan.addControl(`${element.DBColumnName}`, this.Fb.control(null, this[element.ValidationFunctionName]()));
          } else if ((element.FieldType === 'Dropdown' && element.APIName != '' && element.IsDependantField != true)) {
            this.loan.addControl(`${element.DBColumnName}`, this.Fb.control(null));
            this.URL = element.APIName + '/' + this.stateID;
            this.isLoading = true;
            this.service.dynamicData(this.URL).pipe(first()).subscribe((data) => {
              this.URL = '';
              this.isLoading = false;
              if (data.error) {
                Swal.fire({
                  title: data.error_code,
                  text: data.message,
                  type: 'error',
                  showConfirmButton: false,
                  timer: 3000
                });
                return;
              } else {
                this.DataList[element.DBColumnName] = data.data;
              }
            });
          } else {
            this.loan.addControl(`${element.DBColumnName}`, this.Fb.control(null));
          }
          console.log(this.loan);
        });
        // this.fieldsData.forEach(element => {
        //   if (this.propertyDetails != undefined && element.FieldType === 'Dropdown' && element.IsDependantField == true) {
        //     let index = this.fieldsData.findIndex(x => x.DependentFieldName === element.FieldName);
        //     if(index != -1 && index != null) {
        //       this.onChangesDynamicField(index, this.propertyDetails[this.fieldsData[index].DBColumnName]);
        //     }
        //   }
        //   this.loan.controls[element.DBColumnName].setValue(this.propertyDetails[element.DBColumnName]);
        // });
      });
    } else {
      this.DistrictList = [];
      this.fieldsData = [];
      this.dynamicFields.forEach(element => {
        this.loan.removeControl(element);
      });
    }
  }

  removeWhitespace_3(value: any) {
    this.myValue_3 = this.myValue_3.replace(/\s/g, '');
  }
  
  onChangesDynamicField(index, id) {
    this.clearFields(index);
    if (id !== undefined) {
      this.fieldsData.forEach(element => {
        if (this.fieldsData[index].DependentFieldName === element.FieldName) {
          this.URL = element.APIName + '/' + id;
          if (this.URL != '') {
            this.isLoading = true;
            this.service.dynamicData(this.URL).pipe(first()).subscribe((data) => {
              this.URL = '';
              this.isLoading = false;
              if (data.error) {
                Swal.fire({
                  title: data.error_code,
                  text: data.message,
                  type: 'error',
                  showConfirmButton: false,
                  timer: 3000
                });
                return;
              } else {
                this.DataList[element.DBColumnName] = data.data;
              }
            });
          }
        }
      });
    }

  }
  clearFields(i) {
    for (let index = 0; index < this.fieldsData.length; index++) {
      if (this.fieldsData[i].DependentFieldName != '' && this.fieldsData[i].DependentFieldName === this.fieldsData[index].FieldName) {
        this.loan.controls[`${this.fieldsData[index].DBColumnName}`].setValue(null);
        this.clearFields(index);
      }
    }
  }


  isOwnerNameDuplicate(index: number, owners: FormArray, ownerName: string): boolean {
    for (let i = 0; i < index; i++) {
      if (owners.at(i).value.OwnerName.trim() === ownerName.trim()) {
        return true;
      }
    }
    return false;
  }
  removeOwner(i: number) {
    const control = this.f.Owners as FormArray;
    control.removeAt(i);
  }
  addOwner() {
    const control = this.f.Owners as FormArray;
    control.push(this.initOwner());
  }
  isOwnerNameEmpty(index: number): boolean {
    const ownerNameControl = this.loan.controls.Owners['controls'][index].get('OwnerName');
    return !ownerNameControl.value || ownerNameControl.value.trim() === '';
  }

  isOwnerNameTouched(index: number): boolean {
    const ownerNameControl = this.loan.controls.Owners['controls'][index].get('OwnerName');
    return ownerNameControl.touched;
  }
  initOwner(i?) {
    if (i && i !== undefined) {
      return this.Fb.group({
        OwnerName: new FormControl(i.OwnerName ? i.OwnerName.trim():i.OwnerName, Validators.required),
      });
    } else {
      return this.Fb.group({
        OwnerName: new FormControl('', Validators.required)
      });
    }
    
  }
  FN_SubInput() {
    return FN_SubInput();
  }
  get f() { return this.loan.controls; }

  fetchstatelist() {
    this.isLoading = true;
    this.service.GetStatePermissionWise(this.currentUser.UserID)
      .pipe(first())
      .subscribe(
        data => {
          if (data.error) {
            this.isLoading = false;
            Swal.fire({
              title: data.error_code,
              text: data.message,
              type: 'error',
              showConfirmButton: false,
              timer: 3000
            });
            return;
          } else {
            this.isLoading = false;
            this.StateList = data.data;
            // console.log("statelist", this.StateList);
          }
        });
  }

  save(){
    let owners = []
    this.checkForValidation();
    this.checkOwnersValidation();
    this.loan.controls.Owners['controls'].forEach((i, index) => {
      owners.push(this.loan.controls.Owners['controls'][index].getRawValue().OwnerName);
    })
    let data = this.loan.value;
    data['Owners'] = owners;
    this.submitted = true;
    if(this.loan.valid){
      console.log('fields data',this.fieldsData);
      if(this.fieldsData.length === 0){
        Swal.fire({
          title: '',
          text: 'This Functionality is not developed for this state',
          type: 'info',
          showConfirmButton: false,
          timer: 3000
        });
      }
      else{
        this.isLoading=true;
        this.service.POSApplication(data).subscribe((res:any)=>{
          this.isLoading=false;
            Swal.fire({
              title: `POS Added`,
              text: res.message,
              type: 'success',
              showConfirmButton: false,
              timer:1500
            }     
            ).then(()=>{
              this.router.navigate(['/POS/POS-dashboard'])
            })
            this.isLoading = false;
        })
      }
    } else {
        Swal.fire({
          title: 'Invalid',
          text: 'Invalid Form Data',
          type: 'error',
          showConfirmButton: false,
          timer: 3000
        });
      }
 
  }
  

  checkOwnersValidation(){
    console.log('owners length',this.loan.controls['Owners'])
    if(this.loan.controls['Owners'].value.length){
      for(let i=0;i<this.loan.controls['Owners'].value.length;i++){
        for(var control in this.loan.controls['Owners']['controls'][i].value){
          if(this.loan.controls['Owners']['controls'][i].value[control]){
            let a = (typeof (this.loan.controls['Owners']['controls'][i].value[control]) === 'string') ? this.loan.controls['Owners']['controls'][i].value[control].trim() : this.loan.controls['Owners']['controls'][i][control].value;
            this.loan.controls['Owners']['controls'][i].controls[control].setValue(a);
          }
        }
      }
    }
  }
  checkForValidation() {
    for (var control in this.loan.value) {
      if (this.loan.value[control]) {
        let a = (typeof (this.loan.value[control]) === 'string') ? this.loan.controls[control].value.trim() : this.loan.controls[control].value;
        this.loan.controls[control].setValue(a);
      }
    }
  }

}
