import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralService } from 'src/app/services/general.service';
class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
} 
@Component({
  selector: 'app-past-public-notices-main',
  templateUrl: './past-public-notices-main.component.html',
  styleUrls: ['./past-public-notices-main.component.scss']
})
export class PastPublicNoticesMainComponent implements OnInit {
  breadCrumbItems: any;
  currentUser: any;
  dtOptions: DataTables.Settings = {};
  constructor(private service: GeneralService, private datepipe: DatePipe, private http: HttpClient, private renderer: Renderer2, private router: Router) {
    this.breadCrumbItems = [{ label: 'Dashboard', path: 'loan' },
    { label: 'Assignments', path: '/loan/assignment', active: true }];
  }

  ngOnInit() {
    this.currentUser = this.service.getcurrentUser();
    this.dtOptions = {
      pagingType: "simple_numbers",
      pageLength: 10,
      processing: true,
      serverSide: true,
      order: [[0, 'desc']],
      ajax: (dataTablesParameters: any, callback) => {
        this.http
          .get<DataTablesResponse>(
            this.service.GetBaseUrl() + `loan/application/View/forLawyer` + 
            `?LawyerID=${this.currentUser.UserID}` +
            `&pageNo=${((dataTablesParameters.start+dataTablesParameters.length)/ dataTablesParameters.length)}`+
            `${dataTablesParameters.length.value!=''?'&record='+dataTablesParameters.length:''}`+
            `${dataTablesParameters.search.value!=''?'&serchKey='+dataTablesParameters.search.value:''}`+
            `${dataTablesParameters.order ?'&orderKey='+dataTablesParameters.columns[dataTablesParameters.order[0].column].name:'&orderKey=AppID'}`+
            `${dataTablesParameters.order ?'&ordering='+dataTablesParameters.order[0].dir:'&ordering=desc'}`,
             {}
          ).subscribe((resp: any) => {
            console.log(dataTablesParameters);
            callback({
              recordsTotal: resp.Count,
              recordsFiltered: resp.Count,
              data: resp.data
            });
          });
      },
      responsive: true,
      columns: [{
        title: 'App ID',
        name: 'AppID',
        data: '', render: (data, type, row) => {
          return `${row.AppID}`;
        }
      },{
        title: 'Aplicant Name',
        name: 'AplicantName',
        data: '', render: (data, type, row) => {
          return `${row.AplicantName}`;
        }
      }, {
        title: 'Loan',
        name: 'Type_of_Loan',
        data: 'Type_of_Loan'
      }, {
        title: 'Amount',
        name: 'LoanAmount',
        data: 'LoanAmount'
      }, {
        title: 'Status',
        name: 'ApplicationStatus',
        data: 'ApplicationStatus',render:(data)=>{
          if (data === 'Pending') {
            return `<span class="badge badge-danger p-1">${data}</span>`;
          }else if(data ==='Title Clear Complete'){
            return `<span class="badge badge-success p-1">${data}</span>`;
          }else if(data){
            return `<span class="badge badge-secondary p-1">${data}</span>`;
          }else{
            return data;
          }
        }
      }, {
        title: 'Action',
        name: 'Action',
        orderable: false,
        data: null, render: (data, type, row) => {
          return `
          <a class="btn text-primary" title="View Past Public Notice"
          UploadID = "${row.AppID}"><i class="mdi mdi-eye font-18 text-secondary"
          UploadID = "${row.AppID}" aria-hidden="false"></i></a>`;
        }
      }, {
        title: 'Date',
        name: 'CreatedAt',
        data: 'CreatedAt',
        render: (data, type, row) => {
          return this.datepipe.transform(data, 'MMM dd,yyyy');
        }
      }, {
        title: 'Bank Name',
        name: 'BankName',
        data: 'BankName'
      }
      ],
      columnDefs:[{targets:0 ,visible:true}]
    };
  }
  ngAfterViewInit(): void {
    this.renderer.listen('document', 'click', (event) => {
      if (event.target.hasAttribute('UploadID')) {
        this.router.navigate(['/loan/viewpastpublicnotice/' + event.target.getAttribute('UploadID')]);
      }
    });
  }
}
