import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { GeneralService } from 'src/app/services/general.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-request-document-dialog',
  templateUrl: './request-document-dialog.component.html',
  styleUrls: ['./request-document-dialog.component.scss']
})
export class RequestDocumentDialogComponent implements OnInit {
  submitted: boolean;
  requestForm: FormGroup;
  currentUser: any;
  dropdownData: any;
  issubmit: boolean;
  isLoading: boolean;
  isotherDoc: any = false;
  constructor( 
    public dialogRef: MatDialogRef<RequestDocumentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private gs: GeneralService
  ) { }

  ngOnInit() {
    if(this.data.DocumentName === '') {
      this.isotherDoc = true;
    }
    this.currentUser = this.gs.getcurrentUser();
    this.dropdownData = this.data.DropDownValue;
    this.requestForm = this.fb.group({
      DocumentSubTypeId: new FormControl(this.data.DocumentSubType),
      DocumentTypeId: new FormControl(this.data.DocumentType),
      DocumentName: new FormControl(this.data.DocumentName, [Validators.required]),
      PropertyID: new FormControl(this.data.PropertyID ? this.data.PropertyID : ''),
      // PropertyID: new FormControl(''),
      UserID: new FormControl(this.currentUser.UserID, Validators.required),
      RequestedTo: new FormControl(null, Validators.required),
    });
  }
  onConfirmClick() {
    console.log('confirm clic is called',this.requestForm);
    this.issubmit = true;
    if(this.requestForm.invalid == true) {
      // console.log(this.requestForm.value);
      // this.issubmit = false;
      return;
    } 
    this.gs.RequestDocument(this.requestForm.value).subscribe((res) => {
      this.isLoading = false;
      if(res.status == 200) {
        Swal.fire({
          title: `Success`,
          text: 'Document Request Sent Successfully.',
          type: 'success',
          showConfirmButton: false,
          timer:3000
        }).then(() => { 
          this.CloseDialog(true); 
        });
      } else {
        Swal.fire({
          title: `Error`,
          text: res.message,
          type: 'error',
          showConfirmButton: false,
          timer:3000
        }).then(() => {this.CloseDialog(false);});
      }
    }, (error) => {
      console.log(error);
      this.isLoading = false;
      Swal.fire({
        title: `Error`,
        text: 'Internal Server Error',
        type: 'error',
        showConfirmButton: false,
        timer:3000
      }).then(() => {this.CloseDialog(false);});
    });
  }
  // get f() { return this.requestForm.controls}
  CloseDialog(value?:boolean) {
    this.dialogRef.close(value);
    // this.dialogRef.close();
    // this.isLoading = false;
  }
  updateAppStatus(status) {
    let data = {
      applicationStatus: status
    }
    this.isLoading = true;
    console.log(data);
    this.gs.updateAppStatus(this.data.AppID, data).subscribe((res) => {
      this.isLoading = false;
    }, (error) => {
      this.isLoading = false;
    });
  }
}
