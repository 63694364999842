import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { GeneralService } from 'src/app/services/general.service';
import { TitleClearCompleteComponent } from '../title-clear-complete/title-clear-complete.component';
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-view-title-clear-report',
  templateUrl: './view-title-clear-report.component.html',
  styleUrls: ['./view-title-clear-report.component.scss']
})
export class ViewTitleClearReportComponent implements OnInit {
  breadCrumbItems: any;
  isLoading: boolean;
  titleclearData: any;
  currentUser: any;
  fileUrl:any
  constructor(private service: GeneralService, private route: ActivatedRoute, private router: Router,private sanitizer: DomSanitizer) {
    this.currentUser = this.service.getcurrentUser();
  }

  ngOnInit() {
    this.isLoading = true;
    this.service.getTitleCleardoc(this.route.snapshot.params.AppID).subscribe((res: any) => {
      this.titleclearData = res.data[0];
      this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.titleclearData.FileUrl);
      if(this.titleclearData && this.titleclearData.Template){
         this.titleclearData.Template.split(`#`).join("'");
      }
      this.isLoading = false;  
    });
    
    this.service.GetApplicationInformation(this.route.snapshot.params.AppID).subscribe((Response) => {
      if (this.currentUser.UserType === 'Lawyer') {
        this.breadCrumbItems = [{ label: 'Dashboard', path: 'loan' }, { label: 'Assignments', path: `/loan/assignment`},
        // {label: `${Response.data[0].FirstName} ${Response.data[0].LastName}`, 
        // path: `loan/tcc-dashboard-lawyer/${this.route.snapshot.params.AppID}`},
         { label: 'Title Clear Document', path: '/', active: true }];
      } else if (this.currentUser.UserType === 'Bank Manager') {
        this.breadCrumbItems = [{ label: 'Dashboard', path: 'loan' }, { label: 'Applications', path: 'loan/applications' },
        {label: `${Response.data[0].FirstName} ${Response.data[0].LastName}` ,
         path: `/loan/post-mortgage-dashboard/${this.route.snapshot.params.AppID}`}
        , { label: 'Title Clear Document', path: '/', active: true }];
      }
    });
    this.isLoading = true;
    this.service.getTitleCleardoc(this.route.snapshot.params.AppID).subscribe((res: any) => {
      this.titleclearData = res.data[0];
      if(this.titleclearData && this.titleclearData.Template){
         this.titleclearData.Template.split(`#`).join("'");
      }
      this.isLoading = false;  
    });
  }

  exportAsPDF()
  {
    let data = this.titleclearData.FileUrl;  
    html2canvas(data).then(canvas => {
      const contentDataURL = canvas.toDataURL('image/png')  
      let pdf = new jspdf('l', 'cm', 'a4'); //Generates PDF in landscape mode
      // let pdf = new jspdf('p', 'cm', 'a4'); Generates PDF in portrait mode
      pdf.addImage(contentDataURL, 'PNG', 0, 0, 29.7, 21.0);  
      pdf.save('Filename.pdf');   
    }); 
  }
}
