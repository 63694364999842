import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDate, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { GeneralService } from 'src/app/services/general.service';
import Swal from 'sweetalert2';

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Component({
  selector: 'app-applications-list',
  templateUrl: './applications-list.component.html',
  styleUrls: ['./applications-list.component.scss']
})
export class ApplicationsListComponent implements OnInit {
  breadCrumbItems: Array<{}>;
  fromNGDate: NgbDate;
  toNGDate: NgbDate;
  isLoading: boolean;
  currentAppId;
  user_type: number;
  fileExtension = '';
  submitted: boolean;
  @Input() fromDate: Date;
  PVRForm: FormGroup;
  currentUser: any;
  dtOptions: DataTables.Settings = {};
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtTrigger = new Subject<any>();
  @ViewChild('uploadPVR', { static: true }) uploadPVRModal;
  @Input() toDate: Date;
  @Output() dateRangeSelected: EventEmitter<{}> = new EventEmitter();
  selected: any;
  dtOptionsforPVR: DataTables.Settings = {};

  hidden: boolean;
  @ViewChild('dp', { static: true }) datePicker: any;
  allLoanTypes = []
  status = `'iPVR In Progress','iPVR Sent'`;
  allStatus = ['iPVR sent', 'iPVR in Progress'];
  allApplicationNos = ['1', '2', '3', '4', '5', '6'];
  allProperty: any
  tabledata: any;
  countData: any;
  isFilterLoading: boolean;
  filterobj: {
    FilterStartDate: string,
    FilterEndDate: string,
    TypeOfLoan: number,
    LoanPropertyTypeID: number,
    ApplicationStatus: string,
    UserID: string,
    CompanyUserMasterID: string
  }
  applications: any;
  param: any ='';
  inputValue: string ='';
  isLoadingTable: boolean;

  constructor(
    private router: Router, private service: GeneralService, private renderer: Renderer2,
    private formBuilder: FormBuilder, private modalService: NgbModal, private Route: ActivatedRoute, private datePipe: DatePipe, private http: HttpClient) {
      if(this.Route.snapshot.params.AppStatus) {
        this.status = `'${this.Route.snapshot.params.AppStatus}'`;
      } 
  }

  
  ngOnInit() {
    this.isLoading = false;
    this.submitted = false;
    this.breadCrumbItems = [{ label: 'Dashboard', path: '/' },
    { label: 'CG iPVR Applications', path: '/', active: true }];
    this.currentUser = this.service.getcurrentUser();
    this.selected = '';
    this.hidden = true;
    this.filterobj = {
      FilterStartDate: "",
      FilterEndDate: "",
      TypeOfLoan: null,
      LoanPropertyTypeID: null,
      ApplicationStatus: "",
      UserID: this.currentUser.UserID,
      CompanyUserMasterID: this.currentUser.CompanyUserMasterID
    }
    this.isFilterLoading = true;
    this.service.GetLoanPropertyTypes().subscribe((res) => {
      this.allProperty = res.data;
      this.service.GetLoanTypes().subscribe((data) => {
        this.allLoanTypes = data.data;
        this.isFilterLoading = false;
        this.filterCount();
      })
    });

    this.dtOptions = {
      pagingType: "simple_numbers",
      pageLength: 10,
      serverSide: true,
      searching: false,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        this.isLoadingTable = true;
        this.http
          .get<DataTablesResponse>(
            this.service.GetBaseUrl() + `application/list/status/bank`  + 
            `?UserID=${this.currentUser.UserID}` + this.param +
            `&status=${this.status}` +
            `&pageNo=${((dataTablesParameters.start+dataTablesParameters.length)/ dataTablesParameters.length)}`+
            `${dataTablesParameters.length.value!=''?'&limit='+dataTablesParameters.length:''}`+
            `${this.inputValue!=''?'&searchkey='+this.inputValue:''}`+
            `${dataTablesParameters.order ?'&orderingkey='+dataTablesParameters.columns[dataTablesParameters.order[0].column].name:'&orderingkey=AppID'}`+
            `${dataTablesParameters.order ?'&order='+dataTablesParameters.order[0].dir:'&order=desc'}` +
            `${this.filterobj.FilterStartDate!=''?'&startdate='+this.filterobj.FilterStartDate:''}`+
            `${this.filterobj.FilterEndDate!=''?'&enddate='+this.filterobj.FilterEndDate:''}`+
            `${this.filterobj.TypeOfLoan!=null?'&loanType='+this.filterobj.TypeOfLoan:''}`+
            `${this.filterobj.ApplicationStatus!='' && this.filterobj.ApplicationStatus!=null?'&status='+this.filterobj.ApplicationStatus:''}`+
            `${this.filterobj.LoanPropertyTypeID!=null?'&propertyType='+this.filterobj.LoanPropertyTypeID:''}`,
             {}
          ).subscribe((resp: any) => {
            this.isLoadingTable = false;
            this.applications = resp.data;
            callback({
              recordsTotal: resp.allCount,
              recordsFiltered: resp.count,
              data: this.applications
            });
          });
      },
      columns: [{
        title: 'AppID',
        name: 'AppID',
        data: 'AppID'
      },
      // {
      //   title: 'AppID',
      //   name: 'AppID',
      //   data: 'AppID'
      // },
       {
        title: 'Application ID',
        name: 'ApplicationID',
        data: 'ApplicationID'
      },
       {
        title: 'Name',
        name: 'ApplicantName',
        data: 'ApplicantName',
        render: (data) => this.renderData(data),
      }, {
        title: 'State Name',
        name: 'StateName',
        data: 'StateName',
        render: (data) => this.renderData(data),
      }, {
        title: 'Loan Type',
        name: 'Type_of_Loan',
        data: 'Type_of_Loan',
        render: (data) => this.renderData(data),
      },
      //  {
      //   title: 'Bank Name',
      //   name: 'BankName',
      //   data: 'BankName'
      // }, {
      //   title: 'Branch Name',
      //   name: 'BranchCode',
      //   data: 'BranchCode'
      // }, 
      {
        title: 'Status',
        name: 'ApplicationStatus',
        data: 'ApplicationStatus', render: (data) => {
          if (data === 'iPVR Sent' || data === 'Title Clear Sent') {
            return `<span class="badge badge-success p-1">${data}</span>`;
          } else if (data) {
            return `<span class="badge badge-secondary p-1">${data}</span>`;
          } else {
            return data;
          }
        }
      }, {
        title: "Amount (₹)",
        name: "LoanAmount",
        data: "LoanAmount",
        render: (data) => this.renderData(data),
      },
       {
        title: 'Action',
        data: null, render: (data, type, row) => {
          if (row.ApplicationStatus === 'iPVR Sent') {
            return `<a class=" text-primary" title="View Application"
            viewID = "${row.AppID}"><i class="mdi mdi-eye font-18 text-secondary" ApplicationStatus = '${row.ApplicationStatus}'  viewID = "${row.AppID}" aria-hidden="false" ></i></a>
            <a DownloadIPVRAppID = "${row.AppID}" class="m-1"  title="Download iPVR">
            <i class="mdi mdi-file-download font-18 text-secondary" aria-hidden="false" DownloadIPVRAppID = "${row.AppID}"></i>
            </a>`;
          } else {
            return `<a class="text-primary" title="View Application"
            viewID = "${row.AppID}"><i class="mdi mdi-eye font-18 text-secondary" ApplicationStatus = '${row.ApplicationStatus}'  viewID = "${row.AppID}" aria-hidden="false"></i></a>`;
          }

        }
      }
      ], order: [[0, 'desc']], columnDefs: [{ targets: 0, visible: false },{targets:7,orderable:false}]
    };
    this.dtOptionsforPVR = {
      pagingType: "simple_numbers",
      pageLength: 10,
      serverSide: true,
      searching: false,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        this.isLoadingTable = true;
        this.http
          .get<DataTablesResponse>(
            this.service.GetBaseUrl() + `application/list/status/admincg` +
            `?` +
            `pageNo=${((dataTablesParameters.start+dataTablesParameters.length)/ dataTablesParameters.length)}`+
            `${dataTablesParameters.length.value!=''?'&limit='+dataTablesParameters.length:''}`+
            `${this.inputValue!=''?'&searchkey='+this.inputValue:''}`+
            `${dataTablesParameters.order ?'&orderingkey='+dataTablesParameters.columns[dataTablesParameters.order[0].column].name:'&orderingkey=AppID'}`+
            `${dataTablesParameters.order ?'&order='+dataTablesParameters.order[0].dir:'&order=desc'}` +
            `${this.filterobj.FilterStartDate!=''?'&startdate='+this.filterobj.FilterStartDate:''}`+
            `${this.filterobj.FilterEndDate!=''?'&enddate='+this.filterobj.FilterEndDate:''}`+
            `${this.filterobj.TypeOfLoan!=null?'&loanType='+this.filterobj.TypeOfLoan:''}`+
            `${this.filterobj.ApplicationStatus!='' && this.filterobj.ApplicationStatus!=null?'&status='+this.filterobj.ApplicationStatus:''}`+
            `${this.filterobj.LoanPropertyTypeID!=null?'&propertyType='+this.filterobj.LoanPropertyTypeID:''}`,
             {}
          ).subscribe((resp: any) => {
            this.isLoadingTable = false;
            this.applications = resp.data;
            callback({
              recordsTotal: resp.allCount,
              recordsFiltered: resp.count,
              data: this.applications
            });
          });
      },
      columns: [{
        title: 'AppID',
        name: 'AppID',
        data: 'AppID'
      }, 
      {
        title: 'AppID',
        name: 'AppID',
        data: 'AppID'
      },
      // {
      //   title: 'Application ID',
      //   name: 'ApplicationID',
      //   data: 'ApplicationID'
      // }, 
      {
        title: 'Name',
        name: 'ApplicantName',
        data: 'ApplicantName',
        render: (data) => this.renderData(data),
      },{
        title: 'State Name',
        name: 'StateName',
        data: 'StateName',
        render: (data) => this.renderData(data),
      }, {
        title: 'Loan Type',
        name: 'Type_of_Loan',
        data: 'Type_of_Loan',
        render: (data) => this.renderData(data),
      },
      //  {
      //   title: 'Bank Name',
      //   name: 'BankName',
      //   data: 'BankName'
      // }, {
      //   title: 'Branch Name',
      //   name: 'BranchCode',
      //   data: 'BranchCode'
      // },
       {
        title: 'Status',
        name: 'ApplicationStatus',
        data: 'ApplicationStatus', render: (data) => {
          if (data === 'iPVR Sent' || data === 'Title Clear Sent') {
            return `<span class="badge badge-success p-1">${data}</span>`;
          } else if (data) {
            return `<span class="badge badge-secondary p-1">${data}</span>`;
          } else {
            return data;
          }
        }
      },{
        title: "Amount (₹)",
        name: "LoanAmount",
        data: "LoanAmount",
        render: (data) => this.renderData(data),
      }, {
        title: 'Action',
        render: (data: any, type: any, full: any) => {
          // generate PDF
          if (full.PVRID && !full.PVRDocumentID) {
            return `<a class="text-primary" title="Generate PVR Report"
            PVRappID1 = "${full.AppID}"><i PVRappID1 = "${full.AppID}" class="dripicons-document-edit font-18 text-secondary"
            aria-hidden="false"></i></a>
            <a class="text-primary" title="Upload Single Documents"
            UploadSingleDocAppID = "${full.AppID}"><i class="mdi mdi-file-upload font-18 text-secondary"
            UploadSingleDocAppID = "${full.AppID}" aria-hidden="false"></i></a>`;
            // Upload PDF
          } else if (full.PVRID && full.PVRDocumentID) {
            return `<a class="text-primary" title="View iPVR"
            viewPVRID = "${full.PVRDocumentID}" target="_blank" propertyID ="${full.PropertyID}" appID = "${full.AppID}"><i class="mdi mdi-eye font-18 text-secondary"
            viewPVRID = "${full.PVRDocumentID}" propertyID ="${full.PropertyID}" ApplicationStatus = '${full.ApplicationStatus}'  appID = "${full.AppID}" aria-hidden="false"></i></a>
            <a class="text-primary" title="Upload Single Documents"
            UploadSingleDocAppID = "${full.AppID}"><i class="mdi mdi-file-upload font-18 text-secondary"
            UploadSingleDocAppID = "${full.AppID}" aria-hidden="false"></i></a>`;
            // <a class="text-primary" title="Upload All Documents"
            // UploadDocAppID = "${full.AppID}"><i class="mdi mdi-file-document-box-multiple font-18 text-secondary"
            // UploadDocAppID = "${full.AppID}" aria-hidden="false"></i></a>
            // view PDF
          }
          else if(full.ApplicationAddedFor === "MOD"){
            return `
            <a class="text-primary" title="Add iPVR Report"
            appID = "${full.AppID}" >
            <i class="mdi mdi-file-plus font-18 text-secondary"    ApplicationStatus ="${full.ApplicationStatus}"
            appID = "${full.AppID}"  aria-hidden="false"></i>
            </a>
            <a class="ml-1 text-primary" title= 'Add Properties For MOD' ApplicationAddedFor='${full.ApplicationAddedFor}'
            ForMoDID="${full.AppID}" >
            <i class="mdi mdi-plus-circle font-18 text-secondary"ApplicationAddedFor='${full.ApplicationAddedFor}'
            ForMoDID="${full.AppID}"  aria-hidden="false"></i>
            </a>`
          } 
          else {
            return `
            <a class="ml-1 text-primary" title= 'Add iPVR Report' 
            clickID="${full.AppID}" stateID='${full.stateID}' >
            <i class="mdi mdi-file-plus font-18 text-secondary"
            clickID="${full.AppID}" stateID='${full.stateID}' aria-hidden="false"></i>
            </a>` 
            ;
          }
        }
       
      }
      ], order: [[0, 'desc']], columnDefs: [{ targets: 0, visible: false },{targets:7,orderable:false}]
    };
  }

  renderData(data) {
    if (data === '' || data === null || data === 'null') {
      return '--';
    } else {
      return data;
    }
  }

  onDateSelection(date: NgbDate) {
    this.filterobj.FilterStartDate = '';
    this.filterobj.FilterEndDate = '';
    if (!this.fromDate && !this.toDate) {
      this.fromNGDate = date;
      this.fromDate = new Date(date.year, date.month - 1, date.day);
      this.selected = '';
    } else if (this.fromDate && !this.toDate && date.after(this.fromNGDate)) {
      this.toNGDate = date;
      this.toDate = new Date(date.year, date.month - 1, date.day);
      this.hidden = true;
      this.selected = this.fromDate.toLocaleDateString() + '-' + this.toDate.toLocaleDateString();
      this.filterobj.FilterStartDate = this.datePipe.transform(this.fromDate, 'yyyy-MM-dd');
      this.filterobj.FilterEndDate = this.datePipe.transform(this.toDate, 'yyyy-MM-dd');
      this.dateRangeSelected.emit({ fromDate: this.fromDate, toDate: this.toDate });
      this.fromDate = null;
      this.toDate = null;
      this.fromNGDate = null;
      this.toNGDate = null;
      this.filterCount();
    } else {
      this.fromNGDate = date;
      this.fromDate = new Date(date.year, date.month - 1, date.day);
      this.selected = '';
    }
    this.rerender();
  }
  redirect() {
    this.router.navigate(['/loan/addapplication']);
  }
  ngAfterViewInit(): void {
    this.dtTrigger.next();
    this.renderer.listen('document', 'click', (event) => {
      if (event.target.hasAttribute('UploadDocAppID')) {
        this.service.ForDemo(event.target.getAttribute('UploadDocAppID')).subscribe((res) => {
          Swal.fire({
            title: 'Success',
            text: res.message,
            type: 'success',
            showConfirmButton: false,
            timer:3000
          }).then(() => {
            location.reload();
          });
        });
      }
      if(event.target.hasAttribute('ForMoDID')){
        console.log('ApplicationAddedFor',event.target.getAttribute('ApplicationAddedFor'))
        this.router.navigate(['/loan/addPropertiesForMOD'],{state:{AppID:event.target.getAttribute("ForMoDID")}});

      }
      if(event.target.hasAttribute('clickID')){
        console.log('state id',event.target.getAttribute("stateID"))
        this.submitted = true;
        this.isLoading = true;
        const data1 = {
          AppID: event.target.getAttribute("clickID"),
        };
        this.service.onClickIPVR(data1).subscribe((data) => {
          // console.log("line==368", data);
          this.isLoading = true;
          if (data.startTime !== undefined) {
            const date = new Date(data.startTime);
            const formattedDate = date.toUTCString().split("GMT")[0];
            // console.log("line==527", formattedDate);
            // this.isLoading = false;

            Swal.fire({
              title: "Are you sure want to view?<br>" + data.message,
              text: formattedDate,
              type: "warning",
              showCancelButton: true,
              confirmButtonText: "Yes",
              cancelButtonText: "No",
              confirmButtonClass: "btn btn-danger mt-2",
              cancelButtonClass: "btn btn-success ml-2 mt-2",
              buttonsStyling: false,
              showConfirmButton: true,
              timer: 6000,
            }).then((result) => {
              if (result.value) {
                // this.isLoading = true;
                const Data = {
                  AppID: event.target.getAttribute("clickID"),
                  isRestart: 1,
                };
                this.service.onClickIPVR(Data).subscribe((res) => {
                  let appID = event.target.getAttribute("clickID");
                  // console.log("AppID Application",appID)
                  this.router.navigate([
                    "/cg-applications/View-Application",
                  ], { state: { ApplicationStatus: event.target.getAttribute('ApplicationStatus'), AppID: appID } });
                });
              } else if (data.dismiss === Swal.DismissReason.cancel) {
                this.isLoading = false;

                this.router.navigate(["/cg-applications/Applications"]);
              }
            });
          } else if (data) {
            // console.log("appid",event.target.getAttribute("appID"));
            // console.log("appllicationstatus")
            this.router.navigate([
              "/cg-applications/View-Application",
            ], { state: { ApplicationStatus: event.target.getAttribute('ApplicationStatus'), AppID: event.target.getAttribute("clickID") } });
          }

          this.isLoading = false;
        });
          // this.router.navigate(['/cg-applications/View-Application'],{state:{AppID:event.target.getAttribute("clickID"),stateID:event.target.getAttribute("stateID")}});
      }
      if (event.target.hasAttribute('appID')) {
        
        this.isLoading = true;
        const data = {
          AppID: event.target.getAttribute('appID')
        };
        this.service.onClickIPVR(data).subscribe(
          (data) => {
            this.isLoading = false;
        });
        this.router.navigate(['/cg-applications/View-Application'],{state:{ApplicationStatus:event.target.getAttribute('ApplicationStatus'),AppID:event.target.getAttribute('appID')}});
      }
      if (event.target.hasAttribute('UploadSingleDocAppID')) {
        this.router.navigate(['/loan/uploaddocument/' + event.target.getAttribute('UploadSingleDocAppID')]);
      }
      if (event.target.hasAttribute("viewID")) {
        if (event.target.getAttribute('ApplicationStatus') === 'Post Mortgage') {
          this.router.navigate([
            "/loan/post-mortgage-dashboard/" + event.target.getAttribute("viewID"),
          ], { state: { ApplicationStatus: event.target.getAttribute('ApplicationStatus') } });
        }
        else if (event.target.getAttribute('ApplicationStatus') === 'iPVR Sent' || event.target.getAttribute('ApplicationStatus') === 'iPVR In Progress') {
          this.router.navigate([
            "/loan/view-pvr-list",
          ], { state: { ApplicationStatus: event.target.getAttribute('ApplicationStatus'), AppID: event.target.getAttribute("viewID") } });
        }
        else if (event.target.getAttribute('ApplicationStatus') === "TitleClear In Progress" || event.target.getAttribute('ApplicationStatus') === "TC Document In Progress"
          || event.target.getAttribute('ApplicationStatus') === "TC Document Completed" || event.target.getAttribute('ApplicationStatus') === "Title Clear Sent") {
          this.router.navigate([
            "loan/titleclear-dashbboard/" + event.target.getAttribute("viewID"),
          ], { state: { ApplicationStatus: event.target.getAttribute('ApplicationStatus'), AppID: event.target.getAttribute("viewID") } });
        }

      }
      if(event.target.hasAttribute('DownloadIPVRAppID')){
          console.log('event.target.getAttribute("viewID")',event.target.getAttribute("DownloadIPVRAppID"))
          this.router.navigate([
            "loan/viewdocument/" + event.target.getAttribute("DownloadIPVRAppID"),
          ]);
      }
      if (event.target.hasAttribute('viewPVRID') && event.target.hasAttribute('propertyID')) {
        // this.router.navigate(['loan/viewdocument/' + event.target.getAttribute('propertyID')
        //   + '/' + event.target.getAttribute('viewPVRID')]);
          this.router.navigate([ "/cg-applications/View-documents" +'/'+
            // event.target.getAttribute("propertyID") +
            // "/" +
            // event.target.getAttribute("viewPVRID") +
            // "/" +
            event.target.getAttribute("appID") 
            // "/ipvr"
          ]);
      }
      if (event.target.hasAttribute('PVRappID1')) {
        this.ongenerate(event.target.getAttribute('PVRappID1'))
      } else if (event.target.hasAttribute('PVRappID2')) {
        this.ongenerate(event.target.getAttribute('PVRappID2'))
      }
    });
  }
  ngOnDestroy() {
    this.renderer.destroy()
  }

  ongenerate(Appid) {
    this.isLoading = true;
    this.service.GetDocumentList(Appid).subscribe((list) => {
      if (
        list.data.every((x) => x.DocumentID == null && x.Status == "Pending")
      ) {
        Swal.fire({
          title: "Are you sure?",
          text: "There Is No Document Attached With this PVR, Are You Sure You Want To Generate PVR Without Document Attached ?",
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          confirmButtonClass: "btn btn-danger mt-2",
          cancelButtonClass: "btn btn-success ml-2 mt-2",
          buttonsStyling: false,
          showConfirmButton: false,
          timer: 3000,
        }).then((result) => {
          if (result.value) {
            this.service.GeneratePVR(Appid).subscribe((res) => {
              if (res.error) {
                Swal.fire({
                  title: res.error_code,
                  text: res.message,
                  type: "error",
                  showConfirmButton: false,
                  timer:3000
                });
                return;
              } 
              else{
                location.reload();
              }
            });
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            this.router.navigate(["/loan/uploaddocument/" + Appid]);
          }
        });
      } else {
        this.isLoading = true;
        this.service.GeneratePVR(Appid).subscribe((res) => {
          if (res.error) {
            Swal.fire({
              title: res.error_code,
              text: res.message,
              type: "error",
              showConfirmButton: false,
              timer:3000
            });
            return;
          } 
          else{
            location.reload();
          }
        });
      }
    });
  }
  
  filterCount() {
    this.isFilterLoading = true;
    this.service.getLoanDashboard(this.filterobj).subscribe((res) => {
      this.countData = res.data;
      this.isFilterLoading = false;
    });
  }
  callback() {
    this.selected = ''
    this.filterobj.FilterStartDate = '';
    this.filterobj.FilterEndDate = '';
    this.filterCount()
    this.hidden = true;
  }
  ChangeLoan(e) {
    if (e === undefined) {
      this.filterobj.TypeOfLoan = null
    } else {
      this.filterobj.TypeOfLoan = e
    }
    this.filterCount()
    this.rerender();
  }
  onstatusChange(e) {
    if (e === undefined) {
      this.filterobj.ApplicationStatus = null
    } else {
      this.filterobj.ApplicationStatus = e
    }
    this.filterCount();
    this.rerender();
  }
  ChangePropertyType(e) {
    if (e === undefined) {
      this.filterobj.LoanPropertyTypeID = null
    } else {
      this.filterobj.LoanPropertyTypeID = e
    }
    this.filterCount()
    this.rerender();
  }

  onSearch(){
    this.rerender();
  }
  onchangeSearch() {
    console.log(this.inputValue)
    if(this.inputValue != '') {
      return
    }
    this.rerender();
  }
  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }


}
