import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GeneralService } from 'src/app/services/general.service';
class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}  
@Component({
  selector: 'app-view-past-public-notice',
  templateUrl: './view-past-public-notice.component.html',
  styleUrls: ['./view-past-public-notice.component.scss']
})
export class ViewPastPublicNoticeComponent implements OnInit {
  breadCrumbItems: Array<{}>;
  appID: any;
  dtOptions: DataTables.Settings = {};
  isLoading: boolean;
  DocumentList: Array<any>;
  param: any = { 
    "PageSize": '', 
    "PageNumber": '', 
    "SurveyNO": '', 
    "BuildNO": '', 
    "societyname": '',
    "districtname": '', 
    "talukaname": '', 
    "villagename": ''
  };
  applicationData: any;
  constructor(private service: GeneralService, private datepipe: DatePipe, private http: HttpClient, private Route: ActivatedRoute, private router: Router) {
    this.appID = this.Route.snapshot.params.AppID;
    this.isLoading = true;
  }
  ngOnInit() {
    this.DocumentList = [];
    this.service.GetApplicationInformation(this.Route.snapshot.params.AppID).subscribe((res) => {

      this.isLoading = false;
      this.applicationData = res.data[0];
      this.breadCrumbItems = [{ label: 'Dashboard', path: '/' }, { label: 'Assignments', path: '/loan/assignment' }, { label: `${this.applicationData.FirstName != null ? this.applicationData.FirstName : ''} ${ this.applicationData.LastName != null ? this.applicationData.LastName : ''}`, path: '/loan/assignment/' + this.Route.snapshot.params.AppID }, {label: 'Public Notices', path: '/loan/viewpastpublicnotice/' + this.Route.snapshot.params.AppID , active: true}];
      
      console.log(this.applicationData);
      this.dtOptions = {
        serverSide: false,
        ajax: (dataTablesParameters: any, callback) => {
          console.log(dataTablesParameters);
          this.http
            .get<DataTablesResponse>(
              this.service.GetBaseUrl() + `GetPulicNotice/` + this.appID,
               {}
            ).subscribe((resp: any) => {
              console.log(dataTablesParameters);
              callback({
                recordsTotal: resp.data.length,
                recordsFiltered: resp.data.length,
                data: resp.data
              });
            });
        },
        responsive: true,
        // ajax: { url: this.service.GetBaseUrl() + `tcc/loan/application/Documents/AppID/${this.Route.snapshot.params.AppID}` }, responsive: true,
        columns: [{
          title: 'Sr No.', data: 'row', render: (data, type, row, meta) => {
            return meta.row + 1;
          }
        }, 
        {
          title: 'Entry Date',
          name: 'EntryDate',
          data: 'EntryDate',
          render: (data, type, row) => {
            return this.datepipe.transform(data, 'MMM dd,yyyy');
          }
        }, {
          title: 'Publish Date',
          name: 'PublishDate',
          data: 'PublishDate',
          render: (data, type, row) => {
            return this.datepipe.transform(data, 'MMM dd,yyyy');
          }
        }, {
          title: 'Notification Days',
          name: 'NoticeDays',
          data: 'NoticeDays',
          render: (data, type, row) => {
            return data;
          }
        }, {
          title: 'Published By',
          name: 'PublishedBy',
          data: 'PublishedBy',
          render: (data, type, row) => {
            return row.PublishedBy
          }
        }, {
          title: 'Status',
          render: (data, type, row) => {
            this.DocumentList.push(row);
            if (row.isExp === 1 || row.isExp === '1') {
              return `<span class="badge badge-danger p-1">Completed</span>`;
            } else {
              return `<span class="badge badge-success p-1">Published</span>`;
            }
          }
        }, {
          title: 'Action',
          render: (data, type, row) => {
            let btn = '';
            btn += '<a href="javascript:void(0)" class="viewnotice m-1" title="View Notice and Responses" receipt-id="' + row.Id + '">';
            btn += '<i class="mdi mdi-eye font-18 text-secondary" aria-hidden="false" receipt-id="' + row.Id + '"></i>';
            btn += '</a>';
            if (row.isExp === 0 || row.isExp === '0') {
              btn += '<a href="javascript:void(0)" class="addresponse m-1" title="Add a Response" DocumentID="' + row.Id + '">';
              btn += '<i class="mdi mdi-file-upload-outline font-18 text-secondary" aria-hidden="false" DocumentID="' + row.Id + '"></i>';
              btn += '</a>';
            }
            return btn;
          }
        }, {
          title: 'Description',
          render: (data, type, row) => {
            return row.Description.split(`#`).join("'");;
          },
        }
        ], drawCallback: () => {
          $('.viewnotice').on('click', (e) => {
            console.log($(e.target).attr('receipt-id'));
            this.router.navigate(['/loan/viewNotice/' +  this.Route.snapshot.params.AppID + '/' + $(e.target).attr('receipt-id')]); 
          });
          $('.addresponse').on('click', (e) => {
            console.log($(e.target).attr('DocumentID'));
            this.router.navigate(['/loan/addResponse/' + this.Route.snapshot.params.AppID + '/' +$(e.target).attr('DocumentID')]);
          });
        }
      };
    });
  }
  addnotice() {
    this.router.navigate(['/loan/addPublicNotice/' + this.Route.snapshot.params.AppID]); 
  }
}
