import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-view-gpp-doc',
  templateUrl: './view-gpp-doc.component.html',
  styleUrls: ['./view-gpp-doc.component.scss']
})


export class ViewGppDocComponent implements OnInit {
  month: string;
  state: string;
  firstName:string;
  breadCrumbItems: Array<{}>;
  AppID: any;
  fileUrl:string;
  constructor( private Route: ActivatedRoute,
    private router: Router,
    private service: GeneralService) { 
    this.month = this.Route.snapshot.params.mon;
    // this.state = this.Route.snapshot.params.state;
    // this.AppID = this.Route.snapshot.params.AppID;
    console.log(this.month);
    console.log(this.state);
    let a = this.router.getCurrentNavigation().extras;
    if(a){
      if(a.state){
        this.fileUrl = a.state.GPP_URL;
        this.firstName = a.state.firstname
        this.AppID = a.state.AppID;
        this.state = a.state.state;
        localStorage.setItem('GPP_URL',this.fileUrl);
        localStorage.setItem('FirstName',this.firstName);
        localStorage.setItem('AppID',this.AppID);
        localStorage.setItem('state',this.state);
        console.log("file url",this.fileUrl);
      }
    }
    if(localStorage.getItem('GPP_URL')){
      this.fileUrl = localStorage.getItem('GPP_URL');
      this.firstName = localStorage.getItem('FirstName');
      this.AppID = localStorage.getItem('AppID');
      this.state = localStorage.getItem('state');
    }
    if(localStorage.getItem('AppID')){
      this.AppID = localStorage.getItem('AppID');
    }
    
  }


  ngOnInit() {
    // this.AppID = this.Route.snapshot.params.AppID;
    this.breadCrumbItems = [{ label: 'Dashboard', path: 'loan' }, { label: 'Applications', path: 'loan/post-mortgage-applications' },
    {label: `${this.firstName}`,
     path: `/loan/post-mortgage-dashboard/${this.AppID}`}
    ,{ label: 'View GPP', path: `/loan/view-gpp/${this.AppID}/${this.state}`} 
    ,{ label: 'View GPP Document', path: '/', active: true }];
    this.service.GetApplicationInformation(this.AppID).subscribe((Response) => {
     
        this.breadCrumbItems = [{ label: 'Dashboard', path: 'loan' }, { label: 'Applications', path: 'loan/post-mortgage-applications' },
        {label: `${Response.data[0].FirstName}` ,
         path: `/loan/post-mortgage-dashboard/${this.AppID}`}
        ,{ label: 'View GPP', path: `/loan/view-gpp/${this.AppID}/${this.state}`} 
        ,{ label: 'View GPP Document', path: '/', active: true }];
      
      });
  }
  ngOnDestroy(){
    localStorage.removeItem('GPP_URL');
  }

}
