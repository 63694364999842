import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { GeneralService } from 'src/app/services/general.service';
import Swal from 'sweetalert2';
import { FN_SubInput } from '../add-application/fn_subInput.validator';

@Component({
  selector: 'app-add-ipvr-documents',
  templateUrl: './add-ipvr-documents.component.html',
  styleUrls: ['./add-ipvr-documents.component.scss']
})
export class AddIpvrDocumentsComponent implements OnInit {
  breadCrumbItems: any;
  notfoundshow: boolean;
  isLoading: boolean;
  keyword = 'Area';
  areas: string[];
  propertyType: any;
  bankList: any;
  initialValue = '';
  loanTypes: any;
  currentUser: any;
  DistrictList: any;
  StateList: any[];
  baseDocType: any;
  fieldsData: any[];
  DataList: any = {};
  URL: string;
  dynamicFields = [];
  stateID: any;
  StateBaseDocType: any;
  propertyDetails: any;

  public document: FormGroup;
  submitted: boolean;
  DropDownID: string = null;
  DropDownValue: string = '';
  DropDownItemID: string;
  DropdownType: string = null;
  constructor(private service: GeneralService, private route: ActivatedRoute, private Fb: FormBuilder, private router: Router) {
    this.DropDownValue = this.route.snapshot.queryParamMap.get('DropDownValue');
    this.DropDownID = this.route.snapshot.queryParamMap.get('DropDownID');
    this.DropDownItemID = this.route.snapshot.queryParamMap.get('DropDownItemID');
    this.DropdownType = this.route.snapshot.queryParamMap.get('DropdownType');
    this.breadCrumbItems = [
    { label: 'Dashboard', path: 'loan' }, 
    { label: 'iPVR Documents', path: '/loan/iPVRDocuments' },
    { label: 'Add New iPVR Application', path: '/loan/addiPVRDocuments', active: true }];
    this.isLoading = false;
  }

  ngOnInit() {
    this.notfoundshow = false;
    this.submitted = false;
    this.currentUser = this.service.getcurrentUser();
    this.document = this.Fb.group({
      DropDownID: new FormControl(this.DropDownID, Validators.required),
      DropDownValue: new FormControl(this.DropDownValue, Validators.required),
      DropdownType: new FormControl(this.DropdownType, Validators.required),
      isMultiple: new FormControl(0, Validators.required),
    });
    if(this.DropdownType != null || this.DropdownType != undefined) {
      this.fetchstatelist();
    }
    
  }
  save() {
    this.submitted = true;
    if (this.document.valid) {
      this.isLoading = true;
      if(this.DropDownItemID != undefined && this.DropDownItemID != null) {
        const data = {
          DropDownItemID: this.DropDownItemID,
          DropDownValue: this.document.value.DropDownValue,
          isMultiple: this.document.value.isMultiple

        }
        this.service.UpdateiPVRDocuments(data).subscribe((res) => {
          this.isLoading = false;
          if (res.error) {
            Swal.fire({
              title: res.error_code,
              text: res.message,
              type: 'error',
              showConfirmButton: false,
              timer:3000
            });
            return;
          } else {
            Swal.fire({
              title: 'Success',
              text: 'Document Added Successfully',
              type: 'success',
              showConfirmButton: false,
              timer:3000
            }).then(() => {
              this.router.navigate(['/loan/iPVRDocuments'], { state: { DropDownID: this.document.value.DropDownID, DropdownType: this.document.value.DropdownType } });
            });
          }
          this.isLoading = false;
        });
      } else {
        this.service.AddiPVRDocuments(this.document.value).subscribe((res) => {
          this.isLoading = false;
          if (res.error) {
            Swal.fire({
              title: res.error_code,
              text: res.message,
              type: 'error',
              showConfirmButton: false,
              timer:3000
            });
            return;
          } else {
            Swal.fire({
              title: 'Success',
              text: 'Document Added Successfully',
              type: 'success',
              showConfirmButton: false,
              timer:3000
            }).then(() => {
              this.router.navigate(['/loan/iPVRDocuments'], { state: { DropDownID: this.document.controls.DropDownID.value,  } });
            });
          }
          this.isLoading = false;
        });
      }
      
    } else {
      console.log(this.document.value);
      Swal.fire({
        title: 'Invalid',
        text: 'Invalid Form Data',
        type: 'error',
        showConfirmButton: false,
        timer:3000
      });
    }
  }
  get f() { return this.document.controls; }

  fetchstatelist() {
    this.service.GetDropDownData (`?flag=1&flagforUR=${this.document.controls.DropdownType.value}`)
      .pipe(first())
      .subscribe(
        data => {
          if (data.error) {
            Swal.fire({
              title: data.error_code,
              text: data.message,
              type: 'error',
              showConfirmButton: false,
              timer:3000
            });
            return;
          } else {
            this.StateList = data.data;
          }
        });
  }
  changeDocumentType(e) {
    this.document.controls.DropDownID.setValue(null);
    if(e == null || e == undefined) {
      this.StateList = [];
    } else {
      this.fetchstatelist();
    }
  }
}
