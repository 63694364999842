import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { GeneralService } from 'src/app/services/general.service';
import { ViewResponseDialogComponent } from '../view-response-dialog/view-response-dialog.component';
class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
} 
@Component({
  selector: 'app-view-notice',
  templateUrl: './view-notice.component.html',
  styleUrls: ['./view-notice.component.scss']
})
export class ViewNoticeComponent implements OnInit {
  breadCrumbItems: any;
  show: boolean;
  dtOptions: DataTables.Settings = {};
  pastPublicNoticeForm: FormGroup;
  data: {
    firstname: string;
    district: string;
  };
  isImageUploaded: any = false;
  showInfo: boolean;
  isLoading: boolean;
  NoticeDetails: any;
  appID: string;
  applicationData: any;
  constructor(private service: GeneralService, private datepipe: DatePipe, private router: Router, private http: HttpClient, private Route: ActivatedRoute,  public dialog: MatDialog) {
    this.show = false;
    this.showInfo = false;
  }

  ngOnInit() {
    this.service.GetApplicationInformation(this.Route.snapshot.params.AppID).subscribe((res) => {
      this.isLoading = false;
      this.applicationData = res.data[0];
      this.breadCrumbItems = [
        { label: 'Dashboard', path: '/' }, 
        { label: 'Assignments', path: '/loan/assignment' }, 
        { label: `${this.applicationData.FirstName != null ? this.applicationData.FirstName : ''} ${ this.applicationData.LastName != null ? this.applicationData.LastName : ''}`, path: '/loan/assignment/' + this.Route.snapshot.params.AppID }, 
        {label: 'Public Notices', path: '/loan/viewpastpublicnotice/' + this.Route.snapshot.params.AppID}, { label: "View Response", path: "/loan/viewNotice/" +  this.Route.snapshot.params.AppID + '/' + this.Route.snapshot.params.NoticeID, active: true }];
    });
    this.pastPublicNoticeForm = new FormGroup({
      EntryDate: new FormControl({value: null, disabled: true}, Validators.required),
      Description: new FormControl({value: null, disabled: true}, Validators.required),
      PublishDate: new FormControl({value: null, disabled: true}, Validators.required),
      NoticeDays: new FormControl({value: null, disabled: true}, Validators.required),
      NewsPaper: new FormControl({value: null, disabled: true}, Validators.required),
      PublishedBy: new FormControl({value: null, disabled: true}, Validators.required),
      
    });
    this.isLoading = true;
    const param = `?Id=${this.Route.snapshot.params.NoticeID}`;
    this.service.GetNoticeDetails(this.Route.snapshot.params.AppID, param).subscribe((res) => {
      this.isLoading = false;
      this.NoticeDetails = res.data[0];
      const myArray = this.NoticeDetails.ImageUrl.split('.');
      this.pastPublicNoticeForm.patchValue(this.NoticeDetails);
      this.pastPublicNoticeForm.controls.EntryDate.setValue(this.datepipe.transform(this.NoticeDetails.EntryDate, 'yyyy-MM-dd'));
      this.pastPublicNoticeForm.controls.PublishDate.setValue(this.datepipe.transform(this.NoticeDetails.PublishDate, 'yyyy-MM-dd'));
      if(myArray[myArray.length -1] !== 'pdf') {
        this.isImageUploaded = true;
        console.log(this.NoticeDetails);
      }
    });
    this.dtOptions = {
      serverSide: false,
      ajax: (dataTablesParameters: any, callback) => {
        console.log(dataTablesParameters);
        this.http
          .get<DataTablesResponse>(
            this.service.GetBaseUrl() + `GetPublicNoticeResponse/` + this.Route.snapshot.params.NoticeID,
             {}
          ).subscribe((resp: any) => {
            console.log(dataTablesParameters);
            callback({
              recordsTotal: resp.data.length,
              recordsFiltered: resp.data.length,
              data: resp.data
            });
          });
      },
      responsive: true,
      // ajax: { url: this.service.GetBaseUrl() + `tcc/loan/application/Documents/AppID/${this.Route.snapshot.params.AppID}` }, responsive: true,
      columns: [{
        title: 'Sr No.', data: 'row', render: (data, type, row, meta) => {
          return meta.row + 1;
        }
      }, 
      {
        title: 'Entry Date',
        name: 'EntryDate',
        data: 'EntryDate',
        render: (data, type, row) => {
          return this.datepipe.transform(data, 'MMM dd,yyyy');
        }
      }, {
        title: 'Action',
        render: (data, type, row) => {
          return `<a target="_blank" class="viewnotice m-1 view" title="View Responses" ImageUrl="${row.ImageUrl}">
            <i class="mdi mdi-eye font-18 text-secondary" aria-hidden="false" ImageUrl="${row.ImageUrl}"></i>
          </a>`;
        },
      },{
        title: 'Comment',
        render: (data, type, row) => {
          return row.comment;
        },
      }
      ],
      drawCallback: () => {
        $('.view').on('click', (e) => {
          this.openRequestDocumentDialog($(e.target).attr('ImageUrl'));
        });
      },
    };
  }
  addResponse() {
    this.router.navigate(['/loan/addResponse/' +  this.Route.snapshot.params.AppID + '/' + this.Route.snapshot.params.NoticeID]);
  }
  openRequestDocumentDialog(url): void {
    const dialogRef = this.dialog.open(ViewResponseDialogComponent, {
      disableClose: false,
      width:'650px',
      autoFocus: false,
      maxHeight: '550px',
      data: {
        docurl: url
      }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
}
