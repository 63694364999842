import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralService } from 'src/app/services/general.service';
class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}  
@Component({
  selector: 'app-search-in-progress',
  templateUrl: './search-in-progress.component.html',
  styleUrls: ['./search-in-progress.component.scss']
})
export class SearchInProgressComponent implements OnInit {
  breadCrumbItems: any;
  currentUser: any;
  dtOptions: DataTables.Settings = {};
  LawyercountData: any = {
    TCNoticeCompleted: 0,
    TCNoticeInProgress: 0,
    TCDocumentCompleted: 0,
    TCDocumentInProgress: 0,
    TitleClearSent: 0,
    TitleClearInProgress: 0
  };
  isloading: boolean;
  totalAssignmentCount: any;
  constructor(private service: GeneralService, private datepipe: DatePipe, private http: HttpClient, private renderer: Renderer2, private router: Router) {
    this.breadCrumbItems = [{ label: 'Dashboard', path: 'loan' },
    { label: 'Title Clear in Progress', path: '/loan/search-in-progress', active: true }];
  }

  ngOnInit() {
    this.currentUser = this.service.getcurrentUser();
    this.service.getLawyerfilterCount('?LawyerID=' + this.currentUser.UserID).subscribe((res) => {
      this.totalAssignmentCount = res.data[0][0].TotalAssingApp;
      res.data[1].forEach(element => {
        this.LawyercountData[element.ApplicationStatus.replace(/\s/g, '')] = element.count;
      });
      console.log(this.LawyercountData);
      this.isloading = false;
    });
    this.dtOptions = {
      pagingType: "simple_numbers",
      pageLength: 10,
      processing: true,
      serverSide: true,
      order: [[0, 'desc']],
      ajax: (dataTablesParameters: any, callback) => {
        this.http
          .get<DataTablesResponse>(
            this.service.GetBaseUrl() + `loan/application/View/forLawyer` +
            `?LawyerID=${this.currentUser.UserID}` +
            `&ApplicationStatus='TitleClear In Progress','TC Document In Progress','TC Notice In Progress'` +
            `&pageNo=${((dataTablesParameters.start + dataTablesParameters.length) / dataTablesParameters.length)}` +
            `${dataTablesParameters.length.value != '' ? '&record=' + dataTablesParameters.length : ''}` +
            `${dataTablesParameters.search.value != '' ? '&searchkey=' + dataTablesParameters.search.value : ''}` +
            `${dataTablesParameters.order ? '&orderKey=' + dataTablesParameters.columns[dataTablesParameters.order[0].column].name : '&orderKey=AppID'}` +
            `${dataTablesParameters.order ? '&ordering=' + dataTablesParameters.order[0].dir : '&ordering=desc'}`,
            {}
          ).subscribe((resp: any) => {
            console.log(dataTablesParameters);
            callback({
              recordsTotal: resp.Count,
              recordsFiltered: resp.Count,
              data: resp.data
            });
          });
      },
      responsive: true,
      columns: [{
        title: 'App ID',
        name: 'AppID',
        data: '', render: (data, type, row) => {
          return `${row.AppID}`;
        }
      }, {
        title: 'Aplicant Name',
        name: 'AplicantName',
        data: '', render: (data, type, row) => {
          return `${row.ApplicationStatus === 'TitleClear In Progress' ? '<span class="badge badge-pink float-right">New</span>' : ''}${row.AplicantName}`;
        }
      }, {
        title: 'Loan',
        name: 'Type_of_Loan',
        data: 'Type_of_Loan'
      }, {
        title: 'Amount',
        name: 'LoanAmount',
        data: 'LoanAmount'
      }, {
        title: 'Status',
        name: 'ApplicationStatus',
        data: 'ApplicationStatus', render: (data) => {
          if (data === 'Pending') {
            return `<span class="badge badge-danger p-1">${data}</span>`;
          } else if (data === 'Title Clear Sent') {
            return `<span class="badge badge-success p-1">${data}</span>`;
          } else if (data) {
            return `<span class="badge badge-secondary p-1">${data}</span>`;
          } else {
            return data;
          }
        }
      }, {
        title: 'Action',
        name: 'Action',
        orderable: false,
        data: null, render: (data, type, row) => {
          if (row.ApplicationStatus === 'Title Clear Sent') {
            return `<a class="btn btn-xs btn-light" title="Continue Work"
            OpenID = "${row.AppID}"><i class="mdi mdi-pencil font-18 text-secondary" OpenID = "${row.AppID}" aria-hidden="false"></i></a>`;
          }
          else {
            // <a class="btn text-primary" title="Upload Documents"
            // UploadID = "${row.AppID}"><i class="mdi mdi-file-upload-outline font-18 text-secondary"
            // UploadID = "${row.AppID}" aria-hidden="false"></i></a>
            return `
          <a class="btn btn-xs btn-light" title="Continue Work"
          OpenID = "${row.AppID}"><i class="mdi mdi-pencil font-18 text-secondary" OpenID = "${row.AppID}" aria-hidden="false"></i></a>`;
          }
        }
      }, {
        title: 'Date',
        name: 'CreatedAt',
        data: 'CreatedAt',
        render: (data, type, row) => {
          return this.datepipe.transform(data, 'MMM dd,yyyy');
        }
      }, {
        title: 'Bank Name',
        name: 'BankName',
        data: 'BankName'
      }
      ],
      columnDefs: [{ targets: 0, visible: true }]
    };
  }
  ngAfterViewInit(): void {
    this.renderer.listen('document', 'click', (event) => {
      if (event.target.hasAttribute('UploadID')) {
        this.router.navigate(['/loan/uploadlawyerdocument/' + event.target.getAttribute('UploadID')]);
      }
      if (event.target.hasAttribute('OpenID')) {
        // this.router.navigate(['/loan/assignment/' + event.target.getAttribute('OpenID')]);
        this.router.navigate(['/loan/tcc-dashboard-lawyer/' + event.target.getAttribute('OpenID')]);
      }
    });
  }
}
