import { Router } from "@angular/router";
import { Observable, Subject, throwError } from "rxjs";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { Injectable, EventEmitter } from "@angular/core";
import { catchError, map, tap } from "rxjs/operators";
import { CookieService } from "../core/services/cookie.service";

const httpOptionsURLEncoded = {
  headers: new HttpHeaders().set(
    "Content-Type",
    "application/x-www-form-urlencoded"
  ),
};

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
    // "Access-Control-Allow-Origin": "https://hdfcbank.proplegit.com",
    // "Access-Control-Allow-Origin": "https://demo.proplegit.com",
    "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS, HEAD",
  }),
  //live
  // headers: new HttpHeaders({
  //   "Content-Type": "application/json",
  //   // "Access-Control-Allow-Origin": "https://hdfcbank.proplegit.com",
  //   "Access-Control-Allow-Origin": "https://hdfc.proplegit.com",
  //   "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS, HEAD",
  // }),
};

const httpFileUploadOptions = {
  headers: new HttpHeaders(),
};
// const baseurl = `https://hdfcltdapi.proplegit.com/` //live
// const baseurl = `https://hdfcapi.proplegit.com/` //live
// https://hdfcltdapi.proplegit.com/
// const baseurl = `https://demoapi.proplegit.com/`;
// const baseurl = `https://dev8.esmsys.com/`;
const baseurl = `https://qaapi.proplegit.com/`;
// const baseurl = `https://demoapi.proplegit.com`;
// const baseurl = `http://localhost:3000/`;
// const baseurl = "https://hdfcltdapi.proplegit.com/";
// const baseurl = "https://hdfcapi.proplegit.com/";
const PythonBaseURl = "https://qaai.proplegit.com/";
const SelURL = `https://qasel.proplegit.com/`;
const apiUrl = `${baseurl}api/`;
const dynamicURL = `https://qads.proplegit.com/api`;
const register = `${apiUrl}login/register`;
const generateOTP = `${apiUrl}generate/otp/`;
const validateOTP = `${apiUrl}validate/otp/`;
const login = `${apiUrl}login/verify`;
const stateWiseProperty = `${apiUrl}property/list/`;
const PropertyListByState = `${apiUrl}property/list/`;
const deleteProperty = `${apiUrl}property/delete/`;
const stateInfo = `${apiUrl}state/list`;
const count = `${apiUrl}property/dashboard/count`;
const forgotPassword = `${apiUrl}Forgot/Password`;
const loginOTPVerify = `${apiUrl}login/otp/verify`;
const propertyTaxType = `${apiUrl}Property/Tax/List/`;
const GetDocumentComment = `${apiUrl}GetDocumentComment/`;
const generateinvoice = `${apiUrl}Property/rent/generate/invoice/`;
const uploadInvoice = `${apiUrl}property/rent/Upload/invoice/`;
const generateReceipt = `${apiUrl}property/rent/generate/receipt/`;
const getLoanType = `${apiUrl}loan/application/types`;
const getLoanpropertyType = `${apiUrl}loan/Property/types`;
const addLoanApplication = `${apiUrl}loan/application/create`;
const addLoanApplication1 = `${apiUrl}loan/application/create1`;
const AddiPVRDocuments = `${apiUrl}add/dropdownitem`;
const deleteDocument = `${apiUrl}delete/dropdownitem`;
const UpdateiPVRDocuments = `${apiUrl}update/dropdownitem`;
const getApplicationInformation = `${apiUrl}loan/application/View/details/`;
const GetApplicationPublicNotices = `${apiUrl}GetPulicNotice/`;
const GetApplicationPublicNoticesRes = `${apiUrl}GetPulicNotice/res/`;
const GetTCCData = `${apiUrl}get/TCC/`;
const getApplicationInformationdisplay = `${apiUrl}loan/application/Viewloan/details/`;
const getMultiplePropertiesAppInfo = `${apiUrl}GetApplicationDetails/multiple/property/`
const getDocumentURL = `${SelURL}geturl/`;
const postRequestDocument = `${SelURL}postapi`;
const RequestDocument = `${apiUrl}askForDocument`;
const getSurveyList = `${SelURL}getapi`;
const getDocumentList = `${apiUrl}loan/application/Documents/AppID/`;
const GetTitleClearDocumentList = `${apiUrl}tcc/loan/application/Documents/AppID/`;
const getLawyerDocumentList = `${apiUrl}tcc/loan/application/Documents/AppID/`;
const savePVR = `${apiUrl}loan/pvr/createBy/`;
const savePVRRJ = `${apiUrl}loan/pvr/RJ/createBy/`
const getPVRData = `${apiUrl}loan/pvr/View/`;
const getPVRDataRJ = `${apiUrl}loan/pvr/RJ/View`;
const getPVRBankList = `${apiUrl}loan/bank/list`;
const UploadPVR = `${apiUrl}loan/pvr/Genrate/`;
const assignLawyer = `${apiUrl}loan/assign/lawyer/`;
const markasReviewed = `${apiUrl}loan/property/document/reviewed/`;
const addcomments = `${apiUrl}PostDocumentComment/`;
const updatecomments = `${apiUrl}UpdateDocumentComment/`;
const fordemo = `${apiUrl}loan/Property/all/Document/Upload/`;
const fordemochangestatus = `${apiUrl}loan/application/status/`;
const changeStatus = `${apiUrl}update/document/status/`;
const Dashboard = `${apiUrl}counts/bank`;
const Dashboard_1 = `${apiUrl}counts/admin`;
const LawyerfilterCount = `${apiUrl}count/lawyer/deshboard/`;
const getDocfilterCount = `${apiUrl}doc/count`;
const cancelContract = `${apiUrl}property/tenant/ContractCancel/`;
const propertyTaxAlert = `${apiUrl}propertyAlerts/tax/Property/`;
const propertyRentAlert = `${apiUrl}propertyAlerts/rent/Property/`;
const propertyCaseDetails = `${apiUrl}property/case/details/`;
const propertyCaseTypes = `${apiUrl}property/legalcase/types/list`;
const generatePVR = `${apiUrl}loan/pvr/Genrate/merge/`;
const generatePVRRJ = `${apiUrl}loan/pvr/Genrate/mergeRJ/`;
const CGGeneratePVR = `${apiUrl}loan/pvr/Genrate/mergeCG/`
const getallLoanID = `${apiUrl}loan/application/ALL/ID`;
const resendLoginOPT = `${apiUrl}single/generate/otp/`;
const excelExport = `${apiUrl}area/list/Distict/`;
const UserList = `${apiUrl}user/list/`;
const UpdateDocument = `${apiUrl}ismandatory/doc`;
const uploadLawyerHeaders = `${apiUrl}post/LawyerHeadersImage`;
const updateLawyerHeaders = `${apiUrl}update/LawyerHeadersImage/`;
const loanTypePVRStatus = `${apiUrl}loan/Dashboard/loantype/PVR/status/`;
const permissionWiseState = `${apiUrl}user/State/permission/`;
const GetNoticeDetails = `${apiUrl}GetPulicNotice/`;
const ProcessDocument = `${PythonBaseURl}upload`;
const iPVRDetailsRead = `${apiUrl}loan/pvr/details/extract/`;
const GetDropDownData = `${apiUrl}GetDropDownData`;
const getdropdownitem = `${apiUrl}dropdownitem`;
const getDropDownState = `${apiUrl}getdropdownstate/`;
const onClickIPVR = `${apiUrl}Addtbl_IPVR_StartDate/`;
const getLawyers = `${apiUrl}GetLawyerByUserType/`;
const getDocuments = `${apiUrl}getrecomended/document/list`;
const addnewLawyer = `${apiUrl}addLawyer`;
const startTitleClear = `${apiUrl}start/titleclear`;
const updateRequiredDocuments = `${apiUrl}add/required/document`;
const addPublicNotice = `${apiUrl}uploadpublicnotice`;
const addNoticeResponse = `${apiUrl}add/response/`;
const getRequestedDocument = `${apiUrl}requested/document/list`;
const getRequestedDocumentCount = `${apiUrl}count/requested/document/`;
const addDataToPublishNoticeAmount = `${apiUrl}addDataToPublishNoticeAmount`;
const updatePublishNoticeAmount = `${apiUrl}updatePublishNoticeAmount/`;
const getPublishNoticeAmountData = `${apiUrl}getPublishNoticeAmountData/`;
const getTitleCleardoc = `${apiUrl}get/TCC/`;
const updateAppStatus = `${apiUrl}update/application/status/`;
const newAssignmentCount = `${apiUrl}new/app/count`;
const getHeadersFooters = `${apiUrl}get/LawyerHeadersImage/`;
const addEcheckApplication = `${apiUrl}add/e_check/application`;
const addEcheckProperty = `${apiUrl}add/e_check/property`;
const propertyDocument = `${apiUrl}getlist/e_check/document`;
const addEcheckPropertyReport = `${apiUrl}add/e_check/result`
const getGPPList = `${apiUrl}/getlist/gpp`;
const addApplicationByImage = `${apiUrl}add/IPVR/AddApplicationByImage`
const addPropertyByID = `${apiUrl}add/property/byAppID/`
const getDocumentByID = `${apiUrl}GetAppication/Document/add/ByAppID/`
const getPOSData = `${apiUrl}POS/list`;
const updatePOSApplication = `${apiUrl}update/pos/application`
const POSApplication = `${apiUrl}add/pos/appliction`;
const mortgage = `${apiUrl}add/MandatoryCompliances`;
const uploadExcel = `${apiUrl}cg/xl`;
const addGPPAlert = `${apiUrl}add/gpp`
const checkGPPReq = `${apiUrl}check/gpp`;
const addGPPReq = `${apiUrl}req/gpp`;
const GPPAlertsList = `${apiUrl}gpp/req/application/list`
const getRights = `${apiUrl}user/rights/`;
const addModApplication = `${apiUrl}mod/add`;
const getModAppByID = `${apiUrl}mod/app/details/`;
const getPropertyExtraction = `${apiUrl}get/propertyExtrection/`
const BucketCount = `${apiUrl}bucket/count`
// const AddQuestions = `${apiUrl}add/questions`
const AddQuestions = `${apiUrl}add/TSR/question`;
const getQuestionsList = `${apiUrl}get/TSR/question`
const ActiveDeactiveQuestion = `${apiUrl}update/status/TSR/question`




const GPPDate: any = [
  {
    date: "24th Jan 2023",
    Period: "1st Jan - 31st Jan",
  },
  {
    date: "16th Feb 2023",
    Period: "1st Feb - 28th Feb",
  },
];
@Injectable({
  providedIn: "root",
})
export class GeneralService {
  private userID: number;
  private user;
  private otpID;
  // tslint:disable-next-line: variable-name
  private _refresh = new Subject<void>();
  ownerInfo = new EventEmitter<any>();

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private router: Router
  ) { }

  userRegister(userData): any {
    return this.http.post(register, userData, httpOptions);
  }
  GetBaseUrl() {
    return apiUrl;
  }
  getRequestedDocument(userId): any {
    return this.http.get(
      `${getRequestedDocument}?UserID=${userId}&record=10&pageNo=1`,
      httpOptions
    );
  }
  getRequestedDocumentCount(userId): any {
    return this.http.get(`${getRequestedDocumentCount}${userId}`, httpOptions);
  }
  getHeadersFooters(userId): any {
    return this.http.get(`${getHeadersFooters}${userId}`, httpOptions);
  }
  BucketCount(param){
    return this.http.get(`${BucketCount}`+ `${param}`,httpOptions)
  }
  getLawyers() {
    return getLawyers;
  }
  getDocuments() {
    return getDocuments;
  }
  getUserID() {
    return this.userID;
  }
  reFresh() {
    return this._refresh;
  }

  getDashboard(): any {
    return this.http.get(count, httpOptions);
  }
  UpdateDocument(data): any {
    return this.http.post(`${UpdateDocument}`, data, httpOptions);
  }
  uploadLawyerHeaders(data): any {
    return this.http.post(`${uploadLawyerHeaders}`, data);
  }
  updateLawyerHeaders(userid, data): any {
    return this.http.post(`${updateLawyerHeaders}${userid}`, data);
  }
  updateAppStatus(appID, data): any {
    return this.http.post(`${updateAppStatus}${appID}`, data, httpOptions);
  }
  setUserID(userid: number) {
    this.userID = userid;
  }
  setOTPID(otp: number) {
    this.otpID = otp;
  }
  getOTPID() {
    return this.otpID;
  }

  generateOTP(id, data): any {
    return this.http.post(`${generateOTP}${id}`, data, httpOptions);
  }
  onClickIPVR(data): any {
    return this.http.post(`${onClickIPVR}`, data, httpOptions);
  }
  validateOTP(data): any {
    return this.http.post(validateOTP, data, httpOptions);
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error("An error occurred:", error.error.message);
    } else {
      console.error(
        `Backend returned code ${error}, ` + `body was: ${error.error}`
      );
    }
    return throwError("Something bad happened; please try again later.");
  }

  login(data) {
    return this.http.post<any>(login, data, httpOptions);
    // .pipe(map(user => {
    // tslint:disable-next-line: triple-equals
    //   if (user.error && user.status != 200) {
    //     return user;
    //   } else {
    //     this.user = user;
    //     this.cookieService.setCookie('currentUser', JSON.stringify(user.data[0]), 1);
    //     return user;
    //   }
    // }));
  }
  loginOTPVerify(data) {
    return this.http.post<any>(loginOTPVerify, data, httpOptions).pipe(
      map((user) => {
        // tslint:disable-next-line: triple-equals
        if (user.error && user.status != 200) {
          return user;
        } else {
          this.user = user;
          this.cookieService.setCookie(
            "currentUser",
            JSON.stringify(user.data[0]),
            1
          );
          return user;
        }
      })
    );
  }
  addDataToPublishNoticeAmount(data) {
    return this.http.post(`${addDataToPublishNoticeAmount}`, data, httpOptions);
  }
  updatePublishNoticeAmount(data, id) {
    return this.http.post(
      `${updatePublishNoticeAmount}${id}`,
      data,
      httpOptions
    );
  }
  getPublishNoticeAmountData(id): any {
    return this.http.get(`${getPublishNoticeAmountData}${id}`, httpOptions);
  }
  generateTitleClear(data): any {
    return this.http.post(`${apiUrl}Genrate/TCC`, data, httpOptions);
  }
  saveCookie(data) {
    this.cookieService.setCookie("currentUser", JSON.stringify(data), 1);
  }

  logout() {
    this.cookieService.deleteCookie("currentUser");
    this.user = null;
  }
  getcurrentUser() {
    return JSON.parse(this.cookieService.getCookie("currentUser"));
  }

  getStateWisePropertyCount(userId): any {
    return this.http.get(stateWiseProperty + userId, httpOptions);
  }

  getPropertyListByState(id): any {
    return this.http.get(`${PropertyListByState}${id}`, httpOptions);
  }
  deleteProperty(id): any {
    return this.http.delete(`${deleteProperty}${id}`, httpOptions);
  }
  states() {
    return this.http.get<any>(stateInfo, httpOptions);
  }
  // districts(id): any {
  //   return this.http.get(`${apiUrl}district/list/${id}`, httpOptions);
  // }
  talukas(id): any {
    return this.http.get(`${apiUrl}taluka/list/${id}`, httpOptions);
  }
  villages(id): any {
    return this.http.get(`${apiUrl}village/list/${id}`, httpOptions);
  }
  addproperty(data): any {
    return this.http.post(`${apiUrl}property/add`, data, httpOptions);
  }
  propertytype(): any {
    return this.http.get(`${apiUrl}propertytype/list`, httpOptions);
  }
  area(search): any {
    return this.http.get(`${apiUrl}area/list/` + search, httpOptions);
  }
  areabyid(id): any {
    return this.http.get(`${apiUrl}area/view/${id}`);
  }
  Addphotograph(PropertyID, data): any {
    return this.http.post(
      `${apiUrl}property/${PropertyID}/document/add`,
      data,
      httpFileUploadOptions
    );
  }

  AddMultiplePropertyDocument(PropertyID, data, param): any {
    return this.http.post(
      `${apiUrl}loan/property/${PropertyID}/document/multiple/upload${param}`,
      data,
      httpFileUploadOptions
    );
  }

  listphotograph(PropertyID): any {
    return this.http
      .get<any>(`${apiUrl}property/${PropertyID}/photograph`)
      .pipe(
        map((responce) => {
          if (responce.error && responce.status !== 200) {
            return responce;
          } else {
            return responce.data;
          }
        })
      );
  }
  GetGPP() {
    return GPPDate;
  }
  GetAlert(id) {
    return this.http.get<any>(`${apiUrl}postMortgage/?id=${id}`, httpOptions);
  }
  viewproperty(propertyID) {
    return this.http.get<any>(
      `${apiUrl}property/view/${propertyID}`,
      httpOptions
    );
  }
  editproperty(propertyID, data) {
    return this.http.put<any>(
      `${apiUrl}property/update/${propertyID}`,
      data,
      httpOptions
    );
  }
  addtax(PropertyID, data): any {
    return this.http.post(
      `${apiUrl}property/${PropertyID}/tax/add`,
      data,
      httpFileUploadOptions
    );
  }
  addtaxconfirm(PropertyID, data): any {
    return this.http.post(
      `${apiUrl}property/${PropertyID}/tax/add?FileExistenceCheck=1`,
      data,
      httpFileUploadOptions
    );
  }
  newDocumentUpload(data, param?): any {
    return this.http.post(
      `${apiUrl}newDocumentUpload${param}`,
      data,
      httpFileUploadOptions
    );
  }
  listLawyers(): any {
    return this.http.get<any>(`${apiUrl}lawyer/list`, httpOptions);
  }
  deleteLawyer(LawyerId): any {
    return this.http.delete<any>(`${apiUrl}lawyer/delete/${LawyerId}`);
  }
  addLawyer(data): any {
    return this.http.post<any>(`${apiUrl}lawyer/add`, data, httpOptions);
  }
  viewLawyer(LawyerId) {
    return this.http.get<any>(`${apiUrl}lawyer/view/${LawyerId}`, httpOptions);
  }
  updateLawyer(data, LawyerId): any {
    return this.http.put<any>(
      `${apiUrl}lawyer/update/${LawyerId}`,
      data,
      httpOptions
    );
  }
  listTenant(id): any {
    return this.http.get<any>(`${apiUrl}property/${id}/rent/list`, httpOptions);
  }
  deleteTenant(PropertyRentId): any {
    return this.http.delete<any>(
      `${apiUrl}property/rent/delete/${PropertyRentId}`
    );
  }
  addTenant(data, id): any {
    return this.http.post<any>(
      `${apiUrl}property/${id}/tenant/add`,
      data,
      httpFileUploadOptions
    );
  }
  viewTenant(PropertyRentId) {
    return this.http.get<any>(
      `${apiUrl}property/rent/view/${PropertyRentId}`,
      httpOptions
    );
  }
  updateTenant(data, PropertyRentId): any {
    return this.http.put<any>(
      `${apiUrl}property/rent/update/${PropertyRentId}`,
      data,
      httpOptions
    );
  }
  fetchstatelist() {
    return this.http.get<any>(stateInfo, httpOptions);
  }
  areabystateid(StateId, Search, DistrictId?): any {
    if (DistrictId) {
      return this.http.get(
        `${apiUrl}area/list/state/${StateId}/district/area/${Search}?DistrictId=${DistrictId}`
      );
    } else {
      return this.http.get(
        `${apiUrl}area/list/state/${StateId}/district/area/${Search}`
      );
    }
  }

  uploadTaxReceipt(propertyid, taxid, data): any {
    return this.http.post(
      `${apiUrl}property/${propertyid}/tax/${taxid}/receipt/upload`,
      data,
      httpFileUploadOptions
    );
  }
  uploadRentReceipt(rentid, data): any {
    return this.http.post(
      `${apiUrl}property/rent/Upload/receipt/${rentid}`,
      data,
      httpFileUploadOptions
    );
  }
  getDocument(propertyid, id): any {
    return this.http.get(
      `${apiUrl}property/${propertyid}/Document/view/${id}`,
      httpOptions
    );
  }
  getTitleCleardoc(appid) {
    return this.http.get(`${getTitleCleardoc}${appid}`);
  }
  getDocument2(appID, id): any {
    return this.http.get(
      `${apiUrl}tcc/loan/application/Documents/AppID/${appID}/${id}`,
      httpOptions
    );
  }
  getTCDocumentsLawyer(id){
    return this.http.get(`${apiUrl}tcc/loan/application/Documents/AppID/${id}`,httpOptions);
  }
  forgotPassword(data): any {
    return this.http.post(`${forgotPassword}`, data, httpOptions);
  }
  submitForgotPassword(data): any {
    return this.http.put(`${forgotPassword}/update`, data, httpOptions);
  }
  getpropertytaxtypeList(id): any {
    return this.http.get(`${propertyTaxType}${id}`, httpOptions);
  }
  GetDocumentComment(id): any {
    return this.http.get(`${GetDocumentComment}${id}`, httpOptions);
  }
  getRentList(id): any {
    return this.http.get(`${apiUrl}property/rent/view/${id}`, httpOptions);
  }
  GenerateInvoice(id, userId): any {
    return this.http.post(
      `${generateinvoice}${id}`,
      { CreatedBy: userId },
      httpOptions
    );
  }
  UploadInvoice(PropertyRentID, data): any {
    return this.http.post(
      `${uploadInvoice}${PropertyRentID}`,
      data,
      httpFileUploadOptions
    );
  }
  UploadInvoiceConfirm(PropertyRentID, data): any {
    return this.http.post(
      `${uploadInvoice}${PropertyRentID}?FileExistenceCheck=0`,
      data,
      httpFileUploadOptions
    );
  }
  GenerateReceipt(id, data): any {
    return this.http.post(`${generateReceipt}${id}`, data, httpOptions);
  }
  addLegalCase(PropertyID, data) {
    return this.http.post<any>(
      `${apiUrl}property/${PropertyID}/case/add`,
      data,
      httpOptions
    );
  }
  listLegalcase(id): any {
    return this.http.get<any>(`${apiUrl}property/${id}/case/list`, httpOptions);
  }
  GetLoanTypes() {
    return this.http.get<any>(`${getLoanType}`, httpOptions);
  }
  GetLoanPropertyTypes() {
    return this.http.get<any>(`${getLoanpropertyType}`, httpOptions);
  }
  AddLoanApplication(data) {
    return this.http.post<any>(`${addLoanApplication1}`, data, httpOptions);
  }
  AddiPVRDocuments(data) {
    return this.http.post<any>(`${AddiPVRDocuments}`, data, httpOptions);
  }
  deleteDocument(id) {
    return this.http.post<any>(
      `${deleteDocument}?DropDownItemID=${id}`,
      null,
      httpOptions
    );
  }
  UpdateiPVRDocuments(data) {
    return this.http.post<any>(`${UpdateiPVRDocuments}`, data, httpOptions);
  }
  GetApplicationInformation(id) {
    return this.http.get<any>(`${getApplicationInformation}${id}`, httpOptions);
  }
  GetApplicationPublicNotices(id) {
    return this.http.get<any>(
      `${GetApplicationPublicNotices}${id}`,
      httpOptions
    );
  }
  GetApplicationPublicNoticesRes(id) {
    return this.http.get<any>(
      `${GetApplicationPublicNoticesRes}${id}`,
      httpOptions
    );
  }
  GetTCCData(id) {
    return this.http.get<any>(`${GetTCCData}${id}`, httpOptions);
  }
  GetDocumentURL(stateid, doc): any {
    return this.http.get<any>(
      `${getDocumentURL}${stateid}/${doc}`,
      httpOptions
    );
  }
  getSurveyList(id): any {
    return this.http.get<any>(`${getSurveyList}/${id}`, httpOptions);
  }
  downloadDocument(url, data): any {
    return this.http.post<any>(`${url}`, data);
  }
  postRequestDocument(data): any {
    return this.http.post(`${postRequestDocument}`, data);
  }
  RequestDocument(data): any {
    return this.http.post(`${RequestDocument}`, data);
  }
  getApplicationInformationdisplay(id) {
    return this.http.get<any>(
      `${getApplicationInformationdisplay}${id}`,
      httpOptions
    );
  }
  getMultiplePropertyInfo(AppID:string){

    return this.http.get(`${getMultiplePropertiesAppInfo}`+AppID);

  }
  GetDocumentList(id): any {
    return this.http.get<any>(`${getDocumentList}${id}`, httpOptions);
  }
  GetTitleClearDocumentList(id): any {
    return this.http.get<any>(`${GetTitleClearDocumentList}${id}`, httpOptions);
  }
  GetLawyerDocumentList(id): any {
    return this.http.get<any>(`${getLawyerDocumentList}${id}`, httpOptions);
  }
  SavePVR(data, id) {
    return this.http.post<any>(`${savePVR}${id}`, data, httpOptions);
  }
  SavePVRRJ(data, id) {
    return this.http.post<any>(`${savePVRRJ}${id}`, data, httpOptions);
  }
  GetPVRData(id): any {
    return this.http.get<any>(`${getPVRData}${id}`, httpOptions);
  }
  GeneratePVR(appid): any {
    return this.http.get<any>(`${generatePVR}${appid}`, httpOptions);
  }
  GeneratePVRRJ(appid): any {
    return this.http.get<any>(`${generatePVRRJ}${appid}`, httpOptions);
  }
  CGGeneratePVR(appid): any {
    return this.http.get<any>(`${CGGeneratePVR}${appid}`, httpOptions);
  }
  GetPVRBankList(): any {
    return this.http.get<any>(`${getPVRBankList}`, httpOptions);
  }
  UploadPVR(Appid, data): any {
    return this.http.post(`${UploadPVR}${Appid}`, data, httpFileUploadOptions);
  }
  AssignLawyer(AppId, LawyerID): any {
    return this.http.put(
      `${assignLawyer}${AppId}/${LawyerID}`,
      null,
      httpOptions
    );
  }
  MarkAsReviewed(PropertyId, DocumentId): any {
    return this.http.put(
      `${markasReviewed}${PropertyId}/${DocumentId}`,
      null,
      httpOptions
    );
  }
  addcomments(data): any {
    return this.http.post(`${addcomments}`, data, httpOptions);
  }
  updatecomments(data, id): any {
    return this.http.post(`${updatecomments}${id}`, data, httpOptions);
  }
  ForDemo(AppID): any {
    return this.http.get(`${fordemo}${AppID}`, httpOptions);
  }
  changeDocStatus(docid): any {
    return this.http.get(`${changeStatus}${docid}`, httpOptions);
  }
  newAssignmentCount(param): any {
    return this.http.get(`${newAssignmentCount}${param}`, httpOptions);
  }
  getLoanDashboard(param): any {
    return this.http.get(Dashboard + param, httpOptions);
  }
  getLoanDashboard_1(param): any {
    return this.http.get(Dashboard_1 + param, httpOptions);
  }
  getLawyerfilterCount(data): any {
    return this.http.get(`${LawyerfilterCount}${data}`, httpOptions);
  }
  getDocfilterCount(data): any {
    return this.http.get(`${getDocfilterCount}${data}`, httpOptions);
  }
  changeStatus(appID, status): any {
    return this.http.put(
      fordemochangestatus + appID,
      { ApplicationStatus: status },
      httpOptions
    );
  }
  CancelRentContract(tenantId): any {
    return this.http.put(`${cancelContract}${tenantId}`, null, httpOptions);
  }
  GetPropertyTaxAlert(PropertyID): any {
    return this.http.get(`${propertyTaxAlert}${PropertyID}`, httpOptions);
  }
  GetPropertyRentAlert(PropertyID): any {
    return this.http.get(`${propertyRentAlert}${PropertyID}`, httpOptions);
  }
  GetPropertyCaseDetails(caseID): any {
    return this.http.get(`${propertyCaseDetails}${caseID}`, httpOptions);
  }
  GetPropertyLegalCaseTypes(): any {
    return this.http.get(propertyCaseTypes, httpOptions);
  }
  AddLegalCaseAct(CaseID, data): any {
    return this.http.post(
      `${apiUrl}property/case/${CaseID}/CaseActs`,
      data,
      httpOptions
    );
  }
  GetLegalCaseLastHearing(CaseID): any {
    return this.http.get(
      `${apiUrl}property/case/${CaseID}/last-hearing`,
      httpOptions
    );
  }
  AddLegalCaseHearing(CaseID, data): any {
    return this.http.post(
      `${apiUrl}property/case/${CaseID}/next-hearing`,
      data,
      httpOptions
    );
  }
  AddPetitionerAndLawyer(CaseID, data): any {
    return this.http.post(
      `${apiUrl}property/case/${CaseID}/petitioner`,
      data,
      httpOptions
    );
  }
  AddRespondentAndLawyer(CaseID, data): any {
    return this.http.post(
      `${apiUrl}property/case/${CaseID}/respondent`,
      data,
      httpOptions
    );
  }
  GetPetitionerAndLawyer(CaseID): any {
    return this.http.get(
      `${apiUrl}property/case/${CaseID}/petitionersandlawyer`,
      httpOptions
    );
  }
  GetRespondentAndLawyer(CaseID): any {
    return this.http.get(
      `${apiUrl}property/case/${CaseID}/respondentsandlawyer`,
      httpOptions
    );
  }
  GetAllLoanID(
    StateName,
    DistrictName,
    TalukaName,
    VillageName,
    BankName,
    LoanType,
    LoanPropertyType,
    HobliName
  ): any {
    return this.http.post(
      `${getallLoanID}`,
      {
        StateName,
        DistrictName,
        TalukaName,
        VillageName,
        BankName,
        LoanType,
        LoanPropertyType,
        HobliName,
      },
      httpOptions
    );
  }
  ChangeECResponce(CaseID): any {
    return this.http.put(
      `${apiUrl}loan/application/ECRequest/${CaseID}`,
      null,
      httpOptions
    );
  }
  ResendLoginOTP(UserID): any {
    return this.http.post(`${resendLoginOPT}${UserID}`, null, httpOptions);
  }
  GetExcelExportData(StateID): any {
    return this.http.get(`${excelExport}${StateID}`, httpOptions);
  }
  GetUser(UserID): any {
    return this.http.get(`${UserList}${UserID}`, httpOptions);
  }
  GetLoanTypePVRStatus(UserID): any {
    return this.http.get(`${loanTypePVRStatus}${UserID}`, httpOptions);
  }
  GetStatePermissionWise(UserID?): any {
      return this.http.get(`${permissionWiseState}${UserID}`, httpOptions);
  }
  GetNoticeDetails(appid, param): any {
    return this.http.get(`${GetNoticeDetails}${appid}${param}`, httpOptions);
  }
  addnewLawyer(data): any {
    return this.http.post(`${addnewLawyer}`, data, httpOptions);
  }
  addPublicNotice(data): any {
    return this.http.post(`${addPublicNotice}`, data, httpFileUploadOptions);
  }
  addNoticeResponse(data, id): any {
    return this.http.post(
      `${addNoticeResponse}${id}`,
      data,
      httpFileUploadOptions
    );
  }
  startTitleClear(data): any {
    return this.http.post(`${startTitleClear}`, data, httpOptions);
  }
  updateRequiredDocuments(data): any {
    return this.http.post(`${updateRequiredDocuments}`, data, httpOptions);
  }
  ProcessDocumentUpload(data): any {
    console.log(data);
    // return this.http.post<any>(`${ProcessDocument}`, data);
    return new Promise((resolve, reject) => {
      this.http.post<any>(`${ProcessDocument}`, data).subscribe((res: any) => {
        resolve(res);
      }),
        (err: any) => {
          console.log(err);
          reject(err);
        };
    });
  }
  GetDropDownData(param): any {
    return this.http.get(`${GetDropDownData}${param}`, httpOptions);
  }
  getdropdownitem(): any {
    return getdropdownitem;
  }
  GetiPVRDetailsRead(AppID): any {
    return this.http.get(`${iPVRDetailsRead}${AppID}`, httpOptions);
  }
  // uploadDoc(body): any {
  //   return this.http.post<any>(`${ProcessDocument}`, body);
  // }
  getDropDownState(id): any {
    return this.http.get<any>(`${getDropDownState}${id}`, httpOptions);
  }
  getDynamicFields(data) {
    return this.http.post(
      `${dynamicURL}/Dynamicdata`,
      data,
      httpOptionsURLEncoded
    );
  }
  getStateBaseDocTypeByStateID(StateID) {
    return this.http.get(
      `${dynamicURL}/statebasedoctype/list/${StateID}`,
      httpOptions
    );
  }
  dynamicData(URL): any {
    return this.http.get(`${URL}`, httpOptions);
  }
  districts(id): any {
    return this.http.get(`${dynamicURL}district/list/${id}`, httpOptions);
  }
  statesList(id: any) {
    return this.http.get<any>(
      `${baseurl}api/user/state/list/${id}`,
      httpOptions
    );
  }
  Statelist() {
    return this.http.get<any>(
      `${baseurl}api/state/list`,
      httpOptions
    );
  }

  addEcheckApplication(data: any) {
    return this.http.post(`${addEcheckApplication}`, data);
  }
  addEcheckProperty(data: any) {
    return this.http.post(`${addEcheckProperty}`, data);
  }

  getPropertyDocumentList(AppID: any, PropertyID) {
    return this.http.get(`${propertyDocument}?AppID=${AppID}&PropertyID=${PropertyID}`)
  }
  getGPPList() {
    return getGPPList;
  }
  addEcheckPropertyReport(data: any) {
    return this.http.post(`${addEcheckPropertyReport}`, data);
  }
  addApplicationByImage(data: any) {
    return this.http.post(`${addApplicationByImage}`, data);
  }
  addPropertyByID(appID, data: any) {
    return this.http.post(`${addPropertyByID}${appID}`, data);
  }
  getDocumentByID(AppID: any) {
    return this.http.get(`${getDocumentByID}${AppID}`);
  }
  getPOSData() {
    return this.http.get(`${getPOSData}`)
  }

  updatePOSApplication(data: any) {
    return this.http.post(`${updatePOSApplication}`, data);
  }
  POSApplication(data: any) {
    return this.http.post(`${POSApplication}`, data)
  }

  userMortgage(data) {
    return this.http.post(`${mortgage}`, data, httpOptions);
  }
  uploadBucketExcel(url, data) {
    const uploadExcel = `${apiUrl}${url}`;
    console.log("url", uploadExcel);
    // return this.http.post(`${uploadExcel}`,data);
    return this.http.post(`${uploadExcel}`, data);
  }
  uploadExcel(url, data) {
    const uploadExcel = `${apiUrl}${url}`;
    console.log("url", uploadExcel);
    // return this.http.post(`${uploadExcel}`,data);
    return this.http.post(`${uploadExcel}`, data);
  }
  addGPPAlert(data) {
    return this.http.post(`${addGPPAlert}`, data);
  }
  CheckGppReq(AppID) {
    return this.http.get(`${checkGPPReq}/` + AppID);
  }
  AddGppReq(data){
    return this.http.post(`${addGPPReq}`,data);
  }

  GPPAlertsList(AppID) {
    return this.http.get(`${GPPAlertsList}/` + AppID);
  }
  getRights(userId){
    return this.http.get(`${getRights}`+userId);
  }
  addModApplication(data){
    return this.http.post(`${addModApplication}`,data);
  }
  AddQuestions(data){
    return this.http.post(`${AddQuestions}`,data);
  }
  getModApplicationByID(Appid){
    return this.http.get(`${getModAppByID}`+ Appid)
  }
  getPropertyExtraction(Appid){
    return this.http.get(`${getPropertyExtraction}`+ Appid)
  }
  getQuestionsList(){
    return this.http.get(`${getQuestionsList}`);
  }
  ActiveDeactiveQuestion(data){
    return this.http.post(`${ActiveDeactiveQuestion}`,data);
  }
}
