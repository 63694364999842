import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { GeneralService } from 'src/app/services/general.service';
import Swal from 'sweetalert2';
import { FN_SubInput } from './fn_subInput.validator';

@Component({
  selector: 'app-add-properties-for-mod',
  templateUrl: './add-properties-for-mod.component.html',
  styleUrls: ['./add-properties-for-mod.component.scss']
})
export class AddPropertiesForModComponent implements OnInit {

  breadCrumbItems: any;
  fieldsData: any[];
  DataList: any = {};
  dynamicFields = [];
  stateID: any;
  StateBaseDocType: any;
  isLoading: boolean;
  StateList: [];
  currentUser: any;
  data: any = { fieldsData: [], DataLists: [] };
  submitted: boolean;
  AppID:string;
  form: FormGroup;
  URL: string;
  baseDocType: any;
  responseData:any;
  constructor(private fb: FormBuilder, private service: GeneralService, private router:Router) { 
    let a = this.router.getCurrentNavigation().extras;
    if(a){
      if(a.state){
        this.AppID = a.state.AppID;
        localStorage.setItem('AppID',this.AppID);
      }
    }
    if(localStorage.getItem('AppID')){
      this.AppID = localStorage.getItem('AppID');
    }
  }

  ngOnInit() {
    this.currentUser = this.service.getcurrentUser();
    this.form = this.fb.group({
      StateID: [null, Validators.required],
      PropertyType: new FormControl(null, Validators.required),
      BaseDocumentType: new FormControl(null, Validators.required),
      Properties: this.fb.array([
        // this.initproperty()
      ]),
    }
    )
    this.breadCrumbItems = [
      { label: 'Dashboard', path: '/dashboard' },
      { label: 'Applications', path: '/applications ', active: true },];
    this.fetchstatelist();
    this.getModApplication();
  }

  getModApplication(){
    // 256573
    this.service.getModApplicationByID(this.AppID).subscribe((res:any)=>{
      console.log('response',res);
      this.responseData = res.data[0];
    })
  }
 
  fetchstatelist() {
    this.isLoading = true;
    this.service.Statelist()
      .pipe(first())
      .subscribe(
        (data : any) => {
          if (data.error) {
            this.isLoading = false;
            Swal.fire({
              title: data.error_code,
              text: data.message,
              type: 'error',
              showConfirmButton: false,
              timer: 3000
            });
            return;
          } else {
            this.isLoading = false;
            this.StateList = data.data;
            // console.log("statelist", this.StateList);
          }
        });
    // this.f.DistrictID.disable();
  }
  ChangeState(e) {
    this.submitted = false;
    this.getStateBaseDocTypeByStateID(e);
    this.stateID = e;
    this.form.controls.BaseDocumentType.setValue(null);
    // this.dynamicFields.forEach(element => {
    //   this.loan.removeControl(element);
    // });
    for(let i = 0; i< this.f.Properties['controls'].length ;i++ ){
      const control = this.f.Properties as FormArray;
      control.removeAt(i);
      this.data = { fieldsData: [], DataLists: [] }
    }

    this.fieldsData = [];
  }

  getStateBaseDocTypeByStateID(id) {
    this.isLoading = true;
    if(id){
      this.service.getStateBaseDocTypeByStateID(id).subscribe((res: any) => {
        // if (this.propertyDetails != undefined) {
        //   this.loan.controls.BaseDocumentType.setValue(Number(this.propertyDetails.BaseDocumentType));
          // this.ChangeBaseDocType(this.BaseDocumentType);
        // }
        this.isLoading = false;
        this.StateBaseDocType = res.data;
      });
    }
   
  }
  ChangeBaseDocType(data) {

    this.baseDocType = data;
    // this.areas = [];
    if (this.StateBaseDocType != undefined) {
      this.form.controls.PropertyType.setValue(this.StateBaseDocType.find(x => x.ID === data).Name);
    }
    let ele = this.fb.group({});
    if (data !== undefined) {
      let param = new URLSearchParams();
      param.set('StateID', this.stateID);
      param.set('Service_subtype_id', '1');
      param.set('StateBaseDocTypeID', this.baseDocType);
      param.set('DisplayLevel', '1');
      this.isLoading = true;
      for(let i = 0; i< this.f.Properties['controls'].length ;i++ ){
        const control = this.f.Properties as FormArray;
        control.removeAt(i);
        this.data = { fieldsData: [], DataLists: [] }
      }
      this.service.getDynamicFields(param.toString()).subscribe((res: any) => {
        this.isLoading = false;
        this.data['fieldsData'].push(res.data);
        this.fieldsData = res.data;
        this.fieldsData.forEach((element, index) => {
          this.dynamicFields.push(element.DBColumnName);
          if (element.FieldType === 'Text' && element.ValidationFunctionName != '') {
            ele.addControl(`${element.DBColumnName}`, this.fb.control(null, this[element.ValidationFunctionName]()));
          } else if ((element.FieldType === 'Dropdown' && element.APIName != '' && element.IsDependantField != true)) {
            ele.addControl(`${element.DBColumnName}`, this.fb.control(null));
            this.URL = element.APIName + '/' + this.stateID;
            this.isLoading = true;
            this.service.dynamicData(this.URL).pipe(first()).subscribe((data) => {
              this.URL = '';
              this.isLoading = false;
              if (data.error) {
                Swal.fire({
                  title: data.error_code,
                  text: data.message,
                  type: 'error',
                  showConfirmButton: false,
                  timer: 3000
                });
                return;
              } else {
                if (data.data) {
                  this.data['DataLists'].push({[element.DBColumnName]:data.data});
                 
                }
              }
            });
          
          } 
          else {
            ele.addControl(`${element.DBColumnName}`, this.fb.control(null));
          }
        });
        
        if (this.data['fieldsData']) {
          this.users.push(ele);
        }
      });

    } else {
      // this.DistrictList = [];
    }

  

  }
  get users() {
    return this.form.get('Properties') as FormArray;
  }


  onChangesDynamicField(fieldIndex,i, id,DBColumnName) {
    if (DBColumnName === 'DistrictID') {
      this.data['DataLists'][i]['TalukaID'] = [];
      this.data['DataLists'][i]['VillageID'] = [];
      this.data['DataLists'][i]['CitySurveyOffice'] = [];
      this.data['DataLists'][i]['CityWardNo'] = [];
      this.data['DataLists'][i]['HobliID'] = [];
      this.resetControlValue(this.form, i, 'TalukaID');
      this.resetControlValue(this.form, i, 'VillageID');
      this.resetControlValue(this.form, i, 'CitySurveyOffice');
      this.resetControlValue(this.form, i, 'CityWardNo');
      this.resetControlValue(this.form, i, 'HobliID');
    }
    if(DBColumnName === 'CitySurveyOffice'){
      this.data['DataLists'][i]['CityWardNo'] = [];
      this.resetControlValue(this.form, i, 'CityWardNo');
    }
    if (DBColumnName === 'TalukaID') {
      this.data['DataLists'][i]['VillageID'] = [];
      this.data['DataLists'][i]['HobliID'] = [];
      this.resetControlValue(this.form, i, 'VillageID');
      this.resetControlValue(this.form, i, 'HobliID');
    }
    if(DBColumnName === 'HobliID'){
      this.data['DataLists'][i]['VillageID'] = [];
      this.resetControlValue(this.form, i, 'VillageID');
    }
    // this.clearFields(index);
    if (id !== undefined) {
      this.fieldsData.forEach(element => {
        if (this.fieldsData[fieldIndex].DependentFieldName === element.FieldName ) {
        if (element.FieldType === 'Dropdown' && element.APIName !== '') {
          this.URL = element.APIName + '/' + id;
          if (this.URL != '') {
            this.isLoading = true;
            this.service.dynamicData(this.URL).pipe(first()).subscribe((data) => {
              this.URL = '';
              this.isLoading = false;
                if (data.error) {
                  Swal.fire({
                    title: data.error_code,
                    text: data.message,
                    type: 'error',
                    showConfirmButton: false,
                    timer: 3000
                  });
                  return;
                } else {
                  // if (element.DBColumnName !== 'DistrictID') {
                    this.data['DataLists'][i][element.DBColumnName] = data.data;
                  // }
                  ;
              }                
            });
          }
          }

        }
      });
      console.log('this.datalist', this.data['DataLists'])
    }

  }
  FN_SubInput() {
    return FN_SubInput();
  } 
  resetControlValue(formGroup: FormGroup, i: number, controlName: string) {
    if (formGroup instanceof FormGroup && formGroup.controls.Properties['controls'][i]['controls'].hasOwnProperty(controlName)) {
      formGroup.controls.Properties['controls'][i]['controls'][controlName].setValue(null);
    }
  }
 
  checkValidationForProperties(){
    if(this.form.controls['Properties'].value.length){
      for(let i=0;i<this.form.controls['Properties'].value.length;i++){
        for(var control in this.form.controls['Properties']['controls'][i].value){
          if(this.form.controls['Properties']['controls'][i].value[control]){
            let a = (typeof (this.form.controls['Properties']['controls'][i].value[control]) === 'string') ? this.form.controls['Properties']['controls'][i].value[control].trim() : this.form.controls['Properties']['controls'][i][control].value;
            this.form.controls['Properties']['controls'][i].controls[control].setValue(a);
          }
        }
      }
    }
  }

  addAnotherProperty(i) {
    if(this.data['DataLists'][0]['DistrictID']){
      this.data['DataLists'].push({'DistrictID' : this.data['DataLists'][0]['DistrictID']});
    }
    let ele = this.fb.group({});
    this.fieldsData.forEach((element, index) => {
      this.dynamicFields.push(element.DBColumnName);
      if (element.FieldType === 'Text' && element.ValidationFunctionName != '') {
        ele.addControl(`${element.DBColumnName}`, this.fb.control(null, this[element.ValidationFunctionName]()));
      } else if ((element.FieldType === 'Dropdown' && element.APIName != '' && element.IsDependantField != true)) {
        ele.addControl(`${element.DBColumnName}`, this.fb.control(null));
        this.URL = element.APIName + '/' + this.stateID;
        this.isLoading = true;
        
        this.service.dynamicData(this.URL).pipe(first()).subscribe((data) => {
          this.URL = '';
          this.isLoading = false;
          if (data.error) {
            Swal.fire({
              title: data.error_code,
              text: data.message,
              type: 'error',
              showConfirmButton: false,
              timer: 3000
            });
            return;
          } else {
            if(element.DBColumnName !== 'DistrictID'){
              this.data['DataLists'][i][element.DBColumnName] = data.data;
            }
          }
        });
      // } else if(element.FieldType === 'Dropdown' && element.APIName != '' && element.IsDependantField == true){
      //   ele.addControl(`${element.DBColumnName}`, this.fb.control(null));
      }
      else {
        ele.addControl(`${element.DBColumnName}`, this.fb.control(null));
      }
    });
    if (this.data['fieldsData'].length) {
      this.users.push(ele);
    }

  }
  get f() { return this.form.controls; }

  removeProperty(index){
    this.dynamicFields.forEach(element => {
      if(this.data.DataLists[index][element] === element){
        this.data.DataLists[index][element] = {};
      }
    });
    // this.data.DataList[index] = [];
    const control = this.f.Properties as FormArray;
    control.removeAt(index);
  }

  save() {
    this.isLoading = true;
    this.checkValidationForProperties()
    this.submitted = true;
    if (this.form.valid) {
      // this.f.control.value.trim().length === 0;
      let data = {UserID: this.currentUser.UserID}
      this.form.removeControl(`BaseDocumentType`); 
      let formData = Object.assign(this.form.value,data);
      // let data = {}
      this.isLoading = true;
      this.service.addPropertyByID(this.AppID,formData).subscribe((res:any) => {
        if (res.error) {
          this.submitted = false;
          this.isLoading = false;
          Swal.fire({
            title: res.error_code,
            text: res.message,
            type: 'error',
            showConfirmButton: false,
            timer: 3000
          });
          return;
        }
        else {
          this.submitted = false;
          this.isLoading = false;
          Swal.fire({
            title: 'Success',
            text: 'Property Added Successfully',
            type: 'success',
            showConfirmButton: false,
            timer: 3000
          }).then(() => {
            this.router.navigate(['/loan/applications']);
          });
        }
        // this.isLoading = false;
      });
    } else {
      this.isLoading = false;
      
      Swal.fire({
        title: 'Invalid',
        text: 'Invalid Form Data',
        type: 'error',
        showConfirmButton: false,
        timer: 3000
      });
     
    }
  }

}
