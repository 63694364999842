import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { CookieService } from 'src/app/core/services/cookie.service';
import { GeneralService } from 'src/app/services/general.service';
import Swal from 'sweetalert2';
class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}
@Component({
  selector: 'app-requested-document-list',
  templateUrl: './requested-document-list.component.html',
  styleUrls: ['./requested-document-list.component.scss']
})
export class RequestedDocumentListComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtTrigger = new Subject<any>();
  user: any;
  breadCrumbItems: ({ label: string; path: string; active?: undefined; } | { label: string; path: string; active: boolean; })[];
  AppID: any;
  LawyerID: any;
  DocumentList: any;
  myGroup: FormGroup;
  documentID: any = [];
  isLoading: boolean;
  StateList: any=[];
  isShow: boolean;
  constructor(
    private service: GeneralService,
    private router: Router,
    private http: HttpClient,
    private cookie: CookieService
  ) {}
  ngOnInit() {
    this.user = this.service.getcurrentUser();
    this.breadCrumbItems = [
      { label: 'Dashboard', path: '/' },
      { label: 'All Requested Documents', path: '/loan/requested-documents', active: true }];
      let id; 
      if(this.user.UserType == 'Proplegit') {
        id = 0;
      } else {
        id = this.user.UserID;
      }
    if(this.user.UserType == 'Lawyer') {
      this.dtOptions = {
        pagingType: "simple_numbers",
        pageLength: 10,
        ordering: false,
        serverSide: true,
        ajax: (dataTablesParameters: any, callback) => {
          this.http
            .get<DataTablesResponse>(
              `${this.service.GetBaseUrl()}requested/document/list/lawyer?UserID=${id}&record=${dataTablesParameters.length}&pageNo=${(( dataTablesParameters.start + dataTablesParameters.length)/ dataTablesParameters.length)}${dataTablesParameters.search.value != '' ? '&searchKey=' + dataTablesParameters.search.value : ''}`,{}
            ).subscribe((resp: any) => {
              console.log('resp',resp);
              callback({
                recordsTotal: resp.count,
                recordsFiltered: resp.count,
                data: resp.data,
              });
            });
        },
        responsive: true,
        columns: [
          {
            title: 'Sr.No.',
            data: null,
            render: (data, type, row, meta) => {
              return meta.row + 1;
            },
          },
          {
            title: 'App ID',
            data: null,
            render: (data) => {
              return data.AppID;
            },
          },
          {
            title: 'Document Name',
            data: null,
            render: (data) => {
              return `${ data.DocumentName != null && data.DocumentName != 'null' ? data.DocumentName : data.FileName }`;
            },
          },
          {
            title: 'Status',
            render: (data, type, row) => {
              if (row.Status === 'Requested') {
            
                return `<span class="badge badge-danger p-1">${row.Status}</span>`;
              } else {
                return `<span class="badge badge-success p-1"> ${row.Status} </span>`;
              }
            }
          },
          {
            title: 'Action',
            data: null,
            render(data) {
              return `<a href="javascript:void(0)" class="view m-1" title="View Requested Documents" ID="${data.AppID}">
              <i class="mdi mdi-plus font-18 text-secondary" aria-hidden="false" ID="${data.AppID}"></i>
                </a>`;         
            },
          },
        ],
        drawCallback: () => {
          $('.view').on('click', (e) => {
            this.router.navigate(['/loan/view-requested-documents/' + e.target.getAttribute('ID')]);
          });
        },
      };
    } else {
      this.dtOptions = {
        pagingType: "simple_numbers",
        pageLength: 10,
        ordering: false,
        serverSide: true,
        ajax: (dataTablesParameters: any, callback) => {
          this.http
            .get<DataTablesResponse>(
              `${this.service.GetBaseUrl()}requested/document/list?UserID=${id}&record=${dataTablesParameters.length}&pageNo=${(( dataTablesParameters.start + dataTablesParameters.length)/ dataTablesParameters.length)}${dataTablesParameters.search.value != '' ? '&searchKey=' + dataTablesParameters.search.value : ''}`,{}
            ).subscribe((resp: any) => {
              console.log(resp,'resp');
              callback({
                recordsTotal: resp.count,
                recordsFiltered: resp.count,
                data: resp.data,
              });
            });
        },
        responsive: true,
        columns: [
          {
            title: 'Sr.No.',
            data: null,
            render: (data, type, row, meta) => {
              return meta.row + 1;
            },
          },
          {
            title: 'App ID',
            data: null,
            render: (data) => {
              return data.AppID;
            },
          },
          {
            title: 'Document Name',
            data: null,
            render: (data) => {
              return `${ data.DocumentName != null && data.DocumentName != 'null' ? data.DocumentName : data.FileName }`;
            },
          },
          {
            title: 'Status',
            render: (data, type, row) => {
              if (row.Status === 'Requested') {
            
                return `<span class="badge badge-danger p-1">${row.Status}</span>`;
              } else {
                return `<span class="badge badge-success p-1"> Uploaded </span>`;
              }
            }
          },
          {
            title: 'Action',
            data: null,
            render(data) {
              return `<a href="javascript:void(0)" class="view m-1" title="View Requested Documents" ID="${data.AppID}">
              <i class="mdi mdi-plus font-18 text-secondary" aria-hidden="false" ID="${data.AppID}"></i>
                </a>`;         
            },
          },
        ],
        drawCallback: () => {
          $('.view').on('click', (e) => {
            this.router.navigate(['/loan/view-requested-documents/' + e.target.getAttribute('ID')]);
          });
        },
      };
    }
  }
  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }
  ngAfterViewInit() {
         
    this.dtTrigger.next();
  }
}
