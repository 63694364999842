import { ActivatedRoute, Router } from '@angular/router';
import { GeneralService } from 'src/app/services/general.service';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material';
import Swal from 'sweetalert2';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { WindowRef } from '@agm/core/utils/browser-globals';
import { AssignLawyerComponent } from '../assign-lawyer/assign-lawyer.component';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UploadLawyerDocumentsComponent } from '../upload-lawyer-documents/upload-lawyer-documents.component';
import { DialogboxComponent } from '../dialogbox/dialogbox.component';
import { AdminUploadLawyerComponent } from '../admin-upload-lawyer/admin-upload-lawyer.component';

@Component({
  selector: 'app-view-pvr-list',
  templateUrl: './view-pvr-list.component.html',
  styleUrls: ['./view-pvr-list.component.scss']
})
export class ViewPvrListComponent implements OnInit {
  breadCrumbItems: Array<{}>;
  isLoading:Boolean
  interval;
  reviewd = 0;
  lawyerInfo: any;
  IsLawyerLoaded: boolean;
  index = 0;
 
  array = ['2425',];
  PVRDetailsLoaded: boolean;
  statusList = ['Village Level Officer has received the request',
    'Village Level Officer has fetched the latest Govt. Land Records', 'Latest Land Record Document being uploaded',
    'Documents are under review by PropLegit', 'iPVR getting finalized'];
  meth: any;
  totaldocument = 0;
  pending = 0;
  received = 0;
  AppID: any;
  appID:any;
  PropertyID:any
  DocumentID:any
  appData:any;
  dtOptions: DataTables.Settings = {};
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtTrigger = new Subject<any>();
  propertyDocumentData: any;
  loaded: boolean;
  applicationData: any;
  revenueRadialChart: any;
  LawyerDetails: any;
  currentUser: any;
  PropertyForm:FormGroup;
  PostMortageForm: FormGroup;
  ApplicationStatus:string;
  firstName: any;
  lastName: any;
  StateName: any;
  ApplicationID: any;
  owners: any;
  morgage:boolean = true;
  dataFromDialog:any;
  TotalLandSize: any;
  FileUrl: any;
  disableGPPAlert:boolean = false;
  constructor(private service: GeneralService, private Route: ActivatedRoute, private router: Router, public dialog: MatDialog,
    private fb: FormBuilder, private http: HttpClient) { 
      // this.AppID = this.Route.snapshot.params.id;
      let a = this.router.getCurrentNavigation().extras;
    if(a){
      if(a.state){
        this.ApplicationStatus  = a.state.ApplicationStatus;
        this.AppID = a.state.AppID
        this.appID = a.state.AppID;
        console.log("appid",this.AppID)
        localStorage.setItem('ApplicationStatus',this.ApplicationStatus);
        localStorage.setItem('AppID',this.AppID);
      }
    }
    if(localStorage.getItem('AppID')){
      this.AppID = localStorage.getItem('AppID');
      this.appID = this.AppID;
      this.ApplicationStatus = localStorage.getItem('ApplicationStatus');
    }

    }
    ngOnDestroy() {
      clearInterval(this.interval);
    }
  ngOnInit() {
    // this.isLoading =true;
    this.CheckGppReq();
    this.getApplicationData();
    this.PostMortageForm = this.fb.group({
      AppID: [this.AppID],
      CERSAI: [true],
      GovernmentPortal: [true],
      None: [false],
    });
   
    this.currentUser = this.service.getcurrentUser();
    this.IsLawyerLoaded = false;
    this.PropertyForm = this.fb.group({
      PropertyInfo:this.fb.array([this.initPropertyInfo()])
    })
    
  }
  
  initPropertyInfo(i?){
    if (i && i !== undefined){
      return this.fb.group({
        OwnerRemark:new FormControl(i.OwnerRemark),
        PropertyRemark:new FormControl(i.PropertyRemark),
      })
    }
    else{
      return this.fb.group({
        OwnerRemark: new FormControl(""),
        PropertyRemark: new FormControl("")
      })
    }
  }
  onUploadDocument(doctype, docsubtype, docname,Index,docIndex) {
    this.router.navigate(['loan/uploadlawyerdocument/' + this.appID], { queryParams: { DocumentType: doctype, DocumentSubType: docsubtype, DocumentName: docname,PropertyIndex: Index}});
  }  
  onViewDocument(id,propIndex,docIndex) {
    console.log("View Doc",id)
    this.router.navigate(['/loan/viewdocument/' + this.PropertyID + '/' + id + '/' + this.appID+'/ipvr'], { state: { PropertyIndex: propIndex, DocumentIndex: docIndex, isFromAddIPVR : true} } );
  }
  seeFullReport(){
    if(this.FileUrl != null){
      console.log("see full report function is called")
      this.router.navigate(['/loan/viewdocument/'+ this.appID ]);
    }
    else {
      return;
    }
  }
  openAssignLawyerDialog(){
    this.router.navigate(['loan/documents/' + this.AppID],{state:{ApplicationStatus : this.ApplicationStatus}});
  }

  onSubmit() {
    // this.submitted = true;
    let obj = {};
    obj = {
      AppID: Number(this.PostMortageForm.controls.AppID.value),
      CERSAI: this.PostMortageForm.controls.CERSAI.value ? 1 :0,
      GovernmentPortal: this.PostMortageForm.controls.GovernmentPortal.value ? 1 :0,
      None: this.PostMortageForm.controls.None.value ? 1 :0,
    }; 
    // this.service.userMortgage(obj).subscribe((res: any) => {
    //   this.applicationData = res;
    //   this.service.changeStatus(this.AppID, 'Post Mortgage').subscribe(() => {
    //     this.router.navigate(["/loan/title-search/"+this.AppID]);
    //     this.PostMortageForm.reset();
    //   });
    //   this.morgage = false;
    // });
  }

  getApplicationData(){
    this.isLoading = true;
    this.service.getMultiplePropertyInfo(this.AppID).subscribe((res)=>{
      this.appData = res
      this.isLoading=false;
      this.owners = this.appData.data.PropertyOwnerShip;
      this.firstName = this.appData.data.ApplicatntDetails[0].FirstName;
      this.lastName =  this.appData.data.ApplicatntDetails[0].LastName;
      this.StateName = this.appData.data.Properties[0].StateName;
      this.ApplicationID =  this.appData.data.ApplicatntDetails[0].ApplicationID;
      this.PropertyID = this.appData.data.Properties[0].PropertyID
      this.DocumentID = this.appData.data.Properties[0].Documents[0].DocumentID
      this.appID = this.appData.data.ApplicatntDetails[0].AppID
      this.TotalLandSize =this.appData.data.ApplicatntDetails[0].TotalLandSize;
      this.FileUrl = this.appData.data.ApplicatntDetails[0].FileURL
      this.ApplicationStatus = this.appData.data.ApplicatntDetails[0].ApplicationStatus
      if(this.appData.data.Properties[0].StateID ==22){
        this.breadCrumbItems = [{ label: 'Dashboard', path: '/' },
        { label: 'Applications', path: '/rj-applications/Applications' }, { label: 'iPVR Report', path: '/', active: true }];
      }
      else{
        this.breadCrumbItems = [{ label: 'Dashboard', path: '/' },
        { label: 'Applications', path: '/loan/applications' }, { label: 'iPVR Report', path: '/', active: true }];
      }
      // this.loaded = false;
      // this.applicationData = this.appData.data.Properties.Documents[0].DocumentID
      // console.log('Document ID',this.appData.data.Properties.Documents.DocumentID)
      // console.log('Property ID', this.appData.data.Properties.PropertyID)
    })

  }


  openViewDocumentDialog(){
      const dialogRef = this.dialog.open(DialogboxComponent, {
      disableClose: true,
      width: '600px',
      height:'-1vh',
      data: {'applicationData':this.applicationData}
    });

    dialogRef.afterClosed().subscribe((data) => {
      this.dataFromDialog = data.PostMortageForm;
      console.log('data',data);
      if (data) {
        this.router.navigate(["/loan/post-mortgage-dashboard/"+this.AppID]);

    //   let obj = {
    //   AppID: Number(this.PostMortageForm.controls.AppID.value),
    //   CERSAI: this.PostMortageForm.controls.CERSAI.value ? 1 :0,
    //   GovernmentPortal: this.PostMortageForm.controls.GovernmentPortal.value ? 1 :0,
    //   None: this.PostMortageForm.controls.None.value ? 1 :0,
    // };
    // console.log('post mortgage form',this.PostMortageForm.value);
    // this.service.userMortgage(obj).subscribe((res: any) => {
    //   this.applicationData = res;
    //   this.service.changeStatus(this.AppID, 'Post Mortgage').subscribe(() => {
    //     this.router.navigate(["/loan/title-search/"+this.AppID]);
    //     this.PostMortageForm.reset();
    //   });
    // });

      }

    });

  }
  AddGPP(){
    // this.router.navigate(['/loan/Add-Gpp-Alert'],{state:{AppID:this.AppID, ApplicationData : this.appData}});
    let data  = {
      AppID: this.AppID
    }
    this.service.AddGppReq(data).subscribe((res:any)=>{
      if(res.error){
        if(res.error){
          Swal.fire({
            title: res.error_code,
            text: res.message,
            type: "error",
            showConfirmButton: false,
            timer:3000
          });
          return;
        }
       }
    else{
      Swal.fire({
        title: "Success",
        text: res.message,
        type: "success",
        showConfirmButton: false,
        timer:3000
      });
      this.CheckGppReq();
    }
    },(error:any)=>{
      Swal.fire({
        title: 'Error',
        text: 'Failed to ',
        type: "error",
        showConfirmButton: false,
        timer:3000
      });
      return;
    })
    
  }
  CheckGppReq(){
    this.service.CheckGppReq(this.AppID).subscribe((res:any)=>{
      if(res.data.length){
        this.disableGPPAlert = true;
      }
      else {
        this.disableGPPAlert = false;
      }
    })
  }

  viewGPP(){
    this.router.navigate(['/loan/gpp-dashboard'],{state:{ AppID:this.AppID }});
  }
}
