import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { CookieService } from 'src/app/core/services/cookie.service';
import { GeneralService } from 'src/app/services/general.service';
import Swal from 'sweetalert2';
class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}
@Component({
  selector: 'app-view-ipvr-documents',
  templateUrl: './view-ipvr-documents.component.html',
  styleUrls: ['./view-ipvr-documents.component.scss']
})
export class ViewIpvrDocumentsComponent implements OnInit {

  dtOptions: DataTables.Settings = {};
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtTrigger = new Subject<any>();
  user: any;
  breadCrumbItems: ({ label: string; path: string; active?: undefined; } | { label: string; path: string; active: boolean; })[];
  AppID: any;
  LawyerID: any;
  DocumentList: any;
  myGroup: FormGroup;
  documentID: any = [];
  isLoading: boolean;
  StateList: any=[];
  isShow: boolean;
  param: any = '';
  DropDownID: string = null;
  showState: boolean;
  DropdownType: any;
  constructor(
    // public dialogRef: MatDialogRef<AssignLawyerComponent>,
    // @Inject(MAT_DIALOG_DATA) public data: any,
    private service: GeneralService,
    private router: Router,
    private http: HttpClient,
    private cookie: CookieService
  ) {
    if (this.router.getCurrentNavigation().extras.state) {
      this.DropDownID = this.router.getCurrentNavigation().extras.state.DropDownID;
      this.DropdownType = this.router.getCurrentNavigation().extras.state.DropdownType;
    }
  }

  ngOnInit() {
    if(this.DropDownID != undefined || this.DropDownID != null) {
      this.param = `?DropDownID=${this.DropDownID}`;
    }
    this.myGroup = new FormGroup({
      selectState: new FormControl(this.DropDownID),
      selectPropertyType: new FormControl(this.DropdownType),
    });
    this.breadCrumbItems = [
      { label: 'Dashboard', path: '/' },
      { label: 'iPVR Documents', path: '/loan/iPVRDocuments', active: true }]; 
    this.user = JSON.parse(this.cookie.getCookie('currentUser'));
    this.dtOptions = {
      pagingType: "simple_numbers",
      pageLength: 10,
      ordering: true,
      ajax: (dataTablesParameters: any, callback) => {
        this.http
          .get<DataTablesResponse>(
            `${this.service.getdropdownitem()}${this.param}`,{}
          ).subscribe((resp: any) => {
            if(this.param != '') {
              this.DocumentList = resp.data;
            } else {
              this.DocumentList = [];
            }
            callback({
              recordsTotal: this.DocumentList.length,
              recordsFiltered: this.DocumentList.length,
              data: this.DocumentList,
            });
          });
      },
      responsive: true,
      columns: [
        {
          title: 'Sr.No.',
          data: null,
          render: (data, type, row, meta) => {
            return meta.row + 1;
          },
        },
        {
          title: 'State',
          data: null,
          render: (data) => {
            return data.DropDownName;
          },
        },
        {
          title: 'Document Name',
          data: null,
          render: (data) => {
            return data.DropDownValue;
          },
        },
        {
          title: 'Is Multiple Allowed',
          data: null,
          render: (data) => {
            if(data.DropDownItemType === 'multiple'){
              return 'Yes'
            } else {
              return 'No'
            };
          },
        },
        {
          title: 'Action',
          data: null,
          render(data) {
            return `
            <a class="btn text-primary edit" title="Edit Document"
            DropDownID="${data.DropDownID}" DropDownItemID="${data.DropDownItemID}" DropDownValue="${data.DropDownValue}"><i DropDownID="${data.DropDownID}" DropDownItemID="${data.DropDownItemID}" DropDownValue="${data.DropDownValue}" class="dripicons-document-edit font-18 text-secondary"
            aria-hidden="false"></i></a>
            <a class="btn text-primary delete" title="Delete Document"
            DropDownID="${data.DropDownID}" DropDownItemID="${data.DropDownItemID}" DropDownValue="${data.DropDownValue}"><i DropDownID="${data.DropDownID}" DropDownItemID="${data.DropDownItemID}" DropDownValue="${data.DropDownValue}" class="dripicons-document-delete font-18 text-secondary"
            aria-hidden="false"></i></a>`;            
          },
        },        
      ],
      columnDefs: [{targets:4,orderable:false}],
      drawCallback: () => {
        $('.edit').on('click', (e) => {
          this.router.navigate(['/loan/addiPVRDocuments'], { queryParams: { DropDownID: e.target.getAttribute('DropDownID'),DropDownValue : e.target.getAttribute('DropDownValue'),DropDownItemID : e.target.getAttribute('DropDownItemID'), DropdownType : this.myGroup.controls.selectPropertyType.value} });
        });
        $('.delete').on('click', (e) => {
          console.log(e.target.getAttribute('DropDownItemID'));
          this.deleteDocument(e.target.getAttribute('DropDownItemID'));
        });
      },
    };
  }
  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }
  ngAfterViewInit() {
    this.dtTrigger.next();
  }
  fetchstatelist() {
    this.service.GetDropDownData (`?flag=1&flagforUR=${this.myGroup.controls.selectPropertyType.value}`)
      .pipe(first())
      .subscribe(
        data => {
          if (data.error) {
            Swal.fire({
              title: data.error_code,
              text: data.message,
              type: 'error',
              showConfirmButton:false,
              timer:3000
            });
            return;
          } else {
            this.StateList = data.data;
          }
        });
  }
  ChangeState(e) {
    if(e == undefined || e == null) {
      this.isShow = false;
      this.param = '';
    } else {
      this.isShow = true;
      this.param = `?DropDownID=${e}`
    }
    this.rerender();
  }
  deleteDocument(id) {
    this.isLoading = true;
    this.service.deleteDocument(id).subscribe((res) => {
      this.isLoading = false;
      if (res.error) {
        Swal.fire({
          title: res.error_code,
          text: res.message,
          type: 'error',
          showConfirmButton:false,
          timer:3000
        });
        return;
      } else {
        Swal.fire({
          title: 'Success',
          text: 'Document Deleted Successfully',
          type: 'success',
          showConfirmButton:false,
          timer:3000
        }).then(() => {
          this.rerender();
        });
      }
      this.isLoading = false;
    });
  }
  ChangePropertyType(e) {
    if(e == null || e == undefined) {
      this.showState = false;
      this.StateList = [];
    } else {
      this.showState = true;
      this.fetchstatelist();
    }
  }
}
