import { ActivatedRoute, Router } from "@angular/router";
import { GeneralService } from "src/app/services/general.service";
import { Component, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { dateToLocalArray } from "@fullcalendar/core/datelib/marker";
import { DatePipe } from "@angular/common";
import Swal from "sweetalert2";
import { TitleClearCompleteComponent } from "../title-clear-complete/title-clear-complete.component";
import { MatDialog } from "@angular/material/dialog";
import {
  FormControl,
  FormGroup,
  RequiredValidator,
  Validators,
} from "@angular/forms";
import { event } from "jquery";

@Component({
  selector: "app-title-clear",
  templateUrl: "./title-clear.component.html",
  styleUrls: ["./title-clear.component.scss"],
})
export class TitleClearComponent implements OnInit {
  string: any = '"';
  breadCrumbItems: any;
  data: any;
  isallDocumentsReviewed: boolean = false;
  edit: boolean = false;
  isLoading: boolean;
  SubmmitDate: any = this.datepipe.transform(Date(), "yyyy-MM-dd");
  DocumentList: any = [];
  submited: boolean;
  url: any;
  header: any = [];
  footer: any = [];
  applicationData: any;
  isPublicNotice: any = false;
  files: any = [];
  currentUser: any;
  CitySurveyNo = "";
  heightCSS;
  Signature: string = "";
  alldocs: any;
  bankmanagername: any = "";
  // Ramakant Shah
  bankname: any = "";
  // SBI
  applicantname: any = "";
  // Rahul Sharma SBI110
  ownerslist: any = ``;
  // `(1) KURESHI MOHAMMEDHUSSAIN MOHAMMEDSAFI<br />
  //  (2) KURESHI MOHAMMEDEHSAAN MOHAMMEDSAFI <br />`
  reviewedDocs: any = [];
  address: any = ``;
  // Sundaram Nagar, Gujarat Housing Board,
  // Nr. Nurani Maszid, Ajit Mill, <br />Bapunagar, Ahmedabad.</p>
  documentComments: any = ``;
  // The revenue records indicate that originally the land bearing Survey No.
  // 333/1 was in the ownership of Patel Veribhai Maganbhai. Subsequendy, he
  // had orally sold and conveyed the aforementioned land in favour of
  // Jenabhai Gangadas on 25.4.1942. The said event was entered in the
  // revenue records on 25.4.1942 vide mutation entry no. 1282.
  // Note: The aforesaid entry, though not reflected in the latest Village Form
  // No. 7, it is relevant for this tide
  VerifiedDocuments: any = ``;
  TitleClearData: any;
  NoticeData: any = [];
  publicNotice: any = "";
  isallNoticeExpired: any;
  headersData: any;
  Headerurl: any;
  Footerurl: any;
  NoticeDataReceived: boolean;
  headersDataReceived: boolean;
  applicationinforeceived: boolean;
  DocumentListReceived: boolean;
  interval: NodeJS.Timer;
  TCCdataReceived: boolean;
  myValue: any;
  property: any;
  propertyList: any[] = []
  PropertyOwnerShip: any[];
  propertyIds: any[];
  docCommnethtml: string = ``;
  varifiedDocHtml: string = ``;
  // <li>Construction Agreement	</li>

  // <li>RERA Registration No.</li>

  // <li>Copy of Sales Deed</li>

  // <li>Latest copy of 7/12 or Property Card</li>

  // <li>Latest copy of Khata Extract (8/A)</li>

  // <li>Copy of property tax/land revenue/other statutory dues</li>

  // <li>Copy of approved plan / building plan</li>

  // <li>Copy of Government Order for NA</li>

  // <li>Letter from Builder/Society with their account number and name of Bankers for remittance of installments</li>

  // <li>Possession Certficate/Allottment Letter (for Flat)</li>

  // <li>Original Share Certificate (in case of a Society)</li>

  // <li>NOC (in case of a Society)</li>

  // <li>Title Search report - 30 years</li>
  constructor(
    private service: GeneralService,
    private route: ActivatedRoute,
    private datepipe: DatePipe,
    private Router: Router,
    private http: HttpClient,
    public dialog: MatDialog
  ) {
    this.breadCrumbItems = [
      { label: "Dashboard", path: "loan" },
      { label: "Assignments", path: "/loan/assignment" },
      { label: "Public Notice", path: "", active: true },
    ];
    this.edit = false;
//     this.data = `													
//     <p>For ${this.applicantname}<br />
//     <b> Address :</b>${this.address}
//     <h5>Sub: Devolution of the Title of the said land </h5>

// <p>
// Based on the perusal of revenue records and other documents, the devolution of
// Tide of the said Land is as under:</p>
// <p>Estwhile Survey No. ${this.CitySurveyNo}:</p>
// <br />
// <p>${this.docCommnethtml}
// </p>
// <br />
// <p>${this.varifiedDocHtml}
// </p>
// <br />
// <h5>Public Notice:</h5>
// <p>
// ${this.publicNotice}
// </p>

// <h5>Final Observations:</h5>
// <p>

// </p>`;
  }

  ngOnInit() {

    this.currentUser = this.service.getcurrentUser();
    // this.isLoading = true;
    this.service.getHeadersFooters(this.currentUser.UserID).subscribe((res) => {
      this.headersDataReceived = true;
      this.headersData = res.data[0];
      // this.header = 
      // this.footer = 
      console.log('headers data', this.headersData);
    });
    // will implement public notices flow in future
    // this.service
    //   .GetApplicationPublicNoticesRes(this.route.snapshot.params.AppID)
    //   .subscribe((res) => {
    //     this.NoticeDataReceived = true;
    //     this.NoticeData = res.data;
    //     this.isallNoticeExpired = this.CompaireNotice();
    //     this.NoticeData.forEach((element, index) => {
    //       this.publicNotice =
    //         this.publicNotice +
    //         `
    //       <h5> (${index + 1}). ${this.datepipe.transform(
    //           element.PublishDate,
    //           "yyyy-MM-dd"
    //         )} - ${element.NewsPaper}</h5>
    //       ${element.Description} <br /><h5>Responses:</h5>`;
    //       element.Responses.forEach((e, i) => {
    //         this.publicNotice =
    //           this.publicNotice +
    //           `<h5>${this.datepipe.transform(e.EntryDate, "yyyy-MM-dd")}</h5>
    //         ${e.comment} <br />`;
    //       });
    //     });
    //   });
    this.service
      .getMultiplePropertyInfo(this.route.snapshot.params.AppID)
      .subscribe((res: any) => {
        this.applicationinforeceived = true;
        this.applicationData = res.data.ApplicatntDetails[0];
        this.PropertyOwnerShip = res.data.PropertyOwnerShip;
        this.propertyList = res.data.Properties;
        
        if (this.applicationData.FullName) {
          this.bankmanagername = this.applicationData.FullName;
        }
        if (
          Number(this.applicationData.LoanAmount) >
          Number(this.applicationData.minAmount)
        ) {
          this.isPublicNotice = true;
        }
        this.bankname = this.applicationData.BankName;
        this.applicantname =
          this.applicationData.FirstName + " " + this.applicationData.LastName;
        this.address = `${this.propertyList[0].BuildingNo != null
          ? this.propertyList[0].BuildingNo + ", "
          : " "
          }${this.propertyList[0].BuildingName != null
            ? this.propertyList[0].BuildingName + ", "
            : " "
          }${this.propertyList[0].villagename != null
            ? this.propertyList[0].villagename + ", "
            : " "
          }${this.propertyList[0].talukaname != null
            ? this.propertyList[0].talukaname + ", "
            : " "
          }${this.propertyList[0].districtname != null
            ? this.propertyList[0].districtname + ", "
            : " "
          }${this.propertyList[0].StateName != null
            ? this.propertyList[0].StateName + ", "
            : " "
          }`;

        console.log('applicant name', this.applicantname);
        this.PropertyOwnerShip.forEach((ele, index) => {
          this.ownerslist = `(${index}) ${ele.OwnerName}<br />`;
        });
      });
    this.getDocuments();
    this.isLoading = true;
    this.service
      .GetTCCData(this.route.snapshot.params.AppID)
      .subscribe((res) => {
        this.TCCdataReceived = true;
        this.TitleClearData = res.data[0];
        this.isLoading = false;
        if (this.TitleClearData != null && this.TitleClearData != undefined) {
          this.TitleClearData.Template = this.TitleClearData.Template.replace(
            this.string,
            '"'
          );
          this.data = this.TitleClearData.Template;
          console.log(this.data);
          this.Signature = this.TitleClearData.Signature;
        }
      });
      
    this.startTimer();
    
  }
  getDocuments(){
    this.isLoading = true;
    this.service
    .GetLawyerDocumentList(this.route.snapshot.params.AppID)
    .subscribe((resp) => {
      
      this.DocumentListReceived = true;
      this.alldocs = resp.data;
      resp.data[this.propertyList[0].PropertyID].map((data, index) => {
        if (data.FileURL !== null && data.isMandatory == true) {
          this.property =this.propertyList[0].PropertyID;
          this.DocumentList.push(data);
          this.url = this.DocumentList[0].FileURL;
        }
      });
      this.propertyIds = this.propertyList.map((d) => { return d.PropertyID })
      console.log('all documents', this.propertyIds);
      let doclist = [];
      this.propertyIds.forEach((id) => {
        this.alldocs[id].forEach((doc) => {
          doclist.push(doc);
        })
      })
      setTimeout(() => {
        console.log('doclist', doclist);
        this.docCommnethtml = ``
        this.propertyIds.forEach((id, i) => {
          this.documentComments = ``;
          this.VerifiedDocuments = ``;
          this.reviewedDocs = [];
          resp.data[id].forEach((data, index) => {
            if (id === data.PropertyID) {
              this.generateDocumentHtml(data,index);
            }

          });
          this.docCommnethtml = this.docCommnethtml + `<hr> <h5><u>Documents of Property ${i + 1}</u></h5>
          ${this.documentComments}`
          console.log('document html', this.docCommnethtml);
          this.varifiedDocHtml = this.varifiedDocHtml + `<hr> <h5>Verified Documents of Property ${i + 1}</h5>
          ${this.VerifiedDocuments}`
          console.log('verfied doc',this.varifiedDocHtml);
          this.isLoading = false;
          this.ngAfterViewInit();
        })
        

      }, 1000);
      // setTimeout(()=>{
          // if(resp.data['null']){
          //   this.documentComments = ``;
          //   this.VerifiedDocuments = ``;
          //   this.reviewedDocs = [];
          //   resp.data['null'].forEach((data,index)=>{
          //     this.generateDocumentHtml(data,index);
          //     this.docCommnethtml = this.docCommnethtml + `<hr><h5>Documents :</h5>
          //     ${this.documentComments}`;
          //     this.varifiedDocHtml = this.varifiedDocHtml +`<hr><h5>Verified Documents :</h5>
          //     ${this.VerifiedDocuments}`
          //   })
          // }
      // },2000)

      // resp.data[this.propertyList[0].PropertyID].map((data, index) => {
      //   if (data.FileURL !== null && data.isMandatory == true) {
      //     // <h5> ${index + 1}. ${data.DocumentName}</h5>
      //     this.documentComments =
      //       this.documentComments +
      //       `<h5> ${index + 1}. ${data.DocumentName}</h5>`;
      //     if (data.DocumentComment && data.DocumentComment.length > 0) {
      //       data.DocumentComment.forEach((ele, index) => {
      //         this.documentComments =
      //           this.documentComments + `${ele.Comment} <br/>`;
      //       });
      //       console.log('document comments',this.documentComments);
      //     }
      //     ("<br />");
      //     // this.VerifiedDocuments = '';
      //     if (data.Status === "Reviewed") {
      //       this.reviewedDocs.push(data);
      //       this.VerifiedDocuments =
      //         this.VerifiedDocuments +
      //         `<li style='margin-left:10px'>${data.DocumentName}</li>`;
      //     }
      //     if ((this.reviewedDocs.length = 0)) {
      //       this.VerifiedDocuments = "No Documents are Verifed";
      //     }
      //     // this.property = this.propertyList[0].PropertyID;
      //     this.property =this.propertyList[0].PropertyID;
      //     this.DocumentList.push(data);
      //     this.url = this.DocumentList[0].FileURL;
      //   }
      // });
      this.isallDocumentsReviewed = this.Compaire();
      this.submited = false;
    });
  }

  ngAfterViewInit(){
    console.log('ngAgerViwer init called.');
    this.data = `													
    <p>For ${this.applicantname}<br />
    <b> Address :</b>${this.address}
    <h5>Sub: Devolution of the Title of the said land </h5>

<p>
Based on the perusal of revenue records and other documents, the devolution of
Tide of the said Land is as under:</p>
<p>Estwhile Survey No. ${this.CitySurveyNo}:</p>
<br />
<p>${this.docCommnethtml}
</p>
<br />
<p>${this.varifiedDocHtml}
</p>
<br />

<h5>Final Observations:</h5>
<p>

</p>`;
  }
  generateDocumentHtml(data,index){
    if (data.FileURL !== null && data.isMandatory == true) {
      this.documentComments =
        this.documentComments +
        `<h5> ${index + 1}. ${data.DocumentName}</h5>`;
      if (data.DocumentComment && data.DocumentComment.length > 0) {
        data.DocumentComment.forEach((ele, index) => {
          this.documentComments =
            this.documentComments + ` ${ele.Comment}`;
        });
      }
      ("<br />");
      this.VerifiedDocuments = '';
      if (data.Status === "Reviewed") {
        this.reviewedDocs.push(data);
        this.VerifiedDocuments =
          this.VerifiedDocuments +
          `<li style='margin-left:10px'>${data.DocumentName}</li>`;
      }
      if ((this.reviewedDocs.length == 0)) {
        this.VerifiedDocuments =`<li style='margin-left:10px'> No Documents are Verifed</li>`;
      }
    }
  }
  test() {
    if (this.edit == true) {
      this.edit = false;
      this.heightCSS = "max-height : 400px";
    } else {
      this.edit = true;
      this.heightCSS = "max-height : 800px";
    }
  }
  startTimer() {
    let limit;
    limit = 2000;
    this.interval = setInterval(() => {
      if (
        this.NoticeDataReceived == true &&
        this.applicationinforeceived == true &&
        this.applicationinforeceived == true &&
        this.DocumentListReceived == true &&
        this.TCCdataReceived == true
      ) {
        this.pauseTimer();
        if (this.TitleClearData != null && this.TitleClearData != undefined) {
          this.data = this.TitleClearData.Template;
          this.Signature = this.TitleClearData.Signature;
        } else {
          if (this.headersData != undefined) {
            this.data = `<img src="${this.headersData.HeadersImageURL}" alt="" style="width: 100%" />	
                 <br />
                <p>For ${this.applicantname}</p>
                <p><b> Address :</b>${this.address}</p>
                <h5>Sub: Devolution of the Title of the said land </h5>
                <p>${this.docCommnethtml}
                </p>
                <p>${this.varifiedDocHtml}
                </p>
                <br />
                
                <h5>Final Observations:</h5>
                <p>
                </p>
                <img src="${this.headersData.FooterImageURL}  alt="" style="width: 100%"`;
            console.log('Final Observations', this.data);
          } else {
            this.data = `<p>For ${this.applicantname}
            <b> Address :</b>${this.address}
            <h5>Sub: Devolution of the Title of the said land </h5>
                <br />
                <p>${this.docCommnethtml}
                </p>
                <p>${this.varifiedDocHtml}
                </p>
                
                <h5>Final Observations:</h5>
                <p>
                </p>`;
          }
        }
        this.isLoading = false;
      }
    }, limit);
  }
  uploadImages() {
    this.submited = true;
    if (this.header.length == 0 || this.footer.length == 0) {
      return;
    } else {
      this.isLoading = true;
      let headerName = this.header[0].name;
      let footerName = this.footer[0].name;
      const input = new FormData();
      input.append("UserID", this.currentUser.UserID);
      input.append("HeadersImageFileName", this.header[0].name);
      input.append("FooterImageFileName", this.footer[0].name);
      input.append("HeadersImage", this.header[0]);
      input.append("FooterImage", this.footer[0]);
      // input.append("uploadfile", this.fileToUpload[i], this.photographForm.get("FileName").value);
      if (this.headersData === undefined) {
        this.service.uploadLawyerHeaders(input).subscribe(
          (res): any => {
            if (res.status == 200) {
              Swal.fire({
                title: `Success`,
                text: "Header and Footer Updated Successfully.",
                type: "success",
                showConfirmButton: false,
                timer: 3000,
              }).then(() => {
                this.header = [];
                this.footer = [];
                this.service
                  .getHeadersFooters(this.currentUser.UserID)
                  .subscribe((res) => {
                    this.headersData = res.data[0];
                    if (this.headersData != undefined) {
                      this.data =
                        `<img src="${this.headersData.HeadersImageURL}" alt="" style="width: 100%" />` +
                        this.data +
                        `<img src="${this.headersData.FooterImageURL}" alt="" style="width: 100%" />`;
                    }
                    this.isLoading = false;
                    this.submited = false;
                  });
              });
            } else {
              Swal.fire({
                title: `Error`,
                text: "Something went Wrong.",
                type: "error",
                showConfirmButton: false,
                timer: 3000,
              }).then(() => { });
            }
          },
          (error) => {
            this.submited = false;
            this.isLoading = false;
            Swal.fire({
              title: `Error`,
              text: "Internal Server Error",
              type: "error",
              showConfirmButton: false,
              timer: 3000,
            }).then(() => { });
          }
        );
      } else {
        this.service.updateLawyerHeaders(this.headersData.ID, input).subscribe(
          (res): any => {
            if (res.status == 200) {
              Swal.fire({
                title: `Success`,
                text: "Header and Footer added Successfully.",
                type: "success",
                showConfirmButton: false,
                timer: 3000,
              }).then(() => {
                this.header = [];
                this.footer = [];
                this.service
                  .getHeadersFooters(this.currentUser.UserID)
                  .subscribe((res) => {
                    this.headersData = res.data[0];
                    if (this.headersData != undefined) {
                      this.data =
                        `<img src="${this.headersData.HeadersImageURL}" alt="" style="width: 100%" />` +
                        this.data +
                        `<img src="${this.headersData.FooterImageURL}" alt="" style="width: 100%" />`;
                      console.log(this.data);
                    }
                    this.isLoading = false;
                    this.submited = false;
                  });
              });
            } else {
              Swal.fire({
                title: `Error`,
                text: "Something went Wrong.",
                type: "error",
                showConfirmButton: false,
                timer: 3000,
              }).then(() => { });
            }
          },
          (error) => {
            this.submited = false;
            this.isLoading = false;
            Swal.fire({
              title: `Error`,
              text: "Internal Server Error",
              type: "error",
              showConfirmButton: false,
              timer: 3000,
            }).then(() => { });
          }
        );
      }
    }
  }
  save(isSubmmit) {
    const data: any = {
      AppId: this.route.snapshot.params.AppID,
      Template: this.data,
      isSubmmit: isSubmmit,
      SubmmitDate: this.SubmmitDate,
      CreatedBy: this.currentUser.UserID,
      Signature: this.Signature,
      PropertyID: this.applicationData.PropertyID,
    };
    this.isLoading = true;
    this.service.generateTitleClear(data).subscribe(
      (res): any => {
        this.isLoading = false;
        if (res.status == 200) {
          Swal.fire({
            title: `Success`,
            text: res.message,
            type: "success",
            showConfirmButton: false,
            timer: 3000,
          }).then(() => {
            this.Router.navigate([
              "loan/viewTitleReport/" + this.route.snapshot.params.AppID,
            ]);
          });
        } else {
          Swal.fire({
            title: `Error`,
            text: res.message,
            type: "error",
            showConfirmButton: false,
            timer: 3000,
          }).then(() => { });
        }
      },
      (error) => {
        this.isLoading = false;
        Swal.fire({
          title: `Error`,
          text: "Internal Server Error",
          type: "error",
          showConfirmButton: false,
          timer: 3000,
        }).then(() => { });
      }
    );
    // this.service.changeStatus(this.route.snapshot.params.AppID, 'Title Clear In Progress').subscribe(() => {
    //   this.Router.navigate(['/loan/titleclearcompleted/' + this.route.snapshot.params.AppID]);
    // });
  }
  Changedocument(e) {
    console.log(e);
  }
  Compaire() {
    let MendatoryDocumentList: any = [];
    this.DocumentList.forEach((element) => {
      if (element.isMandatory === true) {
        MendatoryDocumentList.push(element);
      }
    });
    return MendatoryDocumentList.every((x) => x.Status === "Reviewed");
  }
  CompaireNotice() {
    return this.NoticeData.every((x) => x.isExp === "1" || x.isExp === 1);
  }
  openPreviewTitleClearDialog(): void {
    console.log('verified doc',this.varifiedDocHtml);
    if (this.headersData != undefined) {
      this.data = `<img src="${this.headersData.HeadersImageURL}" alt="" style="width: 100%" />	
      <br />					
          <p>For ${this.applicantname} </p>
          <b> Address :</b>${this.address}
          <h5>Sub: Devolution of the Title of the said land </h5> 
          <p>${this.docCommnethtml}
          </p>
          <p>${this.varifiedDocHtml}
          </p>
          <br />
          
          <h5>Final Observations:</h5>
          <p>
          </p>
          <img src="${this.headersData.FooterImageURL}  alt="" style="width: 100%"`;
      console.log('Final Observations', this.data);
    } else {
      this.data = `<p>For ${this.applicantname}<br />
      <b> Address :</b>${this.address}
      <h5>Sub: Devolution of the Title of the said land </h5>
          <br />
          <p>${this.docCommnethtml}
          </p>
          <br />
          <p>${this.varifiedDocHtml}
          </p>
         
          <h5>Final Observations:</h5>
          <p>
          </p>`;
    }
    console.log('this.data', this.data);
    const dialogRef = this.dialog.open(TitleClearCompleteComponent, {
      disableClose: false,
      width: "650px",
      autoFocus: false,
      maxHeight: "570px",
      data: this.data,
    });
    dialogRef.afterClosed().subscribe((result) => { });
  }
  pauseTimer() {
    clearInterval(this.interval);
  }
  // removeWhitespace(value:any) {
  //   this.data.Signature = this.data.Signature.replace(/\s/g, '');
  // }
  // removeWhitespace() {
  //   this.data.Signature = this.data.Signature.trim();
  // }
  removeWhitespace(value: string) {
    this.data.Signature = this.data.Signature.replace(/\s/g, '');
  }


  changeProperty(event) {
    console.log('event ', event);
    // this.alldocs = resp.data;
    this.url = '';
    this.DocumentList = [];
    this.alldocs[event].map((data, index) => {
      if (data.FileURL !== null && data.isMandatory == true) {
        // <h5> ${index + 1}. ${data.DocumentName}</h5>

        this.DocumentList.push(data);
        this.url = this.DocumentList[0].FileURL;
      }
    });
    console.log('document list', this.DocumentList);
  }

}
