import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { GeneralService } from 'src/app/services/general.service';
import Swal from 'sweetalert2';
import { RequestDocumentDialogComponent } from '../request-document-dialog/request-document-dialog.component';
class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}
@Component({
  selector: 'app-assignment',
  templateUrl: './assignment.component.html',
  styleUrls: ['./assignment.component.scss']
})
export class AssignmentComponent implements OnInit {
  breadCrumbItems: Array<{}>;
  applicationData: any;
  appID: any;
  dtOptions: DataTables.Settings = {};
  dtOptions1: DataTables.Settings = {};
  isLoading: boolean;
  @ViewChild(DataTableDirective, { static: false })
  dtElement1: DataTableDirective;
  dtTrigger1 = new Subject<any>();
  PVRData: any;
  PVRDetailsLoaded: boolean;
  isNoticeActive: boolean = false;
  DocumentList: Array<any>;
  param: any = { "PageSize": 6, "PageNumber": 1, "SurveyNO": 150, "BuildNO": 8, "societyname": "Kalhar Co", "districtname": "Gandhinagar", "talukaname": "Kalol", "villagename": "Nandoli" };
  noticelist: any = [];
  isallDocumentsReviewed: boolean = false;
  isPublicNotice: boolean = false;
  titleclearData: any;
  BankManagerDetails: any;
  currentUser: any;
  isallNoticeExpired: any;
  LawyercountData: any;
  AppID: any;
  constructor(private service: GeneralService, private datepipe: DatePipe, private http: HttpClient, private Route: ActivatedRoute, private router: Router, public dialog: MatDialog) {
    this.appID = this.Route.snapshot.params.AppID;
    this.isLoading = true;
  }

  ngOnInit() {
    this.currentUser = this.service.getcurrentUser();
    this.service.getLawyerfilterCount(this.currentUser.UserID).subscribe((res) => {
      this.LawyercountData = res.data;

    });
    this.DocumentList = [];
    this.service.GetApplicationInformation(this.Route.snapshot.params.AppID).subscribe((response) => {
      this.isLoading = false;
      this.PVRDetailsLoaded = false;
      this.applicationData = response.data[0];
      this.getBankManagerDetails(this.currentUser.UserID)
      if (this.applicationData.ApplicationStatus === 'Title Clear Sent')
      this.AppID = this.applicationData.AppID
        this.service.getTitleCleardoc(this.Route.snapshot.params.AppID).subscribe((res: any) => {
          this.titleclearData = res.data[0];
          if(this.titleclearData && this.titleclearData.Template){
            this.titleclearData.Template.split(`#`).join("'");
         }
          this.isLoading = false;
        });
      this.breadCrumbItems = [{ label: 'Dashboard', path: '/' }, { label: 'Assignments', path: '/loan/assignment' }, { label: `${this.applicationData.FirstName != null ? this.applicationData.FirstName : ''} ${this.applicationData.LastName != null ? this.applicationData.LastName : ''}`, path: '/loan/assignment/' + this.Route.snapshot.params.AppID, active: true }];
      if (Number(this.applicationData.LoanAmount) > Number(this.applicationData.minAmount)) {
        this.isPublicNotice = true;
      }
      this.service.GetPVRData(this.Route.snapshot.params.AppID).subscribe((PVRData) => {
        this.PVRDetailsLoaded = true;
        this.PVRData = PVRData.data.PVR_Details;
      });
      this.dtOptions1 = {
        serverSide: false,
        ajax: (dataTablesParameters: any, callback) => {
          this.http
            .get<DataTablesResponse>(
              this.service.GetBaseUrl() + `GetPulicNotice/` + this.appID,
              {}
            ).subscribe((resp: any) => {
              this.noticelist = resp.data;
              this.isallNoticeExpired = this.CompaireNotice();
              if (this.isallNoticeExpired && this.noticelist.length > 0 && this.applicationData.ApplicationStatus == 'TC Notice In Progress') {
                this.updateAppStatus('TC Notice Completed');
              }
              this.noticelist.forEach(element => {
                if (element.isExp == 0) {
                  this.isNoticeActive = true;
                }
              });
              callback({
                recordsTotal: resp.data.length,
                recordsFiltered: resp.data.length,
                data: resp.data
              });
            });
        },
        // ajax: { url: this.service.GetBaseUrl() + `GetPulicNotice/` + this.appID},
        responsive: true,
        // ajax: { url: this.service.GetBaseUrl() + `tcc/loan/application/Documents/AppID/${this.Route.snapshot.params.AppID}` }, responsive: true,
        columns: [{
          title: 'Sr No.', data: 'row', render: (data, type, row, meta) => {
            return meta.row + 1;
          }
        },
        {
          title: 'Entry Date',
          name: 'EntryDate',
          data: 'EntryDate',
          render: (data, type, row) => {
            return this.datepipe.transform(data, 'MMM dd,yyyy');
          }
        }, {
          title: 'Publish Date',
          name: 'PublishDate',
          data: 'PublishDate',
          render: (data, type, row) => {
            return this.datepipe.transform(data, 'MMM dd,yyyy');
          }
        }, {
          title: 'Notification Days',
          name: 'NoticeDays',
          data: 'NoticeDays',
          render: (data, type, row) => {
            return data;
          }
        }, {
          title: 'Published By',
          name: 'PublishedBy',
          data: 'PublishedBy',
          render: (data, type, row) => {
            return row.PublishedBy
          }
        }, {
          title: 'Status',
          render: (data, type, row) => {
            if (row.isExp === 1 || row.isExp === '1') {
              return `<span class="badge badge-danger p-1">Completed</span>`;
            } else {
              return `<span class="badge badge-success p-1">Published</span>`;
            }
          }
        }, {
          title: 'Action',
          render: (data, type, row) => {
            let btn = '';
            btn += '<a href="javascript:void(0)" class="viewnotice m-1" title="View Notice and Responses" receipt-id="' + row.Id + '">';
            btn += '<i class="mdi mdi-eye font-18 text-secondary" aria-hidden="false" receipt-id="' + row.Id + '"></i>';
            btn += '</a>';
            if (row.isExp === 0 || row.isExp === '0') {
              btn += '<a href="javascript:void(0)" class="addresponse m-1" title="Add a Response" DocumentID="' + row.Id + '">';
              btn += '<i class="mdi mdi-file-upload-outline font-18 text-secondary" aria-hidden="false" DocumentID="' + row.Id + '"></i>';
              btn += '</a>';
            }
            return btn;
          },
        }, {
          title: 'Description',
          render: (data, type, row) => {
            return row.Description.split(`#`).join("'");
          },
        }
        ],
        columnDefs: [{ targets: 6, orderable: false }],
        drawCallback: () => {
          $('.viewnotice').on('click', (e) => {
            this.router.navigate(['/loan/viewNotice/' + this.Route.snapshot.params.AppID + '/' + $(e.target).attr('receipt-id')]);
          });
          $('.addresponse').on('click', (e) => {
            this.router.navigate(['/loan/addResponse/' + this.Route.snapshot.params.AppID + '/' + $(e.target).attr('DocumentID')]);
          });
        }
      };

      this.dtOptions = {
        ajax: (dataTablesParameters: any, callback) => {
          this.http
            .get<DataTablesResponse>(
              this.service.GetBaseUrl() + `tcc/loan/application/Documents/AppID/${this.Route.snapshot.params.AppID}`,
              {}
            ).subscribe((resp: any) => {
              const list = resp.data;
              this.DocumentList = resp.data;
              this.isallDocumentsReviewed = this.Compaire();
              if (this.isallDocumentsReviewed == true && this.applicationData.ApplicationStatus == 'TC Document In Progress') {
                this.updateAppStatus('TC Document Completed');
              }
              console.log(this.isallDocumentsReviewed);

              callback({
                recordsTotal: resp.data.length,
                recordsFiltered: resp.data.length,
                data: list
              });
            });
        },
        responsive: true,
        columns: [{
          title: 'Sr No.', data: 'row', render: (data, type, row, meta) => {
            return meta.row + 1;
          }
        }, {
          title: 'Document',
          render: (data, type, row) => {
            return row.DocumentName.split(`.PDF`).join("");

          }
        }, {
          title: 'Status',
          render: (data, type, row) => {
            if (row.Status === 'Pending') {
              return `<span class="badge badge-danger p-1">${row.Status}</span>`;
            } else if (row.Status === 'Received') {
              return `<span class="badge badge-primary p-1">${row.Status}</span>`;
            } else if (row.Status === 'requested' || row.Status === 'Requested') {
              return `<span class="badge bg-soft-warning text-warning p-1">${row.Status}</span>`;
            } else {
              return `<span class="badge badge-success p-1">${row.Status}</span>`;
            }
          }
        }, {
          title: 'Action',
          data: null
        },
        {
          title: 'Comment',
          render: (data, type, row) => {
            let comment = '';
            if (row.DocumentComment != undefined) {
              row.DocumentComment.forEach(element => {
                comment += element.Comment + '<br>';
              });
              return comment;
            }
            else {
              return '';
            }
          },
        }
        ],
        columnDefs: [{ targets: 3, orderable: false }],
        rowCallback(row, data: any) {
          let btn = '';
          if (data.FileURL === null && response.data[0].ApplicationStatus !== 'Title Clear Sent') {
            btn += '<a href="javascript:void(0)" class="uploadDocument m-1" title="Upload Document" DocumentSubType="' + data.DocumentSubType + '" DocumentType="' + data.DocumentType + '" DocumentName="' + data.DocumentName + '">';
            btn += '<i class="mdi mdi-file-upload-outline font-18 text-secondary" aria-hidden="false" DocumentSubType="' + data.DocumentSubType + '" DocumentType="' + data.DocumentType + '" DocumentName="' + data.DocumentName + '"></i>';
            btn += '</a>';
            btn += '<a href="javascript:void(0)" class="requestDocument m-1" title="Request this Document" DocumentSubType="' + data.DocumentSubType + '" DocumentType="' + data.DocumentType + '" DocumentName="' + data.DocumentName + '">';
            btn += '<i class="mdi mdi mdi-file-question font-18 text-secondary" aria-hidden="false" DocumentSubType="' + data.DocumentSubType + '" DocumentType="' + data.DocumentType + '" DocumentName="' + data.DocumentName + '"></i>';
            btn += '</a>';
          } else if (data.FileURL !== null) {
            if (data.Status !== 'Reviewed' && response.data[0].ApplicationStatus !== 'Title Clear Sent') {
              btn += '<a href="javascript:void(0)" class="uploadDocument m-1" title="Upload Document" DocumentSubType="' + data.DocumentSubType + '" DocumentType="' + data.DocumentType + '" DocumentName="' + data.DocumentName + '">';
              btn += '<i class="mdi mdi-file-upload-outline font-18 text-secondary" aria-hidden="false" DocumentSubType="' + data.DocumentSubType + '" DocumentType="' + data.DocumentType + '" DocumentName="' + data.DocumentName + '"></i>';
              btn += '</a>';
              btn += '<a href="javascript:void(0)" class="requestDocument m-1" title="Request this Document" DocumentName="' + data.DocumentName + '">';
              btn += '<i class="mdi mdi mdi-file-question font-18 text-secondary" aria-hidden="false" DocumentName="' + data.DocumentName + '"></i>';
              btn += '</a>';
            }
            btn += '<a href="javascript:void(0)" class="viewDocument m-1" title="View Document" receipt-id="' + data.DocumentID + '">';
            btn += '<i class="mdi mdi-eye font-18 text-secondary" aria-hidden="false" receipt-id="' + data.DocumentID + '"></i>';
            btn += '</a>';
            if (data.dropdownitemtype === 'multiple') {
              btn += '<a href="javascript:void(0)" class="uploadDocument m-1" title="Upload Another Document" receipt-id="' + data.DocumentID + '">';
              btn += '<i class="mdi mdi-plus font-18 text-secondary" aria-hidden="false" receipt-id="' + data.DocumentID + '"></i>';
              btn += '</a>';
            }
          }
          if (data.isMandatory == true) {
            btn += '<a title="Mark as Non-Mandatory" class="update m-1" >'
            btn += '<i class="font-18 mdi mdi-cancel text-secondary"  DocumentID="' + data.DocumentID + '" DocumentSubType="' + data.DocumentSubType + '" DocumentType="' + data.DocumentType + '" isMandatory="0"></i>'
            btn += '</a>';
          } else {
            btn += '<a title="Mark as Mandatory" class="update m-1" >'
            btn += '<i class="font-18 mdi mdi-recycle  text-secondary"  DocumentID="' + data.DocumentID + '"DocumentSubType="' + data.DocumentSubType + '" DocumentType="' + data.DocumentType + '" isMandatory="1"></i>'
            btn += '</a>';
          }
          $('td:eq(3)', row).html(btn);
        }, drawCallback: () => {
          $('.uploadDocument').on('click', (e) => {
            this.onUploadDocument($(e.target).attr('DocumentType'), $(e.target).attr('DocumentSubType'), $(e.target).attr('DocumentName'));
          });
          $('.viewDocument').on('click', (e) => {
            this.onViewDocument($(e.target).attr('receipt-id'));
          });
          $('.requestDocument').on('click', (e) => {
            this.openRequestDocumentDialog($(e.target).attr('DocumentType'), $(e.target).attr('DocumentSubType'), $(e.target).attr('DocumentName'));
          });
          $('.update').on('click', (e) => {
            this.UpdateDocument($(e.target).attr('DocumentType'), $(e.target).attr('DocumentSubType'), $(e.target).attr('DocumentID'), $(e.target).attr('isMandatory'));
          });
        }
      };
    });

  }
  onUploadDocument(doctype, docsubtype, docname) {
    this.router.navigate(['loan/uploadlawyerdocument/' + this.applicationData.AppID], { queryParams: { DocumentType: doctype, DocumentSubType: docsubtype, DocumentName: docname } });
  }
  onViewDocument(id) {
    this.router.navigate(['loan/viewdocument/' + this.applicationData.PropertyID + '/' + id + '/' + this.applicationData.AppID]);
  }
  uploadOtherDocument() {
    this.router.navigate(['loan/uploadlawyerdocument/' + this.applicationData.AppID], { queryParams: { OtherDocument: true } });
  }
  proceedTitleClear() {
    this.router.navigate(['loan/titleclear/' + this.applicationData.AppID]);
  }
  getBankManagerDetails(BankID) {
    this.service.GetUser(BankID).subscribe((res) => {
      this.isLoading = false;
      this.BankManagerDetails = res.data[0];
      console.log(this.BankManagerDetails);
    });
  }
  onRequestingDocument(docname) {
    const data: any = {
      DocumentName: docname,
      PropertyID: this.applicationData.PropertyID
    }
    this.service.RequestDocument(data).subscribe((res) => {
      this.isLoading = false;
      if (res.status == 200) {
        Swal.fire({
          title: `Success`,
          text: res.message,
          type: 'success',
          showConfirmButton: false,
          timer: 3000
        }).then(() => { this.router.navigate(['/loan/viewNotice/' + this.Route.snapshot.params.AppID + '/' + this.Route.snapshot.params.NoticeID]); })
      } else {
        Swal.fire({
          title: `Error`,
          text: res.message,
          type: 'error',
          showConfirmButton: false,
          timer: 3000
        }).then(() => { });
      }
    }, (error) => {
      this.isLoading = false;
      Swal.fire({
        title: `Error`,
        text: 'Internal Server Error',
        type: 'error',
        showConfirmButton: false,
        timer: 3000
      }).then(() => { });
    });

  }
  Compaire() {
    let MandatoryDocumentList: any = [];
    this.DocumentList.forEach((element) => {
      if (element.isMandatory === true) {
        MandatoryDocumentList.push(element);
      }
    });
    return MandatoryDocumentList.every(x => x.Status === 'Reviewed');
  }
  CompaireNotice() {
    return this.noticelist.every(x => x.isExp === '1' || x.isExp === 1);
  }
  addnotice() {
    this.router.navigate(['/loan/addPublicNotice/' + this.Route.snapshot.params.AppID]);
  }
  updateAppStatus(status) {
    let data = {
      applicationStatus: status
    }
    this.isLoading = true;
    console.log(data);
    this.service.updateAppStatus(this.Route.snapshot.params.AppID, data).subscribe((res) => {
      this.isLoading = false;
    }, (error) => {
      this.isLoading = false;
    });
    this.isLoading = false;
  }
  addResponse() {
    this.router.navigate(['/loan/viewpastpublicnotice/' + this.Route.snapshot.params.AppID]);
  }

  openRequestDocumentDialog(doctype, docsubtype, docname): void {
    const dialogRef = this.dialog.open(RequestDocumentDialogComponent, {
      disableClose: true,
      width: '480px',
      data: {
        AppID: this.Route.snapshot.params.AppID,
        DocumentSubType: docsubtype,
        DocumentType: doctype,
        DocumentName: docname,
        PropertyID: this.applicationData.PropertyID,
        DropDownValue:
          [
            { ID: this.applicationData.ApplicantID, Name: 'Applicant' },
            { ID: this.applicationData.ApplicationCreatedBy, Name: 'Bank Manager' },
            { ID: '0', Name: 'Proplegit Admin' },
          ]
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.ngOnInit();
    });
  }
  rerender(): void {
    this.dtElement1.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger1.next();
    });
  }
  refreshTable() {
    this.dtOptions = {
      ajax: (dataTablesParameters: any, callback) => {
        this.http
          .get<DataTablesResponse>(
            this.service.GetBaseUrl() + `tcc/loan/application/Documents/AppID/${this.Route.snapshot.params.AppID}`,
            {}
          ).subscribe((resp: any) => {
            const list = resp.data;
            this.DocumentList = resp.data;
            this.isallDocumentsReviewed = this.Compaire();
            if (this.isallDocumentsReviewed == true && this.applicationData.ApplicationStatus == 'TC Document In Progress') {
              this.updateAppStatus('TC Document Completed');
              this.isLoading = false;
            }
            console.log(this.isallDocumentsReviewed);

            callback({
              recordsTotal: resp.data.length,
              recordsFiltered: resp.data.length,
              data: list
            });
          });
      },
      responsive: true,
      columns: [{
        title: 'Sr No.', data: 'row', render: (data, type, row, meta) => {
          return meta.row + 1;
        }
      }, {
        title: 'Document',
        render: (data, type, row) => {
          return row.DocumentName.split(`.PDF`).join("");

        }
      }, {
        title: 'Status',
        render: (data, type, row) => {
          if (row.Status === 'Pending') {
            return `<span class="badge badge-danger p-1">${row.Status}</span>`;
          } else if (row.Status === 'Received') {
            return `<span class="badge badge-primary p-1">${row.Status}</span>`;
          } else if (row.Status === 'requested' || row.Status === 'Requested') {
            return `<span class="badge bg-soft-warning text-warning p-1">${row.Status}</span>`;
          } else {
            return `<span class="badge badge-success p-1">${row.Status}</span>`;
          }
        }
      }, {
        title: 'Action',
        data: null
      },
      {
        title: 'Comment',
        render: (data, type, row) => {
          let comment = '';
          if (row.DocumentComment != undefined) {
            row.DocumentComment.forEach(element => {
              comment += element.Comment + '<br>';
            });
            return comment;
          }
          else {
            return '';
          }
        },
      }
      ], rowCallback(row, data: any) {
        let btn = '';
        if (data.FileURL === null && this.applicationData.ApplicationStatus !== 'Title Clear Sent') {
          btn += '<a href="javascript:void(0)" class="uploadDocument m-1" title="Upload Document" DocumentSubType="' + data.DocumentSubType + '" DocumentType="' + data.DocumentType + '" DocumentName="' + data.DocumentName + '">';
          btn += '<i class="mdi mdi-file-upload-outline font-18 text-secondary" aria-hidden="false" DocumentSubType="' + data.DocumentSubType + '" DocumentType="' + data.DocumentType + '" DocumentName="' + data.DocumentName + '"></i>';
          btn += '</a>';
          btn += '<a href="javascript:void(0)" class="requestDocument m-1" title="Request this Document" DocumentSubType="' + data.DocumentSubType + '" DocumentType="' + data.DocumentType + '" DocumentName="' + data.DocumentName + '">';
          btn += '<i class="mdi mdi mdi-file-question font-18 text-secondary" aria-hidden="false" DocumentSubType="' + data.DocumentSubType + '" DocumentType="' + data.DocumentType + '" DocumentName="' + data.DocumentName + '"></i>';
          btn += '</a>';
        } else if (data.FileURL !== null) {
          if (data.Status !== 'Reviewed' && this.applicationData.ApplicationStatus !== 'Title Clear Sent') {
            btn += '<a href="javascript:void(0)" class="uploadDocument m-1" title="Upload Document" DocumentSubType="' + data.DocumentSubType + '" DocumentType="' + data.DocumentType + '" DocumentName="' + data.DocumentName + '">';
            btn += '<i class="mdi mdi-file-upload-outline font-18 text-secondary" aria-hidden="false" DocumentSubType="' + data.DocumentSubType + '" DocumentType="' + data.DocumentType + '" DocumentName="' + data.DocumentName + '"></i>';
            btn += '</a>';
            btn += '<a href="javascript:void(0)" class="requestDocument m-1" title="Request this Document" DocumentName="' + data.DocumentName + '">';
            btn += '<i class="mdi mdi mdi-file-question font-18 text-secondary" aria-hidden="false" DocumentName="' + data.DocumentName + '"></i>';
            btn += '</a>';
          }
          btn += '<a href="javascript:void(0)" class="viewDocument m-1" title="View Document" receipt-id="' + data.DocumentID + '">';
          btn += '<i class="mdi mdi-eye font-18 text-secondary" aria-hidden="false" receipt-id="' + data.DocumentID + '"></i>';
          btn += '</a>';
          if (data.dropdownitemtype === 'multiple') {
            btn += '<a href="javascript:void(0)" class="uploadDocument m-1" title="Upload Another Document" receipt-id="' + data.DocumentID + '">';
            btn += '<i class="mdi mdi-plus font-18 text-secondary" aria-hidden="false" receipt-id="' + data.DocumentID + '"></i>';
            btn += '</a>';
          }
        }
        if (data.isMandatory == true) {
          btn += '<a title="Mark as Non-Mandatory" class="update m-1" >'
          btn += '<i class="font-18 mdi mdi-cancel text-secondary"  DocumentID="' + data.DocumentID + '" DocumentSubType="' + data.DocumentSubType + '" DocumentType="' + data.DocumentType + '" isMandatory="0"></i>'
          btn += '</a>';
        } else {
          btn += '<a title="Mark as Mandatory" class="update m-1" >'
          btn += '<i class="font-18 mdi mdi-recycle  text-secondary"  DocumentID="' + data.DocumentID + '"DocumentSubType="' + data.DocumentSubType + '" DocumentType="' + data.DocumentType + '" isMandatory="1"></i>'
          btn += '</a>';
        }
        $('td:eq(3)', row).html(btn);
      }, drawCallback: () => {
        $('.uploadDocument').on('click', (e) => {
          this.onUploadDocument($(e.target).attr('DocumentType'), $(e.target).attr('DocumentSubType'), $(e.target).attr('DocumentName'));
        });
        $('.viewDocument').on('click', (e) => {
          this.onViewDocument($(e.target).attr('receipt-id'));
        });
        $('.requestDocument').on('click', (e) => {
          this.openRequestDocumentDialog($(e.target).attr('DocumentType'), $(e.target).attr('DocumentSubType'), $(e.target).attr('DocumentName'));
        });
        $('.update').on('click', (e) => {
          this.UpdateDocument($(e.target).attr('DocumentType'), $(e.target).attr('DocumentSubType'), $(e.target).attr('DocumentID'), $(e.target).attr('isMandatory'));
        });
      }
    };
  }
  ngAfterViewInit() {
    this.dtTrigger1.next();
  }
  UpdateDocument(DocType, DocSubType, DocID, isMemdatory) {
    let data: any;
    if (DocID === null || DocID === 'null' || DocID === '' || DocID === undefined) {
      data = {
        "PropertyID": this.applicationData.PropertyID,
        "UserID": this.currentUser.UserID,
        "DocumentTypeId": DocType,
        "DocumentID": '',
        "DocumentSubTypeId": DocSubType,
        "isMandatory": Number(isMemdatory)
      };
    } else {
      data = {
        "PropertyID": this.applicationData.PropertyID,
        "UserID": this.currentUser.UserID,
        "DocumentID": DocID,
        "DocumentTypeId": '',
        "DocumentSubTypeId": '',
        "isMandatory": Number(isMemdatory)
      };
    }
    this.isLoading = true;
    console.log(data);
    this.service.UpdateDocument(data).subscribe((res) => {
      this.isLoading = false;
      if (res.status == 200) {
        this.updateAppStatus('TC Document In Progress')
        Swal.fire({
          title: `Success`,
          text: `${Number(isMemdatory) == 0 ? 'Document has been Marked as Non Mandatory.' : 'Document has been Marked as Mandatory.'}`,
          type: 'success',
          showConfirmButton: false,
          timer: 3000
        }).then(() => { this.ngOnInit(); })
      } else {
        Swal.fire({
          title: `Error`,
          text: res.message,
          type: 'error',
          showConfirmButton: false,
          timer: 3000
        }).then(() => { });
      }
    }, (error) => {
      this.isLoading = false;
      Swal.fire({
        title: `Error`,
        text: 'Internal Server Error',
        type: 'error',
        showConfirmButton: false,
        timer: 3000
      }).then(() => { });
    });
  }
}
