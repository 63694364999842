import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { GeneralService } from 'src/app/services/general.service';
import Swal from 'sweetalert2';
import { RequestDocumentDialogComponent } from '../request-document-dialog/request-document-dialog.component';
class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}
@Component({
  selector: 'app-document-list',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.scss']
})
export class DocumentListComponent implements OnInit {
  @Input() appID: string;
  dtOptions: DataTables.Settings = {};
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtTrigger = new Subject<any>();
  @ViewChild('uploadPVR', { static: true }) uploadPVRModal;
  applicationData: any;
  isLoading: boolean;
  DocumentList: any;
  isallDocumentsReviewed: any;
  currentUser: any;
  constructor(private service: GeneralService, private datepipe: DatePipe, private http: HttpClient, private Route: ActivatedRoute, private router: Router, public dialog: MatDialog) { }

  ngOnInit() {
    console.log(this.appID);
    this.currentUser = this.service.getcurrentUser();
    this.isLoading = true;
    this.service.GetApplicationInformation(this.appID).subscribe((response) => {
      this.isLoading = false;
      console.log('abdc')
      this.applicationData = response.data[0];
      this.dtOptions = {
        ajax: (dataTablesParameters: any, callback) => {
          this.http
            .get<DataTablesResponse>(
              this.service.GetBaseUrl() + `tcc/loan/application/Documents/AppID/${this.Route.snapshot.params.AppID}`,
              {}
            ).subscribe((resp: any) => {
              const list = resp.data;
              this.DocumentList = resp.data;
              this.isallDocumentsReviewed = this.Compaire();
              if (this.isallDocumentsReviewed == true && this.applicationData.ApplicationStatus == 'TC Document In Progress') {
                this.updateAppStatus('TC Document Completed');
              }
              console.log(this.isallDocumentsReviewed);

              callback({
                recordsTotal: resp.data.length,
                recordsFiltered: resp.data.length,
                data: list
              });
            });
        },
        responsive: true,
        columns: [{
          title: 'Sr No.', data: 'row', render: (data, type, row, meta) => {
            return meta.row + 1;
          }
        }, {
          title: 'Document',
          render: (data, type, row) => {
            return row.DocumentName.split(`.PDF`).join("");

          }
        }, {
          title: 'Status',
          render: (data, type, row) => {
            if (row.Status === 'Pending') {
              return `<span class="badge badge-danger p-1">${row.Status}</span>`;
            } else if (row.Status === 'Received') {
              return `<span class="badge badge-primary p-1">${row.Status}</span>`;
            } else if (row.Status === 'requested' || row.Status === 'Requested') {
              return `<span class="badge bg-soft-warning text-warning p-1">${row.Status}</span>`;
            } else {
              return `<span class="badge badge-success p-1">${row.Status}</span>`;
            }
          }
        }, {
          title: 'Action',
          data: null
        },
        {
          title: 'Comment',
          render: (data, type, row) => {
            let comment = '';
            if (row.DocumentComment != undefined) {
              row.DocumentComment.forEach(element => {
                comment += element.Comment + '<br>';
              });
              return comment;
            }
            else {
              return '';
            }
          },
        }
        ], rowCallback(row, data: any) {
          let btn = '';
          if (data.FileURL === null && response.data[0].ApplicationStatus !== 'Title Clear Sent') {
            btn += '<a href="javascript:void(0)" class="uploadDocument m-1" title="Upload Document" DocumentSubType="' + data.DocumentSubType + '" DocumentType="' + data.DocumentType + '" DocumentName="' + data.DocumentName + '">';
            btn += '<i class="mdi mdi-file-upload-outline font-18 text-secondary" aria-hidden="false" DocumentSubType="' + data.DocumentSubType + '" DocumentType="' + data.DocumentType + '" DocumentName="' + data.DocumentName + '"></i>';
            btn += '</a>';
            btn += '<a href="javascript:void(0)" class="requestDocument m-1" title="Request this Document" DocumentSubType="' + data.DocumentSubType + '" DocumentType="' + data.DocumentType + '" DocumentName="' + data.DocumentName + '">';
            btn += '<i class="mdi mdi mdi-file-question font-18 text-secondary" aria-hidden="false" DocumentSubType="' + data.DocumentSubType + '" DocumentType="' + data.DocumentType + '" DocumentName="' + data.DocumentName + '"></i>';
            btn += '</a>';
          } else if (data.FileURL !== null) {
            if (data.Status !== 'Reviewed' && response.data[0].ApplicationStatus !== 'Title Clear Sent') {
              btn += '<a href="javascript:void(0)" class="uploadDocument m-1" title="Upload Document" DocumentSubType="' + data.DocumentSubType + '" DocumentType="' + data.DocumentType + '" DocumentName="' + data.DocumentName + '">';
              btn += '<i class="mdi mdi-file-upload-outline font-18 text-secondary" aria-hidden="false" DocumentSubType="' + data.DocumentSubType + '" DocumentType="' + data.DocumentType + '" DocumentName="' + data.DocumentName + '"></i>';
              btn += '</a>';
              btn += '<a href="javascript:void(0)" class="requestDocument m-1" title="Request this Document" DocumentName="' + data.DocumentName + '">';
              btn += '<i class="mdi mdi mdi-file-question font-18 text-secondary" aria-hidden="false" DocumentName="' + data.DocumentName + '"></i>';
              btn += '</a>';
            }
            btn += '<a href="javascript:void(0)" class="viewDocument m-1" title="View Document" receipt-id="' + data.DocumentID + '">';
            btn += '<i class="mdi mdi-eye font-18 text-secondary" aria-hidden="false" receipt-id="' + data.DocumentID + '"></i>';
            btn += '</a>';
            if (data.dropdownitemtype === 'multiple') {
              btn += '<a href="javascript:void(0)" class="uploadDocument m-1" title="Upload Another Document" receipt-id="' + data.DocumentID + '">';
              btn += '<i class="mdi mdi-plus font-18 text-secondary" aria-hidden="false" receipt-id="' + data.DocumentID + '"></i>';
              btn += '</a>';
            }
          }
          if (data.isMandatory == true) {
            btn += '<a title="Mark as Non-Mendatory" class="update m-1" >'
            btn += '<i class="font-18 mdi mdi-cancel text-secondary"  DocumentID="' + data.DocumentID + '" DocumentSubType="' + data.DocumentSubType + '" DocumentType="' + data.DocumentType + '" isMendatory="0"></i>'
            btn += '</a>';
          } else {
            btn += '<a title="Mark as Mendatory" class="update m-1" >'
            btn += '<i class="font-18 mdi mdi-recycle  text-secondary"  DocumentID="' + data.DocumentID + '"DocumentSubType="' + data.DocumentSubType + '" DocumentType="' + data.DocumentType + '" isMendatory="1"></i>'
            btn += '</a>';
          }
          $('td:eq(3)', row).html(btn);
        }, drawCallback: () => {
          $('.uploadDocument').on('click', (e) => {
            this.onUploadDocument($(e.target).attr('DocumentType'), $(e.target).attr('DocumentSubType'), $(e.target).attr('DocumentName'));
          });
          $('.viewDocument').on('click', (e) => {
            this.onViewDocument($(e.target).attr('receipt-id'));
          });
          $('.requestDocument').on('click', (e) => {
            this.openRequestDocumentDialog($(e.target).attr('DocumentType'), $(e.target).attr('DocumentSubType'), $(e.target).attr('DocumentName'));
          });
          $('.update').on('click', (e) => {
            this.UpdateDocument($(e.target).attr('DocumentType'), $(e.target).attr('DocumentSubType'), $(e.target).attr('DocumentID'), $(e.target).attr('isMendatory'));
          });
        }
      };
    });
    
  }
  onUploadDocument(doctype, docsubtype, docname) {
    this.router.navigate(['loan/uploadlawyerdocument/' + this.applicationData.AppID], { queryParams: { DocumentType: doctype, DocumentSubType: docsubtype, DocumentName: docname } });
  }
  onViewDocument(id) {
    this.router.navigate(['loan/viewdocument/' + this.applicationData.PropertyID + '/' + id + '/' + this.applicationData.AppID]);
  }
  openRequestDocumentDialog(doctype, docsubtype, docname): void {
    const dialogRef = this.dialog.open(RequestDocumentDialogComponent, {
      disableClose: true,
      width: '480px',
      data: {
        AppID: this.Route.snapshot.params.AppID,
        DocumentSubType: docsubtype,
        DocumentType: doctype,
        DocumentName: docname,
        PropertyID: this.applicationData.PropertyID,
        DropDownValue:
          [
            { ID: this.applicationData.ApplicantID, Name: 'Applicant' },
            { ID: this.applicationData.ApplicationCreatedBy, Name: 'Bank Manager' },
            { ID: '0', Name: 'Proplegit Admin' },
          ]
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (this.applicationData.ApplicationStatus == 'TitleClear In Progress') {
        this.updateAppStatus('TC Document In Progress');
      }
      if(result){
        this.ngOnInit();
      }
    });
  }
  UpdateDocument(DocType, DocSubType, DocID, isMemdatory) {
    let data: any;
    if (DocID === null || DocID === 'null' || DocID === '' || DocID === undefined) {
      data = {
        "PropertyID": this.applicationData.PropertyID,
        "UserID": this.currentUser.UserID,
        "DocumentTypeId": DocType,
        "DocumentID": '',
        "DocumentSubTypeId": DocSubType,
        "isMandatory": Number(isMemdatory)
      };
    } else {
      data = {
        "PropertyID": this.applicationData.PropertyID,
        "UserID": this.currentUser.UserID,
        "DocumentID": DocID,
        "DocumentTypeId": '',
        "DocumentSubTypeId": '',
        "isMandatory": Number(isMemdatory)
      };
    }
    this.isLoading = true;
    console.log(data);
    this.service.UpdateDocument(data).subscribe((res) => {
      this.isLoading = false;
      if (res.status == 200) {
        if (this.applicationData.ApplicationStatus == 'TitleClear In Progress') {
          this.updateAppStatus('TC Document In Progress');
        }
        Swal.fire({
          title: `Success`,
          text: res.message,
          type: 'success',
          showConfirmButton: false,
          timer:3000
        }).then(() => { this.ngOnInit(); })
      } else {
        Swal.fire({
          title: `Error`,
          text: res.message,
          type: 'error',
          showConfirmButton: false,
          timer:3000
        }).then(() => { });
      }
    }, (error) => {
      this.isLoading = false;
      Swal.fire({
        title: `Error`,
        text: 'Internal Server Error',
        type: 'error',
        showConfirmButton: false,
        timer:3000
      }).then(() => { });
    });
  }
  updateAppStatus(status) {
    let data = {
      applicationStatus: status
    }
    this.isLoading = true;
    console.log(data);
    this.service.updateAppStatus(this.Route.snapshot.params.AppID, data).subscribe((res) => {
      this.isLoading = false;
    }, (error) => {
      this.isLoading = false;
    });
  }
  Compaire() {
    let MendatoryDocumentList: any = [];
    this.DocumentList.forEach((element) => {
      if (element.isMandatory === true) {
        MendatoryDocumentList.push(element);
      }
    });
    return MendatoryDocumentList.every(x => x.Status === 'Reviewed');
  }
}
