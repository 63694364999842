import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-view-response-dialog',
  templateUrl: './view-response-dialog.component.html',
  styleUrls: ['./view-response-dialog.component.scss']
})
export class ViewResponseDialogComponent implements OnInit {
  url: any;
  extension: any;

  constructor(
    public dialogRef: MatDialogRef<ViewResponseDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.url = this.data.docurl;
    this.extension = this.url.split('.');
    this.extension = this.extension[this.extension.length - 1].toLowerCase();
   }

  ngOnInit() {
    console.log(this.url, this.extension);
  }

}
