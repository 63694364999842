import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { GeneralService } from 'src/app/services/general.service';
import Swal from 'sweetalert2';
import { ImageModalComponent } from '../image-modal/image-modal.component';
import { ViewDocumentDialogComponent } from '../view-document-dialog/view-document-dialog.component';
class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}
@Component({
  selector: 'app-post-mortgage-dashboard',
  templateUrl: './post-mortgage-dashboard.component.html',
  styleUrls: ['./post-mortgage-dashboard.component.scss']
})
export class PostMortgageDashboardComponent implements OnInit {
  breadCrumbItems: Array<{}>;
  submitted:boolean=false;
  interval;
  reviewd = 0;
  lawyerInfo: any;
  IsLawyerLoaded: boolean;
  index = 0;
  PVRData: any;
  array = ['2425', ];
  PVRDetailsLoaded: boolean;
  statusList = ['Village Level Officer has received the request',
    'Village Level Officer has fetched the latest Govt. Land Records', 'Latest Land Record Document being uploaded',
    'Documents are under review by PropLegit', 'PVR getting finalized'];
  meth: any;
  totaldocument = 0;
  pending = 0;
  received = 0;
  AppID: any;
  dtOptions1: DataTables.Settings = {};
  dtOptions: DataTables.Settings = {};
  propertyDocumentData: any;
  loaded: boolean;
  applicationData: any;
  revenueRadialChart: any;
  LawyerDetails: any;
  documentData: any;
  latestDate: any;
  view:boolean=false;
  view_1:boolean=false;
  notification_date: any;
  fileUrl:string;
  firstName: any;
  route: any;
  titleclearData: any;

  constructor(
    private service: GeneralService, private datepipe: DatePipe,  private Route: ActivatedRoute, private router: Router, public dialog: MatDialog,
    private formBuilder: FormBuilder) {
    this.AppID = this.Route.snapshot.params.id;
     this.latestDate = new Date();
    this.latestDate = this.datepipe.transform(this.latestDate, 'dd MMM yyyy hh:mm a');
    console.log("line===61",this.latestDate)

  }
  ngOnDestroy() {
    clearInterval(this.interval);
  }
  ngOnInit() {
    this.loaded = false;
    console.log(typeof(this.array));
    this.checkDocument();
    this.IsLawyerLoaded = false;
    this.breadCrumbItems = [{ label: 'Dashboard', path: '/' },
    { label: 'Applications', path: '/loan/post-mortgage-applications' }, { label: 'Title Search', path: '/', active: true }];
      this.service.GetApplicationInformation(this.Route.snapshot.params.id).subscribe((res) => {
      this.applicationData = res.data[0];
      // this.notification_date = res.data[0].notification_date;
      this.dtOptions1 = {
        ajax: { url: this.service.GetBaseUrl() + `postMortgage?district=${this.applicationData.DistrictName}&taluka=${this.applicationData.TalukaName}&village=${this.applicationData.VillageName}&survey=${this.applicationData.SurveyNo}`}, responsive: true,
        columns: [{
          title: 'Sr No.', data: 'row', render: (data, type, row, meta) => {
            if(meta.row === 0){
              this.notification_date = row.notification_date;
              this.fileUrl = row.full_image_path;
            }
            return meta.row + 1;
          }
        }, {
          title: 'Document',
          render: (data, type, row) => {
            this.documentData = row;
            return `Public Notice - ${this.datepipe.transform(row.notification_date, 'MMM dd,yyyy')}`;
          },
        }, {
          title: 'Status',
          render: (data, type, row) => {
            return `<span class="badge badge-danger p-1">Alert</span>`;
            // if (row.Status === 'Pending') {
            //   return `<span class="badge badge-primary p-1">${row.Status}</span>`;
            // } else {
            //   return `<span class="badge badge-success p-1">${row.Status}</span>`;
            // }
          }
        }, {
          title: 'Action',
          data: null
        }
        ], 
        columnDefs: [{targets:3,orderable:false}],
        rowCallback(row, data: any) {
          let btn = '';
          btn += '<a href="javascript:void(0)" class="openDocument m-1" title="View Document" id="' + data.id + '">';
          btn += '<i class="mdi mdi-eye font-18 text-secondary" aria-hidden="false" id="' + data.id + '"></i>';
          btn += '</a>';
          // if (data.FileURL === null) {
          //   btn += '<a href="javascript:void(0)" class="uploadDocument m-1" title="Upload Document" DocumentSubType="' + data.DocumentSubType + '" DocumentType="' + data.DocumentType + '" DocumentName="' + data.DocumentName + '">';
          //   btn += '<i class="mdi mdi-file-upload-outline font-18 text-secondary" aria-hidden="false" DocumentSubType="' + data.DocumentSubType + '" DocumentType="' + data.DocumentType + '" DocumentName="' + data.DocumentName + '"></i>';
          //   btn += '</a>';
          // } else if (data.FileURL !== null && typeof(data.DocumentID) == 'object') {
          //   btn += '<a href="javascript:void(0)" class="viewDocument m-1" title="View Document" DocumentID="' + data.DocumentID[0] + '">';
          //   btn += '<i class="mdi mdi-eye font-18 text-secondary" aria-hidden="false" DocumentID="' + data.DocumentID[0] + '"></i>';
          //   btn += '</a>';
          //   if (data.Status !== 'Reviewed') {
          //     btn += '<a href="' + data.FileURL + '" class="m-1" title="Download This Document">';
          //     btn += '<i class="mdi mdi-file-download font-18 text-secondary" aria-hidden="false"></i>';
          //     btn += '</a>';
          //   }
          // } else if (data.FileURL !== null && typeof(data.DocumentID) != 'object') {
          //   btn += '<a href="javascript:void(0)" class="viewDocument m-1" title="View Document" DocumentID="' + data.DocumentID + '">';
          //   btn += '<i class="mdi mdi-eye font-18 text-secondary" aria-hidden="false" DocumentID="' + data.DocumentID + '"></i>';
          //   btn += '</a>';
          //   if (data.Status !== 'Reviewed') {
          //     btn += '<a href="' + data.FileURL + '" class="m-1" title="Download This Document">';
          //     btn += '<i class="mdi mdi-file-download font-18 text-secondary" aria-hidden="false"></i>';
          //     btn += '</a>';
          //   }
          // }
          $('td:eq(3)', row).html(btn);
        }, drawCallback: () => {
          $('.uploadDocument').on('click', (e) => {
            this.onUploadDocument($(e.target).attr('DocumentType'), $(e.target).attr('DocumentSubType'), $(e.target).attr('DocumentName'));
          });
          $('.viewDocument').on('click', (e) => {
            console.log($(e.target).attr('DocumentID'))
            this.onViewDocument($(e.target).attr('DocumentID'));
          });
          $('.requestDocument').on('click', (e) => {
            this.onRequestingDocument($(e.target).attr('receipt-id'));
          });
          $('.openDocument').on('click', (e) => {
            this.openImageDialog($(e.target).attr('id'))
          });
        }
      };
      this.dtOptions = {
        ajax: { url: this.service.GetBaseUrl() + `${this.applicationData.ApplicationStatus !== 'iPVR Sent' && this.applicationData.ApplicationStatus !== 'iPVR In Progress' ? 'tcc/loan/application/Documents/AppID/' : 'loan/application/Documents/AppID/'}${this.Route.snapshot.params.id}`}, responsive: true,
        columns: [{
          title: 'Sr No.', data: 'row', render: (data, type, row, meta) => {
            return meta.row + 1;
          }
        }, {
          title: 'Document',
          data: 'DocumentName',
        }, {
          title: 'Status',
          render: (data, type, row) => {
            // if (row.Status === 'Pending') {
            //   return `<span class="badge badge-danger p-1">${row.Status}</span>`;
            // } else if (row.Status === 'Under Review') {
            //   return `<span class="badge badge-primary p-1">${row.Status}</span>`;
            // } else {
            //   return `<span class="badge badge-success p-1">${row.Status}</span>`;
            // }
            if (row.Status === 'Pending') {
              return `<span class="badge badge-primary p-1">${row.Status}</span>`;
            } else {
              return `<span class="badge badge-success p-1">${row.Status}</span>`;
            }
          }
        }, {
          title: 'Action',
          data: null
        }, {
          title: 'Comment',
          render: (data, type, row) => {
            let comment = '';
            if(row.DocumentComment != undefined ) {
              row.DocumentComment.forEach(element => {
                comment += element.Comment + '<br>';
              });
              return comment;
            }
            else {
              return '';
            }
          },
        }
        ], 
        columnDefs: [{targets:3,orderable:false}],
        rowCallback(row, data: any) {
          let btn = '';
          if (data.FileURL === null) {
            btn += '<a href="javascript:void(0)" class="uploadDocument m-1" title="Upload Document" DocumentSubType="' + data.DocumentSubType + '" DocumentType="' + data.DocumentType + '" DocumentName="' + data.DocumentName + '">';
            btn += '<i class="mdi mdi-file-upload-outline font-18 text-secondary" aria-hidden="false" DocumentSubType="' + data.DocumentSubType + '" DocumentType="' + data.DocumentType + '" DocumentName="' + data.DocumentName + '"></i>';
            btn += '</a>';
          } else if (data.FileURL !== null && typeof(data.DocumentID) == 'object') {
            btn += '<a href="javascript:void(0)" class="viewDocument m-1" title="View Document" DocumentID="' + data.DocumentID[0] + '">';
            btn += '<i class="mdi mdi-eye font-18 text-secondary" aria-hidden="false" DocumentID="' + data.DocumentID[0] + '"></i>';
            btn += '</a>';
            if (data.Status !== 'Reviewed') {
              btn += '<a href="' + data.FileURL + '" class="m-1" title="Download This Document">';
              btn += '<i class="mdi mdi-file-download font-18 text-secondary" aria-hidden="false"></i>';
              btn += '</a>';
            }
          } else if (data.FileURL !== null && typeof(data.DocumentID) != 'object') {
            btn += '<a href="javascript:void(0)" class="viewDocument m-1" title="View Document" DocumentID="' + data.DocumentID + '">';
            btn += '<i class="mdi mdi-eye font-18 text-secondary" aria-hidden="false" DocumentID="' + data.DocumentID + '"></i>';
            btn += '</a>';
            if (data.Status !== 'Reviewed') {
              btn += '<a href="' + data.FileURL + '" class="m-1" title="Download This Document">';
              btn += '<i class="mdi mdi-file-download font-18 text-secondary" aria-hidden="false"></i>';
              btn += '</a>';
            }
          }
          $('td:eq(3)', row).html(btn);
        }, drawCallback: () => {
          $('.uploadDocument').on('click', (e) => {
            this.onUploadDocument($(e.target).attr('DocumentType'), $(e.target).attr('DocumentSubType'), $(e.target).attr('DocumentName'));
          });
          $('.viewDocument').on('click', (e) => {
            console.log($(e.target).attr('DocumentID'))
            this.onViewDocument($(e.target).attr('DocumentID'));
          });
          $('.requestDocument').on('click', (e) => {
            this.onRequestingDocument($(e.target).attr('receipt-id'));
          });
        }
      };
      if (this.applicationData.PVRDocumentID === null) {
        this.PVRDetailsLoaded = false;
        this.statusChange();
      } else {
        this.service.GetPVRData(this.Route.snapshot.params.id).subscribe((PVRData) => {
          this.PVRDetailsLoaded = true;
          this.PVRData = PVRData.data;
        });
      }
      if (this.applicationData.LawyerID) {
        this.service.viewLawyer(this.applicationData.LawyerID).subscribe((lawyerInfo) => {
          this.lawyerInfo = lawyerInfo.data[0];
          this.IsLawyerLoaded = true;
        });
      }
      if(this.applicationData.ApplicationStatus !== 'iPVR Sent' && this.applicationData.ApplicationStatus !== 'iPVR In Progress') {
        this.getLawyerDetails(this.applicationData.LawyerID);
        this.service.GetTitleClearDocumentList(this.Route.snapshot.params.id).subscribe((Response) => {
          this.propertyDocumentData = Response.data;
          if (this.propertyDocumentData.length > 0) {
            this.propertyDocumentData.filter((data) => {
              if(data.isMandatory == true) {
                this.totaldocument++;
              if (data.Status === 'Pending') {
                this.pending++;
              } else {
                this.received++;
              }
              }
            });
          }
          this.LoadChart();
          this.loaded = true;
        });
      } else {
        this.service.GetDocumentList(this.Route.snapshot.params.id).subscribe((Response) => {
          this.propertyDocumentData = Response.data;
          if (this.propertyDocumentData.length > 0) {
            this.propertyDocumentData.filter((data) => {
              this.totaldocument++;
              if (data.Status === 'Pending') {
                this.pending++;
              } else {
                this.received++;
              }
            });
          }
          this.LoadChart();
          this.loaded = true;
        });
      }
      
    });
    
  }
  statusChange() {
    this.interval = setInterval(() => {
      this.index++;
      if(this.statusList[this.index] != null){
      document.getElementById('status').innerHTML = this.statusList[this.index];
      }
      if ((this.index + 1) >= this.statusList.length) {
        clearInterval(this.interval);
      }
    }, 30000);
  }
  openViewDocumentDialog(){
    console.log("application data",this.applicationData);
      const dialogRef = this.dialog.open(ViewDocumentDialogComponent, {
      disableClose: true,
      width: '680px',
      height:'80vh',
      data: {'applicationData':this.applicationData}
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
  
  onUploadDocument(doctype, docsubtype, docname) {
    this.router.navigate(['loan/uploadlawyerdocument/' + this.applicationData.AppID], { queryParams: { DocumentType: doctype, DocumentSubType: docsubtype, DocumentName: docname} });
  }
  onViewDocument(id) {
    this.router.navigate(['/loan/viewdocument/' + this.applicationData.PropertyID + '/' + id + '/' + this.applicationData.AppID]);
  }
  onViewGPP(id) {
    this.router.navigate(['/loan/view-gpp/' + this.applicationData.AppID + '/' + this.applicationData.StateID]);
  }

  onViewLastPPP(id) {  
    this.firstName = this.applicationData.FirstName +' '+ this.applicationData.LastName;
    this.router.navigate(['/loan/view-last-ppp-document'],{state:{fileUrl: this.fileUrl, firstName:this.firstName,AppID: this.AppID}});
  }

  onViewTC(id) {
    // console.log("application data",this.applicationData);
    if( this.titleclearData && this.titleclearData.FileUrl){
      this.router.navigate(['/loan/viewTitleReport/' + this.applicationData.AppID]);
    }
  }
  viewDate(){
    this.view = true;
    // this.latestDate = new Date();
    console.log("line===304",this.latestDate)
  }
  viewDate_1(){
    this.view_1 = true;
    this.latestDate = new Date();
    console.log("line===304",this.latestDate)

  }

  onViewiPVR(id) {
    this.router.navigate(['/loan/viewTitleReport/' + this.applicationData.AppID]);
  }

  onRequestingDocument(id) {
    console.log('Requesting ' + id);

  }
  LoadChart() {
    this.revenueRadialChart = {
      chart: {
        height: 200,
        type: 'radialBar',
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: '65%',
          },
          dataLabels: {
            name: {
              show: false
            },
            value: {
              fontSize: '24px',
              color: 'rgb(29, 173, 56)',
              offsetY: 10,
              formatter: (val) => {
                return val + '';
              }
            }
          }
        }
      },
      colors: ['rgb(29, 173, 56)'],
      series: [this.totaldocument ? (((this.received >= this.totaldocument ? this.totaldocument : this.received) / this.totaldocument) * 100).toFixed() : 0],
      stroke: {
        lineCap: 'round',
      },
    };
  }
  EcChange() {
    this.loaded = false;
    this.service.ChangeECResponce(this.AppID).subscribe(() => {
      this.loaded = true;
      Swal.fire({
        title: 'Request Received',
        text: 'EC Request will be delivered shortly',
        type: 'success',
        showConfirmButton: false,
        timer:3000
      }).then(() => { location.reload() })

    })
  }
  TcChange() {
    // this.loaded = false;
    // this.service.ChangeECResponce(this.AppID).subscribe(() => {
    //   this.loaded = true;
    //   Swal.fire({
    //     title: 'Request Received',
    //     text: 'EC Request will be delivered shortly',
    //     type: 'success',
    //   }).then(() => { location.reload() })

    // })
  }
  openAssignLawyerDialog(): void {
    this.router.navigate(['loan/documents/' + this.AppID]);
  }
  openAssignValuerDialog(): void {
    this.router.navigate(['loan/valuationdocuments/' + this.AppID]);
  }
  getLawyerDetails(LawyerID) {
    this.loaded = false;
    this.service.GetUser(LawyerID).subscribe((res) => {
      this.loaded = true;
      this.LawyerDetails = res.data[0];
      console.log(this.LawyerDetails);
    });
  }
  updateAppStatus(status) {
    let data = {
      applicationStatus: status
    }
    this.loaded = true;
    console.log(data);
    this.service.updateAppStatus(this.AppID, data).subscribe((res) => {
      this.loaded = false;
    }, (error) => {
      this.loaded = false;
    });
  }
  openImageDialog(id) {
    this.router.navigate([`loan/View-Image/${id}/`+ this.AppID]);
    // const dialogRef = this.dialog.open(ImageModalComponent, {
    //   autoFocus: false,
    //   maxHeight: '6oopx',
    //   width: '600px',
    //   data: URL,
    // });
    // dialogRef.afterClosed().subscribe(result => {
    //   this.ngOnInit();
    // });
  }

  checkDocument(){
    this.service.getTitleCleardoc(this.AppID).subscribe((res: any) => {
      this.titleclearData = res.data[0];
      // console.log("title clear data",this.titleclearData);
      this.loaded = false;  
    });
  }
}
