import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs/operators';
import { GeneralService } from 'src/app/services/general.service';
import { AddPropertyComponent } from '../add-property/add-property.component';
import { MatDialog } from '@angular/material';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}


@Component({
  selector: 'app-echeck-dashboard',
  templateUrl: './echeck-dashboard.component.html',
  styleUrls: ['./echeck-dashboard.component.scss']
})
export class EcheckDashboardComponent implements OnInit {

  breadCrumbItems: Array<{}>;
  allStatus:any[] =['E-Check In Progress', 'E-Check Processed'];
  results:any[] = ['Change','No Change'];
  StateArray:any[] =[]
  selected: any;
  hidden: boolean = true;
  fromNGDate: NgbDate;
  toNGDate: NgbDate;
  @Input() fromDate: Date;
  @Input() toDate: Date;
  currentUser: any;
  filterobj: {
    FilterStartDate: string;
    FilterEndDate: string;
    ApplicationStatus: string;
    stateID:any;
    result:string;
  };
  dtOptions: DataTables.Settings = {};
  dtOptionsAdmin: DataTables.Settings = {};
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective | undefined;
  dtTrigger = new Subject<any>();
  isLoadingTable: boolean;
  inputValue: string = '';
  applications: any;
  constructor(
    private router: Router,
    private service: GeneralService,
    private datePipe: DatePipe,
    private http: HttpClient,
    private dialog: MatDialog,
    private renderer: Renderer2,
  ) {
    this.currentUser = this.service.getcurrentUser();
   }

  ngOnInit() {
    this.fetchstate();
    this.filterobj = {
      FilterStartDate: '',
      FilterEndDate: '',
      ApplicationStatus:'',
      stateID:'',
      result:'',
    };
    this.breadCrumbItems = [{ label: 'Dashboard', path: '/' },
    { label: 'E-Check Dashboard', path: '/', active: true }];
    // console.log("dtoption ",)
    this.dtOptions = {
      pagingType: "simple_numbers",
      pageLength: 10,
      serverSide: true,
      searching: false,
      ordering: false,
      // processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        // this.isLoadingTable = true;
        this.http
          .get<DataTablesResponse>(
            this.service.GetBaseUrl() +
              `getlist/remarks` +
              `?pageNo=${
                (dataTablesParameters.start + dataTablesParameters.length) /
                dataTablesParameters.length
              }` +
              `${
                dataTablesParameters.length.value != ""
                  ? "&limit=" + dataTablesParameters.length
                  : ""
              }` +
              `${
                this.inputValue != "" ? "&search=" + this.inputValue : ""
              }` +
              `${
                this.filterobj.FilterStartDate != ""
                  ? "&startdate=" + this.filterobj.FilterStartDate
                  : ""
              }` +
              `${
                this.filterobj.FilterEndDate != ""
                  ? "&enddate=" + this.filterobj.FilterEndDate
                  : ""
              }`+
              `${
                this.filterobj.ApplicationStatus != ""
                  ? "&status=" + this.filterobj.ApplicationStatus
                  : ""
              }`+
              `${
                this.filterobj.stateID != ""
                  ? "&stateID=" + this.filterobj.stateID
                  : ""
              }`+
              `${
                this.filterobj.result != ""
                  ? "&result=" + this.filterobj.result
                  : ""
              }` ,
             
            {}
          )
          .subscribe((resp: any) => {
            // console.log("line==199",resp)
            this.isLoadingTable = false;
            this.applications = resp.data.result;
            callback({
              recordsTotal: resp.data.TotalCount,
              recordsFiltered: resp.data.TotalCount,
              data: this.applications,
            });
          });
      },
      columns: [
        {
          title: "AppID",
          name: "AppID",
          data: "AppID",
        },
        {
          title: "Application ID",
          name: "ApplicationID",
          data: "ApplicationID",
        },
        {
          title: "Status",
          name: "ApplicationStatus",
          data: "ApplicationStatus",
          render:(data, type, row)=>{
            if(data === null || data === undefined || data === 'null'){
              return '--'
            }
            else{
              return data ? data : '--'
            }
        }
        },
        {
          title: "Result",
          name: "result",
          data: "",
          render:(data, type, row)=>{
              if(data === null || data === undefined || data === 'null'){
                return '--'
              }
              else{
                return data ? data : '--'
              }
          }
        },
        {
          title: "Remarks",
          name: "Remarks",
          data: "",
          render:(data, type, row)=>{
            if(data === null || data === undefined || data === 'null'){
              return '--'
            }
            else{
              return data ? data : '--'
            }
        }
        },
        {
          title: "Action",
          data: null,
          render: function (data, type, row) {
              return '<a class="text-primary" title="View Owners" ><i class="expand-row fa fa-plus  title="Expand"></i></a>'+
              `<a class="text-primary" title="View Application"
              viewID = "${row.AppID}"><i class=" m-1 ml-3 mdi mdi-eye font-18 text-secondary" viewID = "${row.AppID}"  
              appData='${JSON.stringify(row.owners)}'  aria-hidden="false"></i></a>`;
          }
        },
      ],
      order: [[0, "desc"]],
      columnDefs: [
        { targets: 0, visible: true },
        { targets: 5, orderable: false },
      ],
    };

    this.dtOptionsAdmin = {
      pagingType: "simple_numbers",
      pageLength: 10,
      serverSide: true,
      searching: false,
      ordering: false,
      // processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        // this.isLoadingTable = true;

        this.http
          .get<DataTablesResponse>(
            this.service.GetBaseUrl() +
              `getlist/remarks` +
              `?pageNo=${
                (dataTablesParameters.start + dataTablesParameters.length) /
                dataTablesParameters.length
              }` +
              `${
                dataTablesParameters.length.value != ""
                  ? "&limit=" + dataTablesParameters.length
                  : ""
              }` +
              `${
                this.inputValue != "" ? "&search=" + this.inputValue : ""
              }` +
              `${
                this.filterobj.FilterStartDate != ""
                  ? "&startdate=" + this.filterobj.FilterStartDate
                  : ""
              }` +
              `${
                this.filterobj.FilterEndDate != ""
                  ? "&enddate=" + this.filterobj.FilterEndDate
                  : ""
              }`+
              `${
                this.filterobj.ApplicationStatus != ""
                  ? "&status=" + this.filterobj.ApplicationStatus
                  : ""
              }`+
              `${
                this.filterobj.stateID != ""
                  ? "&stateID=" + this.filterobj.stateID
                  : ""
              }`+
              `${
                this.filterobj.result != ""
                  ? "&result=" + this.filterobj.result
                  : ""
              }` ,
             
            {}
          )
          .subscribe((resp: any) => {
            this.isLoadingTable = false;
            this.applications = resp.data.result;
            callback({
              recordsTotal: resp.data.TotalCount,
              recordsFiltered: resp.data.TotalCount,
              data: this.applications,
            });
          });
      },
      
      columns: [
        {
          title: "AppID",
          name: "AppID",
          data: "AppID",
        },
        {
          title: "Application ID",
          name: "ApplicationID",
          data: "ApplicationID",
        },
        {
          title: "Status",
          name: "ApplicationStatus",
          data: "ApplicationStatus",
          render:(data, type, row)=>{
            if(data === null || data === undefined || data === 'null'){
              return '--'
            }
            else{
              return data ? data : '--'
            }
        }
        },
        {
          title: "Result",
          name: "result",
          data: "",
          render:(data, type, row)=>{
              if(data === null || data === undefined || data === 'null'){
                return '--'
              }
              else{
                return data ? data : '--'
              }
          }
        },
        {
          title: "Remarks",
          name: "Remarks",
          data: "",
          render:(data, type, row)=>{
            if(data === null || data === undefined || data === 'null'){
              return '--'
            }
            else{
              return data ? data : '--'
            }
        }
        },
        {
          title: "Action",
          data: null,
          render: function (data, type, row) {
              return '<a class="text-primary" title="View Owners" ><i class="expand-row fa fa-plus  title="Expand"></i></a>'+
              `<a class="text-primary" title="View Application"
              viewID = "${row.AppID}"><i class=" m-1 ml-3 mdi mdi-eye font-18 text-secondary" viewID = "${row.AppID}"  
              appData='${JSON.stringify(row.owners)}'  aria-hidden="false"></i></a>
              <a title='Add Property'>  <i class="mdi mdi-plus-box" AppID = "${row.AppID}"></i></a>
`;
          }
        },
      ],
      order: [[0, "desc"]],
      columnDefs: [
        { targets: 0, visible: true },
        { targets: 5, orderable: false },
      ],
    };
    

  }

  onSearch() {
    this.rerender();
  }
  onchangeSearch() {
    // console.log(this.inputValue);
    if (this.inputValue != "") {
      return;
    }
    this.rerender();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  } 
  ngAfterViewInit(): void {
    this.dtTrigger.next();
    this.renderer.listen('document', 'click', (event) => {
      if(event.target.hasAttribute('AppID')){
        localStorage.setItem('AppID',event.target.getAttribute('AppID'))
        this.router.navigate(['/e-check/add-property'], {state:{appID: event.target.getAttribute('AppID')}});
      }
      if (event.target.hasAttribute('viewID')) {
        let appData = JSON.parse(event.target.getAttribute('appData'));
        // console.log("appdata",appData);
        localStorage.setItem('AppID',event.target.getAttribute('viewID'))
        localStorage.setItem('owners', event.target.getAttribute('appData'))
        this.router.navigate(['/e-check/view-echeck-application'], {state:{appData: appData}});
      }
      if (event.target.classList.contains('expand-row')) {
        const iconElement = event.target as HTMLElement;
        const row = iconElement.closest('tr');
        
        if (row && this.dtElement && this.dtElement.dtInstance) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            const table = dtInstance;
            const tableRow = table.row(row);
            const rowData = tableRow.data();
  
            if (tableRow.child.isShown()) {
              // Collapse the row
              tableRow.child.hide();
              iconElement.classList.remove('fa-minus');
              iconElement.classList.add('fa-plus');
            } else {
              // Expand the row
              tableRow.child(this.formatChildRow(rowData)).show();
              iconElement.classList.remove('fa-plus');
              iconElement.classList.add('fa-minus');
            }
          });
        }
      }
    });

  }
  private formatChildRow(rowData: any): string {
    const childRowData = rowData.owners;
    // console.log("child row data",rowData);
    let childRowHtml = '<table style="width:100%">';
    childRowHtml += '<thead >';
    childRowHtml += '<tr style="background-color:#d6d6dc">';
    childRowHtml += '<th>Owner ID</th>';
    childRowHtml += '<th>Owner Name</th>';
    childRowHtml += '<th>Shares</th>';
    childRowHtml += '</tr>';
    childRowHtml += '</thead>';
    childRowHtml += '<tbody>';
  
    // Iterate over the child row data and generate table rows
    if (childRowData.length > 0) {
      childRowData.forEach((row: any, index: number) => {
        const rowColor = index % 2 === 0 ? 'white' : '#F1F5F7';
        childRowHtml += '<tr style="background-color:' + rowColor + '">';
        childRowHtml += '<td>' + row.owner_id + '</td>';
        childRowHtml += '<td>' + row.owner_name + '</td>';
        childRowHtml += '<td>' + row.Share + '</td>';
        childRowHtml += '</tr>';
      });
    } else {
      childRowHtml += '<tr><td colspan="3">No Data Available</td></tr>';
    }
    
    childRowHtml += '</tbody>';
    childRowHtml += '</table>';
  
    return childRowHtml;
  }

  onDateSelection(date: NgbDate) {
    this.filterobj.FilterStartDate = "";
    this.filterobj.FilterEndDate = "";
    if (!this.fromDate && !this.toDate) {
      this.fromNGDate = date;
      this.fromDate = new Date(date.year, date.month - 1, date.day);
      this.selected = "";
    } else if (this.fromDate && !this.toDate && date.after(this.fromNGDate)) {
      this.toNGDate = date;
      this.toDate = new Date(date.year, date.month - 1, date.day);
      this.hidden = true;
      this.selected =
        this.fromDate.toLocaleDateString() +
        "-" +
        this.toDate.toLocaleDateString();
      this.filterobj.FilterStartDate = this.datePipe.transform(
        this.fromDate,
        "yyyy-MM-dd"
      );
      this.filterobj.FilterEndDate = this.datePipe.transform(
        this.toDate,
        "yyyy-MM-dd"
      );
      // this.dateRangeSelected.emit({
      //   fromDate: this.fromDate,
      //   toDate: this.toDate,
      // });
      this.fromDate = null;
      this.toDate = null;
      this.fromNGDate = null;
      this.toNGDate = null;
    } else {
      this.fromNGDate = date;
      this.fromDate = new Date(date.year, date.month - 1, date.day);
      this.selected = "";
    }
    ;
    if( this.filterobj.FilterStartDate !== "" && this.filterobj.FilterEndDate !== "" ){
      this.rerender();
    }
  } 

  callback() {
    this.selected = "";
    this.filterobj.FilterStartDate = "";
    this.filterobj.FilterEndDate = "";
    // this.filterCount()
    this.hidden = true;
  }
  onstatusChange(e) {
    if (e === undefined) {
      this.filterobj.ApplicationStatus = '';
    } else {
      this.filterobj.ApplicationStatus = e;
    }
    this.rerender();
  }

  onResultChange(e) {
    if (e === undefined) {
      this.filterobj.result = '';
    } else {
      this.filterobj.result = e;
    }
    this.rerender();
  }


  onstateChange(e) {
    if (e === undefined) {
      this.filterobj.stateID = '';
    } else {
      
      this.filterobj.stateID = e;
      // console.log("line==697",e)
    }
    this.rerender();
  }

  
  fetchstate() {
    // this.Loading = true
    this.service
      .statesList(this.currentUser.UserID)
      .pipe(first())
      .subscribe((data: any) => {
        // console.log("line==457",data)
        // this.Loading = false;
        if (data.error) {
          // console.log(data.error);
          return;
        } else {
          this.StateArray = data.data;
        }
      });
  }

}
