import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-view-document-dialog',
  templateUrl: './view-document-dialog.component.html',
  styleUrls: ['./view-document-dialog.component.scss']
})
export class ViewDocumentDialogComponent implements OnInit {
  breadCrumbItems: Array<{}>;
  fileUrl:any;
  constructor( @Inject(MAT_DIALOG_DATA) public data: any,private sanitizer:DomSanitizer,
  public dialogRef: MatDialogRef<ViewDocumentDialogComponent>
  ) { }

  ngOnInit() {
    this.breadCrumbItems = [{ label: 'Dashboard', path: 'loan' }, { label: 'Applications', path: '/loan/post-mortgage-applications' },
        { label: 'View Document', path: '/', active: true }];
    this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.applicationData.FileURL) ;
  }
  closeDialog(){
    localStorage.removeItem('DocumentIndex');
    localStorage.removeItem('PropertyIndex');
    localStorage.removeItem('isFromAddIPVR');
    localStorage.removeItem('ApplicationStatus');
    // localStorage.removeItem('AppID');
    this.dialogRef.close();
  }
}
