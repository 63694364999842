import { DatePipe } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  Renderer2,
  AfterViewInit,
  OnChanges,
  OnDestroy,
} from "@angular/core";
import { TooltipPosition } from '@angular/material/tooltip';

import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { Router } from "@angular/router";
import { DataTableDirective } from "angular-datatables";
import { NgbDate, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subject } from "rxjs";
import { GeneralService } from "src/app/services/general.service";
import Swal from "sweetalert2";

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}
interface BreadcrumbItem {
  label: string;
  path: string;
  active?: boolean;
}

@Component({
  selector: 'app-question-master',
  templateUrl: './question-master.component.html',
  styleUrls: ['./question-master.component.scss']
})

export class QuestionMasterComponent implements OnInit, AfterViewInit, OnDestroy {
  breadCrumbItems: Array<{}>;
  currentUser: any;
  dtOptions: DataTables.Settings = {};
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtTrigger = new Subject<any>();
  inputValue: string = "";
  isLoadingTable: boolean;
  isLoading: boolean;
  applications: any;
  constructor(
    private router: Router,
    private service: GeneralService,
    private renderer: Renderer2,
    private http: HttpClient
  ) {}

  ngOnInit() {
    this.isLoading = false;
    this.breadCrumbItems = [
      { label: 'Dashboard', path: '/loan/dashboard' },
      { label: 'Applications', path: '/applications ', active: true },
    ];
    this.currentUser = this.service.getcurrentUser();
    this.dtOptions = {
      pagingType: "simple_numbers",
      pageLength: 10,
      serverSide: true,
      searching: false,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        this.isLoadingTable = true;
        this.http
          .get<DataTablesResponse>(
            this.service.GetBaseUrl() +
            `get/TSR/question` +  `?` +
            `pageNo=${(dataTablesParameters.start + dataTablesParameters.length) /
            dataTablesParameters.length
            }` +
            `${dataTablesParameters.length.value != ""
              ? "&limit=" + dataTablesParameters.length
              : ""
            }` +
            `${this.inputValue != "" ? "&searchkey=" + this.inputValue : ""
            }` ,
            {}
          )
          .subscribe((resp: any) => {
            this.isLoadingTable = false;
            this.applications = resp.data;
            callback({
              recordsTotal: resp.allCount,
              recordsFiltered: resp.count,
              data: this.applications,
            });
          });
      },
      columns: [
        {
          title: 'Sr No.', data: 'row', render: (data, type, row, meta) => {
            return meta.row + 1;
          }
        },
       
        {
          title: "Question",
          name: "Question",
          data: "Question",
        },
        {
          title: "Is Mandatory",
          name: "IsMendatory",
          data: "IsMendatory",
          render:(data)=>{
            if (data.IsMendatory === true) {
              return `<span class="badge badge-success p-1">Yes</span>`
            }
            else{
              return `<span class="badge badge-danger p-1">No</span>`
            }
          }
        },
        {
          title: "Answer Type",
          name: "AnswerType",
          data: "AnswerType",
        },
        {
          title: "Action",
          data: null,
          render:data =>{
            // console.log('data',data);
            if(data.IsActive){
              return ` <i class="mdi mdi-check-circle TSRQuestionMasterId" style="font-size: 24px; color: green;cursor:pointer " title='Inactive Question'
                      TSRQuestionMasterId = "${data.TSRQuestionMasterId}" IsActive=0 ></i>`
            }
            else{
              return  ` <i class="mdi mdi-close-circle TSRQuestionMasterId" style="font-size: 24px; color: red;cursor:pointer" title='Active Question'
               TSRQuestionMasterId = "${data.TSRQuestionMasterId}" IsActive=1></i>`
            }
          }
         
        },
      ],
      order: [[0, "desc"]],
      columnDefs: [
        { targets: 0, visible: true },
        { targets: 2, orderable: false },
      ],
      drawCallback: () => {
        $('.TSRQuestionMasterId').on('click', (e) => {
          // this.onUploadDocument($(e.target).attr('DocumentType'), $(e.target).attr('DocumentSubType'), $(e.target).attr('DocumentName'));
          let data = {
            QuestionMasterId:$(e.target).attr('TSRQuestionMasterId'),
            IsActive: Number($(e.target).attr('IsActive'))
          }
          console.log('data',$(e.target).attr('TSRQuestionMasterId'),$(e.target).attr('IsActive'))
          this.service.ActiveDeactiveQuestion(data).subscribe((res:any)=>{
            if (res.error) {
              this.isLoading = false;
              Swal.fire({
                title: res.error_code,
                text: res.message,
                type: 'error',
                showConfirmButton: false,
                timer: 3000
              });
              return;
            }
            else {
              this.isLoading = false;
              Swal.fire({
                title: 'Success',
                text: res.message,
                type: 'success',
                showConfirmButton: false,
                timer: 3000
              }).then(() => {
                this.rerender()
                // this.router.navigate(['/loan/Question-Master']);
              });
            }
          })
        });
      
      }
    };
    
  }

  renderData(data) {
    if (data === '' || data === null || data === 'null') {
      return '--';
    } else {
      return data;
    }
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
    this.renderer.listen("document", "click", (event) => {
    
      if (event.target.hasAttribute('TSRQuestionMasterId')) {
        console.log('tsr',event.target.getAttribute('TSRQuestionMasterId'))
        // let data = {
        //   QuestionMasterId:event.target.getAttribute('TSRQuestionMasterId'),
        //   IsActive: event.target.getAttribute('IsActive') ? 1:0
        // }
        // this.service.ActiveDeactiveQuestion(data).subscribe((res:any)=>{
        //   if (res.error) {
        //     this.isLoading = false;
        //     Swal.fire({
        //       title: res.error_code,
        //       text: res.message,
        //       type: 'error',
        //       showConfirmButton: false,
        //       timer: 3000
        //     });
        //     return;
        //   }
        //   else {
        //     this.isLoading = false;
        //     Swal.fire({
        //       title: 'Success',
        //       text: res.message,
        //       type: 'success',
        //       showConfirmButton: false,
        //       timer: 3000
        //     }).then(() => {
        //       this.rerender()
        //       // this.router.navigate(['/loan/Question-Master']);
        //     });
        //   }
        // })
      }
    });
  }


 
  ngOnDestroy() {
    this.renderer.destroy();
  }

  onSearch() {
    this.rerender();
  }
  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }
  AddQuestions(){
    this.router.navigate(['/loan/add-Questions'])
  }

 
}
